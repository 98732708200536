import React from 'react';
import { useSportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NInjuryPredisposition';
import { blueColor } from '../Sports';

const NAchillesTendonInjury = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useSportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Προδιάθεση τραυματισμών' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τον';

    if ('SCAT8' === filtered.ID) {
      iconPath = profileToIcon.kneeElbowInjury;
      url = profileToUrl.kneeElbowInjury;
    } else if ('SCAT9' === filtered.ID) {
      iconPath = profileToIcon.achillesTendonInjury;
      url = profileToUrl.achillesTendonInjury;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('SCAT10' === filtered.ID) {
      iconPath = profileToIcon.musculoskeletalHealth;
      url = profileToUrl.musculoskeletalHealth;
    } else if ('SCAT11' === filtered.ID) {
      iconPath = profileToIcon.recoveryAfterExercise;
      url = profileToUrl.recoveryAfterExercise;
    }

    return { ...filtered, icon: iconPath, url, prefix };
  });

  const tab = {
    color: blueColor,
    id: 'sportsPage',
    title: 'Άθληση',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ sportsCard } activePage='sportsInjuryPredisposition' isNutrition />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Τραυματισμός αχίλλειου τένοντα'
        activeColor={ sportsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
      />
    </div>
  );
};

export default NAchillesTendonInjury;
