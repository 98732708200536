/* eslint-disable no-unused-vars */
import React, { useState, useMemo, useEffect } from 'react';
import PublishIcon from '@material-ui/icons/Publish';
import { makeStyles } from '@material-ui/core/styles';
import CheckIcon from '@material-ui/icons/Check';
import {
  Grid,
  Icon,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import AppTable from 'components/app/AppTable';
import useMediaQueries from 'hooks/media-query-hooks';
import { useTranslation } from 'react-i18next';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { useDoctorIsAbleToGiveConsent } from 'components/layout/doctor-dashboard/navigationPages';
import { results } from 'pages/Results';
import useAPI from 'hooks/api-hooks';

export const useStyles = makeStyles((_theme) => {
  return {
    filterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#E5E5E5',
      position: 'relative',
      paddingLeft: '33px',
      textTransform: 'none',
    },
    checkedFilterButton: {
      borderRadius: '100px',
      color: '#3B3B3B',
      fontWeight: 'normal',
      background: '#D0D0D0',
      position: 'relative',
      '&:hover': {
        background: '#D0D0D0',
      },
      paddingLeft: '33px',
      textTransform: 'none',
    },
    iconOnLeft: {
      left: '13px',
      position: 'absolute',
      top: 0,
      bottom: 0,

      height: '20px',
      width: '20px',

      marginTop: 'auto',
      marginBottom: 'auto',
    },
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const ResultsTable = ({ kits, search, setSearch, isLoading, pagination, refetch }) => {
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();
  const { setSerial: set3To1Serial } = useOptionSerial('3-in-1');
  const { setSerial: setCardioSerial } = useOptionSerial('IDNA Cardio Health');

  const [ changePage, setChangePage ] = useState(0);

  useEffect(() => {
    if (0 < changePage) {
      navigateToPage({ pageId: 'registerNewUpgradableKit' });
    }
  }, [ changePage ]);

  const isPgxDoctor = useDoctorIsAbleToGiveConsent();

  const allKits = useMemo(() => {
    return kits?.filter((kit) => {
      return null != kit.thematic_package && Object.prototype.hasOwnProperty.call(kit.thematic_package, 'id')
      && 2 > kit.ancestor_id.length;
    })
      .map((kit, id) => {
        const foundKit = results.find((result) => {
          return result.type === kit.thematic_package.thematic_category_id.name
      || result.alt_type === kit.thematic_package.thematic_category_id.name || result.other_alt_type === kit.thematic_package.thematic_category_id.name;
        });

        let type = kit.thematic_package.name;
        if ('Cardio Health' === type || 'iDNA Cardio Health' === type || 'iDNA Cardio Health kit' === type) {
          type = 'IDNA Cardio Health';
        }

        return {
          id,
          kitId: kit.id,
          serialNumber: kit.serial_number_id,
          product: type,
          serial: kit.serial_number_id.serial,
          navTitle: foundKit?.navTitle,
          icon: foundKit?.icon,
          title: foundKit?.title,
          style: foundKit?.style,
          fields: foundKit?.fields,
          status: 'active',
          pdfAvailable: kit.pdf_available,
          registrationStatus: kit.registrationStatus,
          sendReportStatus: kit.sendReportStatus,
          familyName: kit.customer_id?.family_name ?? '',
          firstName: kit.customer_id?.name ?? '',
          email: kit.customer_id?.email ?? '',
          customerId: kit.customer_id?.id,
          children: kit.ancestor_id,
        };
      });
  }, [ kits, isLoading ]);

  const tableColumns = [
    { id: 1,
      label: t('product'),
      sortable: false,
      field: 'product',
      customRenderer({ row }) {
        const foundResult = results.find((result) => {
          return result.type === row.product
      || result.alt_type === row.product || result.other_alt_type === row.product;
        });

        if (!foundResult) {
          return null;
        }

        return (
          <div>
            {foundResult.title}
            <img
              src={ foundResult.icon }
              alt={ foundResult.title }
              style={ { width: '100px', height: '100px' } }
            />
          </div>
        );
      } },
    { id: 2, label: 'Serial Number', field: 'serial', sortable: false },
    { id: 3, label: isPgxDoctor ? t('patientFirstName') : t('patientClientFirstName'), field: 'firstName', sortable: false },
    { id: 4, label: isPgxDoctor ? t('patientLastName') : t('patientClientLastName'), field: 'familyName', sortable: false },
    { id: 5, label: isPgxDoctor ? t('patientEmail') : t('patientClientEmail'), field: 'email', sortable: false },
    { id: 6,
      label: t('Αναβάθμιση kit'),
      sortable: false,
      field: 'a',
      customRenderer({ row }) {
        const foundResult = results.find((result) => {
          return result.type === row.product
      || result.alt_type === row.product || result.other_alt_type === row.product;
        });

        if (!foundResult) {
          return null;
        }

        const hasCardio = row.children.find((s) => { return 'iDNA Cardio Health kit' === s.thematic_package.name; });
        const has3In1 = row.children.find((s) => { return '3-in-1 kit' === s.thematic_package.name; });

        return (
          <div style={ { display: 'flex',
            gap: '8px',
            flexWrap: 'wrap' } }
          >
            {
              !has3In1 && (
                <Button
                  variant='outlined'
                  color='secondary'
                  startIcon={ (<PublishIcon />) }
                  style={ {
                    color: 'rgb(82, 109, 156)',
                    fontSize: '14px',
                  } }
                  onClick={ () => {
                    set3To1Serial(row.kitId);
                    setCardioSerial(undefined);
                    setChangePage((prev) => { return prev + 1; });
                  } }
                >
                  {t('Αναβάθμιση σε iDNA Test 3σε1')}
                </Button>
              )
            }

            {
              !hasCardio && (
                <Button
                  variant='outlined'
                  color='secondary'
                  startIcon={ <PublishIcon /> }
                  style={ {
                    color: 'rgb(82, 109, 156)',
                    fontSize: '14px',
                  } }
                  onClick={ () => {
                    set3To1Serial(undefined);
                    setCardioSerial(row.kitId);
                    setChangePage((prev) => { return prev + 1; });
                  } }
                >
                  {t('Αναβάθμιση σε iDNA Cardio Health')}
                </Button>
              )
            }

          </div>
        );
      } },
  ];

  return (
    <AppTable
      refetch={ refetch }
      isDoctor
      columns={ tableColumns }
      data={ allKits }
      loading={ isLoading }
      searchProps={ {
        search,
        setSearch,
      } }
      paginationProps={ pagination }
    />
  );
};

const DoctorKitUpgrade = () => {
  const classes = useStyles();

  const { isMd } = useMediaQueries();
  const { t } = useTranslation();

  const [ search, setSearch ] = useState('');

  const [ filterQuery, setFilterQuery ] = useState({
    'thematic_package.thematic_category_id.name_eq': 'Nutrition',
    registrationStatus_eq: 'done',
    'serial_number_id.ancestor_null': true,
  });

  useEffect(() => {
    let searchQueryKey = {
      'thematic_package.thematic_category_id.name_eq': 'Nutrition',
      registrationStatus_eq: 'done',
      _upgradeable: true,
      'serial_number_id.ancestor_null': true,
    };

    if ('' !== search) {
      searchQueryKey = {
        _where: {
          _or: [
            [{ 'serial_number_id.serial_contains': search }],
            [{ customer_id_null: false }, { 'customer_id.family_name_contains': search }],
          ],
          'thematic_package.thematic_category_id.name_eq': 'Nutrition',
          registrationStatus_eq: 'done',
          _upgradeable: true,
          'serial_number_id.ancestor_null': true,
        },
      };
    }

    setFilterQuery({
      ...searchQueryKey,
    });
  }, [ search ]);

  const {
    items: kits,
    itemsLoading: isLoading,
    pagination,
    refetch,
  } = useItems(CollectionKeys.DoctorSortedKits, { defaultPagination: { limit: 5 }, filterQuery });

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {t('Αναβάθμιση kit')}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 }>
        <Grid item xs={ 12 }>
          <ResultsTable
            pagination={ pagination }
            kits={ kits }
            search={ search }
            setSearch={ setSearch }
            refetch={ refetch }
            isLoading={ isLoading }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorKitUpgrade;
