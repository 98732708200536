import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useEffect } from 'react';
import * as _ from 'lodash';
import { useQueryParams } from './query-params-hook';
import useAPI from '../../hooks/api-hooks';
import { usePrevious } from '../../hooks/util-hooks';

export const useItems = (collectionName, options = {}) => {
  const defaultOptions = {
    queryOptions: {
      enabled: true,
    },
    invalidationKeys: [],
    filterQuery: {},
    defaultPagination: {
      limit: 10,
      start: 0,
      sort: { order: 'desc', orderBy: 'updated_at' },
    },
  };

  const opts = _.merge(defaultOptions, options);

  const { client: axiosClient } = useAPI();

  const {
    page,
    setPage,
    limit,
    setLimit,
    sort,
    setSort,
  } = useQueryParams(opts.defaultPagination);

  const prevFilterQuery = usePrevious(opts.filterQuery);
  useEffect(() => {
    if (!_.isEqual(prevFilterQuery, opts.filterQuery)) {
      setPage(0);
    }
  }, [ opts ]);

  const makeAxiosParams = (
    {
      page: p,
      limit: l,
      sort: s,
    },
  ) => {
    const _limit = l;
    const _start = -1 === _limit ? 0 : _limit * p; // in case limit = -1
    const _sort = `${ s.orderBy }:${ s.order.toUpperCase() }`;

    return {
      _start,
      _limit,
      _sort,
    };
  };

  const endpoint = `${ collectionName }`;

  const invalidationKeys = [
    `${ collectionName }`,
    ...opts.invalidationKeys,
  ];

  const queryClient = useQueryClient();

  const invalidateKeys = () => {
    const invalidationPromises = invalidationKeys.map((k) => {
      return queryClient.invalidateQueries([ k ]);
    });

    return Promise.all(invalidationPromises);
  };

  const {
    data: items,
    isLoading: itemsLoading,
    error: itemsError,
    refetch: refetchItems,
  } = useQuery(
    [ `${ collectionName }`, { page, limit, sort, ...opts.filterQuery }],
    () => {
      return axiosClient.get(endpoint, {
        params: {
          ...makeAxiosParams({ page, limit, sort }),
          ...opts.filterQuery,
        },
      });
    },
    {
      ...opts.queryOptions,
    },
  );

  const {
    data: count,
    isLoading: countIsLoading,
    refetch: refetchCount,
  } = useQuery(
    [ `${ collectionName }`, 'COUNT' ],
    () => {
      return axiosClient.get(`${ endpoint }/count`);
    },
    {
      ...opts.queryOptions,
    },
  );

  const {
    data: countFiltered,
    isLoading: countFilteredIsLoading,
    refetch: refetchCountFiltered,
  } = useQuery(
    [ `${ collectionName }`, 'COUNT', { ...opts.filterQuery }],
    () => {
      return axiosClient.get(`${ endpoint }/count`, {
        params: {
          ...opts.filterQuery,
        },
      });
    },
    {
      ...opts.queryOptions,
    },
  );

  // Prefetch the next page!
  useEffect(() => {
    if ([ countFiltered, page, limit ].some((metric) => { return 'number' !== typeof metric; })) {
      return;
    }
    const nextPage = page + 1;
    const hasMore = countFiltered > nextPage * limit;
    if (hasMore) {
      queryClient.prefetchQuery(
        [ `${ collectionName }`, { page: nextPage, limit, sort, ...opts.filterQuery }],
        () => {
          return axiosClient.get(endpoint, {
            params: {
              ...makeAxiosParams({ page: nextPage, limit, sort }),
              ...opts.filterQuery,
            },
          });
        },
      );
    }
  }, [ countFiltered, page, limit, queryClient ]);

  // fix page
  useEffect(() => {
    if ([ countFiltered, page, limit ].some((metric) => { return 'number' !== typeof metric; })) {
      return;
    }
    const lastPossiblePage = -1 === limit ? 0 : Math.floor(Math.max(0, countFiltered - 1) / limit);

    if (lastPossiblePage < page) {
      setPage(lastPossiblePage);
    }
  }, [ countFiltered, page, limit ]);

  const {
    mutateAsync: createItem,
    isLoading: itemIsCreating,
  } = useMutation(
    [ `${ collectionName }` ],
    (payload) => {
      return axiosClient.post(endpoint, payload);
    },
    {
      onSettled() {
        return invalidateKeys();
      },
    },
  );

  const refetch = () => {
    return Promise.all([
      refetchItems(), refetchCount(), refetchCountFiltered(),
    ]);
  };

  return {
    refetch,
    items,
    itemsLoading,
    itemsError,
    count,
    countIsLoading,
    countFiltered,
    countFilteredIsLoading,
    createItem,
    itemIsCreating,
    pagination: {
      page,
      setPage,
      limit,
      setLimit,
      sort,
      setSort,
      count: countFiltered,
    },
  };
};
