import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl } from '../AthleticProfile';

const Strength = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'SCAT1', 'SCAT2', 'SCAT3', 'SCAT4', 'SCAT5', 'SCAT6' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τη';
    const description = 'SCAT2_description';

    if ('SCAT1' === filtered.ID) {
      iconPath = profileToIcon.stamina;
      url = profileToUrl.stamina;
    } else if ('SCAT2' === filtered.ID) {
      iconPath = profileToIcon.strength;
      url = profileToUrl.strength;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('SCAT3' === filtered.ID) {
      iconPath = profileToIcon.power;
      url = profileToUrl.power;
    } else if ('SCAT4' === filtered.ID) {
      iconPath = profileToIcon.aerobics;
      url = profileToUrl.aerobics;
    } else if ('SCAT5' === filtered.ID) {
      iconPath = profileToIcon.increaseMuscle;
      url = profileToUrl.increaseMuscle;
    } else if ('SCAT6' === filtered.ID) {
      iconPath = profileToIcon.wantToExercise;
      url = profileToUrl.wantToExercise;
    } else if ('SCAT7' === filtered.ID) {
      iconPath = profileToIcon.painTolerance;
      url = profileToUrl.painTolerance;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ sportsCard } activePage='athleticProfile' />
      <Result
        activePage='athleticProfile'
        sensitivityProfiles={ sensProfiles }
        activeProfile='Μυϊκή δύναμη'
        activeColor={ sportsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
      />
    </div>
  );
};

export default Strength;
