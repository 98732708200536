import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl, adviceImages } from '../DetoxificationCapacityAntioxidantNeeds';

const DetoxificationCapacity = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT1', 'VCAT1' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';
    const description = 'NCAT1_description';

    if ('NCAT1' === filtered.ID) {
      iconPath = profileToIcon.detoxificationCapacity;
      url = profileToUrl.detoxificationCapacity;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('VCAT1' === filtered.ID) {
      iconPath = profileToIcon.antioxidantNeeds;
      url = profileToUrl.antioxidantNeeds;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ vitaminsCard } activePage='detoxificationCapacityAntioxidantNeeds' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Ικανότητα Αποτοξίνωσης'
        activeColor={ vitaminsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.detoxificationCapacity }
      />
    </div>
  );
};

export default DetoxificationCapacity;
