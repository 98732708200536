import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import useDoctorNavigationPages from 'components/layout/doctor-dashboard/navigationPages';
import { useNavigation } from 'NavigationProvider';
import { useTranslation } from 'react-i18next';
import { Page, useStyles } from './WelcomePage';

const RegisterAndViewResultsPage = () => {
  const navigationPages = useDoctorNavigationPages();
  const classes = useStyles();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslation();

  const specificPages = useMemo(() => {
    return navigationPages.find((page) => {
      return 'register-and-view-folder' === page.id;
    })?.items?.filter((it) => {
      return true !== it.isMenuHidden;
    });
  }, [ navigationPages ]);

  const title = useMemo(() => {
    return navigationPages.find((page) => {
      return 'register-and-view-folder' === page.id;
    })?.title;
  }, [ navigationPages ]);

  if (null == specificPages || null == title) {
    return null;
  }

  return (
    <div style={ {
      background: '#F7F7F7',
      minHeight: '450px',
      padding: '10px 10px 30px 10px',
    } }
    >
      <Grid
        container
        spacing={ 2 }
      >
        <Grid item xs={ 12 }>
          <div className={ classes.titleCta }>
            <Typography variant='h6' color='textPrimary'>
              {t(title)}
            </Typography>
          </div>
        </Grid>
        {
          specificPages.map((page) => {
            const href = `/?pid=${ page.id }`;
            return (
              <Grid
                className={ classes.pageGridItem }
                key={ href }
                item
                xs={ 12 }
                md={ 6 }
                lg={ 6 }
                xl={ 4 }
                component={ true === page.isDisabled ? 'div' : 'a' }
                href={ href }
                onClick={
                  (e) => {
                    e.preventDefault();
                    if (true !== page.isDisabled) {
                      navigateToPage({ pageId: page.id });
                    }
                  }
                }
              >
                <Page pageInfo={ page } />
              </Grid>
            );
          })
        }
      </Grid>
    </div>
  );
};

export default RegisterAndViewResultsPage;
