import { useMutation, useQuery, useQueryClient } from 'react-query';
import * as _ from 'lodash';
import useAPI from '../../hooks/api-hooks';

export const useItem = (collectionName, itemId, options = {}) => {
  const defaultOptions = {
    invalidationKeys: [],
    queryOptions: {},
  };

  const { client: axiosClient } = useAPI();

  const opts = _.merge(defaultOptions, options);
  const endpoint = `${ collectionName }/${ itemId }`;

  const invalidationKeys = [
    `${ collectionName }`,
    ...opts.invalidationKeys,
  ];

  const queryClient = useQueryClient();

  const invalidateKeys = () => {
    const invalidationPromises = invalidationKeys.map((k) => {
      return queryClient.invalidateQueries([ k ]);
    });

    return Promise.all(invalidationPromises);
  };

  const {
    data: item,
    isLoading: itemIsLoading,
    error: itemError,
  } = useQuery(
    [ `${ collectionName }`, `${ itemId }`, 'GET' ],
    () => {
      return axiosClient.get(endpoint);
    },
    {
      ...opts.queryOptions,
    },
  );

  const {
    mutateAsync: updateItem,
    isLoading: itemIsUpdating,
  } = useMutation(
    [ `${ collectionName }`, `${ itemId }`, 'UPDATE' ],
    (payload) => {
      return axiosClient.put(endpoint, payload);
    },
    {
      onSettled() {
        return invalidateKeys();
      },
    },
  );

  const {
    mutateAsync: deleteItem,
    isLoading: itemIsDeleting,
  } = useMutation(
    [ `${ collectionName }`, `${ itemId }`, 'DELETE' ],
    () => {
      return axiosClient.delete(endpoint);
    },
    {
      onSettled() {
        return invalidateKeys();
      },
    },
  );

  return {
    item,
    itemIsLoading,
    itemError,
    updateItem,
    itemIsUpdating,
    deleteItem,
    itemIsDeleting,
  };
};
