import { FormControl, FormHelperText } from '@material-ui/core';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useTranslation } from 'react-i18next';

const noNumbersRegex = /^([^0-9]*)$/;

const SimpleInputFieldWithValue = (
  {
    name,
    defaultValue,
    label,
    type,
    autoComplete,
    error,
    helperText,
    required,
    readOnly,
    loading,
    validate = null,
    value,
    setValue,
    lettersOnly = false,
    upperCase = false,
    ...rest
  },
) => {
  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    setValue(defaultValue);
  }, [ defaultValue ]);

  const { t } = useTranslation();

  const isPostalCode = 'Postal Code' === label || 'Τ. Κ.' === label;

  return (
    <>
      <FormControl size='small' fullWidth error={ error }>
        <FormLabel color='secondary' component='legend'>
          { `${ label }${ required ? ' *' : '' }` }
        </FormLabel>
      </FormControl>
      <Grid container>
        <FormControl size='small' fullWidth error={ error }>
          <OutlinedInput
            disabled={ !!readOnly }
            color='secondary'
            inputProps={ { style: { textTransform: upperCase ? 'uppercase' : 'none' } } }
            margin='dense'
            value={ value }
            onChange={ (e) => {
              if (lettersOnly && !noNumbersRegex.test(e.target.value)) {
                return;
              }

              if (error && null !== validate) {
                validate(e.target.value);
              }

              setValue(e.target.value);
            } }
            type={ isPostalCode ? 'number' : type }
            name={ name }
            autoComplete={ autoComplete }
            endAdornment={ (
              <>
                {loading ? <CircularProgress color='inherit' size={ 20 } /> : null}
                {rest?.InputProps?.endAdornment ? rest.InputProps.endAdornment : null}
              </>
            ) }
            { ...rest }
          />
        </FormControl>
      </Grid>
      { error && (
        <FormControl>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }

    </>
  );
};

SimpleInputFieldWithValue.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  type: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

SimpleInputFieldWithValue.defaultProps = {
  defaultValue: '',
  type: 'text',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
  loading: false,
};

export default SimpleInputFieldWithValue;
