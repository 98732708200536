import { FormControl, FormHelperText, TextField } from '@material-ui/core';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import _ from 'lodash';
import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import FormLabel from '@material-ui/core/FormLabel';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';

function countryToFlag(isoCode) {
  return 'undefined' !== typeof String.fromCodePoint
    ? isoCode
      .toUpperCase()
      .replace(/./g, (char) => { return String.fromCodePoint(char.charCodeAt(0) + 127397); })
    : isoCode;
}

export const countries = [
  {
    value: 'US',
    name: 'USA',
    grName: 'ΗΝΩΜΕΝΕΣ ΠΟΛΙΤΕΙΕΣ',
  },
  {
    name: 'ANDORRA',
    value: 'AD',
    grName: 'ΑΝΔΟΡΡΑ',
  },
  {
    name: 'ALBANIA',
    value: 'AL',
    grName: 'ΑΛΒΑΝΙΑ',
  },
  {
    name: 'AUSTRIA',
    value: 'AT',
    grName: 'ΑΥΣΤΡΙΑ',
  },
  {
    name: 'ALAND ISLANDS',
    value: 'AX',
    grName: 'ΝΗΣΟΙ ΩΛΑΝΤ',
  }, {
    name: 'BOSNIA AND HERZEGOVINA',
    value: 'BA',
    grName: 'ΒΟΣΝΙΑ ΚΑΙ ΕΡΖΕΓΟΒΙΝΗ',
  }, {
    name: 'BELGIUM',
    value: 'BE',
    grName: 'ΒΕΛΓΙΟ',
  }, {
    name: 'BULGARIA',
    value: 'BG',
    grName: 'ΒΟΥΛΓΑΡΙΑ',
  }, {
    name: 'BELARUS',
    value: 'BY',
    grName: 'ΛΕΥΚΟΡΩΣΙΑ',
  }, {
    name: 'SWITZERLAND',
    value: 'CH',
    grName: 'ΕΛΒΕΤΙΑ',
  }, {
    name: 'CYPRUS',
    value: 'CY',
    grName: 'ΚΥΠΡΟ',
  }, {
    name: 'CZECH REPUBLIC',
    value: 'CZ',
    grName: 'ΤΣΕΧΙΑ',
  }, {
    name: 'GERMANY',
    value: 'DE',
    grName: 'ΓΕΡΜΑΝΙΑ',
  }, {
    name: 'DENMARK',
    value: 'DK',
    grName: 'ΔΑΝΙΑ',
  }, {
    name: 'ESTONIA',
    value: 'EE',
    grName: 'ΕΣΘΟΝΙΑ',
  }, {
    name: 'SPAIN',
    value: 'ES',
    grName: 'ΙΣΠΑΝΙΑ',
  }, {
    name: 'FINLAND',
    value: 'FI',
    grName: 'ΦΙΝΛΑΝΔΙΑ',
  }, {
    name: 'FAROE ISLANDS',
    value: 'FO',
    grName: 'ΝΗΣΟΙ ΦΕΡΟΕΣ',
  }, {
    name: 'FRANCE',
    value: 'FR',
    grName: 'ΓΑΛΛΙΑ',
  }, {
    name: 'UNITED KINGDOM',
    value: 'GB',
    grName: 'ΗΝΩΜΕΝΟ ΒΑΣΙΛΕΙΟ',
  }, {
    name: 'GUERNSEY',
    value: 'GG',
    grName: 'ΓΚΕΡΝΖΙ',
  }, {
    name: 'GREECE',
    value: 'GR',
    grName: 'ΕΛΛΑΔΑ',
  }, {
    name: 'CROATIA',
    value: 'HR',
    grName: 'ΚΡΟΑΤΙΑ',
  }, {
    name: 'HUNGARY',
    value: 'HU',
    grName: 'ΟΥΓΓΑΡΙΑ',
  }, {
    name: 'IRELAND',
    value: 'IE',
    grName: 'ΙΡΛΑΝΔΙΑ',
  }, {
    name: 'ISLE OF MAN',
    value: 'IM',
    grName: 'ΝΗΣΟΣ ΤΟΥ ΜΑΝ',
  }, {
    name: 'ICELAND',
    value: 'IS',
    grName: 'ΙΣΛΑΝΔΙΑ',
  }, {
    name: 'ITALY',
    value: 'IT',
    grName: 'ΙΤΑΛΙΑ',
  }, {
    name: 'JERSEY',
    value: 'JE',
    grName: 'ΒΑΙΛΑΤΟ ΤΟΥ ΤΖΕΡΣΕΙ',
  }, {
    name: 'LIECHTENSTEIN',
    value: 'LI',
    grName: 'ΛΙΧΤΕΝΣΤΑΙΝ',
  }, {
    name: 'LITHUANIA',
    value: 'LT',
    grName: 'ΛΙΘΟΥΑΝΙΑ',
  }, {
    name: 'LUXEMBOURG',
    value: 'LU',
    grName: 'ΛΟΥΞΕΜΒΟΥΡΓΟ',
  }, {
    name: 'LATVIA',
    value: 'LV',
    grName: 'ΛΕΤΟΝΙΑ',
  }, {
    name: 'MONACO',
    value: 'MC',
    grName: 'ΜΟΝΑΚΟ',
  }, {
    name: 'MOLDOVA, REPUBLIC OF',
    value: 'MD',
    grName: 'ΜΟΛΔΑΒΙΑ',
  }, {
    name: 'MACEDONIA, THE FORMER YUGOSLAV REPUBLIC OF',
    value: 'MK',
    grName: 'ΔΗΜΟΚΡΑΤΙΑ ΤΗΣ ΒΟΡΕΙΑΣ ΜΑΚΕΔΟΝΙΑΣ',
  }, {
    name: 'MALTA',
    value: 'MT',
    grName: 'ΜΑΛΤΑ',
  }, {
    name: 'NETHERLANDS',
    value: 'NL',
    grName: 'ΟΛΛΑΝΔΙΑ',
  }, {
    name: 'NORWAY',
    value: 'NO',
    grName: 'ΝΟΡΒΗΓΙΑ',
  }, {
    name: 'POLAND',
    value: 'PL',
    grName: 'ΠΟΛΩΝΙΑ',
  }, {
    name: 'PORTUGAL',
    value: 'PT',
    grName: 'ΠΟΡΤΟΓΑΛΙΑ',
  }, {
    name: 'ROMANIA',
    value: 'RO',
    grName: 'ΡΟΥΜΑΝΙΑ',
  }, {
    name: 'RUSSIAN FEDERATION',
    value: 'RU',
    grName: 'ΡΩΣΙΚΗ ΟΜΟΣΠΟΝΔΙΑ',
  }, {
    name: 'SWEDEN',
    value: 'SE',
    grName: 'ΣΟΥΗΔΙΑ',
  }, {
    name: 'SLOVENIA',
    value: 'SI',
    grName: 'ΣΛΟΒΕΝΙΑ',
  }, {
    name: 'SVALBARD AND JAN MAYEN',
    value: 'SJ',
    grName: 'ΣΒΑΛΜΠΑΡΝΤ ΚΑΙ ΓΙΑΝ ΜΑΓΙΕΝ',
  }, {
    name: 'SLOVAKIA',
    value: 'SK',
    grName: 'ΣΛΟΒΑΚΙΑ',
  }, {
    name: 'SAN MARINO',
    value: 'SM',
    grName: 'ΑΓΙΟΣ ΜΑΡΙΝΟΣ',
  }, {
    name: 'UKRAINE',
    value: 'UA',
    grName: 'ΟΥΚΡΑΝΙΑ',
  }, {
    name: 'HOLY SEE (VATICAN CITY STATE)',
    value: 'VA',
    grName: 'ΑΓΙΑ ΕΔΡΑ (ΚΡΑΤΟΣ ΤΗΣ ΠΟΛΗΣ ΤΟΥ ΒΑΤΙΚΑΝΟΥ)',
  }, {
    name: 'IDNA',
    value: 'IDNA',
    grName: 'IDNA',
  },
];

export const countryNameToCode = (name) => {
  return countries.find((co) => {
    return co.name === name || co.grName === name;
  })?.value;
};

const CountrySelectField = (
  {
    name,
    defaultValue,
    label,
    // autoComplete,
    error,
    helperText,
    required,
    readOnly,
    // loading,
    validate = null,
    onChange = null,
    // ...rest
  },
) => {
  const [ value, setValue ] = useState('');
  const { t, i18n } = useTranslation();

  const [ sortedCountries, setSortedCountries ] = useState([]);

  useEffect(() => {
    const copy = _.cloneDeep(countries);

    copy.sort((a, b) => {
      return t(a.name).localeCompare(t(b.name));
    });

    setSortedCountries(copy);
  }, [ i18n.language ]);

  const valueWithName = useMemo(() => {
    if ('' === value || null == value) {
      return null;
    }

    return countries.find((co) => {
      return co.value === value;
    });
  }, [ value ]);

  useEffect(() => {
    if (!defaultValue) {
      return;
    }
    setValue(defaultValue);
  }, [ defaultValue ]);

  const _filterOptions = createFilterOptions({
    matchFrom: 'any',
    stringify: (option) => { return t(option.name); },
  });

  return (
    <>
      <FormControl size='small' fullWidth error={ error }>
        <FormLabel color='secondary' component='legend' disabled={ !!readOnly }>
          { `${ label }${ required ? ' *' : '' }` }
        </FormLabel>
      </FormControl>
      <Grid container>
        <Autocomplete
          id='country-select-demo'
          options={ sortedCountries }
          disableClearable
          fullWidth
          autoHighlight
          getOptionLabel={ (option) => { return option.name; } }
          disabled={ !!readOnly }
          color='secondary'
          value={ valueWithName }
          noOptionsText={ t('No options') }
          filterOptions={ (allOpt, state) => {
            return allOpt.filter((opt) => {
              return 'IDNA' !== opt.name
              && t(opt.name).toLowerCase().includes(state.inputValue.toLowerCase());
            });
          } }
          onChange={ (event, newValue) => {
            if (error && null !== validate) {
              validate(newValue.value);
            }

            if (null != onChange) {
              onChange(newValue?.value);
            }

            return setValue(newValue?.value);
          } }
          renderOption={ (option) => {
            return (
              <>
                <span style={ { marginRight: '5px' } }>{'IDNA' !== option.value ? countryToFlag(option.value) : ''}</span>
                {t(`common.countries.${ option.name }`)}
              </>
            );
          } }
          renderInput={ (params) => {
            return (
              <TextField
                { ...params }
                variant='outlined'
                margin='dense'
                color='secondary'
                name={ name }
                fullWidth
                style={ {
                  marginTop: '0px',
                } }
                inputProps={ {
                  ...params.inputProps,
                  value: t(params.inputProps.value),
                  autoComplete: 'new-password', // disable autocomplete and autofill
                } }
              />
            );
          } }
        />

      </Grid>
      { error && (
        <FormControl>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }

    </>
  );
};

CountrySelectField.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
  loading: PropTypes.bool,
};

CountrySelectField.defaultProps = {
  defaultValue: '',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
  loading: false,
};

export default CountrySelectField;
