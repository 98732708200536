import React from 'react';

const WithLayout = (
  {
    layout: Layout,
    layoutProps,
    component: Component,
    props,
  },
) => {
  return (
    <Layout { ...layoutProps }>
      <Component { ...props } />
    </Layout>
  );
};

export default WithLayout;
