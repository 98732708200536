import React from 'react';
import { useSportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { blueColor } from './Sports';

export const profileToIcon = {
  stamina: { big: '/ANTOXI.jpg', small: '/ANTOXI_SMALL.jpg' },
  strength: { big: '/MYIKI DYNAMH.jpg', small: '/MYIKI DYNAMH_SMALL.jpg' },
  power: { big: '/ISXYS.jpg', small: '/ISXYS_SMALL.jpg' },
  aerobics: { big: '/AEROVIKI IKANOTITA (Vo2max).jpg', small: '/AEROVIKI IKANOTITA (Vo2max)_SMALL.jpg' },
  increaseMuscle: { big: '/IKANOTHTA ANAPTYXHS MYIKHS MAZAS.jpg', small: '/IKANOTHTA ANAPTYXHS MYIKHS MAZAS_SMALL.jpg' },
  wantToExercise: { big: '/KINHTRO GIA ASKISI.jpg', small: '/KINHTRO GIA ASKISI_SMALL.jpg' },
  painTolerance: { big: '/ANTOXI STON PONO.jpg', small: '/ANTOXI STON PONO_SMALL.jpg' },
};

export const profileToUrl = {
  stamina: 'sportsStamina',
  strength: 'sportsStrength',
  power: 'sportsPower',
  aerobics: 'sportsAerobics',
  increaseMuscle: 'sportsIncreaseMuscle',
  wantToExercise: 'sportsWantToExercise',
  painTolerance: 'sportsPainTolerance',
};

const NAthleticProfile = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useSportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Performance' === currData.type && 'Αθλητικό προφίλ' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('SCAT1' === filtered.ID) {
      iconPath = profileToIcon.stamina;
      url = profileToUrl.stamina;
    } else if ('SCAT2' === filtered.ID) {
      iconPath = profileToIcon.strength;
      url = profileToUrl.strength;
    } else if ('SCAT3' === filtered.ID) {
      iconPath = profileToIcon.power;
      url = profileToUrl.power;
    } else if ('SCAT4' === filtered.ID) {
      iconPath = profileToIcon.aerobics;
      url = profileToUrl.aerobics;
    } else if ('SCAT5' === filtered.ID) {
      iconPath = profileToIcon.increaseMuscle;
      url = profileToUrl.increaseMuscle;
    } else if ('SCAT6' === filtered.ID) {
      iconPath = profileToIcon.wantToExercise;
      url = profileToUrl.wantToExercise;
    } else if ('SCAT7' === filtered.ID) {
      url = profileToUrl.painTolerance;
      iconPath = profileToIcon.painTolerance;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: blueColor,
    id: 'sportsPage',
    title: 'Άθληση',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext options={ sportsCard } activePage='sportsAthleticProfile' isNutrition />
      <PhotoWithText
        options={ sportsCard }
        activePage='athleticProfile'
        text={ data[0].value.sports_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ sportsCard.color } />
    </div>
  );
};

export default NAthleticProfile;
