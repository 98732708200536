import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import React from 'react';
import { Checkbox, FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SimpleCheckbox = (
  {
    name,
    value,
    onChange,
    label,
    error,
    helperText,
    required,
    formStyle = {},
    labelStyle = {},
  },
) => {
  const { t } = useTranslation();

  return (
    <FormControl component='fieldset' style={ { ...formStyle } }>
      <FormControlLabel
        label={ `${ label }${ required ? ' *' : '' }` }
        style={ { ...labelStyle } }
        name={ name }
        control={ (
          <Checkbox
            onChange={ (event, checked) => {
              return onChange(checked);
            } }
            checked={ value }
            color='secondary'
            value={ value ? 'yes' : 'no' }
          />
        ) }
      />
      {error && (
        <FormHelperText margin='dense' error>
          { t(helperText) }
        </FormHelperText>
      ) }
    </FormControl>
  );
};

SimpleCheckbox.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
};

SimpleCheckbox.defaultProps = {
  error: false,
  helperText: null,
  required: false,
};

export default SimpleCheckbox;
