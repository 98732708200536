import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { IoMdRemoveCircleOutline } from 'react-icons/all';

const useClasses = makeStyles((theme) => {
  return {
    iconContainer: {
      borderRadius: 8,
      padding: theme.spacing(1),
      '&:hover $icon': {
        color: theme.palette.error.main,
      },
    },
    icon: {
    },
  };
});

const AppIconRemove = (props) => {
  const classes = useClasses();
  if (props.disabled) {
    return (
      <IconButton className={ classes.iconContainer } { ...props } aria-label='remove'>
        <IoMdRemoveCircleOutline className={ classes.icon } />
      </IconButton>
    );
  }

  return (
    <Tooltip title='Remove' aria-label='remove'>
      <IconButton className={ classes.iconContainer } { ...props } aria-label='Remove'>
        <IoMdRemoveCircleOutline className={ classes.icon } />
      </IconButton>
    </Tooltip>
  );
};

export default AppIconRemove;
