import React from 'react';
import { useVitaminsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NDetoxificationCapacityAntioxidantNeeds';
import { adviceImages } from '../../iDNA3ToOne/DetoxificationCapacityAntioxidantNeeds';
import { greenColor } from '../VitaminsPage';

const NAntioxidantNeeds = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useVitaminsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Ικανότητα αποτοξίνωσης & αντιοξειδωτικές ανάγκες' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τις';

    if ('NCAT1' === filtered.ID) {
      iconPath = profileToIcon.detoxificationCapacity;
      url = profileToUrl.detoxificationCapacity;
    } else if ('VCAT1' === filtered.ID) {
      iconPath = profileToIcon.antioxidantNeeds;
      url = profileToUrl.antioxidantNeeds;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    }

    return { ...filtered, icon: iconPath, url, prefix };
  });

  const tab = {
    color: greenColor,
    id: 'vitaminsPage',
    title: 'Βιταμίνες',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext
        options={ vitaminsCard }
        activePage='vitaminsDetoxificationCapacityAntioxidantNeeds'
        isNutrition
      />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Αντιοξειδωτικές ανάγκες'
        activeColor={ vitaminsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.antioxidantNeeds }
      />
    </div>
  );
};

export default NAntioxidantNeeds;
