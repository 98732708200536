import React from 'react';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import PDFViewer from '../../components/PDFViewer';

const NutriGenetixPdfVersion = () => {
  const { serial: newSerial } = useOptionSerial('nutriGenetix');
  const { activePageId } = useNavigation();

  console.log('activePageId', activePageId);

  return (
    <PDFViewer
      serial={ newSerial }
      nameSuffix={
        'nutriGenetixAnalysisPdfVersion' === activePageId ? '_analysis'
          : '_diet'
      }
    />
  );
};

export default NutriGenetixPdfVersion;
