import { useState } from 'react';
import { isValidPhoneNumber } from 'libphonenumber-js/';
import moment from 'moment';
import { isValidDate } from '../utils';

export function useFormRules() {
  const applyRules = (rules, v) => {
    const appliedRule = rules.find((rule) => {
      return 'string' === typeof rule(v);
    });
    return appliedRule === undefined ? false : appliedRule(v);
  };

  const requiredFieldRule = (v) => {
    return !!v || 'validation.requiredField';
  };

  const requiredPhoneRule = (v) => {
    return (!!v.phoneNumber && !!v.country) || 'validation.requiredField';
  };

  const promptPasswordRule = (v) => { return !!v || 'validation.requiredPass'; };

  const atLeastRule = (v, min) => { return (v.length && min <= v.length) || `${ 'validation.passwordError' }`; };

  const atMostRule = (v, max) => { return (v.length && max >= v.length) || `${ 'validation.noMore' } ${ max } ${ 'validation.characters' }`; };

  const atMost10Rule = (v) => { return /^[\d-]{1,10}$/.test(v) || 'validation.atMost10Characters'; };
  const elevenCharsRule = (v) => { return /^[\d-]{11}$/.test(v) || 'validation.elevenChars'; };
  const nineCharsRule = (v) => { return /^[\d-]{9}$|^$/.test(v) || 'validation.nineChars'; };

  // eslint-disable-next-line
  const emailRegex = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  const emailRule = (v) => { return emailRegex.test(v) || 'validation.emailNoValid'; };

  const hasNumberRule = (v) => { return /\d/.test(v) || 'validation.passwordError'; };

  const hasAndFloatNumRule = (v) => { return /^[-]|[0-9]*\.?[0-9]+$/.test(v) || 'validation.hasNumberOrDash'; }; // ^-?\d+\.?\d*$, ^[-]?\d+(?:\.\d+)?(?:-\d+(?:\.\d+)?)*$

  const hasLowerCaseRule = (v) => { return /[a-z]/.test(v) || 'validation.passwordError'; };

  const hasUpperCaseRule = (v) => { return /[A-Z]/.test(v) || 'validation.passwordError'; };

  // eslint-disable-next-line
  const hasSpecialCharacterRule = (v) => { return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(v) || 'validation.passwordError'; };

  const passwordRules = [
    (v) => { return atLeastRule(v, 8); },
    hasLowerCaseRule,
    hasUpperCaseRule,
    hasNumberRule,
    hasSpecialCharacterRule,
  ];

  const phoneNumberRule = (v) => {
    if (!v || !v.phoneNumber || !v.country) {
      // this is to verify the number only of it actually has a value
      // because a phone number field might be optional
      return true;
    }
    const { phoneNumber } = v;

    return isValidPhoneNumber(phoneNumber) || 'validation.phoneNumberNoValid';
  };

  const _mobilePhoneNumberRule = (v) => {
    if (!v || !v.phoneNumber || !v.country) {
      // this is to verify the number only of it actually has a value
      // because a phone number field might be optional
      return true;
    }
    const { phoneNumber, country } = v;

    return isValidPhoneNumber(phoneNumber, country) || 'validation.phoneNumberNoValid';
  };

  const dateRule = (v) => {
    return isValidDate(v) || 'validation.dateNoValid';
  };

  const adultRule = (v, customMsg = undefined) => {
    return 18 <= moment().diff(v, 'years') || customMsg || 'Μόνο ενήλικες μπορούν να εγγραφτούν στην πλατφόρμα';
  };

  const notAdultRule = (v, customMsg = undefined) => {
    return 18 > moment().diff(v, 'years') || customMsg || 'Μόνο ενήλικες μπορούν να εγγραφτούν στην πλατφόρμα';
  };

  const genderRule = (v) => {
    return [ 'male', 'female' ].includes(v) || 'validation.genderNoValid';
  };

  return {
    applyRules,
    requiredFieldRule,
    atLeastRule,
    notAdultRule,
    requiredPhoneRule,
    atMostRule,
    emailRule,
    promptPasswordRule,
    passwordRules,
    phoneNumberRule,
    mobilePhoneNumberRule: phoneNumberRule,
    dateRule,
    genderRule,
    hasAndFloatNumRule,
    adultRule,
    atMost10Rule,
    elevenCharsRule,
    nineCharsRule,
  };
}

export function useInputValidation(rules) {
  const [ error, setError ] = useState(false);
  const [ helperText, setHelperText ] = useState('');

  const { applyRules } = useFormRules();

  /**
   * Return true if validation succeeds, else false
   * @param v
   * @returns {boolean}
   */
  const validate = (v, newRules) => {
    const ht = applyRules(newRules || rules, v);
    setError(!!ht);
    setHelperText(ht || '');
    return !ht;
  };

  /**
   * Return true if validation succeeds, else false
   * @param v
   * @returns {boolean}
   */
  const justValidate = (v) => {
    const ht = applyRules(rules, v);
    return !ht;
  };

  return [
    error,
    helperText,
    validate,
    justValidate,
  ];
}
