/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useAuth } from 'AuthCtx';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Container, Dialog, makeStyles, IconButton } from '@material-ui/core';
import CancelIcon from '@material-ui/icons/Cancel';
import { FilePDFViewer } from 'components/PDFViewer';
import SimpleDateField from '../atoms/SimpleDateField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import { useKit } from '../../../models/kits';
// import cottonSwabs from '../../../assets/cotton_swabs.svg';
// import sendSample from '../../../assets/send_paper.svg';
/*
const CottonSwabs = () => {
  return (
    <img
      alt='follow-instructions'
      width='20px'
      height='auto'
      src={ cottonSwabs }
    />
  );
};

const SendSample = () => {
  return (
    <img
      alt='send-sample'
      width='20px'
      color='rgb(148, 195, 32)'
      height='auto'
      src={ sendSample }
    />
  );
}; */

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      color: theme.palette.secondary.main,
      textAlign: 'initial',
    },
    alertStyle: {
      '& .MuiAlert-message': {
        width: '100%',
      },
    },
  };
});

const SampleCollectionForm = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const classes = useStyles();

  const [ openPdfDialog, setOpenPdfDialog ] = useState(false);

  const { t, i18n } = useTranslation();

  const { isLoading, updateKitMetadata, sampleCollectionDate, data, isKitCvd, isKit3InOne, isKitNutrition,
    isKitSports, isKitVitamins, isKitPGX, isKitOnlyNutri, isKitOnlyNutriLabs } = useKit(kitId);

  const { isDoctor } = useAuth();

  const [ isSubmitting, setSubmitting ] = useState(false);

  const country = data?.customer_id?.country;

  const {
    dateRule,
  } = useFormRules();

  const [
    errorDate,
    helperTextDate,
    validateDate,
  ] = useInputValidation(
    [
      dateRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      sampleCollectionDate: dateOfSampleCollection,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateDate(dateOfSampleCollection); },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return updateKitMetadata({
          metadata: {
            sampleCollectionDate: dateOfSampleCollection,
          },
        });
      })
      .then(onSubmit)
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  const now = new Date();
  const dateString = sampleCollectionDate || `${ now.getFullYear() }-${ (`0${ now.getMonth() + 1 }`).slice(-2) }-${ (`0${ now.getDate() }`).slice(-2) }`;

  let path = '/Collect sample instructions_CVD.pdf';

  let productName = 'cardio';

  if (isKitCvd) {
    productName = 'cardio';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_CVD.pdf' : 'assets/pdfs/CVDInstructionsEL.pdf';
  } else if (isKitOnlyNutriLabs) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_NutriGenetix-Labs.pdf' : 'assets/pdfs/Διαδικασία λήψης δείγματος NutriGenetix-Labs.pdf';
  } else if (isKitOnlyNutri) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_NutriGenetix.pdf' : 'assets/pdfs/Διαδικασία λήψης δείγματος NutriGenetix.pdf';
  } else if (isKitVitamins) {
    productName = 'vitamines';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitNutrition) {
    productName = 'diatrofi';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitSports) {
    productName = 'athlisi';
    path = 'en-US' === i18n.language ? 'assets/pdfs/WellnessInstructionsEN.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKit3InOne) {
    productName = '3-se-1';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_Wellness.pdf' : 'assets/pdfs/WellnessInstructionsEL.pdf';
  } else if (isKitPGX) {
    productName = 'pgx';
    path = 'en-US' === i18n.language ? 'assets/pdfs/Collect sample instructions_PGx.pdf'
      : '/assets/pdfs/Διαδικασία λήψης δείγματος_PGx (1).pdf';
  }

  let extraFieldInAddress = '';
  if ('en-US' === i18n.language) {
    extraFieldInAddress = '/en';
  }

  let collectSampleInfo;

  if (isDoctor) {
    collectSampleInfo = t('reg_kit.sample.doctor.collect_sample_info');
  } else if (isKitPGX) {
    collectSampleInfo = t('reg_kit.sample.non_doctor.pgx.collect_sample_info');
  } else {
    collectSampleInfo = t('reg_kit.sample.non_doctor.non_pgx.collect_sample_info');
  }

  const collectSampleInfoArr = collectSampleInfo.split(t('common.here'));

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Container style={ { padding: 0 } }>
        <Dialog
          open={ openPdfDialog }
          onClose={ () => { setOpenPdfDialog(false); } }
          fullWidth
          maxWidth='lg'
        >
          <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
            <IconButton
              style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
              onClick={ () => { setOpenPdfDialog(false); } }
            >
              <CancelIcon style={ { color: 'red' } } />
            </IconButton>
            <FilePDFViewer path={ path } />
          </div>
        </Dialog>
        <Alert
          severity='info'
          color='rgba(0, 0, 0, 0)'
          style={ { textAlign: 'initial' } }
          icon={ (
            <Grid />
          ) }
        >
          <AlertTitle className={ classes.root }>
            {t('reg_kit.sample.common.collect_sample_title')}
          </AlertTitle>

          <div>
            { collectSampleInfoArr[0] }
            <a
              href=''
              onClick={ (e) => {
                e.preventDefault();
                setOpenPdfDialog(true);
              } }
            >
              {t('common.here')}
            </a>
            { collectSampleInfoArr[1] }
          </div>

        </Alert>
        <form
          noValidate
          onSubmit={ handleSubmit }
        >
          <Grid
            container
            style={ {
              padding: '24px',
            } }
          >
            <FormSection label={ t('reg_kit.sample.common.sample_date_title') }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleDateField
                    label={ t('reg_kit.sample.common.sample_collection_date') }
                    defaultValue={ dateString }
                    name='sampleCollectionDate'
                    required
                    error={ errorDate }
                    helperText={ helperTextDate }
                    country={ country }
                  />
                </Grid>
              </Grid>
            </FormSection>
          </Grid>
          <Grid container>
            <FormSectionSubmit
              label={ t('common.save') }
              loading={ isSubmitting }
              goToPrevPage={ goToPrevPage }
              color={ isDoctor ? '#526D9C' : undefined }
            />
            <Alert
              severity='info'
              style={ { marginTop: '24px', textAlign: 'initial', width: '100%' } }
              className={ classes.alertStyle }
              icon={ (
                <Grid />
              ) }
            >
              <AlertTitle className={ classes.root }>
                {t('reg_kit.sample.common.send_sample_title')}
              </AlertTitle>
              {
                isDoctor
                  ? t('reg_kit.sample.doctor.send_to_idna')
                  : isKitPGX ? (
                    t('reg_kit.sample.non_doctor.pgx.send_to_idna')
                  ) : t('reg_kit.sample.non_doctor.non_pgx.send_to_idna')
              }
              <div style={ { background: '#484848', color: 'white', padding: '7px', borderRadius: '5px', marginTop: '2px', marginBottom: '2px', width: '100%' } }>
                {t('reg_kit.sample.common.this_shipping_address')}
                <br />

                {
                  'US' === country ? ''
                    : t('reg_kit.sample.common.idna_labs')
                }

                <br />

                {
                  'US' === country ? t('reg_kit.sample.common.send_address_us')
                    : isKitPGX ? t('reg_kit.sample.common.send_address_pgx') : t('reg_kit.sample.common.send_address_gr')
                }
                <br />
                {
                  'US' === country ? t('reg_kit.sample.common.idna_phone_url_us')
                    : isKitPGX ? t('reg_kit.sample.common.idna_phone_url_pgx') : t('reg_kit.sample.common.idna_phone_url_gr')
                }
              </div>
              {
                !isDoctor && (
                  <>
                    {
                      t('reg_kit.sample.non_doctor.send_sample_info')
                    }
                    {' '}
                    <a
                      target='_blank'
                      href={
                        isKitPGX
                          ? `https://idnalaboratories.com${ extraFieldInAddress }/process/`
                          : `https://idnagenomics.com${ extraFieldInAddress }/product/${ productName }/#av-tab-section-1-5-link`
                      }
                      rel='noreferrer'
                    >
                      {t('common.here')}
                    </a>
                  </>
                )
              }
            </Alert>
          </Grid>
        </form>
      </Container>
    </>
  );
};

SampleCollectionForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

SampleCollectionForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default SampleCollectionForm;
