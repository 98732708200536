/* eslint-disable no-unused-vars */
import React, { useMemo } from 'react';
import { useQuery } from 'react-query';
import { use3ToOneDataReport, useKits } from 'models/kits';
import { useAuth } from 'AuthCtx';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { Icon, Grid } from '@material-ui/core';
import { I18nextProvider, useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import useAPI from 'hooks/api-hooks';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useStylesCardio } from 'pages/iDNACardioHealth/iDNACardioHealth';
import i18nEng from '../../i18n_eng';
import i18nEl from '../../i18n_el';
import { useNavigation, useOptionSerial } from '../../NavigationProvider';

const greyColor = '#666666';

const useStyles = makeStyles(() => {
  return {
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: 'min(28px, 5.8vw)',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: greyColor,
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    explanationText: {
      marginTop: '51px',
      display: 'flex',
      flexDirection: 'column',
      // width: 'fit-content',
      minWidth: '200px',
      width: '50%',
      marginLeft: '23px',

      '@media (max-width:750px)': {
        width: '100%',
        gap: '30px',
      },

      '@media (max-width:1600px)': {
        minWidth: '30%',
      },

      '@media (min-width:1600px)': {
        paddingLeft: '40px',
      },

      '@media (min-width:750px)': {
        maxWidth: '250px',
      },

      '@media (max-width:1500px)': {
        minWidth: '20%',
      },

    },
    explanationImage: {
      borderTopLeftRadius: '10px',
      borderBottomLeftRadius: '10px',
      display: 'block',
      height: '440px',
      width: 'calc(100% - max(200px, 50%))',

      '@media (max-width:750px)': {
        display: 'none',
      },

      '@media (max-width:1600px)': {
        width: 'calc(100% - max(200px, 30%))',
      },

      '@media (max-width:1500px)': {
        width: 'calc(100% - max(200px, 20%))',
      },
    },
    largeTextOrSmall: {
      fontWeight: '600',
      fontSize: 'min(26px, 5vw)',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',

      '@media (max-width:1600px)': {
        fontSize: '20px',
      },
    },
    explanation: {
      borderRadius: '27px',
      background: 'linear-gradient(109.37deg, #FFFFFF 5.49%, rgba(255, 255, 255, 0.51) 103.09%)',
      backdropFilter: 'blur(40px)',
      display: 'flex',
      height: '440px',

      '@media (max-width:1280px)': {
        height: 'auto',
      },
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 1.59602px 5.32008px 0px rgba(0, 0, 0, 0.02), 0px 5.36071px 17.86905px 0px rgba(0, 0, 0, 0.03), 0px 24px 55px 0px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    downloadIconSmall: {
      width: '50px',
      height: '50px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 1.59602px 5.32008px 0px rgba(0, 0, 0, 0.02), 0px 5.36071px 17.86905px 0px rgba(0, 0, 0, 0.03), 0px 24px 55px 0px rgba(0, 0, 0, 0.05)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    cardFields: {
      display: 'flex',
      flexDirection: 'column',
      gap: '30px',
      alignItems: 'flex-start',
    },
    cardField: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      marginLeft: '8%',
    },
    container: {
      fontFamily: 'Roboto',
      display: 'flex',
      color: '#484848',
      flexDirection: 'column',
      gap: '25px',
    },
    whatYouWillFindNew: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    subtitleNew: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    titleNew: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: 'min(43.4717px, 9vw)',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: 'min(43.4717px, 9vw)',
      lineHeight: '51px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
    },
    categoriesText: {
      marginTop: '10px',
      fontWeight: '500',
      fontFamily: 'Roboto',
      fontSize: '16px',
      lineHeight: '154.68%',
    },
    whatYouWillFind: {
      fontWeight: '600',
      fontSize: '24px',
      fontFamily: 'Roboto',
      lineHeight: '154.19%',
      letterSpacing: '0.03em',
      width: '60%',
    },
    verBar: {
      borderLeft: '1px solid #000000',
      marginLeft: '39px',
      marginRight: '30px',
    },
    whatYouWillLearn: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '161.19%',
      letterSpacing: '0.03em',
    },
    card: {
      height: '475px',
      borderRadius: '16px',
      paddingTop: '3px',
      position: 'relative',
    },
    cardTitle: {
      fontWeight: '700',
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
      color: 'white',
      marginTop: '17px',
      marginLeft: '30px',
    },
    cardBody: {
      marginTop: '1rem',
      background: 'white',
      height: '78%',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '16px',
      paddingTop: '44px',
    },
    learnMoreArrow: {
      // marginTop: '2px',
      // transform: 'rotate(-45deg)',
      marginLeft: '5px',
    },
    learnMore: {
      color: 'white',
      borderRadius: '50px',
      boxShadow: '0px 2.01762px 6.72539px 0px rgba(0, 0, 0, 0.02), 0px 6.77675px 22.58917px 0px rgba(0, 0, 0, 0.03), 0px 30.33962px 101.13208px 0px rgba(0, 0, 0, 0.05)',

      height: '56px',
      width: 'min(230px, 80%)',
      display: 'flex',
      textAlign: 'center',
      position: 'absolute',
      bottom: '20px',
      alignItems: 'center',
      justifyContent: 'center',
      // right: '5%',
      left: 'calc(95% - min(255px, 80%))',

      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 350,
      lineHeight: '136%',

      transition: 'all .4s',

      '&:hover': {
        cursor: 'pointer',
        width: 'min(280px, 85%)',
        boxShadow: '0px 6.77675px 22.58917px 0px rgba(133, 133, 133, 0.40)',
      },
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    largeText: {
      fontWeight: '600',
      fontSize: 'min(26px, 5vw)',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    downloadText: {
      background: '#93C221',
      color: 'white',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      height: '4rem',
      display: 'flex',
      width: 'fit-content',
      paddingLeft: '30px',
      paddingRight: '30px',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'relative',
      marginTop: 'auto',
      // marginBottom: '30%',
      marginBottom: '53px',

      transition: 'all .4s',
      '&:hover': {
        paddingLeft: '40px',
        paddingRight: '33px',
        boxShadow: '0px 6.77675px 22.58917px 0px rgba(133, 133, 133, 0.40)',
      },

      '@media (max-width:1450px)': {
        // marginBottom: '5%',
      },
    },
  };
});

export const iDNA3ToOneCards = [
  {
    id: 'diet',
    title: 'diet',
    fields: [
      {
        text: 'weightManagement',
        icon: '/scale.svg',
        image: '/image_Διαχείριση Βάρους.png',
        nurl: 'nutritionWeightManagement',
      },
      {
        text: 'cardiometabolicHealth',
        icon: '/heart.svg',
        image: '/image_Καρδιομεταβολική υγεία.png',
        nurl: 'nutritionCardiometabolicHealth',
      },
      {
        text: 'foodSensitivities',
        icon: '/food.svg',
        image: '/image_Τροφικές-ευαισθησίες.png',
        nurl: 'nutritionFoodSensitivities',
      },
    ],
    url: '',
    color: '#F5C91E',
  },

  {
    id: 'vitamins',
    title: 'vitaminsTraceElements',
    fields: [
      {
        text: 'detoxificationCapacityAntioxidantNeeds',
        icon: '/body.svg',
        image: '/image_Αποτοξίνωση&αντιοξειδωτικές-ανάγκες.png',
        nurl: 'vitaminsDetoxificationCapacityAntioxidantNeeds',
      },
      {
        text: 'vitamins',
        icon: '/vitamins.svg',
        image: '/image_Απορρόφηση  βιταμινών.png',
        nurl: 'vitaminsVitamins',
      },
      {
        text: 'traceElements',
        icon: '/traceElements.svg',
        image: 'image_Μεταβολισμός-Ιχνοστοιχείων.png',
        nurl: 'vitaminsTraceElements',
      },

    ],
    url: '',
    color: '#93C221',
  },

  {
    id: 'sports',
    title: 'sports',
    fields: [
      {
        text: 'athleticProfile',
        icon: '/athletic.svg',
        image: '/image_Αθλητικό προφίλ.png',
        nurl: 'sportsAthleticProfile',
      },
      {
        text: 'injuryPredisposition',
        icon: 'injuries.svg',
        image: '/image_Προδιάθεση-τραυματισμών.png',
        nurl: 'sportsInjuryPredisposition',
      },
    ],
    url: '',
    color: '#31B5E1',
  },
];

export const WellnessPdf = ({ navigateToPage, url = undefined, buttonColor = null }) => {
  const classes = useStylesCardio();
  const { t } = useTranslation();

  return (
    <div className={ classes.pdf }>
      <div style={ { display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 2,
        rowGap: '10px',
        alignItems: 'center' } }
      >
        <Icon className={ clsx(classes.downloadIcon) }>
          <img
            src='/download.svg'
            alt='1'
          />
        </Icon>
        <div style={ { marginLeft: '40px', maxWidth: 'fit-content' } }>
          <div
            style={ {
              fontWeight: '600',
              fontSize: 'min(30px, 6vw)',
              lineHeight: '125.19%',
              fontFamily: 'Roboto',
              marginBottom: '10px',
            } }
          >
            Pdf version
          </div>
          <div className={ classes.longTextOnDesktop }>
            {t('Here you will find your personal results report in a printable format.')}
          </div>
        </div>
      </div>
      <div
        className={ clsx(classes.hoverPointer, classes.downloadText) }
        style={ { width: 'fit-content',
          display: 'flex',
          alignItems: 'center',
          background: buttonColor ?? '#93C221',
          color: 'white',
          marginBottom: 0,
          height: '4rem',
          position: 'relative',
          borderRadius: '27px',
          justifyContent: 'center' } }
        aria-hidden='true'
        onClick={ () => { navigateToPage({ pageId: url }); } }
      >
        {t('download')}
      </div>
    </div>
  );
};

const Card = ({ info }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  return (
    <div className={ classes.card } style={ { background: info.color } }>
      <div className={ classes.cardTitle }>
        {t(info.title)}
      </div>
      <div className={ clsx(classes.cardBody, classes.cardFields) }>
        {
          info.fields.map((field) => {
            return (
              <div
                className={ clsx(classes.cardField, classes.smallText) }
                key={ field.text }
                aria-hidden='true'
              >
                <Icon
                  className={ classes.cardIcon }
                  style={ { background: info.color } }
                >
                  <img src={ field.icon } alt='' />
                </Icon>
                {t(field.text)}
              </div>
            );
          })
        }
      </div>

      {/* <div style={ { display: 'flex', justifyContent: 'center' } }> */}
      <div style={ { display: 'none', justifyContent: 'center' } }>
        <div
          className={ classes.learnMore }
          style={ { background: info.color } }
          onClick={ () => { navigateToPage({ pageId: info.id }); } }
          aria-hidden='true'
        >
          {t('Δες το report σου')}

          <ArrowForwardIcon className={ classes.learnMoreArrow } />
        </div>
      </div>
    </div>
  );
};

const MiddlePart = () => {
  return (
    <Grid container spacing={ 3 }>
      {
        iDNA3ToOneCards.map((card) => {
          return (
            <Grid item md={ 6 } lg={ 4 } xs={ 12 } key={ card.title }>
              <Card info={ card } />
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export const BottomPartNutri = ({ pdfDownloadLink = null, resultsPageLink = null,
  showExplanation = true, buttonColor = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  return (
    <Grid container spacing={ 3 }>
      <Grid
        item
        lg={ showExplanation ? 4 : 12 }
        sm={ 12 }
      >
        <div
          style={ { padding: '44px 30px 44px 30px',
            background: 'white',
            borderRadius: '16px',
            position: 'relative',
            height: '440px' } }
        >
          <div style={ { display: 'flex', alignItems: 'center', gap: '30px' } }>
            <div>
              <Icon className={ clsx(classes.downloadIconSmall) }>
                <img
                  src='/download.svg'
                  alt=''
                />
              </Icon>
            </div>
            <div className={ classes.smallText } style={ { marginTop: '16px' } }>
              {t('Here you will find your personal results report in a printable format.')}
            </div>
          </div>

          <div
            className={ clsx(classes.largeTextOrSmall) }
            style={ { marginTop: '30px',
              fontSize: 'min(22px, 5vw)',
              marginBottom: '5px' } }
          >
            {t('Διατροφογενετική Ανάλυση')}
          </div>

          <div
            className={ clsx(classes.downloadText, classes.smallText, classes.hoverPointer) }
            style={ { ...(null != buttonColor) && { background: buttonColor },
              marginBottom: 0 } }
            aria-hidden='true'
            onClick={ () => { navigateToPage({ pageId: null === pdfDownloadLink ? 'nutriGenetixAnalysisPdfVersion' : pdfDownloadLink }); } }
          >
            {t('download')}
          </div>

          <div>
            <div
              className={ clsx(classes.largeTextOrSmall) }
              style={ {
                fontSize: 'min(22px, 5vw)',
                marginTop: '25px',
              } }
            >
              {t('Πλάνο διατροφής')}
            </div>

            <div
              className={ clsx(classes.downloadText, classes.smallText, classes.hoverPointer) }
              style={ { ...(null != buttonColor) && { background: buttonColor },
                marginBottom: 0 } }
              aria-hidden='true'
              onClick={ () => { navigateToPage({ pageId: null === pdfDownloadLink ? 'nutriGenetixNutritionPlanPdfVersion' : pdfDownloadLink }); } }
            >
              {t('download')}
            </div>
          </div>

        </div>

      </Grid>

      {
        showExplanation && (
          <Grid
            item
            lg={ 8 }
            sm={ 12 }
          >
            <div
              className={ classes.explanation }
            >
              <img
                className={ classes.explanationImage }
                src='/Image_Επεξηγηση-αποτελεσματων_Croped.jpg'
                alt=''
              />
              <div className={ classes.explanationText }>
                <div className={ clsx(classes.largeTextOrSmall) }>
                  {t('explanationOfResults')}
                </div>
                <div
                  className={ classes.smallText }
                  style={ { marginTop: '13px' } }
                >
                  {
                    t('Book a phone appointment with our scientific team to explain the personalized results report.')
                  }
                </div>
                <div
                  className={ clsx(classes.downloadText, classes.smallText, classes.hoverPointer) }
                  style={ {
                    ...(null != buttonColor) && { background: buttonColor },
                  } }
                  aria-hidden
                  onClick={ () => { navigateToPage({ pageId: null === resultsPageLink ? '' : resultsPageLink }); } }
                >
                  {t('makeAnAppointment')}
                </div>
              </div>
            </div>
          </Grid>
        )
      }

    </Grid>
  );
};

export const BottomPart = ({ pdfDownloadLink = null, resultsPageLink = null,
  showExplanation = true, buttonColor = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  return (
    <Grid container spacing={ 3 }>
      <Grid
        item
        lg={ showExplanation ? 4 : 12 }
        sm={ 12 }
      >
        <div
          style={ { padding: '44px',
            background: 'white',
            borderRadius: '16px',
            position: 'relative',
            height: '440px' } }
        >
          <div>
            <Icon className={ clsx(classes.downloadIcon) }>
              <img
                src='/download.svg'
                alt=''
              />
            </Icon>
          </div>
          <div
            className={ clsx(classes.largeText) }
            style={ {
              marginTop: '32px',
            } }
          >
            PDF version
          </div>
          <div className={ classes.smallText } style={ { marginTop: '16px' } }>
            {t('Here you will find your personal results report in a printable format.')}
          </div>
          <div
            className={ clsx(classes.downloadText, classes.smallText, classes.hoverPointer) }
            style={ { ...(null != buttonColor) && { background: buttonColor },
              marginBottom: 0,
              position: 'absolute',
              bottom: '53px' } }
            aria-hidden='true'
            onClick={ () => { navigateToPage({ pageId: null === pdfDownloadLink ? 'pdfVersion' : pdfDownloadLink }); } }
          >
            {t('download')}
          </div>
        </div>

      </Grid>

      {
        showExplanation && (
          <Grid
            item
            lg={ 8 }
            sm={ 12 }
          >
            <div
              className={ classes.explanation }
            >
              <img
                className={ classes.explanationImage }
                src='/Image_Επεξηγηση-αποτελεσματων_Croped.jpg'
                alt=''
              />
              <div className={ classes.explanationText }>
                <div className={ clsx(classes.largeTextOrSmall) }>
                  {t('explanationOfResults')}
                </div>
                <div
                  className={ classes.smallText }
                  style={ { marginTop: '13px' } }
                >
                  {
                    t('Book a phone appointment with our scientific team to explain the personalized results report.')
                  }
                </div>
                <div
                  className={ clsx(classes.downloadText, classes.smallText, classes.hoverPointer) }
                  style={ {
                    ...(null != buttonColor) && { background: buttonColor },
                  } }
                  aria-hidden
                  onClick={ () => { navigateToPage({ pageId: null === resultsPageLink ? '' : resultsPageLink }); } }
                >
                  {t('makeAnAppointment')}
                </div>
              </div>
            </div>
          </Grid>
        )
      }

    </Grid>
  );
};

const TopPart = ({ child = null, customerName = null }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');

  const { isSm } = useMediaQueries();

  const { userAttributes, isDoctor } = useAuth();

  let {
    firstName,
  } = userAttributes;

  if (null != customerName && isDoctor) {
    firstName = customerName;
  }

  if (null != child) {
    firstName = child.name;
  }

  return (
    <div className={ classes.topPart } style={ { paddingBottom: isDoctor ? '0px' : '42px' } }>
      <div style={ { display: 'flex', justifyContent: 'space-between' } }>
        <div style={ { marginTop: '1rem' } }>
          <div className={ classes.titleNew }>
            {t('iDNA Test 3 σε 1')}
          </div>
          <div className={ classes.subtitleNew }>
            Serial Number:
            {' '}
            {newSerial}
          </div>
        </div>
        <img
          src='/iDNA Genomics - To Banner - 3σε1.png'
          alt=''
          style={ { display: isSm ? 'none' : '' } }
        />
      </div>

      {
        !isDoctor && (
          <>
            <div className={ classes.hello }>
              <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
              <div>
                {
                  `${ t('hello') } ${ firstName },`
                }
              </div>
            </div>
            <div
              className={ classes.whatYouWillFindNew }
              style={ {
                lineHeight: '161.19%',
              } }
            >
              <div style={ { width: '72%' } }>
                <div>
                  {t('hereYouWillFind3InOne')}
                </div>
                <div style={ { marginTop: '30px', fontWeight: 'bold', marginBottom: '5px' } }>
                  {t('learnHowGenes')}
                </div>
                <div>
                  <ul style={ { marginTop: '0', paddingLeft: '26px' } }>
                    <li>
                      {t('learnWeightControl')}
                    </li>
                    <li>
                      {t('learnAbsorbVitamins')}
                    </li>
                    <li>
                      {t('learnAthleticProfile')}
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </>
        )
      }

    </div>
  );
};

const iDNA3ToOne = () => {
  const classes = useStyles();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);

  const { navigateToPage } = useNavigation();
  const { client } = useAPI();
  const { isDoctor } = useAuth();

  const { data, isLoading } = useKits();

  const theKit = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; });
  let cashStatus = theKit?.metadata?.cashStatus;
  if (cashStatus === undefined) {
    cashStatus = theKit?.serial_number_id?.cashStatus;
  }

  const appointmentAvailability = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; })?.appointment_availability;
  const child = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; })?.child_id;
  const customerName = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; })?.customer_id?.name;

  const { data: bookedAppointments, isLoading: isBookedAppointmentsLoading } = useQuery(
    [ 'Appointment', 'booked', newSerial, isDoctor ], () => {
      if (!isDoctor) {
        return client.get('/appointments/getTimeSlots', { params: { serial_number: newSerial, serial_number_eq: newSerial } });
      }
      return null;
    },
  );

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (isLoading || isBookedAppointmentsLoading) {
    return <LoaderSkeleton />;
  }

  const alreadyHadAppointment = null !== bookedAppointments && 0 < bookedAppointments.length;

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div className={ classes.container }>
        <TopPart child={ child } customerName={ customerName } />
        <MiddlePart />
        {
          !isDoctor && (
            <>
              {
                (('paid' === cashStatus || true === appointmentAvailability) && !alreadyHadAppointment)
                  ? (
                    <BottomPart
                      pdfDownloadLink='pdfVersion'
                      resultsPageLink='explanationOfResults'
                    />
                  ) : (
                    <WellnessPdf navigateToPage={ navigateToPage } url='pdfVersion' />
                  )
              }
            </>
          )
        }

      </div>
    </I18nextProvider>
  );
};

export default iDNA3ToOne;
