import axios from 'axios';
import * as qs from 'qs';
import { Auth } from 'aws-amplify';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../AuthCtx';
import { useAlerts } from './alerts-hooks';
import errors from '../errors';

/**
 * The HTTP 401 Unauthorized
 * client error status response code
 * indicates that the request has not
 * been applied because it lacks valid
 * authentication credentials for
 * the target resource.
 */
const UNAUTHORIZED = 401;
const SERVER_ERROR = '5xx';
const BAD_REQUEST = 400;

const defaultOptions = {
  baseURL: `${ process.env.REACT_APP_API_URL }`,
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: (params) => {
    return qs.stringify(params);
  },
};

const useAPI = ({
  codeAlertSuccess,
  codeAlertError,
  suppressAlertError,
  noAuthorization,
} = {
  suppressAlertError: false,
}) => {
  // const { user, signOut } = useAuth();
  const { signOut } = useAuth();
  const { alertError, alertInfo, alertSuccess, alertWarning } = useAlerts();
  const instance = axios.create(defaultOptions);
  const { t } = useTranslation();

  const sessionExpired = {
    code: 'sessionExpired',
  };
  const networkError = {
    code: 'networkError',
  };

  // const axiosRequestConfig = (config) => {
  //   return new Promise((resolve, reject) => {
  //     try {
  //       user.getSession((error, session) => {
  //         if (error || !session) {
  //           reject(error);
  //         }
  //         try {
  //           const idToken = session.getIdToken().getJwtToken();
  //           resolve(idToken);
  //         } catch (err_) {
  //           reject(err_);
  //         }
  //       });
  //     } catch (err) {
  //       reject(err);
  //     }
  //   })
  //     .then((idToken) => {
  //       config.headers.Authorization = `Bearer ${ idToken }`;
  //       return config;
  //     })
  //     .catch((err) => {
  //       alertInfo(sessionExpired);
  //       signOut();
  //       throw err;
  //     });
  // };

  const axiosRequestConfig = (config) => {
    return Auth.currentSession()
      .then((userSession) => {
        const idToken = userSession.getIdToken().getJwtToken();
        config.headers.Authorization = `Bearer ${ idToken }`;
        return config;
      })
      .catch((_err) => {
        config.headers.Authorization = 'Bearer ThisIsNotAToken';
        alertInfo(sessionExpired);
        signOut();
        return config;
      });
  };

  if (!noAuthorization) {
    instance.interceptors.request.use(axiosRequestConfig);
  }

  instance.interceptors.response.use(
    (response) => {
      // Do something with response data
      if (codeAlertSuccess) {
        alertSuccess({ code: codeAlertSuccess });
      }
      return response.data;
    },
    (err) => {
      if (!err.isAxiosError) {
        return Promise.reject(err);
      }

      if (!err.response) {
        // something went wrong with the request
        // probably no internet connection
        switch (err.message) {
          case 'Network Error':
            alertWarning(networkError);
            break;
          default:
            alertError(errors.SomethingWentWrong);
        }
        return Promise.reject(err);
      }
      // Do something with response error
      const { status: httpStatus, data: { error, message, statusCode } } = err.response || { data: {} };

      const { baseURL, url, method } = err.config;
      console.debug(`${ method.toUpperCase() } @ [${ baseURL }/${ url }]\n[${ statusCode }] Error: ${ message }, ${ JSON.stringify(error) }`);

      let status = httpStatus;

      // if (401 === status) {

      // }

      if (500 <= status && 511 >= status) {
        status = SERVER_ERROR;
      }

      const { code } = err?.response?.data?.data || { code: 'somethingWentWrong' };
      switch (status) {
        case UNAUTHORIZED:
          signOut();
          break;
        case SERVER_ERROR:
          alertError(t('The server is not responding. Please try again later'));
          break;
        case BAD_REQUEST:
          if (!suppressAlertError) {
            alertError({ code: codeAlertError || code });
          }
          break;
        default:
          break;
      }
      return Promise.reject(err);
    },
  );

  return {
    client: instance,
  };
};

export default useAPI;
