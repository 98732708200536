import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography, IconButton, Dialog } from '@material-ui/core';
import { FilePDFViewer } from 'components/PDFViewer';
import CancelIcon from '@material-ui/icons/Cancel';
import { useTranslation } from 'react-i18next';
import { useInputValidation } from 'hooks/form-rules';
import PropTypes from 'prop-types';
import * as _ from 'lodash';
import { wellnessConsents, generalConsents, marketingConsents, bioAgeConsents } from 'models/agreements';
import { useKit } from 'models/kits';
import SimpleCheckbox from '../atoms/SimpleCheckbox';
import SimpleRadioInlineRequired from '../atoms/SimpleRadioInlineRequired';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';

const DoctorGeneralConsentWellnessForm = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const { t, i18n } = useTranslation();

  const [ isSubmitting, setIsSubmitting ] = useState(false);

  const { isLoading, kitAgreements, upsertAgreement, isKitBioAge, isKitLabs, isKitOnlyNutri } = useKit(kitId);

  const [ geneticConsent, setGeneticConsent ] = useState(null);
  const [ anonymousConsent, setAnonymousConsent ] = useState(null);
  const [ informativeMaterialConsent, setInformativeMaterialConsent ] = useState(null);

  useEffect(() => {
    if (isKitBioAge) {
      setGeneticConsent(true === kitAgreements[bioAgeConsents.BIO_DATA_CONSENT]);
    } else {
      setGeneticConsent(true === kitAgreements[wellnessConsents.GENETIC_ANALYSIS_CONSENT]);
    }

    setAnonymousConsent(null == kitAgreements[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT] ? null : true === kitAgreements[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT] ? 'yes' : 'no');
    setInformativeMaterialConsent(null == kitAgreements[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT] ? null : true === kitAgreements[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT] ? 'yes' : 'no');
  }, [ isLoading, kitAgreements, isKitBioAge ]);

  const [ agreeError, agreeHelperText, validateAgree ] = useInputValidation(
    [ (v) => { return [ 'yes', 'no' ].includes(v) || 'reg_kit.consents.common.required_consent'; } ],
  );

  const [ agreeErrorAnonymous, agreeHelperTextAnonymous, validateAgreeAnonymous ] = useInputValidation(
    [ (v) => { return !!v || 'reg_kit.consents.common.required_selection'; } ],
  );

  const [ agreeErrorInformative, agreeHelperTextInformative, validateAgreeInformative ] = useInputValidation(
    [ (v) => { return !!v || 'reg_kit.consents.common.required_selection'; } ],
  );

  const handleSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const {
      [wellnessConsents.GENETIC_ANALYSIS_CONSENT]: geneticAnalysisConsent,
      [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: anonymousDnaConsent,
      [marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]: informativeConsent,
    } = Object.fromEntries(formData);

    if (!validateAgree(geneticAnalysisConsent)) {
      return null;
    }

    if (!validateAgreeAnonymous(anonymousDnaConsent)) {
      return null;
    }

    if (!validateAgreeInformative(informativeConsent)) {
      return null;
    }

    setIsSubmitting(true);

    return Promise.resolve()
      .then(() => {
        const upsertAgreements = [
          {
            consentName: wellnessConsents.GENETIC_ANALYSIS_CONSENT,
            status: 'yes' === geneticAnalysisConsent,
          },
          {
            consentName: generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT,
            status: 'yes' === anonymousDnaConsent,
          },
          {
            consentName: marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT,
            status: 'yes' === informativeConsent,
          },
        ].map((d) => {
          return Promise.resolve().then(() => {
            return upsertAgreement(d);
          });
        });
        return Promise.all(upsertAgreements);
      })
      .then((data) => {
        return onSubmit(data);
      }).catch(onError)
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const handleBioAgeSubmit = (e) => {
    e.preventDefault();

    const formData = new FormData(e.target);
    const {
      [bioAgeConsents.BIO_DATA_CONSENT]: geneticAnalysisConsent,
      [generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]: anonymousDnaConsent,
      [marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]: informativeConsent,
    } = Object.fromEntries(formData);

    if (!validateAgree(geneticAnalysisConsent)) {
      return null;
    }

    if (!validateAgreeAnonymous(anonymousDnaConsent)) {
      return null;
    }

    if (!validateAgreeInformative(informativeConsent)) {
      return null;
    }

    setIsSubmitting(true);

    return Promise.resolve()
      .then(() => {
        const upsertAgreements = [
          {
            consentName: bioAgeConsents.BIO_DATA_CONSENT,
            status: 'yes' === geneticAnalysisConsent,
          },
          {
            consentName: generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT,
            status: 'yes' === anonymousDnaConsent,
          },
          {
            consentName: marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT,
            status: 'yes' === informativeConsent,
          },
        ].map((d) => {
          return Promise.resolve().then(() => {
            return upsertAgreement(d);
          });
        });
        return Promise.all(upsertAgreements);
      })
      .then((data) => {
        return onSubmit(data);
      }).catch(onError)
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const [ openPdfDialog, setOpenPdfDialog ] = useState(false);

  if (isLoading) {
    return <p>Loading</p>;
  }

  return (
    <form
      noValidate
      onSubmit={ isKitBioAge ? handleBioAgeSubmit : handleSubmit }
    >
      <Grid
        container
        style={ {
          padding: '24px',
        } }
      >
        <FormSection label={ t('reg_kit.consents.doctor.non_pgx.common.title') }>
          <Typography>
            {
              isKitBioAge
                ? t('reg_kit.consents.doctor.non_pgx.bioage.gdpr_file_info')
                : t('reg_kit.consents.doctor.non_pgx.non_bioage.gdpr_file_info')
            }
          </Typography>

          <Dialog
            open={ openPdfDialog }
            onClose={ () => { setOpenPdfDialog(false); } }
            fullWidth
            maxWidth='lg'
          >
            <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
              <IconButton
                style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
                onClick={ () => { setOpenPdfDialog(false); } }
              >
                <CancelIcon style={ { color: 'red' } } />
              </IconButton>
              <FilePDFViewer
                path={ isKitBioAge ? 'en-US' === i18n.language ? '/Form GDPR BIOLOGICAL AGE ENG 11 05 23.pdf' : '/Form GDPR ΒΙΟΛΟΓΙΚΗ ΗΛΙΚΙΑ 03 04 23 FINAL.pdf'
                  : 'en-US' === i18n.language ? '/Form GDPR ENG 11 05 23.pdf' : '/Form GDPR 03 04 23 FINAL.pdf' }
                showDownload
              />
            </div>
          </Dialog>

          <Typography>
            {t('reg_kit.consents.doctor.common.save_file')}
          </Typography>

          <Typography
            style={ { cursor: 'pointer', textDecoration: 'underline', color: '#5A7D98' } }
            onClick={ () => { setOpenPdfDialog(true); } }
          >
            {t('reg_kit.consents.doctor.non_pgx.common.show_file')}
          </Typography>

          <Grid container spacing={ 2 } style={ { marginTop: '10px' } }>
            <Grid item xs={ 12 } md={ 9 }>
              <Typography variant='body2' style={ { textAlign: 'initial' } }>
                {
                  isKitBioAge
                    ? t('reg_kit.consents.doctor.non_pgx.bioage.process_personal_data')
                    : isKitLabs || isKitOnlyNutri
                      ? t('reg_kit.consents.doctor.non_pgx.non_bioage.process_personal_data_lab')
                      : t('reg_kit.consents.doctor.non_pgx.non_bioage.process_personal_data')
                }
              </Typography>

              <Typography style={ { fontStyle: 'italic', fontSize: '14px', marginTop: '7px' } }>
                {t('reg_kit.consents.common.unable_to_process_warning')}
              </Typography>
            </Grid>

            <Grid item xs={ 12 } md={ 3 }>
              {
                isKitBioAge ? (
                  <SimpleCheckbox
                    required
                    label={ t('common.he_agrees') }
                    name={ bioAgeConsents.BIO_DATA_CONSENT }
                    value={ geneticConsent }
                    onChange={ (v) => {
                      setGeneticConsent(v);
                      validateAgree(true === v ? 'yes' : 'no');
                    } }
                    error={ agreeError }
                    helperText={ agreeHelperText }
                  />
                ) : (
                  <SimpleCheckbox
                    required
                    label={ t('common.he_agrees') }
                    name={ wellnessConsents.GENETIC_ANALYSIS_CONSENT }
                    value={ geneticConsent }
                    onChange={ (v) => {
                      setGeneticConsent(v);
                      validateAgree(true === v ? 'yes' : 'no');
                    } }
                    error={ agreeError }
                    helperText={ agreeHelperText }
                  />
                )
              }

            </Grid>
          </Grid>

          <Grid container spacing={ 2 } style={ { marginTop: '10px' } }>
            <Grid item xs={ 12 } md={ 9 }>
              <Typography variant='body2' style={ { textAlign: 'initial' } }>
                {isKitBioAge ? t('reg_kit.consents.doctor.non_pgx.bioage.anonymized_data_consent')
                  : t('reg_kit.consents.doctor.non_pgx.non_bioage.anonymized_data_consent')}
              </Typography>
            </Grid>

            <Grid item xs={ 12 } md={ 3 }>
              <SimpleRadioInlineRequired
                required
                label=''
                name={ generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT }
                options={ [
                  {
                    label: t('common.yes'),
                    value: 'yes',
                  },
                  {
                    label: t('common.no'),
                    value: 'no',
                  },
                ] }
                defaultValue={ anonymousConsent }
                error={ agreeErrorAnonymous }
                helperText={ agreeHelperTextAnonymous }
                validate={ validateAgreeAnonymous }
              />
            </Grid>

          </Grid>

          <Grid container spacing={ 2 } style={ { marginTop: '10px' } }>
            <Grid item xs={ 12 } md={ 9 }>
              <Typography variant='body2' style={ { textAlign: 'initial' } }>
                { isKitLabs
                  ? t('reg_kit.consents.doctor.non_pgx.common.advertized_content_consent_labs')
                  : t('reg_kit.consents.doctor.non_pgx.common.advertized_content_consent')}
              </Typography>
            </Grid>

            <Grid item xs={ 12 } md={ 3 }>
              <SimpleRadioInlineRequired
                required
                label=''
                name={ marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT }
                options={ [
                  {
                    label: t('common.yes'),
                    value: 'yes',
                  },
                  {
                    label: t('common.no'),
                    value: 'no',
                  },
                ] }
                defaultValue={ informativeMaterialConsent }
                error={ agreeErrorInformative }
                helperText={ agreeHelperTextInformative }
                validate={ validateAgreeInformative }
              />
            </Grid>

          </Grid>

        </FormSection>
      </Grid>
      <FormSectionSubmit
        color='#526D9C'
        label={ t('common.save') }
        loading={ isSubmitting }
        goToPrevPage={ goToPrevPage }
      />
    </form>
  );
};

DoctorGeneralConsentWellnessForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

DoctorGeneralConsentWellnessForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default DoctorGeneralConsentWellnessForm;
