import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Button, LinearProgress, useTheme } from '@material-ui/core';
import DialogContent from '@material-ui/core/DialogContent';
import { useTranslation } from 'react-i18next';

const AppDialog = (
  {
    open,
    onClose,
    onKill,
    title,
    onSubmit,
    children,
  },
) => {
  const [ loading, setLoading ] = useState(false);

  const theme = useTheme();
  const { t } = useTranslation();

  const handleSubmit = () => {
    return Promise.resolve()
      .then(() => { setLoading(true); })
      .then(() => { return onSubmit(); })
      .finally(() => { setLoading(false); });
  };

  return (
    <Dialog keepMounted={ false } open={ open } onClose={ onClose } onExited={ onKill }>
      {loading && <LinearProgress />}
      <DialogTitle>
        {title}
      </DialogTitle>
      <DialogContent>
        {children}
      </DialogContent>
      <DialogContent>
        <div
          style={ { padding: theme.spacing(2, 0), display: 'flex', justifyContent: 'space-between', flexGrow: 1 } }
        >
          <Button
            variant='outlined'
            style={ { borderColor: theme.palette.error.main } }
            disabled={ loading }
            onClick={ onClose }
          >
            {t('cancel')}
          </Button>
          <Button
            variant='outlined'
            style={ { borderColor: theme.palette.success.main } }
            onClick={ handleSubmit }
            disabled={ loading }
          >
            { t('submit') }
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default AppDialog;
