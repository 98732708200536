import i18n from 'i18next';
// import { initReactI18next } from 'react-i18next';

import elGR from './assets/locales/el-GR';

const resources = {
  'el-GR': elGR,
};

// i18n
//   // .use(initReactI18next) // passes i18n down to react-i18next
//   .init({
//     resources,
//     // lng: localStorage.getItem('language') || 'el-GR',
//     fallbackLng: 'en-US',
//     interpolation: {
//       escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
//     },
//   });

const i18next = i18n.createInstance(
  {
    resources,
    lng: 'el-GR',
    ns: [ 'translation' ],
    defaultNS: 'translation',
    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
    react: { useSuspense: false },
    // We must provide a function as second parameter, otherwise i18next errors
  },
  (err) => {
    if (err) { return console.log(err); }
    return null;
  },
);

export default i18next;
