import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { IoIosAddCircleOutline } from 'react-icons/all';

const useClasses = makeStyles((theme) => {
  return {
    iconContainer: {
      borderRadius: 8,
      padding: theme.spacing(1),
      '&:hover $icon': {
        color: theme.palette.success.main,
      },
    },
    icon: {
    },
  };
});

const AppIconAdd = (props) => {
  const classes = useClasses();
  if (props.disabled) {
    return (
      <IconButton className={ classes.iconContainer } { ...props } aria-label='add'>
        <IoIosAddCircleOutline className={ classes.icon } />
      </IconButton>
    );
  }

  return (
    <Tooltip title='Add' aria-label='add'>
      <IconButton className={ classes.iconContainer } { ...props } aria-label='add'>
        <IoIosAddCircleOutline className={ classes.icon } />
      </IconButton>
    </Tooltip>
  );
};

export default AppIconAdd;
