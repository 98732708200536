import Grid from '@material-ui/core/Grid';
import { Divider, Typography } from '@material-ui/core';
import React from 'react';

const FormSection = ({ label, children, color = undefined, style = undefined }) => {
  return (
    <Grid container style={ { marginBottom: '32px', ...style } }>
      <Grid item xs={ 12 }>
        <Typography color='secondary' variant='h6' style={ { color } }>
          {label}
        </Typography>
      </Grid>
      <Grid item xs={ 8 }>
        <Divider />
      </Grid>
      <Grid item xs={ 12 } style={ { marginTop: '24px' } }>
        {children}
      </Grid>
    </Grid>
  );
};

export default FormSection;
