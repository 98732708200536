import React, { useState, useRef, useEffect, useMemo } from 'react';
import { useAuth } from 'AuthCtx';
import useAPI from 'hooks/api-hooks';
import { useLocation } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { marketingConsents, generalConsents } from 'models/agreements';
import CollectionKeys from 'models/atoms/collection-keys';
import FormSectionSubmit from 'components/forms/atoms/FormSectionSubmit';
import { AppCtx } from 'AppCtx';
import { Dialog, Divider, Grid, DialogTitle, DialogActions,
  Button, Typography, makeStyles } from '@material-ui/core';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { useTranslation } from 'react-i18next';
import QuestionnaireAnswers from 'components/QuestionnaireAnswers';
import { greenColor, orangeColor } from 'pages/BiologicalAge/BiologicalAge';
import { redColor } from 'pages/iDNACardioHealth/iDNACardioHealth';
import { blueColor } from 'pages/NutriGenetix/NutriGenetix';
import { langTypeToText } from 'components/app/LangSwitcher';
import Payment from 'components/forms/Payment';
import { useKit } from '../../models/kits';
import { useFetching } from '../../NavigationProvider';

const useStyles = makeStyles((theme) => {
  return {
    alert: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      flexWrap: 'nowrap',
      '& .MuiAlert-icon': {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
    title: {
      color: theme.palette.secondary.main,
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      textAlign: 'initial',
      marginTop: '10px',
    },
  };
});

const LabelValue = ({ label, value, translate = true, isDoctor = false }) => {
  const { t } = useTranslation();

  if (null == value || '' === value) {
    return null;
  }

  return (
    <div style={ { } }>
      <Typography
        variant='body2'
        display='inline'
        component='span'
        style={ { color: '#5c5b5a', marginRight: '8px' } }
      >
        {
          translate ? t(label) : label
        }
        {('?' === label.slice(-1) || ';' === label.slice(-1) || ':' === label.slice(-1)) ? '' : ':'}
      </Typography>

      <Typography
        style={ { fontWeight: 'bold' } }
        variant='body2'
        component='span'
        display='inline'
      >
        {value.constructor === Array ? value.map((val) => {
          if (isDoctor && ('string' === typeof val || val instanceof String) && (val.includes('wl_answer_') || val.includes('id_answer_') || val.includes('ng_answer_'))) {
            return t(`doc_${ val }`);
          }

          if (!('string' === typeof val || val instanceof String)) {
            return Object.values(val)?.[0];
          }

          return t(val);
        }).join(', ') : t(value)}
      </Typography>
    </div>
  );
};

export const InfoReview = ({ kitHealthcareProfessionalInvitations, kitAgreements, sampleCollectionDate, locale, customer, guardian,
  kitId, showAggrements = true, showReportLanguage = true, fullWidth = false }) => {
  const { userAttributes, isDoctor } = useAuth();
  const { t, i18n } = useTranslation();
  const classes = useStyles();

  const hasInvitedDoctor = kitHealthcareProfessionalInvitations && 0 < kitHealthcareProfessionalInvitations.length && null != kitHealthcareProfessionalInvitations[0];

  const { isKitWellness, data: kitData, questions, isKitPGX, isKitBioAge, child, isKitLabs, isKitOnlyNutri } = useKit(kitId);
  let extraInfo = kitData?.customer_id?.extra_info;
  const extraInfoPGx = kitData?.customer_id?.extra_info_pgx;
  const metadata = kitData?.metadata;

  if (null != child) {
    extraInfo = child.extra_info;
  }

  const { client } = useAPI();
  const {
    data: locales,
    isLoading: localesLoading,
  } = useQuery(
    [ 'locales' ],
    () => {
      return client.get('locales');
    },
  );

  if (!userAttributes || null == kitHealthcareProfessionalInvitations || null == kitAgreements || localesLoading) {
    return null;
  }

  let initialStreetAddress = '';
  let initialPostalCode = '';
  let initialCity = '';

  if (isDoctor) {
    if ('' !== customer?.address && null != customer?.address) {
      try {
        const jsonAddress = JSON.parse(customer?.address || JSON.stringify({}));

        initialStreetAddress = jsonAddress.streetAddress;
        initialPostalCode = jsonAddress.postalCode;
        initialCity = jsonAddress.city;
      } catch (e) {
        console.log('address error', customer?.address);
      }
    }
  } else if (null != child) {
    if ('' !== child?.address && null != child?.address) {
      try {
        const jsonAddress = JSON.parse(child?.address || JSON.stringify({}));

        initialStreetAddress = jsonAddress.streetAddress;
        initialPostalCode = jsonAddress.postalCode;
        initialCity = jsonAddress.city;
      } catch (e) {
        console.log('address error', customer?.address);
      }
    }
  }

  const localeText = locales?.find((loc) => { return loc.id === locale; });

  const theColor = isDoctor ? '#648DAE' : greenColor;

  return (
    <Grid container spacing={ 1 }>
      {
        showReportLanguage && (
          <>
            <Grid item xs={ 12 }>
              <Typography
                variant='h6'
                style={ { color: theColor } }
                className={ classes.title }
              >
                {t('reg_kit.review.common.report_lang')}
              </Typography>
              <Divider style={ { width: '60%' } } />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <LabelValue
                label='common.language'
                value={ localeText?.name === undefined ? undefined : t(langTypeToText[localeText.name].name) }
              />
            </Grid>
          </>
        )
      }
      {
        null != metadata?.referenceNumber && '' !== metadata?.referenceNumber && (
          <>
            <Grid item xs={ 12 }>
              <Typography
                variant='h6'
                style={ { color: theColor } }
                className={ classes.title }
              >
                {t('common.referral_num')}
              </Typography>
              <Divider style={ { width: '60%' } } />
            </Grid>

            <Grid item xs={ 12 } md={ 6 }>
              <LabelValue
                label='common.num'
                value={ metadata.referenceNumber }
              />
            </Grid>
          </>
        )
      }
      <Grid item xs={ 12 }>
        <Typography
          variant='h6'
          style={ { color: theColor } }
          className={ classes.title }
        >
          {t('reg_kit.review.common.personal_info')}
        </Typography>
        <Divider style={ { width: '60%' } } />
      </Grid>
      <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
        <LabelValue
          label='common.first_name'
          value={ isDoctor ? customer?.name : null != child ? child.name : userAttributes.firstName }
        />
      </Grid>
      <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
        <LabelValue
          label='common.last_name'
          value={ isDoctor ? customer?.family_name : null != child ? child.family_name : userAttributes.lastName }
        />
      </Grid>
      <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
        <LabelValue
          label='common.birthdate'
          value={ isDoctor ? customer?.birthdate : null != child ? child.birthdate : userAttributes.dateOfBirth }
        />
      </Grid>
      <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
        <LabelValue
          label='common.gender'
          value={ isDoctor ? t(customer?.gender) : null != child ? t(child.gender) : t(userAttributes.gender) }
        />
      </Grid>

      <Grid
        item
        xs={ 12 }
        md={ fullWidth ? 12 : 6 }
        style={ { display: isDoctor && ('' === initialStreetAddress || null == initialStreetAddress) ? 'none' : '' } }
      >
        <LabelValue
          label='common.street_and_number'
          value={
            isDoctor ? initialStreetAddress
              : userAttributes.streetAddress
          }
        />
      </Grid>
      <Grid
        item
        xs={ 12 }
        md={ fullWidth ? 12 : 6 }
        style={ { display: isDoctor && ('' === initialPostalCode || null == initialPostalCode) ? 'none' : '' } }
      >
        <LabelValue
          label='common.postal_code'
          value={ isDoctor ? initialPostalCode : userAttributes.postalCode }
        />
      </Grid>
      <Grid
        item
        xs={ 12 }
        md={ fullWidth ? 12 : 6 }
        style={ { display: isDoctor && ('' === initialCity || null == initialCity) ? 'none' : '' } }
      >
        <LabelValue label='common.city' value={ isDoctor ? initialCity : userAttributes.city } />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <LabelValue
          label='common.country'
          value={
            isDoctor
              ? t(langTypeToText[customer?.country])
              : null != child
                ? t(langTypeToText[child.country])
                : t(langTypeToText[userAttributes.country])
          }
        />
      </Grid>
      <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
        <LabelValue
          label='common.email'
          value={ isDoctor ? customer?.email : userAttributes.email }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 6 }>
        <LabelValue
          label='common.mobile_number'
          value={ isDoctor ? customer?.phone_number
            : null != child ? child.phone_number : userAttributes.mobileNumber }
        />
      </Grid>

      {
        null != userAttributes.houseNumber && '' !== userAttributes.houseNumber && !isDoctor && (
          <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
            <LabelValue
              label='common.home_number'
              value={ userAttributes.houseNumber }
            />
          </Grid>
        )
      }

      {
        null != child && null != child.house_number && '' !== child.house_number && !isDoctor && (
          <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
            <LabelValue
              label='common.home_number'
              value={ child.house_number }
            />
          </Grid>
        )
      }

      {
        isDoctor && null != customer?.house_number && '' !== customer?.house_number && (
          <Grid item xs={ 12 } md={ fullWidth ? 12 : 6 }>
            <LabelValue
              label='common.home_number'
              value={ customer?.house_number }
            />
          </Grid>
        )
      }

      {
        isKitWellness && null != extraInfo && (
          <>
            {
              questions.filter((que) => {
                return null != extraInfo?.[que.id] && 0 < extraInfo?.[que.id]?.length;
              }).map((que) => {
                let desc = 'en-US' === i18n.language ? que.description_en : que.description_el;
                if ('US' === customer?.country) {
                  desc = desc.replace('cm', 'feet').replace('kg', 'pounds').replace('εκ.', 'feet').replace('κιλά', 'pounds');
                }

                return (
                  <Grid
                    item
                    xs={ 12 }
                    md={ ('multiple_choice' === que.type || 'one_choice' === que.type) ? 12 : fullWidth ? 12 : 6 }
                    key={ que.id }
                    style={ { display: '' === extraInfo?.[que.id] ? 'none' : '' } }
                  >
                    <LabelValue
                      label={ desc }
                      value={ extraInfo?.[que.id] }
                      translate={ false }
                      isDoctor={ isDoctor || null != child }
                    />
                  </Grid>
                );
              })
            }
          </>
        )
      }

      {
        isKitPGX && null != extraInfoPGx && null != extraInfoPGx[1] && 0 < extraInfoPGx[1].length && (
          <>
            {
              questions.map((que) => {
                let desc = 'en-US' === i18n.language ? que.description_en : que.description_el;
                if ('US' === customer?.country) {
                  desc = desc.replace('cm', 'feet').replace('kg', 'pounds').replace('εκ.', 'feet').replace('κιλά', 'pounds');
                }

                return (
                  <Grid
                    item
                    xs={ 12 }
                    md={ ('multiple_choice' === que.type || 'one_choice' === que.type || 'multichoice_dropdown' === que.type) ? 12 : 6 }
                    key={ que.id }
                    style={ { display: '' === extraInfoPGx?.[que.id] ? 'none' : '' } }
                  >
                    <LabelValue
                      label={ desc }
                      value={ extraInfoPGx?.[que.id] }
                      translate={ false }
                      isDoctor={ isDoctor }
                    />
                  </Grid>
                );
              })
            }
          </>
        )
      }
      {
        hasInvitedDoctor && (
          <>
            <Grid item xs={ 12 }>
              <Typography variant='h6' style={ { color: theColor } } className={ classes.title }>
                {t('common.doctor_info')}
              </Typography>
              <Divider style={ { width: '60%' } } />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <LabelValue
                label='common.first_name'
                value={ kitHealthcareProfessionalInvitations[0]?.metadata?.firstName }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <LabelValue
                label='common.last_name'
                value={ kitHealthcareProfessionalInvitations[0]?.metadata?.lastName }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <LabelValue
                label='common.specialty'
                value={ kitHealthcareProfessionalInvitations[0]?.metadata?.specialty }
              />
            </Grid>
          </>
        )
      }
      {
        isDoctor && showAggrements && (
          <>
            <Grid item xs={ 12 }>
              <Typography variant='h6' style={ { color: theColor } } className={ classes.title }>
                {t('reg_kit.review.common.consents')}
              </Typography>
              <Divider style={ { width: '60%' } } />
            </Grid>

            {
              isKitBioAge && 'en-US' === i18n.language && (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.bioage.collect_data_consent'
                    value={ t('common.he_agrees') }
                  />
                </Grid>
              )
            }

            {
              isKitPGX ? (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.pgx.data_collection'
                    value={ t('common.he_agrees') }
                  />
                </Grid>
              ) : isKitBioAge ? (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.bioage.data_collection'
                    value={ t('common.he_agrees') }
                  />
                </Grid>
              ) : (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label={ isKitLabs || isKitOnlyNutri
                      ? 'reg_kit.review.doctor.non_pgx_non_bioage.data_collection_labs'
                      : 'reg_kit.review.doctor.non_pgx_non_bioage.data_collection' }
                    value={ t('common.he_agrees') }
                  />
                </Grid>
              )
            }

            {
              isKitBioAge ? (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.bioage.anonymized_consent'
                    value={ true === kitAgreements[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]
                      ? t('common.yes') : t('common.no') }
                  />
                </Grid>
              ) : (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.non_bioage.anonymized_consent'
                    value={ true === kitAgreements[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]
                      ? t('common.yes') : t('common.no') }
                  />
                </Grid>
              )
            }

            {
              !isKitPGX && (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.doctor.non_pgx.advertized_content_consent'
                    value={ true === kitAgreements[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]
                      ? t('common.yes') : t('common.no') }
                  />
                </Grid>
              )
            }

            {
              null != sampleCollectionDate && (
                <>
                  <Grid item xs={ 12 }>
                    <Typography
                      variant='h6'
                      style={ { color: theColor } }
                      className={ classes.title }
                    >
                      {t('reg_kit.review.common.sample_date_title')}
                    </Typography>
                    <Divider style={ { width: '60%' } } />
                  </Grid>
                  <Grid item xs={ 12 }>
                    <LabelValue
                      label='reg_kit.review.common.sample_date'
                      value={ sampleCollectionDate }
                    />
                  </Grid>
                </>
              )
            }

          </>
        // <Grid item xs={ 12 }>
        //   <LabelValue
        //     label='PROCESSING OF PERSONAL DATA'
        //     value={ t('agree') }
        //   />
        // </Grid>

        )
      }
      {!isDoctor && showAggrements && (
        <>
          <Grid item xs={ 12 }>
            <Typography variant='h6' style={ { color: theColor } } className={ classes.title }>
              {t('reg_kit.review.common.consents')}
            </Typography>
            <Divider style={ { width: '60%' } } />
          </Grid>

          {
            isKitPGX ? (
              <Grid item xs={ 12 }>
                <LabelValue
                  label='reg_kit.review.non_doctor.pgx.analysis_real_data_consent'
                  value={ t('common.agree') }
                />
              </Grid>
            )
              : (
                <Grid item xs={ 12 }>
                  <LabelValue
                    label={ isKitBioAge
                      ? 'reg_kit.review.non_doctor.bioage.analysis_real_data_consent'
                      : 'reg_kit.review.non_doctor.non_pgx_non_bioage.analysis_real_data_consent' }
                    value={ t('common.agree') }
                  />
                </Grid>
              )
          }

          {
            isKitPGX ? (
              <Grid item xs={ 12 }>
                <LabelValue
                  label='reg_kit.review.non_doctor.pgx.collect_data_consent'
                  value={ t('common.agree') }
                />
              </Grid>
            ) : (
              <Grid item xs={ 12 }>
                <LabelValue
                  label={ child
                    ? 'reg_kit.review.non_doctor.child.collect_data_consent'
                    : isKitBioAge ? 'reg_kit.review.non_doctor.bioage.collect_data_consent'
                      : isKitLabs
                        ? 'reg_kit.review.non_doctor.adult.collect_data_consent_labs'
                        : 'reg_kit.review.non_doctor.adult.collect_data_consent' }
                  value={ t('common.agree') }
                />
              </Grid>
            )
          }
          <Grid item xs={ 12 }>
            <LabelValue
              label={ child
                ? 'reg_kit.review.non_doctor.child.anonymized_consent'
                : isKitBioAge ? 'reg_kit.review.non_doctor.bioage.anonymized_consent'
                  : 'reg_kit.review.non_doctor.adult.anonymized_consent' }
              value={ true === kitAgreements[generalConsents.ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT]
                ? t('common.yes') : t('common.no') }
            />
          </Grid>

          {
            !isKitPGX && (
              <>
                <Grid item xs={ 12 }>
                  <LabelValue
                    label={ isKitLabs
                      ? 'reg_kit.review.non_doctor.non_pgx.advertized_content_consent_labs'
                      : 'reg_kit.review.non_doctor.non_pgx.advertized_content_consent' }
                    value={ true === kitAgreements[marketingConsents.EMAIL_INFORMATIVE_MATERIAL_CONSENT]
                      ? t('common.yes') : t('common.no') }
                  />
                </Grid>

                {/* <Grid item xs={ 12 }>
                  <LabelValue
                    label='Ενημέρωση για την προστασία δεδομένων προσωπικού χαρακτήρα'
                    value={ t('agree') }
                  />
                </Grid> */}
              </>
            )
          }
          {
            null != guardian && '' !== guardian && (
              // Not used
              <Grid item xs={ 12 }>
                <LabelValue
                  label='Γονέας ή κηδεμόνας'
                  value={ guardian }
                />
              </Grid>
            )
          }

          {
            null != sampleCollectionDate && (
              <>
                <Grid item xs={ 12 }>
                  <Typography
                    variant='h6'
                    style={ { color: theColor } }
                    className={ classes.title }
                  >
                    {t('reg_kit.review.common.sample_date_title')}
                  </Typography>
                  <Divider style={ { width: '60%' } } />
                </Grid>
                <Grid item xs={ 12 }>
                  <LabelValue
                    label='reg_kit.review.common.sample_date'
                    value={ sampleCollectionDate }
                  />
                </Grid>
              </>
            )
          }

        </>
      )}

    </Grid>
  );
};

const KitRegistrationAnswersReview = ({ goToPrevPage, kitId, onError, onSubmit }) => {
  const [ submitting, setSubmitting ] = useState(false);

  const [ isQuestionnaireLoaded, setIsQuestionnaireLoaded ] = useState(false);

  const formRef = useRef(null);
  const [ alphaBankFormData, setAlphaBankFormData ] = useState(null);

  const { isDoctor } = useAuth();

  const { setFetchKits } = useFetching();

  const { isLoading, updateKitMetadata, serial, isKitBioAge, isKitCvd,
    isKitOnlyNutri, kitHealthcareProfessionalInvitations, kitAgreements,
    // eslint-disable-next-line camelcase
    sampleCollectionDate, locale, data, kitMetadata, isKitPGX, child, cost } = useKit(kitId);

  const serialCashStatus = data?.serial_number_id?.metadata?.cashStatus;
  const serialCashStatusOther = data?.serial_number_id?.cashStatus;
  const thematicPackageId = data?.thematic_package?.id;
  const regiKitCashStatus = data?.metadata?.cashStatus;

  const hasToPay = useMemo(() => {
    return (isKitPGX || isKitCvd || isKitOnlyNutri) && 'paid' !== serialCashStatus && 'paid' !== serialCashStatusOther
    && 'free' !== serialCashStatus && 'free' !== serialCashStatusOther
    && 'paid' !== regiKitCashStatus && 'free' !== regiKitCashStatus;
  }, [ isKitPGX, isKitCvd, isKitOnlyNutri, serialCashStatus, serialCashStatusOther, regiKitCashStatus ]);

  const { userAttributes, userAttributesLoaded } = useAuth();

  const params = useLocation().search;
  const bankMsg = new URLSearchParams(params).get('msg');

  const buttonlbl = (hasToPay && 'success' !== bankMsg && !isDoctor) ? 'reg_kit.review.non_doctor.pgx.pay_kit' : 'reg_kit.review.common.complete_registration';

  const customer = data?.customer_id;

  const guardian = kitMetadata?.guardian;

  const [ afterPaymentDialogOpen, setAfterPaymentDialogOpen ] = useState(true);

  const { setShowNewRegiKitModal, setJustBoughtAKit } = React.useContext(AppCtx);
  const queryClient = useQueryClient();
  const classes = useStyles();

  const hasQuestionnaire = isKitBioAge || isKitOnlyNutri || isKitCvd;

  const [ sureDialogOpen, setSureDialogOpen ] = useState(false);

  const { client } = useAPI();

  const { t } = useTranslation();

  const isKitPaid = 'success' === bankMsg;

  const [ paymentDetails, setPaymentDetails ] = useState({
    first_name: '',
    last_name: '',
    billing_country: '',
    billing_address: '',
    billing_zip_code: '',
    billing_city: '',
    customer_email: '',
    customer_phone: '',
    installments: 0,
  });

  const [ paymentType, setPaymentType ] = useState('credit-card');

  useEffect(() => {
    setPaymentDetails({
      first_name: userAttributes.firstName,
      last_name: userAttributes.lastName,
      billing_country: userAttributes.country,
      billing_address: userAttributes.streetAddress,
      billing_zip_code: userAttributes.postalCode,
      billing_city: userAttributes.city,
      customer_email: userAttributes.email,
      customer_phone: userAttributes.mobileNumber,
      installments: 0,
    });
  }, [ userAttributesLoaded ]);

  let color = 'red';
  if (isKitBioAge) {
    color = orangeColor;
  } else if (isKitCvd) {
    color = redColor;
  } else if (isKitOnlyNutri) {
    color = blueColor;
  }

  let theColor = greenColor;
  if (isDoctor) {
    theColor = '#648DAE';
  }

  useEffect(async () => {
    if (alphaBankFormData) {
      setTimeout(
        () => {
          formRef.current.submit();
          setSubmitting(false);
        },
        3000,
      );
    }
  }, [ alphaBankFormData ]);

  const handlePGxKitSubmit = (newCost = undefined, promoCodeEntity = undefined) => {
    setSubmitting(true);
    setSureDialogOpen(false);

    const requestBody = {
      first_name: paymentDetails.first_name,
      last_name: paymentDetails.last_name,
      billing_country: paymentDetails.billing_country,
      company: 'none',
      billing_address_1: paymentDetails.billing_address,
      billing_address_2: paymentDetails.billing_address,
      billing_zip_code: paymentDetails.billing_zip_code,
      billing_city: paymentDetails.billing_city,
      billing_state: paymentDetails.billing_city,
      customer_email: paymentDetails.customer_email,
      customer_phone: paymentDetails.customer_phone,
      installments: paymentDetails.installments,
      kit_id: kitId,
      total_price: newCost ?? cost,
      promotion_code: promoCodeEntity ?? null,
    };

    const url = process.env.REACT_APP_API_BANK;

    client.post(url, requestBody).then((dd) => {
      setAlphaBankFormData({ formData: dd.form_data, postUrl: dd.post_url });
    });
  };

  const handleKitSubmit = (promoCodeEntity = undefined) => {
    if ('credit-card' === paymentType && 'success' !== bankMsg && !isDoctor && hasToPay) {
      return handlePGxKitSubmit();
    }

    return Promise.resolve()
      .then(() => {
        setSubmitting(true);
        setSureDialogOpen(false);

        if ('bank' === paymentType && !isDoctor) {
          return client.post('payment-transactions', {
            kitId: data.id,
            payment_method: 'bank_deposit',
            promotion_code: promoCodeEntity ?? null,
          });
        }

        return null;
      })
      .then(() => {
        const now = new Date();
        const month = `${ now.getMonth() + 1 }`.padStart(2, '0');
        const date = `${ now.getDate() }`.padStart(2, '0');
        const registrationDoneDate = `${ now.getFullYear() }-${ month }-${ date }`;

        if (isKitBioAge) {
          return updateKitMetadata({
            registrationStatus: 'analysis',
            metadata: { registrationDoneDate },
          }).then(() => {
            client.post('/dynamic-reports/createBiologicalAgeDynamicReport', { serial_number: serial });
          });
        }
        return updateKitMetadata({
          registrationStatus: null != data.ancestor_id ? 'sample_received' : 'registration_done',
          metadata: { registrationDoneDate },
        });
      })
      .catch(() => {
        onError();
      })
      .finally(() => {
        queryClient.invalidateQueries([ 'registration-kits', 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 0 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 1 ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, 'COUNT' ]);
        queryClient.invalidateQueries([ CollectionKeys.Kits, { kitId: `${ kitId }` }]);
      })
      .finally(() => {
        setFetchKits(1);
      })
      .finally(() => {
        setSubmitting(false);
        setShowNewRegiKitModal(true);
        onSubmit();
      });
  };

  useEffect(() => {
    if ('success' === bankMsg) {
      setJustBoughtAKit(true);
      handleKitSubmit();
    }
  }, [ bankMsg ]);

  return (
    <div>
      {
        ('fail' === bankMsg || 'cancel' === bankMsg) && afterPaymentDialogOpen && (
          <Dialog
            open={ afterPaymentDialogOpen }
            onClose={ () => { setAfterPaymentDialogOpen(false); } }
          >
            <DialogTitle>
              {
                'fail' === bankMsg && (
                  <>
                    {t('reg_kit.review.non_doctor.pgx.payment_error')}
                  </>
                )
              }

              {
                'cancel' === bankMsg && (
                  <>
                    {t('reg_kit.review.non_doctor.pgx.cancel_payment')}
                  </>
                )
              }

            </DialogTitle>
            <DialogActions style={ { gap: '20px', padding: '16px 24px' } }>
              <Button
                style={ { background: '#93C221', color: 'white' } }
                onClick={ () => { setAfterPaymentDialogOpen(false); } }
                autoFocus
              >
                {t('ok')}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }
      {
        alphaBankFormData && (
          <form
            ref={ formRef }
            action={ alphaBankFormData.postUrl }
            method='POST'
            style={ { display: 'none', visibility: 'hidden', width: 0, height: 0, overflow: 'hidden' } }
          >
            {
              Object.keys(alphaBankFormData.formData)
                .map((k) => {
                  return (
                    <input
                      key={ k }
                      type='text'
                      name={ k }
                      value={ alphaBankFormData.formData[k] }
                    />
                  );
                })
            }
            <button type='submit'> Post to alpha bank </button>
          </form>
        )
      }
      {
        !isKitPaid && !isDoctor && hasToPay ? (
          <Payment
            sureDialogOpen={ sureDialogOpen }
            setSureDialogOpen={ setSureDialogOpen }
            paymentDetails={ paymentDetails }
            setPaymentDetails={ setPaymentDetails }
            handlePGxKitSubmit={ (newCost = undefined, promoCodeEntity = undefined) => {
              return 'credit-card' === paymentType ? handlePGxKitSubmit(newCost, promoCodeEntity?.id) : handleKitSubmit(promoCodeEntity?.id);
            } }
            isKitPGX={ isKitPGX }
            isKitOnlyNutri={ isKitOnlyNutri }
            isKitCvd={ isKitCvd }
            paymentType={ paymentType }
            setPaymentType={ setPaymentType }
            kitName={ data?.thematic_package?.real_name }
            cost={ cost }
            thematicPackage={ thematicPackageId }
          />
        ) : (
          <Dialog
            open={ sureDialogOpen }
            onClose={ () => { setSureDialogOpen(false); } }
            aria-labelledby='alert-dialog-title-review'
            aria-describedby='alert-dialog-description-review'
          >
            <DialogTitle id='alert-dialog-title-review'>
              {
                (isKitPGX && !isDoctor && hasToPay) ? (
                  <>
                    {t('reg_kit.review.common.payment_confirmation')}
                  </>
                ) : (
                  <>
                    {
                      t('reg_kit.review.common.complete_registration_warning')
                    }
                  </>
                )
              }
            </DialogTitle>
            <DialogActions style={ { gap: '20px', padding: '16px 24px' } }>
              <Button
                style={ { background: 'red', color: 'white' } }
                onClick={ () => { setSureDialogOpen(false); } }
              >
                {t('common.no')}
              </Button>
              <Button
                style={ { background: '#93C221', color: 'white' } }
                onClick={ () => { handleKitSubmit(); } }
                autoFocus
              >
                {t('common.yes')}
              </Button>
            </DialogActions>
          </Dialog>
        )
      }

      <Alert severity='warning' className={ classes.alert }>
        <AlertTitle>
          {/* {
            isDoctor ? isKitPGX ? t('reviewYourAnwsers1PGx') : t('reviewYourAnwsers1')
          }
          {' '}

          <u>
            <strong>
              {t('ΔΕΝ')}
            </strong>
          </u>

          {' '}

          {
            isDoctor ? t('reviewYourAnwsers2')
              : t('θα μπορείτε να κάνετε αλλαγές.')
          } */}

          {
            isDoctor
              ? t('reg_kit.review.doctor.common.review_info_warning')
              : null == child
                ? t('reg_kit.review.non_doctor.adult.review_info_warning')
                : t('reg_kit.review.non_doctor.child.review_info_warning')
          }
        </AlertTitle>
      </Alert>

      <InfoReview
        kitId={ kitId }
        kitHealthcareProfessionalInvitations={ kitHealthcareProfessionalInvitations }
        kitAgreements={ kitAgreements }
        sampleCollectionDate={ sampleCollectionDate }
        locale={ locale }
        customer={ customer }
        guardian={ guardian }
      />

      {
        (hasQuestionnaire) && (
          <div style={ { marginTop: '10px' } }>
            <QuestionnaireAnswers
              questionVariant='body2'
              serial={ serial }
              color={ color }
              titleVariant='h6'
              titleClassName={ classes.title }
              titleColor={ theColor }
              setIsQuestionnaireLoaded={ setIsQuestionnaireLoaded }
              questionColor='#5c5b5a'
              smallGap
              isChild={ null != child }
            />
          </div>
        )
      }

      <form
        noValidate
        onSubmit={ (e) => {
          e.preventDefault();
          setSureDialogOpen(true);
        } }
      >
        <FormSectionSubmit
          label={ buttonlbl }
          disabled={ false }
          loading={ submitting || isLoading || (!isQuestionnaireLoaded && hasQuestionnaire) }
          goToPrevPage={ goToPrevPage }
          color={ isDoctor ? '#526D9C' : undefined }
        />
      </form>
    </div>
  );
};

export default KitRegistrationAnswersReview;
