import React, { useMemo } from 'react';
import { useOptionSerial } from 'NavigationProvider';
import ExplanationOfResults from 'pages/ExplanationOfResults';
import { useNutritionDataReport } from 'models/kits';
import { I18nextProvider } from 'react-i18next';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const NutrigenetixExplanation = () => {
  const { serial } = useOptionSerial('nutriGenetix');
  const { locale } = useNutritionDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <ExplanationOfResults serial={ serial } />
    </I18nextProvider>
  );
};

export default NutrigenetixExplanation;
