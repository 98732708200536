import React, { useMemo } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { useQuery } from 'react-query';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { BottomPart, WellnessPdf } from 'pages/iDNA3ToOne/iDNA3ToOne';
import { makeStyles, Icon } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import clsx from 'clsx';
import { I18nextProvider, useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import { useKits, useNutritionDataReport } from 'models/kits';
import useAPI from 'hooks/api-hooks';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const iDNA3ToOneCards = [
  {
    id: 'diet',
    title: 'diet',
    fields: [
      {
        text: 'weightManagement',
        icon: '/scale.svg',
        image: '/image_Διαχείριση Βάρους.png',
        nurl: 'nutritionWeightManagement',
      },
      {
        text: 'cardiometabolicHealth',
        icon: '/heart.svg',
        image: '/image_Καρδιομεταβολική υγεία.png',
        nurl: 'nutritionCardiometabolicHealth',
      },
      {
        text: 'foodSensitivities',
        icon: '/food.svg',
        image: '/image_Τροφικές-ευαισθησίες.png',
        nurl: 'nutritionFoodSensitivities',
      },
      {
        text: 'detoxificationCapacity',
        icon: '/body.svg',
        image: '/image_Αποτοξίνωση&αντιοξειδωτικές-ανάγκες.png',
        nurl: 'vitaminsDetoxificationCapacityAntioxidantNeeds',
      },
    ],
    url: '',
    color: '#F5C91E',
  },
];

export const yellowColor = '#F5C91E';

const useStyles = makeStyles(() => {
  return {
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        gap: '8px',
      },
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    cardField: {
      width: '220px',
      // width: '282px',
      display: 'flex',
      alignItems: 'center',
      gap: '25px',
      marginLeft: '8%',
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: 'min(43.4717px, 9vw)',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: 'min(28px, 5.8vw)',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: yellowColor,
    },
    whatYouWillFind: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    infoOuter: {
      background: yellowColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '250px',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media (max-width:600px)': {
        bottom: '-25px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      minHeight: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    categories: {
      borderRadius: '15px',
      padding: '25px',
      background: yellowColor,
      display: 'flex',
      position: 'relative',
    },
    category: {
      color: 'white',
    },
    innerCategories: {
      background: 'white',
      width: '100%',
      padding: '70px 0',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-around',
      flexWrap: 'wrap',
      rowGap: '10px',
    },
    innerCategoriesMobile: {
      background: 'white',
      width: '100%',
      display: 'flex',
      gap: '30px',
      flexWrap: 'wrap',
      padding: '5px',
    },
    learnMoreArrowOther: {
      // marginTop: '2px',
      // transform: 'rotate(-45deg)',
      marginLeft: '5px',
    },
    learnMoreOther: {
      color: 'white',
      borderRadius: '50px',
      boxShadow: '0px 2.01762px 6.72539px 0px rgba(0, 0, 0, 0.02), 0px 6.77675px 22.58917px 0px rgba(0, 0, 0, 0.03), 0px 30.33962px 101.13208px 0px rgba(0, 0, 0, 0.05)',

      height: '56px',
      width: 'min(230px, 80%)',
      display: 'flex',
      textAlign: 'center',
      position: 'absolute',
      bottom: '00px',
      alignItems: 'center',
      justifyContent: 'center',
      // right: '5%',
      left: 'calc(95% - min(255px, 80%))',

      fontFamily: 'Roboto',
      fontSize: '16px',
      fontStyle: 'normal',
      fontWeight: 350,
      lineHeight: '136%',

      transition: 'all .4s',

      '&:hover': {
        cursor: 'pointer',
        width: 'min(280px, 85%)',
        boxShadow: '0px 6.77675px 22.58917px 0px rgba(133, 133, 133, 0.40)',
      },
    },
  };
});

const Categories = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { isSm: _1 } = useMediaQueries();
  const { navigateToPage } = useNavigation();

  return (
    <div className={ classes.categories }>
      <div className={ classes.innerCategories }>
        {
          iDNA3ToOneCards[0].fields.map((card) => {
            return (
              <div
                className={ clsx(classes.cardField, classes.smallText) }
                key={ card.text }
                aria-hidden='true'
                style={ { marginLeft: '0' } }
              >
                <Icon
                  className={ classes.cardIcon }
                  style={ { background: yellowColor } }
                >
                  <img src={ card.icon } alt='' />
                </Icon>
                {t(card.text)}
              </div>
            );
          })
        }
      </div>

      {/* <div style={ { display: 'flex', */}
      <div style={ { display: 'none',
        justifyContent: 'center',
        marginBottom: '-20px' } }
      >
        <div
          className={ classes.learnMoreOther }
          style={ { background: yellowColor } }
          onClick={ () => { navigateToPage({ pageId: 'nutritionWeightManagement' }); } }
          aria-hidden='true'
        >
          {t('Δες το report σου')}

          <ArrowForwardIcon className={ classes.learnMoreArrowOther } />
        </div>
      </div>
    </div>
  );
};

const NutritionInner = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  const { serial: newSerial } = useOptionSerial('nutrition');

  const { client } = useAPI();

  const { data, isLoading } = useKits();
  const theKit = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; });
  let cashStatus = theKit?.metadata?.cashStatus;
  if (cashStatus === undefined) {
    cashStatus = theKit?.serial_number_id?.cashStatus;
  }

  const appointmentAvailability = data?.find((kit) => { return kit.serial_number_id.serial === newSerial; })?.appointment_availability;

  const { data: bookedAppointments, isLoading: isBookedAppointmentsLoading } = useQuery(
    [ 'Appointment', 'booked', newSerial ], () => {
      return client.get('/appointments/getTimeSlots', { params: { serial_number: newSerial, serial_number_eq: newSerial } });
    },
  );

  const { isMdDown } = useMediaQueries();

  const { userAttributes } = useAuth();

  if (!userAttributes || isLoading || isBookedAppointmentsLoading || isBookedAppointmentsLoading) {
    return null;
  }

  const alreadyHadAppointment = null !== bookedAppointments && 0 < bookedAppointments.length;

  const {
    firstName,
  } = userAttributes;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart }>
        <div style={ { display: 'flex',
          justifyContent: 'space-between',
          backgroundImage: isMdDown ? '' : 'url("/food.png")',
          backgroundRepeat: 'no-repeat',
          minHeight: '160px',
          backgroundPosition: 'right' } }
        >
          <div style={ { marginTop: '1rem' } }>
            <div className={ classes.title }>
              {t('Διατροφή')}
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {newSerial}
            </div>
          </div>
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          className={ classes.whatYouWillFind }
          style={ {
            lineHeight: '161.19%',
          } }
        >
          <div style={ { width: '72%' } }>
            {t('whatYouWillFindNutrition')}
          </div>
        </div>
      </div>
      <Categories />

      {
        (('paid' === cashStatus || true === appointmentAvailability) && !alreadyHadAppointment)
          ? (
            <BottomPart
              pdfDownloadLink='nutritionPdfVersion'
              resultsPageLink='nutritionExplanationOfResults'
              buttonColor={ yellowColor }
            />
          ) : (
            <WellnessPdf
              navigateToPage={ navigateToPage }
              url='nutritionPdfVersion'
              buttonColor={ yellowColor }
            />
          )
      }

    </div>
  );
};

const Nutrition = () => {
  const { serial: newSerial } = useOptionSerial('nutrition');
  const { locale } = useNutritionDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <NutritionInner />
    </I18nextProvider>
  );
};

export default Nutrition;
