import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import React from 'react';

const useStylesBootstrap = makeStyles((theme) => {
  return {
    tooltipPlacementBottom: {
      boxShadow: '0 2px 10px 0 rgb(23 70 161 / 11%)', // theme.shadows[3],
      fontSize: '12px',
      fontFamily: 'Roboto',
      color: theme.palette.secondary.main,
      margin: 0,
    },
    arrow: {
      color: theme.palette.alternate.main,
    },
    tooltip: {
      backgroundColor: theme.palette.alternate.main,
    },
  };
});

const AppTooltip = (props) => {
  const classes = useStylesBootstrap();

  return (
    <Tooltip
      placement='bottom'
      arrow
      classes={ classes }
      { ...props }
    />
  );
};

export default AppTooltip;
