import React from 'react';
import { useOptionSerial } from 'NavigationProvider';
import PDFViewer from '../../components/PDFViewer';

const VitaminsPdfVersion = () => {
  const { serial: newSerial } = useOptionSerial('vitamins');

  return (
    <PDFViewer serial={ newSerial } />
  );
};

export default VitaminsPdfVersion;
