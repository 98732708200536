import React from 'react';
import { useNutritionData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { adviceImages } from '../../iDNA3ToOne/CardiometabolicHealth';
import { yellowColor } from '../Nutrition';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NCardioMetabolicHealth';

const NTransFat = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useNutritionData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Καρδιομεταβολική Υγεία' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τα';

    if ('NCAT15' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToSalt;
      url = profileToUrl.sensitivityToSalt;
    } else if ('NCAT5' === filtered.ID) {
      iconPath = profileToIcon.saturatedFat;
      url = profileToUrl.saturatedFat;
    } else if ('NCAT10' === filtered.ID) {
      iconPath = profileToIcon.fattyAcids;
      url = profileToUrl.fattyAcids;
    } else if ('NCAT4' === filtered.ID) {
      iconPath = profileToIcon.transFats;
      url = profileToUrl.transFats;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    }

    return { ...filtered, icon: iconPath, url, prefix };
  });

  const tab = {
    color: yellowColor,
    id: 'nutritionalGuide',
    title: 'Διατροφή',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ dietCard } activePage='nutritionCardiometabolicHealth' isNutrition />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Τρανς λιπαρά'
        activeColor={ dietCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.transFats }
      />
    </div>
  );
};

export default NTransFat;
