import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography, Avatar, Select, MenuItem } from '@material-ui/core';
import TheAccordion from 'components/app/TheAccordion';
import clsx from 'clsx';
import React from 'react';
import Badge from '@material-ui/core/Badge';
import * as _ from 'lodash';
import useMediaQueries from '../../../hooks/media-query-hooks';
import { useNavigation, useOptionSerial } from '../../../NavigationProvider';

const WIDTH_OFFSET = 15;

const useStyles = makeStyles((theme) => {
  return {
    listItemHover: {
      '&:hover':
      {
        background: 'linear-gradient(90deg, #EDFBD8 0%, #EDFBD8 100%)',
      },
    },
    folder: {
      textDecoration: 'none',
    },
    hideBorder: {
      '&.MuiExpansionPanel-root:before': {
        display: 'none',
      },
    },
    list: {
      display: 'inline-flex',
      overflow: 'auto',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
      },
    },
    listItem: {
      flex: 0,
      justifyContent: 'flex-start',
      // padding: '6px 10px 6px 4px',
      padding: '13px 13px 13px 8px',
      // padding: '13px',
      margin: '6px 0 6px 0',
      borderRadius: '8px',
      [theme.breakpoints.up('md')]: {
      },
    },
    listItemActive: {
      [theme.breakpoints.up('md')]: {
        background: '#93C221',
      },
      [theme.breakpoints.down('sm')]: {
        background: '#93C221',
      },
      '& .menu__item': {
        color: 'white',
      },
    },
  };
});

export const isActive = (page, pageId) => {
  if (page.id === pageId) {
    return true;
  }

  if (page.alternativeIds !== undefined && page.alternativeIds.includes(pageId)) {
    return true;
  }

  if ('folder' === page.type) {
    for (let i = 0; i < page.items.length; i++) {
      if (isActive(page.items[i], pageId)) {
        return true;
      }
    }
  }

  return false;
};

const SerialSelect = ({ value, setValue, values }) => {
  return (
    <div style={ { marginLeft: 'auto', marginRight: '40px' } }>
      <Select
        value={ value }
        onChange={ (e) => {
          e.stopPropagation();
          setValue(e.target.value);
        } }
      >
        {
          values.map((kit) => {
            return (
              <MenuItem key={ kit } value={ kit }>
                <Typography style={ { fontSize: '12px' } }>
                  {kit}
                </Typography>
              </MenuItem>
            );
          })
        }
      </Select>
    </div>
  );
};

const Menu = (
  {
    pages,
    isChild = false,
    width = 100,
    cardioKits,
    threeToOneKits,
    nutritionKits,
    vitaminsKits,
    sportsKits,
    bioAgeKits,
    pgxKits,
    nutrigenetixKits,
  },
) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMd, isMdDown } = useMediaQueries();
  const { activePageId: pageId, navigateToPage } = useNavigation();
  const { serial: cardioSerial, setSerial: setCardioSerial } = useOptionSerial('IDNA Cardio Health');
  const { serial: threeToOneSerial, setSerial: setThreeToOneSerial } = useOptionSerial('3-in-1');
  const { serial: nutritionalSerial, setSerial: setNutritionalSerial } = useOptionSerial('nutrition');
  const { serial: vitaminsSerial, setSerial: setVitaminsSerial } = useOptionSerial('vitamins');
  const { serial: sportsSerial, setSerial: setSportsSerial } = useOptionSerial('sports');
  const { serial: bioAgeSerial, setSerial: setBioAgeSerial } = useOptionSerial('bioAge');
  const { serial: pgxSerial, setSerial: setPgxSerial } = useOptionSerial('pgx');
  const { serial: nutriGenetixSerial, setSerial: setNutriGenetixSerial } = useOptionSerial('nutriGenetix');

  const filteredPages = pages.filter((item) => {
    const {
      isMenuHidden,
    } = { ...{ isMenuHidden: false }, ...item };
    return !isMenuHidden;
  });

  return (
    <List disablePadding className={ classes.list }>
      {filteredPages
        .map((item, pIdx) => {
          const href = `/?pid=${ item.id }`;
          const {
            Icon,
            badgeProps: { count: badgeCount },
          } = { ...{ isMenuHidden: false, badgeProps: { count: undefined } }, ...item };

          if ('folder' === item.type) {
            return (
              <TheAccordion
                expanded={ isActive(item, pageId) }
                key={ item.id }
                isActive={ isActive(item, pageId) }
                isFirstPage={ 0 === pIdx }
                isLastPage={ filteredPages.length - 1 === pIdx }
                isTopLevel={ 100 - WIDTH_OFFSET === width }
                numOfItems={ item.items.filter((it) => {
                  return null == it.isMenuHidden || false === it.isMenuHidden;
                })?.length }
                title={ (
                  <ListItem
                    aria-hidden
                    style={ { width: `${ width }%`,
                      marginLeft: isChild ? 'auto' : '0',
                      background: isActive(item, pageId) ? null != item.color ? item.color : '#93C221' : '',
                      ...(85 === width) && { marginRight: '6px' } } }
                    component={ true === item.isDisabled ? 'div' : 'a' }
                    href={ href }
                    disableGutters
                    onClick={ (e) => {
                      e.preventDefault();
                      if (true !== item.isDisabled) {
                        navigateToPage({ pageId: item.id });
                      }
                    } }
                    className={ clsx(
                      classes.listItem,
                      isActive(item, pageId) ? classes.listItemActive : classes.listItemHover,
                    ) }
                  >
                    {
                      Object.prototype.hasOwnProperty.call(item, 'Icon')
                  && (
                    <Badge badgeContent={ badgeCount } color='error'>
                      <Avatar style={ {
                        background: isActive(item, pageId) ? '#93C221' : '#F4F4F4',
                      } }
                      >
                        <Icon
                          isActive={ isActive(item, pageId) }
                        />
                      </Avatar>
                    </Badge>
                  )
                    }
                    <Typography
                      variant='subtitle1'
                      color='textSecondary'
                      className='menu__item'
                      noWrap={ !isMd }
                      style={ { marginLeft: !Object.prototype.hasOwnProperty.call(item, 'Icon') ? '0px' : isMdDown ? '5px' : theme.spacing(3) } }
                    >
                      {t(item.title)}
                    </Typography>
                    { 'iDNACardioHealth' === item.id && null != cardioKits && 1 < cardioKits.length && (
                      <SerialSelect
                        value={ cardioSerial }
                        values={ cardioKits }
                        setValue={ setCardioSerial }
                      />
                    )}
                    { 'nutritionalGuide' === item.id && null != nutritionKits && 1 < nutritionKits.length && (
                      <SerialSelect
                        value={ nutritionalSerial }
                        values={ nutritionKits }
                        setValue={ setNutritionalSerial }
                      />
                    )}
                    { 'sportsPage' === item.id && null != sportsKits && 1 < sportsKits.length && (
                      <SerialSelect
                        value={ sportsSerial }
                        values={ sportsKits }
                        setValue={ setSportsSerial }
                      />
                    )}
                    { 'vitaminsPage' === item.id && null != vitaminsKits && 1 < vitaminsKits.length && (
                      <SerialSelect
                        value={ vitaminsSerial }
                        values={ vitaminsKits }
                        setValue={ setVitaminsSerial }
                      />
                    )}
                    { 'biologicalAge' === item.id && null != bioAgeKits && 1 < bioAgeKits.length && (
                      <SerialSelect
                        value={ bioAgeSerial }
                        values={ bioAgeKits }
                        setValue={ setBioAgeSerial }
                      />
                    )}
                    { 'iDNATest3to1' === item.id && null != threeToOneKits && 1 < threeToOneKits.length && (
                      <SerialSelect
                        value={ threeToOneSerial }
                        values={ threeToOneKits }
                        setValue={ setThreeToOneSerial }
                      />
                    )}
                    { 'iDNAPGxCNS' === item.id && null != pgxKits && 1 < pgxKits.length && (
                      <SerialSelect
                        value={ pgxSerial }
                        values={ pgxKits }
                        setValue={ setPgxSerial }
                      />
                    )}
                    { 'nutriGenetix' === item.id && null != nutrigenetixKits && 1 < nutrigenetixKits.length && (
                      <SerialSelect
                        value={ nutriGenetixSerial }
                        values={ nutrigenetixKits }
                        setValue={ setNutriGenetixSerial }
                      />
                    )}
                  </ListItem>
                ) }
              >
                <div style={ { position: 'relative' } }>
                  <Menu
                    pages={ item.items }
                    isChild
                    width={
                      (100 === width)
                        ? width - WIDTH_OFFSET : width - WIDTH_OFFSET * 1.5
                    }
                    cardioKits={ cardioKits }
                    threeToOneKits={ threeToOneKits }
                    nutritionKits={ nutritionKits }
                    vitaminsKits={ vitaminsKits }
                    sportsKits={ sportsKits }
                    bioAgeKits={ bioAgeKits }
                    pgxKits={ pgxKits }
                    nutrigenetixKits={ nutrigenetixKits }
                  />
                </div>
              </TheAccordion>
            );
          }

          return (
            <ListItem
              style={ { width: `${ width }%`,
                marginLeft: isChild ? 'auto' : '0',
                ...(62.5 === width) && {
                  padding: '10px 10px 10px 8px',
                  marginRight: 'calc(22.5% + 6px)',
                },
                background: isActive(item, pageId) ? null != item.color ? item.color : '#93C221' : '' } }
              key={ item.id }
              component={ true === item.isDisabled ? 'div' : 'a' }
              href={ href }
              onClick={ (e) => {
                e.preventDefault();
                if (true !== item.isDisabled) {
                  navigateToPage({ pageId: item.id });
                }
              } }
              className={ clsx(
                classes.listItem,
                isActive(item, pageId) ? classes.listItemActive : classes.listItemHover,
              ) }
              disableGutters
            >
              {
                Object.prototype.hasOwnProperty.call(item, 'Icon')
                  && (
                    <Badge badgeContent={ badgeCount } color='error'>
                      <Avatar style={ {
                        background: isActive(item, pageId) ? '#93C221'
                          : item.isDisabled ? '#a8a8a8' : '#F4F4F4',
                      } }
                      >
                        <Icon
                          isActive={ pageId === item.id }
                          isDisabled={ item.isDisabled }
                        />
                      </Avatar>
                    </Badge>
                  )
              }
              <Typography
                variant='subtitle1'
                color='textSecondary'
                className='menu__item'
                noWrap={ !isMd }
                style={ { marginLeft: !Object.prototype.hasOwnProperty.call(item, 'Icon') ? '0px' : isMdDown ? '5px' : theme.spacing(3) } }
              >
                {t(item.title)}
              </Typography>
            </ListItem>
          );
        })}
    </List>
  );
};

export default Menu;
