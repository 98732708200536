import { useOptionSerial } from 'NavigationProvider';
import React, { useMemo } from 'react';
import { useBioAgeDataReport } from 'models/kits';
import QuestionnaireAnswers from 'components/QuestionnaireAnswers';
import { I18nextProvider } from 'react-i18next';
import { orangeColor } from './BiologicalAge';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const BioAgeQuestionnaireAnswers = () => {
  const { serial } = useOptionSerial('bioAge');
  const { locale } = useBioAgeDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <QuestionnaireAnswers serial={ serial } color={ orangeColor } />
    </I18nextProvider>
  );
};

export default BioAgeQuestionnaireAnswers;
