import React from 'react';
import clsx from 'clsx';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DoneIcon from '@material-ui/icons/Done';
import { Typography, Accordion, AccordionSummary, AccordionDetails, Dialog, Avatar } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useBioAgeData } from 'models/kits';
import { useAuth } from 'AuthCtx';
import { explanationTypes } from './BioAgeAnalysis';
import { Card, useStyles } from './BioAgeResults';
import { orangeColor } from './BiologicalAge';

export const BioAgeExplanationMobile = ({ open, handleClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Dialog
      open={ open }
      onClose={ handleClose }
      fullWidth
      maxWidth='sm'
      className={ clsx('tw-m-0') }
      PaperProps={ { style: { margin: '10px', width: '100%' } } }
    >
      <div style={ { marginTop: '20px' } }>
        <Card>

          <div style={ { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
            <Typography
              variant='h6'
              className={ classes.bigText }
              style={ { marginBottom: '45px' } }
            >
              {t('Επεξήγηση συμβόλων')}
            </Typography>

            <div
              className='tw-w-full'
              style={ { display: 'flex', flexDirection: 'column', width: '100%' } }
            >
              {explanationTypes.map((row, id) => {
                return (
                  <div
                  // eslint-disable-next-line react/no-array-index-key
                    key={ id }
                    className={ clsx('tw-w-full', 'tw-gap-1', 'md:tw-gap-12', classes.paddingPerSize) }
                    style={ { background: '#F1F3F3',
                      display: 'flex',
                      borderRadius: '15px',
                      justifyContent: 'space-between',
                      alignItems: 'center' } }
                  >
                    <Typography variant='body2'>
                      {t(row.description)}
                    </Typography>

                    <div
                      className={ classes.explanationIconRow }
                      style={ { background: row.color, maxWidth: '160px', minWidth: '160px' } }
                    >
                      <Avatar style={ { background: 'white', marginRight: '8.5px', width: '38px', height: '38px' } }>
                        {
                          'green_tick' === row.iconType && (
                            <DoneIcon style={ { color: row.color } } />
                          )
                        }
                        {
                          'orange_up_arrow' === row.iconType && (
                            <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(45deg)' } } />
                          )
                        }

                        {
                          'orange_down_arrow' === row.iconType && (
                            <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(145deg)' } } />
                          )
                        }

                        {
                          'red_down_arrow' === row.iconType && (
                            <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(180deg)' } } />
                          )
                        }

                        {
                          'red_up_arrow' === row.iconType && (
                            <ArrowUpwardIcon style={ { color: row.color } } />
                          )
                        }

                      </Avatar>

                      <Typography style={ { fontWeight: '500', color: 'white' } } variant='body2'>
                        {t(row.recomendation)}
                      </Typography>
                    </div>

                  </div>
                );
              })}
            </div>
          </div>

        </Card>
      </div>
    </Dialog>
  );
};

const BioAgeTableItem = ({ item, idx }) => {
  const { t } = useTranslation();
  const bgColor = 1 === idx % 2 ? '#FFF4EA' : '#FFF9F2';

  return (
    <Accordion>
      <AccordionSummary
        style={ { background: bgColor } }
        expandIcon={ null }
      >
        <div
          className='tw-flex tw-h-8
      tw-w-8 tw-items-center tw-justify-center tw-rounded-md tw-text-xl tw-text-white'
          style={ { background: orangeColor } }
        >
          <ExpandMoreIcon />
        </div>

        <Typography className='tw-ml-2'>
          {t(item.description)}
        </Typography>

        <div className='tw-ml-auto'>
          {
            'green_tick' === item.icon && (
              <DoneIcon style={ { color: '#00B050' } } />
            )
          }
          {
            'orange_up_arrow' === item.icon && (
              <ArrowUpwardIcon style={ { color: '#FFC721', transform: 'rotate(45deg)' } } />
            )
          }

          {
            'orange_down_arrow' === item.icon && (
              <ArrowUpwardIcon style={ { color: '#FFC721', transform: 'rotate(145deg)' } } />
            )
          }

          {
            'red_down_arrow' === item.icon && (
              <ArrowUpwardIcon style={ { color: 'red', transform: 'rotate(180deg)' } } />
            )
          }

          {
            'red_up_arrow' === item.icon && (
              <ArrowUpwardIcon style={ { color: 'red' } } />
            )
          }
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className='tw-flex tw-w-full tw-justify-between'>
          <div className='tw-flex tw-w-full tw-flex-col tw-text-center'>
            <Typography className='tw-font-medium'>
              {t('Λειτουργία')}
            </Typography>

            <Typography className='tw-font-medium'>
              {t('Φυσιολογικές τιμές')}
            </Typography>

            <Typography className='tw-font-medium'>
              {t('Αποτέλεσμα')}
            </Typography>
          </div>

          <div className='tw-flex tw-w-full tw-flex-col tw-text-center'>
            <Typography>
              {t(item.operation)}
            </Typography>

            <Typography>
              {item.normalValues}
            </Typography>

            <Typography>
              {item.value}
            </Typography>
          </div>
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

const BioAgeAnalysisMobile = ({ testTypes }) => {
  const { t } = useTranslation();

  const bioAgeData = useBioAgeData()?.[0]?.value?.demoContent?.__values;

  const { userAttributes } = useAuth();

  if (!userAttributes) {
    return null;
  }

  let date = bioAgeData._DATE_OF_BLOOD_EXAMINATION;
  if ('US' === userAttributes.country) {
    date = date.split('/');
    date = `${ date[1] }/${ date[0] }${ date[2] }`;
  }

  return (
    <div className='tw-space-y-2'>
      <div className='tw-rounded-2xl tw-bg-white tw-p-1 tw-shadow'>
        <Typography
          style={ { marginTop: '5px', fontStyle: 'italic', padding: '10px' } }
          variant='body1'
        >
          {t('Η βιολογική σου ηλικία υπολογίστηκε βάσει των αιματολογικών σου εξετάσεων που πραγματοποιήθηκαν στις x/x/xx και τα αποτελέσματα ελέγχθηκαν από την εξειδικευμένη επιστημονική ομάδα μας.', { date })}
        </Typography>
      </div>

      <div className='tw-rounded-2xl tw-shadow'>
        {
          testTypes.map((test, idx) => {
            return (
              <BioAgeTableItem key={ test.description } item={ test } idx={ idx } />
            );
          })
        }
      </div>

    </div>
  );
};

export default BioAgeAnalysisMobile;
