import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { iDNA3ToOneCards } from './iDNA3ToOne';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';

export const profileToIcon = {
  lowCalcium: { big: '/ASVESTIO - XAMHLA EPIPEDA ASVESTIOU.jpg', small: '/ASVESTIO - XAMHLA EPIPEDA ASVESTIOU_SMALL.jpg' },
  highCalcium: { big: '/ASVESTIO - AYXHMENH SYGENTRWSH ASVESTIOU.jpg', small: '/ASVESTIO - AYXHMENH SYGENTRWSH ASVESTIOU_SMALL.jpg' },
  lowIron: { big: '/SIDIROS-HAMILA EPIPEDA SIDIROU.jpg', small: '/SIDIROS-HAMILA EPIPEDA SIDIROU_SMALL.jpg' },
  highIron: { big: '/SIDIROS-YPERFORTOSI SIDIROU.jpg', small: '/SIDIROS-YPERFORTOSI SIDIROU_SMALL.jpg' },
  magnesium: { big: '/MAGNHSIO.jpg', small: '/MAGNHSIO_SMALL.jpg' },
};

export const profileToUrl = {
  lowCalcium: 'lowCalcium',
  highCalcium: 'highCalcium',
  lowIron: 'lowIron',
  highIron: 'highIron',
  magnesium: 'magnesium',
};

export const adviceImages = {
  lowCalcium: { left: '/asvestio-1.jpg', right: '/asvestio-2.jpg' },
  highCalcium: { left: '/asvestio-1.jpg', right: '/asvestio-2.jpg' },
  lowIron: { left: '/sidiros-1.jpg', right: '/sidiros-2.jpg' },
  highIron: { left: '/sidiros-1.jpg', right: '/sidiros-2.jpg' },
  magnesium: { left: '/magnisio-1.jpg', right: '/magnisio-2.jpg' },
};

const TraceElements = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'VCAT9', 'VCAT10', 'VCAT11', 'VCAT12', 'VCAT13' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('VCAT9' === filtered.ID) {
      iconPath = profileToIcon.lowCalcium;
      url = profileToUrl.lowCalcium;
    } else if ('VCAT10' === filtered.ID) {
      iconPath = profileToIcon.highCalcium;
      url = profileToUrl.highCalcium;
    } else if ('VCAT11' === filtered.ID) {
      iconPath = profileToIcon.lowIron;
      url = profileToUrl.lowIron;
    } else if ('VCAT12' === filtered.ID) {
      iconPath = profileToIcon.highIron;
      url = profileToUrl.highIron;
    } else if ('VCAT13' === filtered.ID) {
      iconPath = profileToIcon.magnesium;
      url = profileToUrl.magnesium;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ vitaminsCard } activePage='traceElements' />
        <PhotoWithText
          options={ vitaminsCard }
          activePage='traceElements'
          text={ data[0].value.elements_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default TraceElements;
