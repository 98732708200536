import { Tab, PrevNext } from 'pages/iDNA3ToOne/Common';
import React from 'react';
import { blueColor, nutriGenetixCards } from './NutriGenetix';

const NutriGenetixProfile = () => {
  const tab = {
    color: blueColor,
    id: 'nutriGenetix',
    title: 'iDNA NutriGenetix',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ nutriGenetixCards } isNutrition activePage='nutriGenetixProfile' />
    </div>
  );
};

export default NutriGenetixProfile;
