import React, { useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { Typography, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { useFormRules, useInputValidation } from 'hooks/form-rules';
import { useTranslation } from 'react-i18next';
import { linear } from '../../../utils';

const useStyles = makeStyles(() => {
  return {
    error: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    } },

  };
});

const SelectAux = (
  {
    value,
    onChange,
    options,
    disabledValue,
    style,
    error,
    className = '',
    ...rest
  },
) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <FormControl fullWidth size='small' { ...rest }>
      <Select
        { ...rest }
        size='small'
        className={ error ? classes.error : className }
        fullWidth
        variant='outlined'
        color='secondary'
        value={ value || disabledValue }
        style={ { ...style } }
        onChange={ (event) => {
          return onChange(event.target.value);
        } }
      >
        <MenuItem disabled value={ disabledValue }>
          <Typography component='div' variant='subtitle2'>
            { t(disabledValue) }
          </Typography>
        </MenuItem>
        {
          options.map((option) => {
            return (
              <MenuItem key={ option } value={ option }>
                <Typography component='div' variant='subtitle2'>
                  { option }
                </Typography>
              </MenuItem>
            );
          })
        }

      </Select>
    </FormControl>
  );
};

// const days = linear(1, 31)
//   .map((day) => {
//     return day.toString()
//       .padStart(2, '0');
//   });
// const months = linear(1, 12)
//   .map((month) => {
//     return month.toString()
//       .padStart(2, '0');
//   });
const currentYear = (new Date()).getFullYear();
const years = linear(1930, currentYear)
  .map((year) => {
    return year.toString();
  })
  .reverse();

const SimpleDateFieldValue = (
  {
    label,
    required,
    defaultValue: _defaultValue,
    name,
    style = {},
    className = '',
    value,
    onChange = null,
  },
) => {
  const { dateRule } = useFormRules();

  const [ error, helperText, validateDate ] = useInputValidation(
    [ dateRule ],
  );
  const _helperText = helperText;

  const getDays = (_month) => {
    return linear(1, 31)
      .map((day) => {
        return day.toString()
          .padStart(2, '0');
      });
  };

  const getMonths = (_day) => {
    return linear(1, 12)
      .map((month) => {
        return month.toString()
          .padStart(2, '0');
      });
  };

  const [ days, _setDays ] = useState(getDays());

  const [ months, _setMonth ] = useState(
    getMonths(),
  );

  const [ yyyy, mm, dd ] = value?.split('-') || _defaultValue.split('-');

  const handleDateChange = (v) => {
    const { day, month, year } = { ...{ day: dd, month: mm, year: yyyy }, ...v };
    const newDate = `${ year }-${ month }-${ day }`;

    validateDate(newDate);

    if (null != onChange) {
      onChange(newDate);
    }
  };

  return (
    <div>
      <TextField
        name={ name }
        type='date'
        value={ value }
        className={ className }
        style={ {
          display: 'none',
          visibility: 'hidden',
          width: 0,
          height: 0,
          overflow: 'hidden',
          ...style,

        } }
      />
      {
        null != label && (
          <FormControl style={ { lineHeight: 0 } } size='small' fullWidth error={ error }>
            <FormLabel color='secondary' component='legend'>
              { `${ label }${ required ? ' *' : '' }` }
            </FormLabel>
          </FormControl>
        )
      }

      <Grid
        container
        spacing={ 1 }
        style={ { gap: '5px',
          marginTop: '3px',
          flexWrap: 'nowrap',
          maxWidth: 'fit-content' } }
      >
        <div style={ { width: '80px' } }>
          <SelectAux
            disabledValue='ηη'
            options={ days }
            error={ error }
            className={ className }
            onChange={ (v) => {
              handleDateChange({ day: v });
            } }
            style={ { ...style } }
            value={ dd }
          />
        </div>
        <div style={ { width: '80px' } }>
          <SelectAux
            disabledValue='μμ'
            error={ error }
            options={ months }
            className={ className }
            style={ { ...style } }
            onChange={ (v) => {
              handleDateChange({ month: v });
            } }
            value={ mm }
          />
        </div>
        <div style={ { width: '80px' } }>
          <SelectAux
            disabledValue='έτος'
            error={ error }
            className={ className }
            options={ years }
            style={ { ...style } }
            onChange={ (v) => {
              handleDateChange({ year: v });
            } }
            value={ yyyy }
          />
        </div>
      </Grid>
    </div>
  );
};

SimpleDateFieldValue.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
};

SimpleDateFieldValue.defaultProps = {
  defaultValue: 'έτος-μμ-ηη',
};

export default SimpleDateFieldValue;

