/* eslint-disable no-unused-vars */
import { useKit } from 'models/kits';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import React, { useEffect, useState } from 'react';
import 'react-medium-image-zoom/dist/styles.css';
import { Controlled as ControlledZoom } from 'react-medium-image-zoom';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { makeStyles } from '@material-ui/core/styles';
import { InputLabel, FormControl, Select, MenuItem, Typography, TextField, Button, Grid } from '@material-ui/core';
import { langTypeToText } from 'components/app/LangSwitcher';
import { UserGroups, useAuth } from 'AuthCtx';
import { useFormRules, useInputValidation } from 'hooks/form-rules';
import FormSection from './atoms/FormSection';
import FormSectionSubmit from './atoms/FormSectionSubmit';
import { userGroupToColor } from './SignUpForm';
import SimpleInputField from './atoms/SimpleInputField';

const useStyles = makeStyles((theme) => {
  return {
    patientColor: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Patient],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Patient],
      },
      '&.checked': {
        color: userGroupToColor[UserGroups.Patient],
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: userGroupToColor[UserGroups.Patient],
        },
      },
      borderColor: userGroupToColor[UserGroups.Patient],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
        '&:hover fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
        '&.Mui-focused fieldset': {
          borderColor: userGroupToColor[UserGroups.Patient],
        },
      },
    },
    doctorColor: {
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Doctor],
      },
      '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: userGroupToColor[UserGroups.Doctor],
      },
      '&.checked': {
        color: userGroupToColor[UserGroups.Doctor],
      },
      '& .MuiPickersDay-root': {
        '&.Mui-selected': {
          backgroundColor: userGroupToColor[UserGroups.Doctor],
        },
      },
      borderColor: userGroupToColor[UserGroups.Doctor],
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
        '&:hover fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
        '&.Mui-focused fieldset': {
          borderColor: userGroupToColor[UserGroups.Doctor],
        },
      },
    },
    alert: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      flexWrap: 'nowrap',
      '& .MuiAlert-icon': {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
  };
});

const SelectReportLanguageForm = (
  {
    kitId,
    onSubmit,
    onError,
    href,
  },
) => {
  const { client } = useAPI();
  const {
    data: locales,
    isLoading,
  } = useQuery(
    [ 'locales' ],
    () => {
      return client.get('locales');
    },
  );

  const [ isSubmitting, setSubmitting ] = useState(false);
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  const [ referenceNumber, setReferenceNumber ] = useState('');
  const [ referenceNumberErrorText, setReferenceNumberErrorText ] = useState('');
  const [ referenceNumberExampleZoomedIn, setReferenceNumberExampleZoomedIn ] = useState(false);

  useEffect(() => {
    if (null != referenceNumber && 0 !== referenceNumber.length && 13 !== referenceNumber.length) {
      setReferenceNumberErrorText('Ο αριθμός παραστατικού θα πρέπει να αποτελείται από 13 ψηφία.');
    } else {
      setReferenceNumberErrorText('');
    }
  }, [ referenceNumber ]);

  const { locale, updateLocale, isLoading: isKitLoading, isKitPGX, kitMetadata, updateKitMetadata, child, data } = useKit(kitId);

  const [ amka, setAmka ] = useState('');
  const [ errorAMKA, setErrorAMKA ] = useState(false);

  const initialAmka = (null != data?.customer_id?.social_security_number) ? data?.customer_id?.social_security_number : '';

  useEffect(() => {
    setAmka(initialAmka);
  }, [ initialAmka ]);

  const [ newLocale, setNewLocale ] = useState(undefined);

  const [ availableLocales, setAvailableLocales ] = useState([]);

  useEffect(() => {
    if (null != kitMetadata && null !== kitMetadata?.referenceNumber && '' === referenceNumber) {
      setReferenceNumber(kitMetadata.referenceNumber);
    }
  }, [ kitMetadata ]);

  useEffect(() => {
    if (null == locales) { return; }
    setAvailableLocales(locales);
  }, [ locales ]);

  const { isDoctor } = useAuth();

  useEffect(() => {
    if (!isKitLoading && null != locale && newLocale === undefined) {
      setNewLocale(locale);
    } else if (!isLoading && null != availableLocales && newLocale === undefined && null === locale) {
      const foundLocale = availableLocales.find((loc) => { return loc.name === i18n.language; }) ?? availableLocales[0];
      if (foundLocale !== undefined) {
        setNewLocale(foundLocale.id);
      }
    }
  }, [ isLoading, availableLocales, isKitLoading, locale ]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    setSubmitting(true);

    const metadata = { referenceNumber };

    // eslint-disable-next-line no-constant-condition
    if (isKitPGX && false) {
      if (11 !== amka.length) {
        setSubmitting(false);
        setErrorAMKA(true);
        return;
      }

      if (!isDoctor) {
        await client.put('customers/me/details', { social_security_number: amka });
      } else if (null == data.customer_id) {
        await client.post('/customers/doctorcreate', {
          registration_kit_id: kitId,
          social_security_number: amka,
        });
      } else {
        await client.put('/customers/details/doctorupdate', {
          customerId: data.customer_id.id,
          social_security_number: amka,
        });
      }
    }

    updateKitMetadata({ metadata }).then(() => {
      updateLocale({ newLocale }).then(() => {
        setSubmitting(false);
        onSubmit();
      });
    });
  };

  if (isLoading || newLocale === undefined) {
    return <LoaderSkeleton />;
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
    >

      {isKitPGX && false && (
        <FormSection label={ t('reg_kit.select_report_locale.pgx.referral_registration') }>
          <Grid container spacing={ 1 }>
            <Grid item lg={ 8 } xs={ 12 }>

              <Typography variant='subtitle1'>
                {
                  isDoctor ? (
                    <>
                      {t('reg_kit.select_report_locale.doctor.pgx.have_referral_part1') }
                    </>
                  )
                    : (
                      <>
                        {t('reg_kit.select_report_locale.adult.pgx.have_referral_part1') }
                      </>
                    )
                }
              </Typography>

              <Typography variant='subtitle1' style={ { marginBottom: '30px' } }>
                {t('reg_kit.select_report_locale.pgx.have_referral_part2')}
              </Typography>

              <Typography
                variant='body2'
                style={ { marginBottom: '10px',
                  display: 'flex',
                  alignItems: 'center' } }
              >
                {t('reg_kit.select_report_locale.pgx.enter_referral_num')}
              </Typography>

              <TextField
                fullWidth
                variant='outlined'
                value={ referenceNumber }
                onChange={ (e) => { setReferenceNumber(e.target.value); } }
                label={ t('common.referral_num') }
                color='secondary'
                helperText={ t(referenceNumberErrorText) }
                type='number'
                error={ '' !== referenceNumberErrorText }
                style={ { maxWidth: '388px' } }
              />

            </Grid>

            <Grid item xs={ 12 } lg={ 4 }>
              <div style={ { display: 'flex',
                flexDirection: 'column',
                background: '#F2F0F1',
                padding: '10px',
                borderRadius: '5px' } }
              >
                <Typography
                  variant='body2'
                  style={ { fontWeight: 'bold',
                    color: '#888781',
                    marginBottom: '8px' } }
                >
                  {t('reg_kit.select_report_locale.pgx.referral_sample')}
                </Typography>

                <Typography variant='body2' style={ { color: '#888781', marginBottom: '8px' } }>
                  {t('reg_kit.select_report_locale.pgx.view_referral_sample')}
                </Typography>
                <ControlledZoom
                  isZoomed={ referenceNumberExampleZoomedIn }
                  onZoomChange={ (e) => {
                    setReferenceNumberExampleZoomedIn(e);
                  } }
                >
                  <img
                    src='ReferenceNumberExample.png'
                    alt='Reference Number Example'
                    style={ { width: '100%', height: 'auto' } }
                  />

                </ControlledZoom>

                <Button
                  variant='contained'
                  fullWidth
                  startIcon={ <ZoomInIcon /> }
                  onClick={ () => { setReferenceNumberExampleZoomedIn(true); } }
                  style={ { color: '#50A2DD' } }
                >
                  {t('reg_kit.select_report_locale.pgx.zoom_in')}
                </Button>
              </div>
            </Grid>

            {
              isKitPGX && (
                <Grid
                  item
                  xs={ 12 }
                >
                  <FormSection
                    label={ t('reg_kit.select_report_locale.pgx.amka_registration') }
                    style={ { marginBottom: 0 } }
                  >
                    <TextField
                      fullWidth
                      variant='outlined'
                      value={ amka }
                      onChange={ (e) => {
                        if (11 === e.target.value.length) {
                          setErrorAMKA(false);
                        }
                        setAmka(e.target.value);
                      } }
                      required
                      label={ t('common.amka') }
                      color='secondary'
                      helperText={ errorAMKA && t('validation.elevenChars') }
                      type='number'
                      error={ errorAMKA }
                      style={ { maxWidth: '388px' } }
                    />
                  </FormSection>
                </Grid>
              )
            }
          </Grid>

        </FormSection>
      )}

      <FormSection label={ t('reg_kit.select_report_locale.common.header') }>
        <Typography variant='subtitle1' style={ { marginBottom: '30px' } }>
          {
            null != child
              ? t('reg_kit.select_report_locale.child.select_lang_desc')
              : isDoctor ? isKitPGX ? t('reg_kit.select_report_locale.doctor.pgx.select_lang_desc') : t('reg_kit.select_report_locale.doctor.non_pgx.select_lang_desc')
                : t('reg_kit.select_report_locale.adult.select_lang_desc')
          }
        </Typography>

        <Alert
          severity='warning'
          className={ classes.alert }
        >
          <AlertTitle
            style={ { fontWeight: 'bold' } }
          >
            {t('reg_kit.select_report_locale.common.cant_change_language')}
          </AlertTitle>
        </Alert>

        <FormControl variant='outlined' fullWidth style={ { maxWidth: '300px' } } color='secondary'>
          <InputLabel id='demo-simple-select-outlined-label-locale'>{t('reg_kit.select_report_locale.common.available_languages')}</InputLabel>
          <Select
            className={ isDoctor ? classes.doctorColor : classes.patientColor }
            labelId='demo-simple-select-outlined-label-locale'
            value={ newLocale }
            onChange={ (e) => {
              setNewLocale(e.target.value);
              i18n.changeLanguage(langTypeToText[e.target.value]);
            } }
            label={ t('reg_kit.select_report_locale.common.available_languages') }
          >
            {availableLocales?.map((v) => {
              return (
                <MenuItem key={ v.id } value={ v.id }>
                  <img
                    style={ { marginRight: '10px' } }
                    src={ langTypeToText[v.name]?.icon }
                    alt={ langTypeToText[v.name]?.name }
                  />
                  {t(langTypeToText[v.name].name)}
                </MenuItem>
              );
            })}
          </Select>
        </FormControl>

      </FormSection>

      <FormSectionSubmit
        label={ t('common.save') }
        loading={ isSubmitting }
        color={ isDoctor ? '#526D9C' : undefined }
      />
    </form>
  );
};

export default SelectReportLanguageForm;
