import { Redirect } from 'react-router';
import React from 'react';

const AuthRedirect = (props) => {
  const { from } = props.location.state || { from: { pathname: '/' } };

  // console.log('AuthRedirect', props, from);

  return (
    <Redirect to={ from } />
  );
};

export default AuthRedirect;
