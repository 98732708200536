import React from 'react';
import { Redirect, Route } from 'react-router';

const ProtectedRoute = ({ component: Component, auth, redirectTo, ...rest }) => {
  return (
    <Route
      { ...rest }
      render={ (props) => {
        // console.log('ProtectedRoute', props.location, redirectTo, rest, 'props', props, 'auth', auth);
        return auth
          ? <Component { ...props } />
          : (
            <>
              {
                redirectTo ? (
                  <Redirect to={ { pathname: redirectTo.pathname,
                    search: props.location.search,
                    state: { from: props.location, search: props.location.search } } }
                  />
                ) : (
                  <Redirect to={ {
                    pathname: '/login',
                    search: props.location.search,
                    state: { from: props.location, search: props.location.search },
                  } }
                  />
                )
              }
            </>
          );
      } }
    />
  );
};

export default ProtectedRoute;
