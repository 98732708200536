import React, { useContext, useState } from 'react';
import AppDialog from '../components/app/AppDialog';

const DialogCtx = React.createContext({});

export function ConfirmationDialogProvider(props) {
  const [ dialogOpen, setDialogOpen ] = useState(false);
  const [ dialogConfig, setDialogConfig ] = useState({});
  const [ state, setState ] = useState({});

  const openDialog = ({ title, body, onSubmit, onCancel }) => {
    setDialogOpen(true);
    setDialogConfig({ title, body, onSubmit, onCancel });
  };

  const resetDialog = () => {
    setDialogOpen(false);
    setTimeout(() => { return setDialogConfig({}); }, 100);
    setState({});
  };

  const onSubmit = () => {
    return Promise.resolve()
      .then(() => {
        return dialogConfig.onSubmit(state);
      })
      .then(() => {
        resetDialog();
      });
  };

  const onCancel = () => {
    return Promise.resolve()
      .then(() => {
        if (!('function' === typeof dialogConfig.onCancel)) { return null; }
        return dialogConfig.onCancel(state);
      })
      .then(() => {
        resetDialog();
      });
  };

  const { body: Body } = dialogConfig || { body() { return null; } };

  return (
    <DialogCtx.Provider value={ openDialog }>
      {props.children}
      <AppDialog
        open={ dialogOpen }
        title={ dialogConfig.title }
        onClose={ onCancel }
        onSubmit={ onSubmit }
      >
        {Body && <Body state={ state } setState={ setState } />}
      </AppDialog>
    </DialogCtx.Provider>
  );
}

export const useDialog = () => {
  const openDialog = useContext(DialogCtx);

  return { openDialog };
};
