import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import * as _ from 'lodash';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl } from '../FoodSensitivities';

const SensitivityToAlcohol = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const categories = _.cloneDeep(data[0].value.categories);

  categories.push(categories.splice(
    categories.findIndex(
      (s) => { return 'NCAT16' === s.ID; },
    ), 1,
  )[0]);

  const sensProfiles = categories.filter((currData) => {
    return [ 'NCAT14', 'NCAT16', 'NCAT17', 'NCAT18' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';
    const description = 'NCAT16_description';

    if ('NCAT14' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToCoffee;
      url = profileToUrl.sensitivityToCoffee;
    } else if ('NCAT16' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToAlcohol;
      url = profileToUrl.sensitivityToAlcohol;
      resultText = filtered.genetic_profile_text;
      value = `${ filtered.result_alcohol_metabolism }:${ filtered.result_alcohol_desire }`;
      advice = filtered.advice;
    } else if ('NCAT17' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToLactose;
      url = profileToUrl.sensitivityToLactose;
    } else if ('NCAT18' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToGluten;
      url = profileToUrl.sensitivityToGluten;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ dietCard } activePage='foodSensitivities' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Ευαισθησία στο Αλκοόλ'
        activeColor={ dietCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
      />
    </div>
  );
};

export default SensitivityToAlcohol;
