import React, { useMemo } from 'react';
import 'moment/locale/el';
import { useBioAgeDataReport, useBioAgeData } from 'models/kits';
import { useOptionSerial } from 'NavigationProvider';
import 'moment/locale/en-gb';
import { Tab } from 'pages/iDNA3ToOne/Common';
import { makeStyles } from '@material-ui/styles';
import { Grid, Typography } from '@material-ui/core';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useAuth } from 'AuthCtx';
import useMediaQueries from 'hooks/media-query-hooks';
import { PrevNextCardio } from 'pages/iDNACardioHealth/Common';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';
import { orangeColor } from './BiologicalAge';

export const useStyles = makeStyles({
  card: {
    background: '#FFFFFF',
    boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
    borderRadius: '15px',
    padding: '60px 90px',
    '@media (max-width:1000px)': {
      padding: '10px 20px !important',
    },
    '@media (max-width:600px)': {
      padding: '10px 20px !important',
    },
  },
  history: {
    '@media (max-width:600px)': {
      textAlign: 'center',
    },
  },
  bigText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 700,
    color: '#545454',
  },
  smallText: {
    fontFamily: 'Roboto',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '16px',
    lineHeight: '145.19%',
    letterSpacing: '0.03em',
    color: '484848',
  },
  tableRow: {
    // '&:last-child td, &:last-child th': { border: 0 },
  },
  tableRowOdd: {
    background: '#FFF4EA',
  },
  tableRowEven: {
    background: '#FFF9F2',
  },
  tableHeaderCell: {
    color: 'white',
  },
  tableCell: {
  },
  table: {
  },
  explanationIconRow: {
    border: '1px solid rgba(0, 0, 0, 0.2)',
    borderRadius: '36px',
    height: '40px',
    width: '289px',
    display: 'flex',
    alignItems: 'center',
  },
  paddingPerSize: {
    padding: '10px 28px',
    '@media (max-width:600px)': {
      padding: '10px 10px',
    },
  },
  mobileDialog: {
    width: '100%',
  },
});

export const Card = (props) => {
  const classes = useStyles();

  return (
    <div className={ classes.card } style={ { padding: props.padding, ...props.style } }>
      {props.children}
    </div>
  );
};

export const BioAgeCards = {
  id: 'bioAge',
  title: 'bioAge',
  color: orangeColor,
  fields: [
    {
      text: 'Ιστορικό',
      url: 'bioAgeHistory',
      icon: '/icon_history.svg',
      image: '',
    },

    {
      text: 'results',
      url: 'bioAgeResults',
      icon: '/icon_results.svg',
      image: '',
    },

    {
      text: 'Ανάλυση',
      url: 'bioAgeAnalysis',
      icon: '/icon_analysis.svg',
      image: '',
    },
  ],
};

export const bioAgeTab = {
  color: orangeColor,
  id: 'biologicalAge',
  title: 'biologicalAge',
};

const BioAgeResultsInner = () => {
  const classes = useStyles();

  const bioAgeData = useBioAgeData()?.[0]?.value?.demoContent?.__values;

  const { t } = useTranslation();

  const { userAttributes } = useAuth();

  const { isMdDown, isSm } = useMediaQueries();

  if (!userAttributes || null == bioAgeData) {
    return <LoaderSkeleton />;
  }

  const bioAgeText = bioAgeData?._BIO_AGE_TEXT;
  const ageRateText = bioAgeData?._BIO_AGE_RATE_TEXT;
  const advice = bioAgeData?._ADVICE_TEXT;

  return (

    <div style={ { display: 'flex', flexDirection: 'column' } }>
      <Tab tab={ bioAgeTab } />

      <PrevNextCardio
        options={ BioAgeCards }
        activePage='results'
        hasIcons
      />

      <Card>
        <div style={ { display: 'flex',
          gap: '17px',
          alignItems: 'center',
          border: `1px solid ${ orangeColor }`,
          borderRadius: '81px',
          width: 'fit-content',
          padding: '3px 3px 3px 30px' } }
        >
          <Typography className={ classes.bigText } variant={ isSm ? 'h5' : 'h4' }>
            {t('Η βιολογική σου ηλικία είναι')}
          </Typography>

          <div style={ {
            width: '58px',
            height: '58px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: orangeColor,
            borderRadius: '0px 50px 50px 50px',
            padding: '3px',
          } }
          >
            <Typography
              className={ classes.bigText }
              variant='h5'
              style={ { color: 'white',
                fontWeight: 'bold' } }
            >

              {bioAgeData?._biologicalAgeDetails?.biologicalAge}
            </Typography>
          </div>
        </div>
        <Grid
          container
          spacing={ 3 }
          style={ { marginBottom: '50px',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center' } }
        >
          <Grid item md={ 12 } lg={ 6 }>
            <img
              src={ bioAgeData?._BIO_AGE_LINE_IMG }
              alt='bio-line'
              style={ { maxWidth: '100%',
                height: 'auto',
                width: 'auto' } }
            />
          </Grid>
          <Grid item md={ 12 } lg={ 6 }>
            <div
              className={ classes.smallText }
              style={ { marginTop: '10px',
                background: '#F1F3F3',
                padding: '30px 30px',
                borderRadius: '13px' } }
            >
              {bioAgeText}
            </div>
          </Grid>
        </Grid>

        <div style={ { display: 'flex',
          gap: '17px',
          alignItems: 'center',
          border: `1px solid ${ orangeColor }`,
          borderRadius: '81px',
          width: 'fit-content',
          padding: '3px 3px 3px 30px' } }
        >
          <Typography
            className={ classes.bigText }
            variant={ isSm ? 'h5' : 'h4' }
          >
            {t('Ο ρυθμός γήρανσής σου είναι')}
          </Typography>

          <div style={ {
            width: '58px',
            height: '58px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: orangeColor,
            borderRadius: '0px 50px 50px 50px',
            padding: '3px',
          } }
          >
            <Typography
              className={ classes.bigText }
              variant='h5'
              style={ { color: 'white',
                fontWeight: 'bold' } }
            >

              {bioAgeData?._biologicalAgeDetails?.ageRate}
            </Typography>
          </div>
        </div>
        <Grid
          container
          spacing={ 3 }
          style={ { display: 'flex',
            flexDirection: 'row',
            marginBottom: '50px',
            alignItems: 'center' } }
        >
          <Grid item md={ 12 } lg={ 6 }>
            <img
              src={ bioAgeData?._BIO_AGE_RATE_IMG }
              alt='bio half circle'
              style={ { maxWidth: '100%',
                height: 'auto',
                width: 'auto' } }
            />
          </Grid>

          <Grid item md={ 12 } lg={ 6 }>
            <div
              className={ classes.smallText }
              style={ { marginTop: '30px',
                background: '#F1F3F3',
                padding: '30px 30px',
                borderRadius: '13px' } }
            >
              {ageRateText}
            </div>
          </Grid>
        </Grid>

        <div style={ {
          background: 'url("idna-biological-age-active-people-1327x557.png")',
          minHeight: '300px',
          borderRadius: '15px',
          padding: '30px 60px',
        } }
        >
          <Typography
            className={ classes.bigText }
            variant='h5'
            style={ { fontWeight: 'bold', marginBottom: '35px' } }
          >
            {t('Συμβουλή')}
          </Typography>

          <Typography style={ { width: !isMdDown ? '60%' : '100%' } }>
            {advice}
          </Typography>
        </div>
      </Card>
    </div>
  );
};

const BioAgeResults = () => {
  const { serial } = useOptionSerial('bioAge');
  const { locale } = useBioAgeDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <BioAgeResultsInner />
    </I18nextProvider>
  );
};

export default BioAgeResults;
