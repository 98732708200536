import React, { useEffect, useState } from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import FormLabel from '@material-ui/core/FormLabel';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { FormControl, FormHelperText, Input } from '@material-ui/core';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import CountrySelect, { supportedTelephoneCountries } from '../../app/CountrySelect';

const SimplePhoneField = (
  {
    name,
    defaultValue,
    countrySelectName,
    label,
    error,
    helperText,
    required,
    defaultCountryValue,
    autoComplete,
    readOnly,
    validate = null,
    disabled,
    setExternalValue = undefined,
  },
) => {
  const [ number, setNumber ] = useState('');
  const [ inputNumber, setInputNumber ] = useState('');
  const [ country, setCountry ] = useState(defaultCountryValue || '');
  const { t } = useTranslation();

  useEffect(() => {
    let num = defaultValue;
    if ('+' !== num[0]) {
      num = `+${ num }`;
    }

    if (setExternalValue !== undefined) {
      setExternalValue(defaultValue);
    }

    const defaultNumber = parsePhoneNumber(num);
    setNumber(defaultNumber?.number || '');
    setInputNumber(defaultNumber?.nationalNumber || '');
    setCountry(defaultNumber?.country || defaultCountryValue || '');
  }, [ defaultValue ]);

  const handleChange = ({ inputNumber: p, country: c }) => {
    setInputNumber(p);
    setCountry(c);
    const n = parsePhoneNumber(p, c);
    setNumber(n?.number || '');
  };

  return (
    <>
      <Input
        style={ { display: 'none' } }
        name={ name }
        disabled={ disabled }
        type='tel'
        value={ number || '' }
      />
      <FormControl size='small' fullWidth error={ error } disabled={ disabled }>
        <FormLabel color='secondary' component='legend' disabled={ !!readOnly || disabled }>
          {`${ label }${ (required && !disabled) ? ' *' : '' }`}
        </FormLabel>
      </FormControl>
      <Grid container>
        <FormControl size='small' fullWidth error={ error } disabled={ disabled }>
          <OutlinedInput
            startAdornment={ (
              <CountrySelect
                name={ countrySelectName }
                value={ country }
                disabled={ disabled }
                onChange={ (v) => {
                  return handleChange({ inputNumber, country: v });
                } }
                readOnly={ !!readOnly }
              />
            ) }
            color='secondary'
            margin='dense'
            value={ inputNumber }
            onChange={ (e) => {
              if (error && null !== validate) {
                validate({ phoneNumber: e.target.value, country });
              }

              if (setExternalValue !== undefined) {
                setExternalValue(e.target.value);
              }

              return handleChange({ inputNumber: e.target.value, country });
            } }
            type='tel'
            autoComplete={ autoComplete }
            disabled={ !!readOnly || disabled }
          />
        </FormControl>
      </Grid>
      {error && (
        <FormControl size='small' fullWidth error={ error }>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }
    </>
  );
};

SimplePhoneField.propTypes = {
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  countrySelectName: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultCountryValue: PropTypes.oneOf([ ...supportedTelephoneCountries ]),
  autoComplete: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  readOnly: PropTypes.bool,
};

SimplePhoneField.defaultProps = {
  defaultValue: '',
  defaultCountryValue: 'GR',
  autoComplete: null,
  error: false,
  helperText: null,
  required: false,
  readOnly: false,
};

export default SimplePhoneField;
