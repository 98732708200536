import React from 'react';
import { useNutritionData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { yellowColor } from './Nutrition';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';

export const profileToIcon = {
  sensitivityToSalt: { big: '/ALATI.jpg', small: '/ALATI_SMALL.jpg' },
  saturatedFat: { big: '/KORESMENA LIPARA.jpg', small: '/KORESMENA LIPARA_SMALL.jpg' },
  fattyAcids: { big: '/OMEGA6 OMEGA 3.jpg', small: '/OMEGA6 OMEGA 3_SMALL.jpg' },
  transFats: { big: '/TRANS LIPARA.jpg', small: '/TRANS LIPARA_SMALL.jpg' },
};

export const profileToUrl = {
  sensitivityToSalt: 'nutritionSensitivityToSalt',
  saturatedFat: 'nutritionSaturatedFat',
  fattyAcids: 'nutritionFattyAcids',
  transFats: 'nutritionTransFats',
};

const NCardiometabolicHealth = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useNutritionData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Καρδιομεταβολική Υγεία' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT15' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToSalt;
      url = profileToUrl.sensitivityToSalt;
    } else if ('NCAT5' === filtered.ID) {
      iconPath = profileToIcon.saturatedFat;
      url = profileToUrl.saturatedFat;
    } else if ('NCAT10' === filtered.ID) {
      iconPath = profileToIcon.fattyAcids;
      url = profileToUrl.fattyAcids;
    } else if ('NCAT4' === filtered.ID) {
      iconPath = profileToIcon.transFats;
      url = profileToUrl.transFats;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: yellowColor,
    id: 'nutritionalGuide',
    title: 'Διατροφή',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext options={ dietCard } activePage='nutritionCardiometabolicHealth' isNutrition />
      <PhotoWithText
        options={ dietCard }
        activePage='cardiometabolicHealth'
        text={ data[0].value.cardio_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
    </div>
  );
};

export default NCardiometabolicHealth;
