import React, { useEffect } from 'react';
import { useAuth } from '../../AuthCtx';

const Logout = () => {
  const { signOut } = useAuth();
  useEffect(() => {
    signOut();
  }, []);
  return <></>;
};

export default Logout;
