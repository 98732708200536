import React, { useMemo } from 'react';
import { useCardioDataReport } from 'models/kits';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useOptionSerial } from 'NavigationProvider';
import i18nEng from '../../../i18n_eng';
import i18nEl from '../../../i18n_el';
import CardioResults, { ImageText } from '../Common';

const EgkefalikoEpeisodeio = () => {
  const { i18n } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { data, locale } = useCardioDataReport(serial, i18n.language);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null == data) {
    return <LoaderSkeleton />;
  }

  if (0 === data.length) {
    return (
      <h1>
        No data available
      </h1>
    );
  }

  const nosos = data[0].value['ischemic stroke'];

  const prsImage = [ JSON.parse(nosos._IMG_SCORE).base64, JSON.parse(nosos._IMG_SCORE_MOBILE).base64 ];
  const adjPrsImage = [ JSON.parse(nosos._IMG_ADJ_PRS_SCORE).base64, JSON.parse(nosos._IMG_ADJ_PRS_SCORE_MOBILE).base64 ];
  const prsScore = nosos.PRS_SCORE;
  const adjPrsScore = nosos.ADJPRS_SCORE;
  const prsCharacterisation = nosos.PRS_CHARACTERISATION;
  const traitProfile = nosos.TRAIT_PROFILE;
  const adjPrsCharacterisationLevels = nosos.ADJPRS_CHARACTERISATION_LEVELS;
  const adjPrsCharacterisationFem = nosos.ADJPRS_CHARACTERISATION_FEMALE;

  let characterisation = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === characterisation) {
    characterisation = 'Τυπική';
  } else if ('HIGH' === characterisation) {
    characterisation = 'Βέλτιστη';
  } else if ('LOW' === characterisation) {
    characterisation = 'Mειωμένη';
  }

  const imageContent = 'Ένα επεισόδιο συμβαίνει όταν διακόπτεται ή μειώνεται η παροχή αίματος σε μέρος του εγκεφάλου, εμποδίζοντας με αυτό τον τρόπο τον εγκεφαλικό ιστό να πάρει οξυγόνο και θρεπτικά συστατικά. Αποτελεί μια επείγουσα ιατρική κατάσταση της οποίας έγκαιρη αντιμετώπιση είναι ζωτικής σημασίας.';

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div>
        <CardioResults />
        <ImageText
          traitProfile={ traitProfile }
          characterisation={ characterisation }
          adjPrsCharacterisationLevels={ adjPrsCharacterisationLevels }
          prsImage={ prsImage }
          adjPrsCharacterisationFem={ adjPrsCharacterisationFem }
          adjPrsImage={ adjPrsImage }
          prsScore={ prsScore }
          adjPrsScore={ adjPrsScore }
          prsCharacterisation={ prsCharacterisation }
          activeProfile='Ισχαιμικό εγκεφαλικό επεισόδιο'
          imageTitle='Για το Ισχαιμικό εγκεφαλικό επεισόδιο'
          imageContent={ imageContent }
          source='https://www.mayoclinic.org/diseases-conditions/stroke/symptoms-causes/syc-20350113'
        />
      </div>
    </I18nextProvider>
  );
};

export default EgkefalikoEpeisodeio;
