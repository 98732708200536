import React, { useMemo } from 'react';
import { Tab } from 'pages/iDNA3ToOne/Common';
import { PrevNextCardio } from 'pages/iDNACardioHealth/Common';
import { useBioAgeData, useBioAgeDataReport } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { Grid, Typography } from '@material-ui/core';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { orangeColor } from './BiologicalAge';
import { bioAgeTab, BioAgeCards, Card, useStyles } from './BioAgeResults';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const BioAgeHistoryInner = () => {
  const { t } = useTranslation();
  const bioAgeData = useBioAgeData()?.[0]?.value?.demoContent?.__values;
  const classes = useStyles();

  if (null == bioAgeData) {
    return <LoaderSkeleton />;
  }

  return (
    <div style={ { display: 'flex', flexDirection: 'column' } }>
      <Tab tab={ bioAgeTab } />

      <PrevNextCardio
        options={ BioAgeCards }
        activePage='Ιστορικό'
        hasIcons
      />

      <Card>
        <div>
          <div
            style={ {
              border: `1px solid ${ orangeColor }`,
              borderRadius: '81px',
              padding: '3px 3px 3px 30px',
              marginBottom: '62px',
            } }
          >

            <Typography variant='h5' className={ classes.bigText }>
              {t('To ιστορικό της βιολογικής σου ηλικίας')}
            </Typography>

          </div>

          <Grid container spacing={ 4 }>
            <Grid item sm={ 12 } lg={ 6 }>
              <img
                src={ bioAgeData?._BIO_AGE_HISTORY_IMG }
                alt='bio half circle'
                style={ { maxWidth: '100%',
                  height: 'auto',
                  width: 'auto' } }
              />
            </Grid>

            <Grid
              item
              sm={ 12 }
              lg={ 6 }
              style={ {
                background: '#F1F3F3',
                borderRadius: '15px',
                padding: '50px',
              } }
            >
              <Typography>
                {t('Μέσω της συχνής παρακολούθησης της βιολογικής σου ηλικίας και του ρυθμού γήρανσης μπορείς να βγάλεις πολύτιμα συμπεράσματα για την μεταβολή της τάσης της λειτουργικής σου ικανότητας και της συνολικής υγείας σου στον χρόνο. Μπορείς επιπλέον να κατανοήσεις την επίδραση καθημερινών σου συνηθειών στην υγεία και να παρατηρήσεις τα οφέλη θετικών παρεμβάσεων για την ευζωία και τη μακροζωία, όπως ένα νέο χόμπι, μια νέα διατροφή ή ένα νέο πρόγραμμα εκγύμνασης.')}
              </Typography>

              <div style={ { height: '1rem' } } />

              <Typography>
                {t('Προσπάθησε να διατηρείς τη βιολογική σου ηλικία χαμηλότερα από τη χρονολογική. Αν η βιολογική σου ηλικία ξεπεράσει τη χρονολογική, να θυμάσαι ότι η βιολογική ηλικία είναι εύπλαστη και θετικές παρεμβάσεις για την υγεία μπορούν να επιβραδύνουν ή ακόμη και να επαναφέρουν τη βιολογική σου ηλικία σε νεότερη κατάσταση.')}
              </Typography>

            </Grid>

          </Grid>

        </div>

      </Card>

    </div>
  );
};

const BioAgeHistory = () => {
  const { serial } = useOptionSerial('bioAge');
  const { locale } = useBioAgeDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <BioAgeHistoryInner />
    </I18nextProvider>
  );
};

export default BioAgeHistory;
