import React, { useMemo } from 'react';
import { useBioAgeDataReport, useBioAgeData } from 'models/kits';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useOptionSerial, useNavigation } from 'NavigationProvider';
import { makeStyles, Typography } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import { I18nextProvider, useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import { WellnessPdf } from 'pages/iDNA3ToOne/iDNA3ToOne';
import i18nEng from '../../i18n_eng';
import i18nEl from '../../i18n_el';

export const orangeColor = '#FF7B00';
export const greenColor = '#93C221';

const useStyles = makeStyles(() => {
  return {
    cardIcon: {
      height: '71px',
      width: '71px',
      display: 'flex',
      padding: '15px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      '@media (max-width:600px)': {
        flexWrap: 'wrap',
        gap: '8px',
      },
    },
    smallText: {
      fontWeight: '400',
      fontSize: '16px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
    },
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    cardField: {
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      marginLeft: '8%',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: '43.4717px',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: '28px',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      marginTop: '-10px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: orangeColor,
    },
    whatYouWillFind: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      padding: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    infoOuter: {
      background: orangeColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '250px',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      '@media (max-width:600px)': {
        bottom: '-25px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      height: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    categories: {
      borderRadius: '15px',
      padding: '30px',
      background: orangeColor,
      display: 'flex',
      position: 'relative',
    },
    category: {
      color: 'white',
    },
    innerCategories: {
      background: 'white',
      width: '100%',
      padding: '70px 100px 70px 100px',
      borderRadius: '15px',
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'nowrap',
    },
    innerCategoriesMobile: {
      background: 'white',
      width: '100%',
      display: 'flex',
      gap: '30px',
      flexWrap: 'wrap',
      padding: '5px',
    },
  };
});

const BiologicalAgeInner = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { serial: newSerial } = useOptionSerial('bioAge');

  useBioAgeData();

  const { navigateToPage } = useNavigation();

  const { isMdDown } = useMediaQueries();

  const { userAttributes } = useAuth();

  if (!userAttributes) {
    return null;
  }

  const {
    firstName,
  } = userAttributes;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart } style={ { position: 'relative', marginBottom: '25px' } }>
        <div style={ { display: 'flex',
          justifyContent: 'space-between',
          backgroundImage: isMdDown ? '' : 'url("/Banner-Biological-age-918x192.png")',
          minHeight: '180px',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          paddingBottom: '15px' } }
        >
          <div style={ { marginTop: '1rem' } }>
            <div
              className={ classes.title }
            >
              {t('iDNA Βιολογική Ηλικία')}
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {newSerial}
            </div>
          </div>
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          className={ classes.whatYouWillFind }
          style={ {
            lineHeight: '161.19%',
          } }
        >
          <div style={ { display: 'flex', flexDirection: 'column', gap: '15px' } }>
            <Typography variant='h4' style={ { color: orangeColor, fontWeight: 'bold' } }>
              {t('getToKnowYourActualAge')}
            </Typography>

            <Typography>
              {t('Η βιολογική σου ηλικία υποδεικνύει την κατάσταση της υγείας σου και τη λειτουργική ικανότητα του οργανισμού σου.')}
            </Typography>

            <Typography>
              {t('Εξηγεί γιατί άτομα της ίδιας χρονολογικής ηλικίας έχουν βαθιές διαφορές μεταξύ τους.')}
            </Typography>

            <Typography>
              {t('bioAgeKnowledge')}
            </Typography>
          </div>
        </div>

        <div
          aria-hidden
          onClick={ () => {
            navigateToPage({ pageId: 'bioAgeResults' });
          } }
          style={ { position: 'absolute',
            bottom: '-25px',
            left: '0',
            right: '0',
            marginLeft: 'auto',
            marginRight: 'auto',
            width: '242px',
            textAlign: 'center',
            cursor: 'pointer',
            background: 'white',
            padding: '17px 60px',
            borderRadius: '27px',
            borderBottom: `5px solid ${ orangeColor }`,
            boxShadow: 'box-shadow: 0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)' } }
        >
          {t('Δες το report σου')}
        </div>
      </div>
      <WellnessPdf
        navigateToPage={ navigateToPage }
        url='bioAgePdfVersion'
        buttonColor={ orangeColor }
      />

    </div>
  );
};

const BiologicalAge = () => {
  const { serial } = useOptionSerial('bioAge');
  const { locale } = useBioAgeDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <BiologicalAgeInner />
    </I18nextProvider>
  );
};

export default BiologicalAge;
