import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import { FormHelperText, Typography, makeStyles } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import FormLabel from '@material-ui/core/FormLabel';
import { useTranslation } from 'react-i18next';
import { linear } from '../../../utils';

const useStyles = makeStyles(() => {
  return {
    error: { '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      borderColor: 'red',
    } },

  };
});

const SelectAux = (
  {
    value,
    onChange,
    options,
    disabledValue,
    style,
    error,
    className = '',
    disabled = false,
    ...rest
  },
) => {
  const classes = useStyles();

  return (
    <FormControl fullWidth size='small' { ...rest }>
      <Select
        { ...rest }
        size='small'
        className={ error ? classes.error : className }
        fullWidth
        variant='outlined'
        color='secondary'
        disabled={ disabled }
        value={ value || disabledValue }
        style={ { ...style } }
        onChange={ (event) => {
          return onChange(event.target.value);
        } }
      >
        <MenuItem disabled value={ disabledValue }>
          <Typography component='div' variant='subtitle2'>
            { disabledValue }
          </Typography>
        </MenuItem>
        {
          options.map((option) => {
            return (
              <MenuItem key={ option } value={ option }>
                <Typography component='div' variant='subtitle2'>
                  { option }
                </Typography>
              </MenuItem>
            );
          })
        }

      </Select>
    </FormControl>
  );
};

// const days = linear(1, 31)
//   .map((day) => {
//     return day.toString()
//       .padStart(2, '0');
//   });
// const months = linear(1, 12)
//   .map((month) => {
//     return month.toString()
//       .padStart(2, '0');
//   });
const currentYear = (new Date()).getFullYear();
const years = linear(1930, currentYear)
  .map((year) => {
    return year.toString();
  })
  .reverse();

const SimpleDateField = (
  {
    label,
    required,
    defaultValue,
    error,
    helperText,
    name,
    style = {},
    className = '',
    validate = null,
    disabled = false,
    country = 'GR',
  },
) => {
  const [ value, setValue ] = useState(defaultValue);
  const { t, i18n } = useTranslation();

  const getDays = (_month) => {
    return linear(1, 31)
      .map((day) => {
        return day.toString()
          .padStart(2, '0');
      });
  };

  const getMonths = (_day) => {
    return linear(1, 12)
      .map((month) => {
        return month.toString()
          .padStart(2, '0');
      });
  };

  const [ days, _setDays ] = useState(getDays());

  const [ months, _setMonth ] = useState(
    getMonths(),
  );

  useEffect(() => {
    setValue(t(defaultValue));
  }, [ defaultValue ]);

  const [ yyyy, mm, dd ] = value.split('-');

  useEffect(() => {
    setValue(`${ t(yyyy) }-${ t(mm) }-${ t(dd) }`);
  }, [ i18n.language, setValue ]);

  const handleDateChange = (v) => {
    const { day, month, year } = { ...{ day: dd, month: mm, year: yyyy }, ...v };
    const newDate = `${ year }-${ month }-${ day }`;
    setValue(newDate);

    if (error && null !== validate) {
      validate(newDate);
    }
  };

  return (
    <>
      <TextField
        name={ name }
        type='date'
        value={ value }
        className={ className }
        style={ {
          display: 'none',
          visibility: 'hidden',
          width: 0,
          height: 0,
          overflow: 'hidden',
          ...style,

        } }
      />
      <FormControl style={ { lineHeight: 0 } } size='small' fullWidth error={ error }>
        <FormLabel color='secondary' component='legend' disabled={ disabled }>
          { `${ label }${ required ? ' *' : '' }` }
        </FormLabel>
      </FormControl>
      <Grid container spacing={ 1 } style={ { gap: '5px', marginTop: '3px', flexWrap: 'nowrap' } }>

        {
          'US' === country ? (
            <>
              <div style={ { width: '80px' } }>
                <SelectAux
                  disabledValue={ t('μμ') }
                  error={ error }
                  options={ months }
                  className={ className }
                  style={ { ...style } }
                  onChange={ (v) => {
                    handleDateChange({ month: v });
                  } }
                  value={ mm }
                />
              </div>

              <div style={ { width: '80px' } }>
                <SelectAux
                  disabledValue={ t('ηη') }
                  options={ days }
                  error={ error }
                  className={ className }
                  disabled={ disabled }
                  onChange={ (v) => {
                    handleDateChange({ day: v });
                  } }
                  style={ { ...style } }
                  value={ dd }
                />
              </div>
            </>
          ) : (
            <>
              <div style={ { width: '80px' } }>
                <SelectAux
                  disabledValue={ t('ηη') }
                  options={ days }
                  error={ error }
                  disabled={ disabled }
                  className={ className }
                  onChange={ (v) => {
                    handleDateChange({ day: v });
                  } }
                  style={ { ...style } }
                  value={ dd }
                />
              </div>
              <div style={ { width: '80px' } }>
                <SelectAux
                  disabledValue={ t('μμ') }
                  error={ error }
                  options={ months }
                  disabled={ disabled }
                  className={ className }
                  style={ { ...style } }
                  onChange={ (v) => {
                    handleDateChange({ month: v });
                  } }
                  value={ mm }
                />
              </div>
            </>
          )
        }

        <div style={ { width: '80px' } }>
          <SelectAux
            disabledValue={ t('έτος') }
            disabled={ disabled }
            error={ error }
            className={ className }
            options={ years }
            style={ { ...style } }
            onChange={ (v) => {
              handleDateChange({ year: v });
            } }
            value={ yyyy }
          />
        </div>
      </Grid>

      { error && (
        <FormControl size='small' fullWidth error={ error }>
          <FormHelperText margin='dense' error>
            { t(helperText) }
          </FormHelperText>
        </FormControl>
      ) }
    </>
  );
};

SimpleDateField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

SimpleDateField.defaultProps = {
  defaultValue: 'έτος-μμ-ηη',
  error: false,
  helperText: '',
};

export default SimpleDateField;

