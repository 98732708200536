import React from 'react';
import { useNutritionData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { yellowColor } from 'pages/Nutrition/Nutrition';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';

export const profileToIcon = {
  carbohydrates: { big: '/YDATAN8RAKES.jpg', small: '/YDATAN8RAKES_SMALL.jpg' },
  proteins: { big: '/PROTEINI.jpg', small: '/PROTEINI_SMALL.jpg' },
  fats: { big: '/LIPARA.jpg', small: '/LIPARA_SMALL.jpg' },
  eatingSnacksBetweenMeals: { big: '/SNACK METAXY GEYMATON.jpg', small: '/SNACK METAXY GEYMATON_SMALL.jpg' },
  preferenceForSweetTaste: { big: '/PROTIMISI STI GLYKIA GEYSH.jpg', small: '/PROTIMISI STI GLYKIA GEYSH_SMALL.jpg' },
  biologicalClock: { big: '/BIOLOGIKO ROLOI.jpg', small: '/BIOLOGIKO ROLOI_SMALL.jpg' },
};

export const profileToUrl = {
  carbohydrates: 'nutritionCarbohydrates',
  proteins: 'nutritionProteins',
  fats: 'nutritionFats',
  eatingSnacksBetweenMeals: 'nutritionSnacksBetweenMeals',
  preferenceForSweetTaste: 'nutritionSweetTaste',
  biologicalClock: 'nutritionBiologicalClock',
};

const NWeightManagement = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useNutritionData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT3', 'NCAT6', 'NCAT12', 'NCAT9', 'NCAT19', 'NCAT8' ].includes(currData.ID);
  })
    .map((filtered) => {
      let iconPath = '';
      let url = '';

      if ('NCAT3' === filtered.ID) {
        iconPath = profileToIcon.carbohydrates;
        url = profileToUrl.carbohydrates;
      } else if ('NCAT6' === filtered.ID) {
        iconPath = profileToIcon.proteins;
        url = profileToUrl.proteins;
      } else if ('NCAT12' === filtered.ID) {
        iconPath = profileToIcon.fats;
        url = profileToUrl.fats;
      } else if ('NCAT9' === filtered.ID) {
        iconPath = profileToIcon.preferenceForSweetTaste;
        url = profileToUrl.preferenceForSweetTaste;
      } else if ('NCAT19' === filtered.ID) {
        iconPath = profileToIcon.biologicalClock;
        url = profileToUrl.biologicalClock;
      } else if ('NCAT8' === filtered.ID) {
        iconPath = profileToIcon.eatingSnacksBetweenMeals;
        url = profileToUrl.eatingSnacksBetweenMeals;
      }

      return { ...filtered, icon: iconPath, url };
    });

  const tab = {
    color: yellowColor,
    id: 'nutritionalGuide',
    title: 'Διατροφή',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext options={ dietCard } activePage='nutritionWeightManagement' isNutrition />
      <PhotoWithText
        options={ dietCard }
        activePage='weightManagement'
        text={ data[0].value.weight_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
    </div>
  );
};

export default NWeightManagement;
