import React, { useEffect } from 'react';
import Container from '@material-ui/core/Container';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import { useBlockingLoader } from '../../hooks/loader-hooks';
import { useAlerts } from '../../hooks/alerts-hooks';
import { useAuth } from '../../AuthCtx';
import { useFormStylesUp } from './form-styles-signup';
import ConfirmationCodeVerification from './ConfirmationCodeVerification';

const DeliveryMediumVerificationForm = () => {
  const { t } = useTranslation();
  const formClasses = useFormStylesUp();

  const { automaticSignIn, confirmSignUp, deliveryDetails, resendSignUp, verCodeAlreadySend, setVerCodeAlreadySend } = useAuth();
  const { showBlockingLoader, hideBlockingLoader } = useBlockingLoader('delivery-medium-verification');
  const { alertSuccess, alertError, alertInfo } = useAlerts();

  const handleVerify = (code) => {
    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return confirmSignUp({ code });
      })
      .catch((err) => {
        alertError(err);
        throw err;
      })
      .then(() => {
        alertSuccess(t('Your account has been verified'));
      })
      .then(() => {
        return automaticSignIn();
      })
      .catch(() => {

      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  const handleResend = () => {
    setVerCodeAlreadySend(true);
    return resendSignUp()
      .then(() => {
        alertInfo(t('Verification Code Sent'));
      })
      .catch(() => {
        alertError(t('Something went wrong. Please try again in a moment'));
      });
  };

  useEffect(() => {
    if (false === verCodeAlreadySend) {
      handleResend();
    }
  }, [ verCodeAlreadySend ]);

  // let title;
  let subtitle;
  let destination;

  if (!deliveryDetails) {
    subtitle = 'We have sent you a verification code during registration process';
  } else {
    const {
      CodeDeliveryDetails: {
        Destination,
      },
    } = deliveryDetails;
    destination = Destination;
    subtitle = 'We have sent you a verification code at';
  }

  return (
    <Container className={ formClasses.container }>
      <div className={ formClasses.paper }>
        <Typography component='div' variant='h5' color='secondary'>
          {t('confirmAccount')}
        </Typography>
        <Grid className={ formClasses.description }>
          <Typography align='left' component='div' variant='body2' color='textSecondary'>
            {`${ t(subtitle) }${ !deliveryDetails ? '' : `: ${ destination }` }`}
          </Typography>
        </Grid>
        <Grid container spacing={ 2 }>
          <ConfirmationCodeVerification onVerify={ handleVerify } />
        </Grid>

      </div>
      <Grid
        className={ formClasses.links }
        container
        direction='column'
        item
        xs={ 12 }
        alignItems='flex-end'
        spacing={ 1 }
      >
        <Grid item>
          <Typography component='div' variant='body2'>
            {`${ t('haveNotReceivedConfirmationCode') } `}
            <a
              href='/resend-confirmation-code'
              onClick={ (e) => {
                e.preventDefault();
                return handleResend();
              } }
            >
              {t('resendCode')}
            </a>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
};

export default DeliveryMediumVerificationForm;
