import React, { useMemo, useState } from 'react';
import BlockingLoader from './components/app/BlockingLoader';

export const LoaderCtx = React.createContext({});

export function LoaderProvider(props) {
  const [ blockingLoaders, setBlockingLoaders ] = useState([]);

  const openBlockingLoader = useMemo(() => {
    return 0 < blockingLoaders.length;
  }, [ blockingLoaders ]);

  const showBlockingLoader = (key) => {
    setBlockingLoaders((prev) => {
      if (prev.includes(key)) {
        return prev;
      }
      return [ ...prev, key ];
    });
  };

  const hideBlockingLoader = (key) => {
    setBlockingLoaders((prev) => {
      if (!prev.includes(key)) {
        return prev;
      }
      return prev.filter((k) => { return key !== k; });
    });
  };

  const ctx = {
    showBlockingLoader,
    hideBlockingLoader,
  };

  return (
    <LoaderCtx.Provider value={ ctx }>
      {props.children}
      <BlockingLoader open={ openBlockingLoader } />
    </LoaderCtx.Provider>
  );
}
