import React from 'react';
import { useOptionSerial } from 'NavigationProvider';
import PDFViewer from '../../components/PDFViewer';

const SportsPdfVersion = () => {
  const { serial: newSerial } = useOptionSerial('sports');

  return (
    <PDFViewer serial={ newSerial } />
  );
};

export default SportsPdfVersion;
