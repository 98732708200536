import { useKit } from 'models/kits';
import { CircularProgress, Divider, Typography } from '@material-ui/core';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import React, { useEffect } from 'react';
import QuestionnaireAnswers from 'components/QuestionnaireAnswers';
import { useTranslation } from 'react-i18next';
import { InfoReview } from '../../../components/layout/KitRegistrationAnswersReview';

const PatientDetailsPage = () => {
  const { serial: kitId } = useOptionSerial('3-in-1');
  const { navigateToPage, activePageId } = useNavigation();

  console.log('activePageId', activePageId);

  const { t } = useTranslation();

  const data = useKit(kitId);

  useEffect(() => {
    if ('' === kitId || null == kitId) {
      navigateToPage({ pageId: 'general' });
    }
  }, [ kitId ]);

  if (null == data || data.isLoading || null == kitId) {
    return (
      <div style={ { width: '100%', justifyContent: 'center', alignItems: 'center', display: 'flex', marginTop: '100px' } }>
        <CircularProgress color='secondary' />
      </div>
    );
  }

  const { locale, data: { customer_id: customer }, kitMetadata: { guardian }, kitAgreements,
    kitHealthcareProfessionalInvitations, sampleCollectionDate, serial,
    isKitBioAge, isKitOnlyNutri, isKitCvd } = data;

  const hasQuestionnaire = isKitBioAge || isKitOnlyNutri || isKitCvd;

  return (
    <div style={ { background: 'white', padding: '30px', borderRadius: '15px' } }>
      {
        'patientDetails' === activePageId && (
          <InfoReview
            kitId={ kitId }
            kitHealthcareProfessionalInvitations={ kitHealthcareProfessionalInvitations }
            kitAgreements={ kitAgreements }
            sampleCollectionDate={ sampleCollectionDate }
            locale={ locale }
            customer={ customer }
            guardian={ guardian }
            showAggrements={ false }
            showReportLanguage={ false }
            fullWidth
          />
        )
      }

      {
        hasQuestionnaire && (
          <>
            <Typography
              variant='h6'
              style={ { color: '#648DAE',
                fontSize: '20px',
                fontFamily: 'Roboto',
                lineHeight: '21px',
                letterSpacing: '.04em',
                textAlign: 'initial',
                marginTop: '30px' } }
            >
              {t('questionnaireAnswers')}
            </Typography>
            <Divider style={ { width: '60%' } } />

            <QuestionnaireAnswers
              serial={ serial }
              color='#B8CEE0'
              isChild={ false }
              showTitle={ false }
              smallGap
              questionVariant='body2'
            />
          </>
        )
      }
    </div>
  );
};

export default PatientDetailsPage;
