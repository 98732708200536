import React from 'react';
import { Divider, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQueries from '../hooks/media-query-hooks';
import NotificationsList from './user-notifications-page/NotificationsList';

const useStyles = makeStyles((_theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const UserNotificationsPage = (props) => {
  const { title } = props;
  const classes = useStyles();
  const { isMd } = useMediaQueries();
  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {title}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 }>
        <Grid item xs={ 12 }>
          <NotificationsList />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default UserNotificationsPage;
