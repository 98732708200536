import React, { useMemo } from 'react';
import { useCardioDataReport } from 'models/kits';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useOptionSerial } from 'NavigationProvider';
import i18nEng from '../../../i18n_eng';
import i18nEl from '../../../i18n_el';
import CardioResults, { ImageText } from '../Common';

const YpertrofikiMyocardioPathia = () => {
  const { i18n } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { data, locale } = useCardioDataReport(serial, i18n.language);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null == data) {
    return <LoaderSkeleton />;
  }

  if (0 === data.length) {
    return (
      <h1>
        No data available
      </h1>
    );
  }

  const nosos = data[0].value['hypertrophic cardiomyopathy'];

  const prsImage = [ JSON.parse(nosos._IMG_SCORE).base64, JSON.parse(nosos._IMG_SCORE_MOBILE).base64 ];
  const adjPrsImage = [ JSON.parse(nosos._IMG_ADJ_PRS_SCORE).base64, JSON.parse(nosos._IMG_ADJ_PRS_SCORE_MOBILE).base64 ];
  const prsScore = nosos.PRS_SCORE;
  const adjPrsScore = nosos.ADJPRS_SCORE;
  const prsCharacterisation = nosos.PRS_CHARACTERISATION;
  const adjPrsCharacterisationFem = nosos.ADJPRS_CHARACTERISATION_FEMALE;
  const traitProfile = nosos.TRAIT_PROFILE;
  const adjPrsCharacterisationLevels = nosos.ADJPRS_CHARACTERISATION_LEVELS;

  const imageContent = 'Η υπερτροφική μυοκαρδιοπάθεια είναι μια ασθένεια κατά την οποία ο καρδιακός μυς γίνεται αφύσικα παχύς (υπερτροφικός) και μπορεί να δυσκολέψει την καρδιά να αντλήσει αίμα. Η υπερτροφική μυοκαρδιοπάθεια συχνά μένει αδιάγνωστη επειδή η πλειοψηφία των ανθρώπων με τη νόσο έχουν λίγα, ή και καθόλου, συμπτώματα και μπορούν να ζήσουν μια φυσιολογική ζωή χωρίς σημαντικά προβλήματα. Ωστόσο, σε έναν μικρό αριθμό ατόμων, ο παχύς καρδιακός μυς μπορεί να προκαλέσει δύσπνοια, πόνο στο στήθος ή προβλήματα στο ηλεκτρικό σύστημα της καρδιάς, με αποτέλεσμα απειλητικούς για τη ζωή μη φυσιολογικούς καρδιακούς ρυθμούς (αρρυθμίες).';

  let characterisation = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === characterisation) {
    characterisation = 'Τυπική';
  } else if ('HIGH' === characterisation) {
    characterisation = 'Βέλτιστη';
  } else if ('LOW' === characterisation) {
    characterisation = 'Mειωμένη';
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div>
        <CardioResults />
        <ImageText
          prsImage={ prsImage }
          traitProfile={ traitProfile }
          characterisation={ characterisation }
          adjPrsCharacterisationLevels={ adjPrsCharacterisationLevels }
          adjPrsCharacterisationFem={ adjPrsCharacterisationFem }
          adjPrsImage={ adjPrsImage }
          prsScore={ prsScore }
          adjPrsScore={ adjPrsScore }
          prsCharacterisation={ prsCharacterisation }
          activeProfile='Μυοκαρδιοπάθεια - Υπερτροφική'
          imageContent={ imageContent }
          imageTitle='Για τη Μυοκαρδιοπάθεια - Υπερτροφική'
          source='https://www.mayoclinic.org/diseases-conditions/hypertrophic-cardiomyopathy/symptoms-causes/syc-20350198'
        />
      </div>
    </I18nextProvider>
  );
};

export default YpertrofikiMyocardioPathia;
