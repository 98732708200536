import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import React from 'react';
import { useTranslation } from 'react-i18next';

const AppPagination = (
  {
    page,
    limit,
    count,
    setPage,
    hideInfo,
  },
) => {
  let label = '';
  let numberOfPages = 0;
  const { t } = useTranslation();

  switch (count) {
    case 0:
      label = 'No records';
      break;
    case 1:
      label = '1 record';
      break;
    case undefined:
      label = '';
      break;
    default:
      if (-1 === limit) {
        label = `${ count } ${ t('records') }`;
      } else {
        label = `${ Math.min(count, page * limit + 1) } - ${ Math.min(count, (page + 1) * limit) } ${ t('from') } ${ count }`;
      }
      break;
  }

  switch (limit) {
    case -1:
      numberOfPages = 1;
      break;
    default:
      if (0 === count || count === undefined) {
        numberOfPages = 0;
      } else {
        numberOfPages = Math.ceil(count / limit);
      }
  }

  return (
    <Grid container alignItems='center' spacing={ 1 }>
      {!hideInfo && (
        <Grid item>
          <Typography variant='subtitle2'>
            { t(label) }
          </Typography>
        </Grid>
      ) }
      <Grid item>
        <Pagination
          page={ page + 1 }
          onChange={ (e, v) => { setPage(v - 1); } }
          count={ numberOfPages }
          variant='outlined'
          shape='rounded'
        />
      </Grid>
    </Grid>
  );
};

export default AppPagination;
