/* eslint-disable no-unused-vars */
import React from 'react';
import { useAuth } from '../AuthCtx';

const Playground = () => {
  const auth = useAuth();

  return (
    <div
      style={ {
        position: 'fixed',
        padding: '10px',
        right: '30px',
        bottom: '150px',
        width: '200px',
        height: '200px',
        border: '1px solid black',
        zIndex: 9999,
        overflow: 'auto',
      } }
    >
      <pre>{JSON.stringify(auth, undefined, 2)}</pre>
    </div>
  );
};

export const Debug = () => {
  return <></>;
  // return 'development' === process.env.NODE_ENV ? <Playground /> : null;
};
