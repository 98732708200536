import React, { useEffect, useState } from 'react';
import * as _ from 'lodash';
import { Grid, RadioGroup, FormControlLabel, Radio, FormControl,
  FormLabel, FormGroup, Typography, TextField, Divider, Select, OutlinedInput, MenuItem, Checkbox, ListItemText, InputLabel } from '@material-ui/core';
import { useKit } from 'models/kits';
import SimpleInputFieldWithValue from 'components/forms/atoms/SimpleInputFieldWithValue';
import { useTranslation } from 'react-i18next';
import { floatRegex, intRegex } from './Questionnaire';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
  getContentAnchorEl: () => { return null; },
};

const MultipleChoiceOther = ({ question, multipleChangeOther, label, disabled, possibleValue, qId }) => {
  const [ otherText, setOtherText ] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    if (null == possibleValue || 0 === possibleValue.length) { return; }

    const foundPossibleValue = possibleValue.find((val) => {
      return 'string' !== typeof val && !(val instanceof String) && Object.keys(val).includes(qId);
    })?.[qId];

    if (null != foundPossibleValue) {
      setOtherText(foundPossibleValue);
    }

    // for (let i = 0; i < possibleValue.length; i++) {
    //   if (!question.options.includes(possibleValue[i])) {
    //     setOtherText(possibleValue[i]);
    //     break;
    //   }
    // }
  }, [ possibleValue, question ]);

  useEffect(() => {
    if (disabled) {
      setOtherText('');
    }
  }, [ disabled ]);

  return (
    <div style={ { display: 'flex', alignItems: 'center', gap: '9px', width: '100%' } }>
      <Typography
        style={ {
          fontSize: '1rem',
          fontFamily: 'Roboto',
          fontWeight: '400',
          lineHeight: '1.5',
          color: disabled ? '#B4B4B4' : '',
        } }
      >
        {t(label)}
        :
      </Typography>

      <TextField
        disabled={ disabled }
        color='secondary'
        value={ otherText }
        onChange={ (e) => {
          setOtherText(e.target.value);
          multipleChangeOther(question.id, e.target.value, question, qId);
        } }
      />
    </div>
  );
};

const LimitedQuestionnaire = ({ kitId, wellnessQuestionnaire, setWellnessQuestionnaire, wellnessQuestionnaireErrorID, setWellnessQuestionnaireErrorID }) => {
  const { questions, data: kitData, isKitPGX, child } = useKit(kitId);
  const customerCountry = kitData?.customer_id?.country;

  let extraInfo = kitData?.customer_id?.extra_info;
  if (null != child) {
    extraInfo = child?.extra_info;
  }

  if (isKitPGX) {
    extraInfo = kitData?.customer_id?.extra_info_pgx;
  }

  const { i18n, t } = useTranslation();

  useEffect(() => {
    if (null != extraInfo && 0 < Object.keys(extraInfo).length) {
      setWellnessQuestionnaire(extraInfo);
      return;
    }

    setWellnessQuestionnaire((val) => {
      const copy = _.cloneDeep(val);

      questions.forEach((item) => {
        const index = item.id;
        const questiondata = null;

        if (null == questiondata || 0 === questiondata.length || null == questiondata[0] || null == questiondata[0].value) {
          if ('multiple_choice' === item.type || 'multichoice_dropdown' === item.type) {
            copy[index] = [];
          } else if ('date' === item.type) {
            copy[index] = null;
          } else if ('date_integer' === item.type) {
            copy[index] = null;
            copy[`${ Number(index) + 1 }`] = '';
          } else {
            copy[index] = '';
          }
        }
      });

      return copy;
    });
  }, [ extraInfo, questions ]);

  const itemChange = (e, index, target = true) => {
    const quest = questions[index - 1];

    if (index === wellnessQuestionnaireErrorID) {
      setWellnessQuestionnaireErrorID(-1);
    }

    setWellnessQuestionnaire((val) => {
      const copy = _.cloneDeep(val);

      if (target) {
        copy[index] = e.target.value;
      } else {
        copy[index] = e;
      }

      // Clear answer if it is not displayed.
      if (null != quest.referrence) {
        const refOpt = 'el-GR' === i18n.language ? quest.referrence.options_el : quest.referrence.options_en;
        // TODO: Questionnaire language changed this.
        // const refOpt = quest.referrence.options;

        if (!refOpt.includes(copy[index])) {
          const refPos = Number(quest.referrence.id) - 1;

          if ('date' === questions[refPos].type || 'date_integer' === questions[refPos].type) {
            copy[quest.referrence.id] = null;
          } else {
            copy[quest.referrence.id] = '';
          }
        }
      }

      return copy;
    });
  };

  const multipleDropdownChange = (e, index) => {
    if (index === wellnessQuestionnaireErrorID) {
      setWellnessQuestionnaireErrorID(-1);
    }

    setWellnessQuestionnaire((val) => {
      const copy = _.cloneDeep(val);

      copy[index] = 'string' === typeof e.target.value ? e.target.value.split(',') : e.target.value;

      console.log(e.target.value, copy[index]);

      return copy;
    });
  };

  const multipleChange = (e, index, value) => {
    if (index === wellnessQuestionnaireErrorID) {
      setWellnessQuestionnaireErrorID(-1);
    }

    setWellnessQuestionnaire((val) => {
      const copy = _.cloneDeep(val);

      if (false === e.target.checked) {
        const tIndex = copy[index].indexOf(value);
        if (-1 !== index) {
          copy[index].splice(tIndex, 1);
        }
      } else if (questions[Number(index) - 1].disable_when?.includes(value)) {
        copy[index] = [ value ];
      } else {
        copy[index].push(value);
      }

      return copy;
    });
  };

  const multipleChangeOther = (index, value, question, qId) => {
    if (index === wellnessQuestionnaireErrorID) {
      setWellnessQuestionnaireErrorID(-1);
    }

    setWellnessQuestionnaire((val) => {
      const copy = _.cloneDeep(val);

      let pos = 0;

      const foundPossibleValue = copy[index].find((ss) => {
        const isFound = 'string' !== typeof ss && !(ss instanceof String) && Object.keys(ss).includes(qId);
        if (!isFound) {
          pos += 1;
        }

        return isFound;
      });

      if (null == foundPossibleValue) {
        copy[index].push({ [qId]: value });
      } else if ('' === value) {
        copy[index].splice(pos, 1);
      } else {
        foundPossibleValue[qId] = value;
      }

      return copy;
    });
  };

  const shouldShowQuestion = (referral) => {
    if (referral === undefined) {
      return true;
    }

    const refQuestion = questions.find((q) => {
      return q.id === referral;
    });

    if (refQuestion === undefined) {
      return true;
    }

    return refQuestion.referrence.options.includes(wellnessQuestionnaire[referral]);
  };

  const checkMultiDisabled = (id, value) => {
    if (null == wellnessQuestionnaire[id] || 0 === wellnessQuestionnaire[id].length || null == questions[Number(id) - 1].disable_when
    ) {
      return false;
    }

    if (questions[Number(id) - 1].disable_when.includes(value)) {
      return false;
    }

    if (questions[Number(id) - 1].disable_when.some((r) => { return wellnessQuestionnaire[id].includes(r); })) {
      return true;
    }

    return false;
  };

  if (null == wellnessQuestionnaire || 0 === Object.entries(wellnessQuestionnaire).length) {
    return null;
  }

  return (
    <Grid container spacing={ 2 }>
      {
        questions.map((q) => {
          let desc = 'en-US' === i18n.language ? q.description_en : q.description_el;
          if ('US' === customerCountry) {
            desc = desc.replace('cm', 'feet').replace('kg', 'pounds').replace('εκ.', 'feet').replace('κιλά', 'pounds');
          }
          const options = q?.options?.map((opt, idx) => {
            return {
              opt,
              label: 'en-US' === i18n.language ? q.options_en[idx] : q.options_el[idx],
            };
          });

          const width = options === undefined || 2 >= options.length ? 6 : 12;

          const hasError = q.id === wellnessQuestionnaireErrorID;

          return (
            <Grid
              item
              xs={ 12 }
              md={ width }
              key={ q.id }
              style={ { display: shouldShowQuestion(q.referral) ? '' : 'none' } }
            >
              {
                'multichoice_dropdown' === q.type && (
                  <FormControl fullWidth color='secondary'>
                    <InputLabel
                      variant='outlined'
                      id='multichoice_dropdown-label'
                    >
                      {desc}
                    </InputLabel>
                    <Select
                      labelId='multichoice_dropdown-label'
                      multiple
                      value={ wellnessQuestionnaire[q.id] }
                      MenuProps={ MenuProps }
                      label={ desc }
                      onChange={
                        (e) => {
                          multipleDropdownChange(e, q.id);
                        }
                      }
                      input={ <OutlinedInput label={ desc } /> }
                      renderValue={ (selected) => {
                        return selected.map((sel) => {
                          return options.find((opt) => {
                            return opt.opt === sel;
                          })?.label;
                        }).join(', ');
                      } }
                    >
                      {options.map((opt) => {
                        return (
                          <MenuItem key={ opt.opt } value={ opt.opt }>
                            <Checkbox checked={ -1 < wellnessQuestionnaire[q.id].indexOf(opt.opt) } />
                            <ListItemText primary={ opt.label } />
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </FormControl>
                )
              }

              {
                'short_float' === q.type && (
                  <SimpleInputFieldWithValue
                    error={ q.id === wellnessQuestionnaireErrorID }
                    label={ desc }
                    required={ q.required }
                    helperText={ q.id === wellnessQuestionnaireErrorID && t('validation.requiredField') }
                    value={ wellnessQuestionnaire[q.id] }
                    setValue={ (val) => {
                      if (floatRegex.test(val)) {
                        itemChange(val, q.id, false);
                      }
                    } }
                  />
                )
              }

              {
                'float_if_usa_else_int' === q.type && (
                  <SimpleInputFieldWithValue
                    error={ q.id === wellnessQuestionnaireErrorID }
                    label={ desc }
                    required={ q.required }
                    helperText={ q.id === wellnessQuestionnaireErrorID && t('validation.requiredField') }
                    value={ wellnessQuestionnaire[q.id] }
                    setValue={ (val) => {
                      if ('US' === customerCountry && floatRegex.test(val)) {
                        itemChange(val, q.id, false);
                      } else if (intRegex.test(val)) {
                        itemChange(val, q.id, false);
                      }
                    } }
                  />
                )
              }

              {
                'short_int' === q.type && (
                  <SimpleInputFieldWithValue
                    error={ q.id === wellnessQuestionnaireErrorID }
                    label={ desc }
                    required={ q.required }
                    helperText={ q.id === wellnessQuestionnaireErrorID && t('validation.requiredField') }
                    value={ wellnessQuestionnaire[q.id] }
                    setValue={ (val) => {
                      if (intRegex.test(val)) {
                        itemChange(val, q.id, false);
                      }
                    } }
                  />
                )
              }

              {
                'one_choice' === q.type && (
                  <FormControl>
                    <FormLabel style={ { color: hasError ? 'red' : '#5F5F5F' } }>{desc}</FormLabel>
                    <RadioGroup
                      value={ wellnessQuestionnaire[q.id] }
                      onChange={ (e) => { itemChange(e, q.id); } }
                      row
                    >
                      {
                        options.map((opt) => {
                          return (
                            <FormControlLabel
                              key={ opt.opt }
                              value={ opt.opt }
                              control={ <Radio /> }
                              label={ opt.label }
                            />
                          );
                        })
                      }
                    </RadioGroup>

                    {hasError && (
                      <FormLabel style={ { color: 'red' } }>
                        <Typography style={ { fontSize: '14px' } }>
                          {t('validation.requiredField')}
                        </Typography>
                      </FormLabel>
                    )}

                    <Divider />
                  </FormControl>
                )
              }

              {
                'multiple_choice' === q.type && (
                  <FormControl>
                    <FormLabel style={ { color: hasError ? 'red' : '#5F5F5F' } }>{desc}</FormLabel>
                    <FormGroup
                      row
                    >
                      {
                        options.map((opt) => {
                          return (
                            <React.Fragment key={ opt.opt }>
                              {
                                null !== q.is_text && q.is_text?.includes(opt.opt) ? (
                                  <MultipleChoiceOther
                                    qId={ opt.opt }
                                    label={ opt.label }
                                    question={ q }
                                    multipleChangeOther={ multipleChangeOther }
                                    disabled={ checkMultiDisabled(q.id, opt.opt) }
                                    possibleValue={ wellnessQuestionnaire[q.id] }
                                  />
                                ) : (
                                  <FormControlLabel
                                    control={ (
                                      <Checkbox
                                        disabled={ checkMultiDisabled(q.id, opt.opt) }
                                        checked={ null == wellnessQuestionnaire[q.id] ? false : wellnessQuestionnaire[q.id].includes(opt.opt) }
                                        onChange={ (e) => {
                                          multipleChange(e, q.id, opt.opt);
                                        } }
                                      />
                                    ) }
                                    label={ opt.label }
                                  />
                                )
                              }
                            </React.Fragment>
                          );
                        })
                      }
                    </FormGroup>

                    {hasError && (
                      <FormLabel style={ { color: 'red' } }>
                        <Typography style={ { fontSize: '14px' } }>
                          {t('validation.requiredField')}
                        </Typography>
                      </FormLabel>
                    )}

                    <Divider style={ { marginTop: '15px' } } />
                  </FormControl>
                )
              }
            </Grid>
          );
        })
      }
      {
        3 < 2 && (
          <div
            onClick={
              () => {
                console.log(wellnessQuestionnaire);
              }
            }
            aria-hidden
          >
            Click me
          </div>
        )
      }
    </Grid>
  );
};

export default LimitedQuestionnaire;
