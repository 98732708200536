import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import * as _ from 'lodash';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';
import { iDNA3ToOneCards } from './iDNA3ToOne';

export const profileToIcon = {
  sensitivityToCoffee: { big: '/KAFEINI.jpg', small: '/KAFEINI_SMALL.jpg' },
  sensitivityToAlcohol: { big: '/ALKOOL.jpg', small: '/ALKOOL_SMALL.jpg' },
  sensitivityToLactose: { big: '/LACTOZI.jpg', small: '/LACTOZI_SMALL.jpg' },
  sensitivityToGluten: { big: '/GLOUTENI.jpg', small: '/GLOUTENI_SMALL.jpg' },
};

export const profileToUrl = {
  sensitivityToCoffee: 'sensitivityToCoffee',
  sensitivityToAlcohol: 'sensitivityToAlcohol',
  sensitivityToLactose: 'sensitivityToLactose',
  sensitivityToGluten: 'sensitivityToGluten',
};

export const adviceImages = {
  sensitivityToCoffee: { left: '/kafeini-1.jpg', right: '/kafeini-2.jpg' },
  sensitivityToAlcohol: { left: null, right: null },
  sensitivityToLactose: { left: '/lactosi.jpg', right: null },
  sensitivityToGluten: { left: null, right: 'glouteni.jpg' },
};

const FoodSensitivities = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const categories = _.cloneDeep(data[0].value.categories);

  categories.push(categories.splice(
    categories.findIndex(
      (s) => { return 'NCAT16' === s.ID; },
    ), 1,
  )[0]);

  const sensProfiles = categories.filter((currData) => {
    return [ 'NCAT14', 'NCAT16', 'NCAT17', 'NCAT18' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT14' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToCoffee;
      url = profileToUrl.sensitivityToCoffee;
    } else if ('NCAT16' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToAlcohol;
      url = profileToUrl.sensitivityToAlcohol;
    } else if ('NCAT17' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToLactose;
      url = profileToUrl.sensitivityToLactose;
    } else if ('NCAT18' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToGluten;
      url = profileToUrl.sensitivityToGluten;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ dietCard } activePage='foodSensitivities' />
        <PhotoWithText
          options={ dietCard }
          activePage='foodSensitivities'
          text={ data[0].value.sensitivities_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default FoodSensitivities;
