import { useQuery } from 'react-query';
import useAPI from '../hooks/api-hooks';

const DOCTOR_SPECIALTIES = 'doctor-specialties';

export const useDoctorSpecialties = () => {
  const { client } = useAPI({ noAuthorization: true });

  const q = useQuery(
    DOCTOR_SPECIALTIES,
    () => {
      return client.get('specialties?_limit=-1');
    },
  );

  return {
    ...q,
  };
};
