import React, { useEffect, useState, createRef, useCallback } from 'react';
import _ from 'lodash';
import { InputAdornment, Button, TextField } from '@material-ui/core';
import { isValidDate } from 'utils';
import { MdLibraryAdd, MdDeleteOutline } from 'react-icons/md';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { useAuth } from 'AuthCtx';
import RadioGroup from '@material-ui/core/RadioGroup';
import Input from '@material-ui/core/Input';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import FormGroup from '@material-ui/core/FormGroup';
import Checkbox from '@material-ui/core/Checkbox';
import AlertTitle from '@material-ui/lab/AlertTitle';
import Alert from '@material-ui/lab/Alert';
import { useTranslation } from 'react-i18next';
import SimpleDateFieldValue from 'components/forms/atoms/SimpleDateFieldValue';
import { useKit } from 'models/kits';

export const intRegex = /(^(\d+)$)|^$/;
export const floatRegex = /(^((\d+)((\.\d*)?))$)|^$/;

export const useStyles = makeStyles((theme) => {
  return {
    question: {
      background: 'white',
      border: '1px solid #dadce0',
      borderRadius: '8px',
      padding: '24px 24px 2px 24px',
      // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
      boxShadow: 'rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px',
    },
    questionInWellness: {
      // background: 'white',
      borderRadius: '8px',
      // padding: '24px 24px 2px 24px',
      // boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
    },
    questionHeaderColor: {
      background: '#93c221',
      height: '10px',
      width: '100%',
      borderTopRightRadius: '8px',
      position: 'relative',
      top: '5px',
      borderTopLeftRadius: '8px',
    },
    alert: {
      display: 'flex',
      alignItems: 'center',
      marginBottom: '10px',
      flexWrap: 'nowrap',
      '& .MuiAlert-icon': {
        fontSize: 30,
      },
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'wrap',
      },
    },
  };
});

const MultipleChoiceOther = ({ question, multipleChangeOther, label, disabled, possibleValue, qId }) => {
  const [ otherText, setOtherText ] = useState('');

  useEffect(() => {
    if (null == possibleValue || 0 === possibleValue.length) { return; }

    const foundPossibleValue = possibleValue.find((val) => {
      return 'string' !== typeof val && !(val instanceof String) && Object.keys(val).includes(qId);
    })?.[qId];

    if (null != foundPossibleValue) {
      setOtherText(foundPossibleValue);
    }

    // for (let i = 0; i < possibleValue.length; i++) {
    //   if (!question.options.includes(possibleValue[i])) {
    //     setOtherText(possibleValue[i]);
    //     break;
    //   }
    // }
  }, [ possibleValue, question ]);

  useEffect(() => {
    if (disabled) {
      setOtherText('');
    }
  }, [ disabled ]);

  return (
    <div style={ { display: 'flex', alignItems: 'center', gap: '9px', width: '100%' } }>
      <Typography
        style={ {
          fontSize: '1rem',
          fontFamily: 'Roboto',
          fontWeight: '400',
          lineHeight: '1.5',
          color: disabled ? '#B4B4B4' : '',
        } }
      >
        {label}
        :
      </Typography>

      <TextField
        disabled={ disabled }
        color='secondary'
        value={ otherText }
        onChange={ (e) => {
          setOtherText(e.target.value);
          multipleChangeOther(question.id, e.target.value, question, qId);
        } }
      />
    </div>
  );
};

const DateInteger = ({ question, formData, dateIntegerChange, pos }) => {
  const datePos = `${ Number(question.id) + pos * 2 }`;
  const numPos = `${ Number(question.id) + (pos * 2 + 1) }`;
  const { t } = useTranslation();

  return (
    <div style={ { display: 'flex', justifyContent: 'space-around', marginTop: '10px', alignItems: 'center', flexWrap: 'wrap', rowGap: '5px' } }>
      <SimpleDateFieldValue
        value={ formData[datePos] }
        onChange={ (value) => {
          dateIntegerChange(pos, value, datePos, false);
        } }
      />
      <div>
        <Input
          color='secondary'
          placeholder={ t('reg_kit.questionnaire.common.your_answer') }
          value={ formData[numPos] }
          onChange={ (e) => {
            if (floatRegex.test(e.target.value)) {
              dateIntegerChange(pos, e, numPos);
            }
          } }
        />
      </div>
    </div>
  );
};

const MultipleDateInteger = ({ question, index, formData, dateIntegerChange, setFormData }) => {
  const [ numOfItems, setNumOfItems ] = useState(Math.max(1, Math.round((Object.entries(formData).length - Number(question.id) + 1) / 2)));

  const { i18n, t } = useTranslation();
  const description1 = 'el-GR' === i18n.language ? question.description_1_el : question.description_1_en;
  const description2 = 'el-GR' === i18n.language ? question.description_2_el : question.description_2_en;

  return (
    <div>
      <div>
        <Typography style={ { marginBottom: '15px' } }>
          <span style={ { marginRight: '12px' } }>
            {
              `${ index + 1 }.`
            }
          </span>
          <span style={ { display: 'flex', justifyContent: 'space-around' } }>
            <span style={ { marginLeft: '-20px' } }>
              {description1}
            </span>
            <span style={ { marginLeft: '-17px' } }>
              {description2}
            </span>
          </span>
          {
            true === question.required && (
              <span style={ { color: 'red', marginLeft: '3px' } }>*</span>
            )
          }
        </Typography>
      </div>
      <div>
        {
          Array.from(Array(numOfItems)).map((_1, i) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <div key={ i }>
                <DateInteger
                  formData={ formData }
                  question={ question }
                  dateIntegerChange={ dateIntegerChange }
                  pos={ i }
                />
              </div>
            );
          })
        }
      </div>
      <div style={ { display: 'flex', marginTop: '40px', gap: '10px' } }>
        <Button
          variant='contained'
          color='secondary'
          startIcon={ <MdLibraryAdd /> }
          onClick={ () => {
            setNumOfItems((i) => {
              const copy = formData;
              copy[`${ Number(question.id) + i * 2 }`] = null;
              copy[`${ Number(question.id) + i * 2 + 1 }`] = '';
              setFormData({ ...copy });

              return i + 1;
            });
          } }
          style={ {
            color: 'white',
            backgroundColor: '#94c320',
          } }
        >
          {t('Προσθήκη')}
        </Button>

        <Button
          variant='outlined'
          startIcon={ <MdDeleteOutline /> }
          onClick={ () => {
            setNumOfItems((i) => {
              if (0 !== i) {
                const copy = formData;

                delete copy[`${ Number(question.id) + (i - 1) * 2 }`];
                delete copy[`${ Number(question.id) + ((i - 1) * 2 + 1) }`];

                setFormData({ ...copy });
              }

              return 0 === i ? 0 : i - 1;
            });
          } }
          style={ {
            color: 'red',
            borderColor: 'red',
            background: 'white',
          } }
        >
          {t('Διαγραφή')}
        </Button>
      </div>
    </div>
  );
};

const Question = ({ question, index, formData, itemChange,
  correctValues, setCorrectValues, multipleChange, multipleChangeOther, itemChangeThree, itemChangeTwo, dateIntegerChange,
  setFormData, emptyAnswerId, customerCountry, kitId, checkMultiDisabled, visibleIndex }) => {
  const correctValueChange = (value, theIndex) => {
    const copy = correctValues;

    copy[theIndex] = value;

    setCorrectValues({ ...copy });
  };

  const { isKitWellness } = useKit(kitId);

  const InputComponent = isKitWellness ? OutlinedInput : Input;

  const { i18n, t } = useTranslation();
  const description = 'el-GR' === i18n.language ? question.description_el : question.description_en;
  const info = 'el-GR' === i18n.language ? question.info_el : question.info_en;
  // const options = 'el-GR' === i18n.language ? question.options_el : question.options_en;
  // TODO: Questionnaire language changed this.
  // const { options } = question;

  const subDescription = 'el-GR' === i18n.language ? question?.sub_description_el : question?.sub_description_en;

  const qOpt = 'el-GR' === i18n.language ? question?.options : question?.options;

  const options = qOpt?.map((x, i) => {
    let label = question.options_el[i];
    if ('en-US' === i18n.language) {
      label = question.options_en[i];
    }

    return { opt: x, label };
  });

  useEffect(() => {
    if ('short_integer' === question.type && null !== question.limit_up) {
      if ('' !== formData[question.id] && null !== formData[question.id] && formData[question.id] !== undefined) {
        if ((null === question.limit_up || Number(formData[question.id]) <= Number(question.limit_up))
        && (null === question.limit_down || Number(formData[question.id] >= Number(question.limit_down)))) {
          correctValueChange(true, question.id);
        } else {
          correctValueChange(false, question.id);
        }
      } else {
        correctValueChange(true, question.id);
      }
    } else if (('short_float' === question.type || 'float_if_usa_else_int' === question.type) && null !== question.limit_up) {
      if ('' !== formData[question.id] && null !== formData[question.id] && formData[question.id] !== undefined) {
        if ((null === question.limit_up || Number(formData[question.id]) <= Number(question.limit_up))
        && (null === question.limit_down || Number(formData[question.id] >= Number(question.limit_down)))) {
          correctValueChange(true, question.id);
        } else {
          correctValueChange(false, question.id);
        }
      } else {
        correctValueChange(true, question.id);
      }
    } else if ('three_integers' === question.type && null !== question.limit_up && null != formData[question.id]) {
      const values = formData[question.id]?.split('/');
      const upLimit = Number(question.limit_up);
      const downLimit = null != question.limit_down ? Number(question.limit_down) : Infinity;

      if (null == values || 3 !== values.length) {
        correctValueChange(true, question.id);
      } else if (('' !== values[0] && (Number(values[0]) > upLimit || Number(values[0] < downLimit)))
            || ('' !== values[1] && (Number(values[1]) > upLimit || Number(values[1] < downLimit)))
            || ('' !== values[2] && (Number(values[2]) > upLimit || Number(values[2] < downLimit)))
      || ('' !== values[1] && '' !== values[2] && (Number(values[1]) > Number(values[2])))) {
        correctValueChange(false, question.id);
      } else {
        correctValueChange(true, question.id);
      }
    } else if ('two_floats' === question.type && null !== question.limit_up && null != formData[question.id]) {
      const values = formData[question.id]?.split('/');
      const upLimit = Number(question.limit_up);
      const downLimit = null != question.limit_down ? Number(question.limit_down) : Infinity;

      if (null == values || 3 !== values.length) {
        correctValueChange(true, question.id);
      } else if (Number(values[0]) > upLimit || Number(values[0] < downLimit)
      || Number(values[1]) > upLimit || Number(values[1] < downLimit)) {
        correctValueChange(false, question.id);
      } else {
        correctValueChange(true, question.id);
      }
    } else if ('date' === question.type && null != formData[question.id]) {
      correctValueChange(isValidDate(formData[question.id]), question.id);
    } else if ('multiple_choice' === question.type && null != formData[question.id]) {
      // correctValueChange(0 !== formData[question.id].length, question.id);
    } else if ('date_integer' === question.type) {
      const start = Number(question.id);
      const formDataEntr = Object.entries(formData);
      const numOfItems = Math.ceil((formDataEntr.length - start + 1) / 2);

      // At least one with error.
      let atLeastOne = false;

      for (let i = start; i < start + numOfItems * 2; i += 2) {
        if (null == formDataEntr[i - 1] || null === formDataEntr[i - 1][1]
              || null == formDataEntr[i] || '' === formDataEntr[i][1]) {
          atLeastOne = true;
          break;
        }
      }

      let hasError = false;

      if (!atLeastOne) {
        for (let i = start; i < start + numOfItems * 2; i += 2) {
          if (!isValidDate(formDataEntr[i - 1][1]) || '' === formDataEntr[i][1]) {
            hasError = true;
            break;
          }
        }
      }

      correctValueChange(!hasError, question.id);
    } else {
      correctValueChange(true, question.id);
    }
  }, [ formData ]);

  const isDateIntegerMissingAnswer = (queId) => {
    const formDataEntr = Object.entries(formData);
    const start = Number(queId);
    const numOfItems = Math.ceil((formDataEntr.length - start + 1) / 2);

    // At least one with error.
    let atLeastOne = false;

    for (let i = start; i < start + numOfItems * 2; i += 2) {
      if (null == formDataEntr[i - 1] || null === formDataEntr[i - 1][1]
              || null == formDataEntr[i] || '' === formDataEntr[i][1]) {
        atLeastOne = true;
        break;
      }
    }

    return atLeastOne;
  };

  return (
    <div>
      {
        description && (
          <Typography style={ { marginBottom: isKitWellness ? '0' : '15px' } }>
            {
              !isKitWellness && (
                <span style={ { marginRight: '12px' } }>
                  {
                    `${ visibleIndex + 1 }.`
                  }
                </span>
              )
            }
            {'US' === customerCountry
              ? description.replace('cm', 'feet').replace('kg', 'pounds').replace('εκ.', 'feet').replace('κιλά', 'pounds')
              : description}

            {
              subDescription && '' !== subDescription && (
                <Typography style={ { fontWeight: 'bold', marginLeft: '5px' } } display='inline'>
                  (
                  {subDescription}
                  )
                </Typography>
              )
            }

            {
              true === question.required && (
                <span style={ { color: isKitWellness ? '' : 'red', marginLeft: '3px' } }>*</span>
              )
            }

          </Typography>
        )
      }

      {
        'short_text' === question.type && (
          <Input
            color='secondary'
            placeholder={ t('reg_kit.questionnaire.common.your_answer') }
            value={ formData[question.id] }
            onChange={ (e) => {
              itemChange(e, question.id);
            } }
          />
        )
      }
      {
        'one_choice' === question.type && (
          <RadioGroup
            value={ formData[question.id] }
            onChange={ (e) => { itemChange(e, question.id); } }
          >
            {
              options.map((opt) => {
                return (
                  <FormControlLabel
                    key={ opt.opt }
                    value={ opt.opt }
                    control={ <Radio /> }
                    label={ opt.label }
                  />
                );
              })
            }
          </RadioGroup>
        )
      }
      {
        'date' === question.type && (
          <>
            <SimpleDateFieldValue
              value={ formData[question.id] }
              onChange={ (value) => {
                itemChange(value, question.id, false);
              } }
            />
          </>
        )
      }
      {
        'short_integer' === question.type && (
          <Input
            color='secondary'
            placeholder={ t('reg_kit.questionnaire.common.your_answer') }
            value={ formData[question.id] }
            onChange={ (e) => {
              if (intRegex.test(e.target.value)) {
                itemChange(e, question.id);
              }
            } }
          />
        )
      }
      {
        'short_float' === question.type && (
          <InputComponent
            margin={ isKitWellness ? 'dense' : 'none' }
            color='secondary'
            placeholder={ t('reg_kit.questionnaire.common.your_answer') }
            value={ formData[question.id] }
            onChange={ (e) => {
              if (floatRegex.test(e.target.value)) {
                itemChange(e, question.id);
              }
            } }
          />
        )
      }
      {
        'float_if_usa_else_int' === question.type && (
          <InputComponent
            margin={ isKitWellness ? 'dense' : 'none' }
            color='secondary'
            placeholder={ t('reg_kit.questionnaire.common.your_answer') }
            value={ formData[question.id] }
            onChange={ (e) => {
              if ('US' === customerCountry && floatRegex.test(e.target.value)) {
                itemChange(e, question.id);
              } else if (intRegex.test(e.target.value)) {
                itemChange(e, question.id);
              }
            } }
          />
        )
      }
      {
        'two_floats' === question.type && (
          <div style={ { display: 'flex', gap: '20px', alignItems: 'flex-end' } }>
            <TextField
              color='secondary'
              label={ t('common.value') }
              value={ formData[question.id]?.split('/')[0] ?? '' }
              onChange={ (e) => {
                if (floatRegex.test(e.target.value)) {
                  itemChangeTwo(e, question.id, 0);
                }
              } }
            />
            <TextField
              color='secondary'
              style={ { maxWidth: '100px', marginLeft: '50px' } }
              InputProps={ {
                startAdornment: <InputAdornment position='start'>{'<'}</InputAdornment>,
              } }
              value={ formData[question.id]?.split('/')[1] ?? '' }
              onChange={ (e) => {
                if (floatRegex.test(e.target.value)) {
                  itemChangeTwo(e, question.id, 1);
                }
              } }
            />
          </div>
        )
      }
      {
        'three_integers' === question.type && (
          <div style={ { display: 'flex', gap: '20px' } }>
            <TextField
              color='secondary'
              label={ t('common.value') }
              value={ formData[question.id]?.split('/')[0] ?? '' }
              onChange={ (e) => {
                if (floatRegex.test(e.target.value)) {
                  itemChangeThree(e, question.id, 0);
                }
              } }
            />
            <TextField
              color='secondary'
              style={ { maxWidth: '100px', marginLeft: '50px' } }
              label={ t('common.from') }
              value={ formData[question.id]?.split('/')[1] ?? '' }
              onChange={ (e) => {
                if (floatRegex.test(e.target.value)) {
                  itemChangeThree(e, question.id, 1);
                }
              } }
            />
            <TextField
              color='secondary'
              label={ t('common.to') }
              style={ { maxWidth: '100px' } }
              value={ formData[question.id]?.split('/')[2] ?? '' }
              onChange={ (e) => {
                if (floatRegex.test(e.target.value)) {
                  itemChangeThree(e, question.id, 2);
                }
              } }
            />
          </div>
        )
      }
      {
        'date_integer' === question.type && (
          <MultipleDateInteger
            question={ question }
            index={ index }
            formData={ formData }
            dateIntegerChange={ dateIntegerChange }
            setFormData={ setFormData }
          />
        )
      }
      {
        'multiple_choice' === question.type && (
          <div>
            <FormGroup>
              {
                options.map((opt) => {
                  return (
                    <React.Fragment key={ opt.opt }>
                      {
                        null !== question.is_text && question.is_text?.includes(opt.opt) ? (
                          <MultipleChoiceOther
                            qId={ opt.opt }
                            label={ opt.label }
                            question={ question }
                            multipleChangeOther={ multipleChangeOther }
                            disabled={ checkMultiDisabled(question.id, opt.opt) }
                            possibleValue={ formData[question.id] }
                            // opt={ opt.opt }
                            // key={ opt.opt }
                            // formData={ formData }
                            // question={ question }
                            // disabled={ checkMultiDisabled(question.id, opt.opt) }
                            // multipleChangeOther={ multipleChangeOther }
                          />
                        ) : (
                          <FormControlLabel
                            key={ opt.opt }
                            control={ (
                              <Checkbox
                                disabled={ checkMultiDisabled(question.id, opt.opt) }
                                checked={ null == formData[question.id] ? false : formData[question.id].includes(opt.opt) }
                                onChange={ (e) => {
                                  multipleChange(e, question.id, opt.opt);
                                } }
                              />
                            ) }
                            label={ opt.label }
                          />
                        )
                      }
                    </React.Fragment>
                  );
                })
              }
            </FormGroup>
          </div>
        )
      }

      <div style={ { color: 'red',
        visibility: (correctValues[question.id] && !(emptyAnswerId === Number(question.id))) ? 'hidden' : 'visible',
        marginTop: '13px',
        minHeight: '20px' } }
      >
        {
          !correctValues[question.id] && (
            <>
              {
                'two_floats' === question.type && (
                  <>
                    {t('reg_kit.questionnaire.common.values_between', { limit_down: question.limit_down, limit_up: question.limit_up })}
                  </>
                )
              }

              {
                'three_integers' === question.type && (
                  <>
                    {
                      null != formData[question.id] && 3 === formData[question.id].split('/').length && '' !== formData[question.id].split('/')[1] && '' !== formData[question.id].split('/')[2] && Number(formData[question.id].split('/')[1]) > Number(formData[question.id].split('/')[2]) ? (
                        <>
                          {t('reg_kit.questionnaire.common.from_gt_to')}
                        </>
                      ) : (
                        <>
                          {t('reg_kit.questionnaire.common.values_between', { limit_down: question.limit_down, limit_up: question.limit_up })}
                        </>
                      )
                    }
                  </>
                )
              }
              {
                'two_floats' !== question.type && 'three_integers' !== question.type && 'date' !== question.type && 'date_integer' !== question.type && 'multiple_choice' !== question.type ? (
                  <>
                    {t('reg_kit.questionnaire.common.value_between', { limit_down: question.limit_down, limit_up: question.limit_up })}
                  </>
                ) : ('date' === question.type || 'date_integer' === question.type) ? (
                  <>
                    {t('common.invalid_date')}
                  </>
                ) : (null)
              }
            </>
          )
        }
        {
          correctValues[question.id] && ('' === formData[question.id] || null == formData[question.id]
          || ('three_integers' === question.type
          && (3 !== formData[question.id].split('/').length
          || '' === formData[question.id].split('/')[0] || '' === formData[question.id].split('/')[1] || '' === formData[question.id].split('/')[2]))
          || ('two_floats' === question.type
          && (2 !== formData[question.id].split('/').length
          || '' === formData[question.id].split('/')[0] || '' === formData[question.id].split('/')[1]))
          || ('date_integer' === question.type && isDateIntegerMissingAnswer(question.id))
          || ('multiple_choice' === question.type && (null == formData[question.id] || 0 === formData[question.id].length))
          )
          && emptyAnswerId === Number(question.id)
            && (
              <>
                {t('reg_kit.questionnaire.common.please_answer_question')}
              </>
            )

        }
      </div>

      {
        '' !== info && (
          <Typography style={ { fontSize: '14.7px' } }>
            {info}
          </Typography>
        )
      }
    </div>
  );
};

const Questionnaire = ({ questions, formData,
  setFormData, categoryId, correctValues, setCorrectValues, emptyAnswerId,
  performScroll, wrongAnswerId, customerCountry, insideOtherPage, kitId }) => {
  const { t, i18n } = useTranslation();
  const { isDoctor } = useAuth();
  const { isKitWellness, child, isKitOnlyNutri } = useKit(kitId);

  const [ indexSubtracter, setIndexSubtracter ] = useState(-1);

  let firstText = '';
  let secondText = '';

  const [ formRefs, setFormRefs ] = useState({});

  useEffect(() => {
    if (0 !== performScroll) {
      let value = emptyAnswerId;

      // Wrong value
      if (-2 === emptyAnswerId) {
        value = wrongAnswerId;
      }

      if (0 < value) {
        formRefs?.[value]?.current?.scrollIntoView({ block: 'center' });
      }
    }
  }, [ performScroll ]);

  useEffect(() => {
    setFormRefs(
      questions.map((item) => {
        return (
          formRefs?.[item.id] || createRef()
        );
      }),
    );
  }, []);

  useEffect(() => {
    const hiddenQuestionIdx = questions?.findIndex((question) => {
      return false === shouldShowQuestion(question.referral);
    });

    if (-1 !== hiddenQuestionIdx && hiddenQuestionIdx !== undefined) {
      setIndexSubtracter(hiddenQuestionIdx + 1);
    } else {
      setIndexSubtracter(-1);
    }
  }, [ questions, formData ]);

  if ('NutriGenetix' === categoryId || 'NutriGenetix-Laboratories' === categoryId) {
    firstText = t('reg_kit.questionnaire.nutrigenetix.quest_title');
    secondText = 'reg_kit.questionnaire.common.quest_subtitle';
  } else if ('Cardio Health' === categoryId) {
    firstText = 'reg_kit.questionnaire.cardio.quest_title';
    secondText = 'reg_kit.questionnaire.common.quest_subtitle';
  } else if ('Biological Age' === categoryId) {
    firstText = 'reg_kit.questionnaire.bioage.quest_title';
    secondText = 'reg_kit.questionnaire.common.quest_subtitle';
  }

  const classes = useStyles();

  const itemChange = (e, index, target = true) => {
    const quest = questions[index - 1];

    const copy = formData;
    if (target) {
      copy[index] = e.target.value;
    } else {
      copy[index] = e;
    }

    // Clear answer if it is not displayed.
    if (null != quest.referrence) {
      const refOpt = 'el-GR' === i18n.language ? quest.referrence.options_el : quest.referrence.options_en;
      // TODO: Questionnaire language changed this.
      // const refOpt = quest.referrence.options;

      if (!refOpt.includes(copy[index])) {
        const refPos = Number(quest.referrence.id) - 1;

        if ('date' === questions[refPos].type) {
          copy[quest.referrence.id] = null;
        } else if ('date_integer' === questions[refPos].type) {
          copy[quest.referrence.id] = null;
          copy[`${ Number(quest.referrence.id) + 1 }`] = '';
        } else {
          copy[quest.referrence.id] = '';
        }
      }
    }

    setFormData({ ...copy });
  };

  const itemChangeThree = (e, index, pos) => {
    const copy = formData;

    let values = copy[index]?.split('/');

    if (null == values || 3 !== values.length) {
      values = [ '', '', '' ];
    }

    values[pos] = e.target.value;

    copy[index] = values.join('/');

    setFormData({ ...copy });
  };

  const itemChangeTwo = (e, index, pos) => {
    const copy = formData;

    let values = copy[index]?.split('/');

    if (null == values || 0 === values.length || 1 === values.length) {
      values = [ '', '', '' ];
    }

    values[pos] = e.target.value;

    copy[index] = values.join('/');

    setFormData({ ...copy });
  };

  const dateIntegerChange = (pos, e, index, target = true) => {
    const copy = formData;
    if (target) {
      copy[index] = e.target.value;
    } else {
      copy[index] = e;
    }

    setFormData({ ...copy });
  };

  const multipleChange = (e, index, value) => {
    const copy = formData;

    if (false === e.target.checked) {
      const tIndex = copy[index].indexOf(value);
      if (-1 !== index) {
        copy[index].splice(tIndex, 1);
      }
    } else if (questions[Number(index) - 1].disable_when?.includes(value)) {
      copy[index] = [ value ];
    } else {
      copy[index].push(value);
    }

    setFormData({ ...copy });
  };

  const multipleChangeOther = (index, value, question, qId) => {
    // const options = 'el-GR' === i18n.language ? question.options_el : question.options_en;
    // TODO: Questionnaire language changed this.
    // const { options } = question;

    // setFormData((prev) => {
    //   const copy = _.cloneDeep(prev);

    //   let prevValue;

    //   // Check if there is another 'Other' value.
    //   for (let i = 0; i < copy[index].length; i++) {
    //     if (!options.includes(copy[index][i])) {
    //       prevValue = copy[index][i];
    //       break;
    //     }
    //   }

    //   // Remove previous other value.
    //   if (null != prevValue) {
    //     const tIndex = copy[index].indexOf(prevValue);
    //     if (-1 !== index) {
    //       copy[index].splice(tIndex, 1);
    //     }
    //     // Add updated value
    //     if ('' !== value && !options.includes(value)) {
    //       copy[index].push(value);
    //     }
    //   } else if ('' !== value && !options.includes(value)) {
    //   // There was no other value
    //     copy[index].push(value);
    //   }

    //   return copy;
    // });

    setFormData((val) => {
      const copy = _.cloneDeep(val);

      let pos = 0;

      const foundPossibleValue = copy[index].find((ss) => {
        const isFound = 'string' !== typeof ss && !(ss instanceof String) && Object.keys(ss).includes(qId);
        if (!isFound) {
          pos += 1;
        }

        return isFound;
      });

      if (null == foundPossibleValue) {
        copy[index].push({ [qId]: value });
      } else if ('' === value) {
        copy[index].splice(pos, 1);
      } else {
        foundPossibleValue[qId] = value;
      }

      return copy;
    });
  };

  const shouldShowQuestion = (referral) => {
    if (referral === undefined) {
      return true;
    }

    const refQuestion = questions.find((q) => {
      return q.id === referral;
    });

    if (refQuestion === undefined) {
      return true;
    }

    // TODO: Questionnaire language changed this.
    return refQuestion.referrence.options.includes(formData[referral]);
  };

  const checkMultiDisabled = useCallback((id, value) => {
    if (null == formData[id] || 0 === formData[id].length) {
      return false;
    }

    if (questions[Number(id) - 1].rules !== undefined) {
      const foundRule = questions[Number(id) - 1].rules.find((rl) => {
        return rl.target === value;
      });

      if (foundRule && 'disable' === foundRule.type
      && foundRule.when.some((r) => { return formData[id].includes(r); })) {
        return true;
      }
    }

    if (0 < questions[Number(id) - 1].max_choices
    && formData[id].length >= questions[Number(id) - 1].max_choices
    && !formData[id].includes(value)) {
      return true;
    }

    if (null == questions[Number(id) - 1].disable_when) {
      return false;
    }

    if (questions[Number(id) - 1].disable_when.includes(value)) {
      return false;
    }

    if (questions[Number(id) - 1].disable_when.some((r) => { return formData[id].includes(r); })) {
      return true;
    }

    return false;
  }, [ questions, formData ]);

  return (
    <Grid container style={ { gap: '12px' } }>
      {
        insideOtherPage === undefined && (
          <Grid item>
            <div
              className={ classes.questionHeaderColor }
              style={ { background: isDoctor ? '#648DAE' : '' } }
            />
            <div
              className={ isKitWellness ? classes.questionInWellness : classes.question }
              style={ { paddingBottom: '24px' } }
            >
              <Typography variant='h4' style={ { marginBottom: '15px' } }>
                {t(firstText)}
              </Typography>

              <Typography style={ { marginBottom: '15px' } }>
                {t(secondText)}
              </Typography>

              <Alert
                severity='warning'
                className={ classes.alert }
              >
                <AlertTitle
                  style={ { fontWeight: 'bold' } }
                >
                  {
                    isDoctor
                      ? t('reg_kit.questionnaire.doctor.first_warning')
                      : null == child ? t('reg_kit.questionnaire.adult.first_warning') : t('reg_kit.questionnaire.child.first_warning')
                  }
                </AlertTitle>
              </Alert>

              {
                isKitOnlyNutri && (
                  <Alert
                    severity='warning'
                    className={ classes.alert }
                  >
                    <AlertTitle
                      style={ { fontWeight: 'bold' } }
                    >
                      {
                        isDoctor
                          ? t('reg_kit.questionnaire.nutrigenetix.doctor.middle_warning')
                          : null == child
                            ? t('reg_kit.questionnaire.nutrigenetix.adult.middle_warning')
                            : t('reg_kit.questionnaire.nutrigenetix.child.middle_warning')
                      }
                    </AlertTitle>
                  </Alert>
                )
              }

              <Alert
                severity='warning'
                className={ classes.alert }
              >
                <AlertTitle style={ { fontWeight: 'bold' } }>
                  {
                    isDoctor
                      ? t('reg_kit.questionnaire.doctor.second_warning')
                      : null == child
                        ? t('reg_kit.questionnaire.adult.second_warning')
                        : t('reg_kit.questionnaire.child.second_warning')
                  }
                </AlertTitle>
              </Alert>
            </div>
          </Grid>
        )
      }
      {
        questions
          .map((question, index) => {
            return (
              <div
                key={ question.id }
                style={ { width: '100%' } }
              >
                {
                  '9' === question.id && 'three_integers' === question.type && (
                    <div style={ { margin: '20px 0 10px 0' } }>
                      <Typography variant='h5'>
                        {t('reg_kit.questionnaire.bioage.blood_tests')}
                      </Typography>
                      <div style={ { display: 'flex', marginLeft: '18px', marginTop: '15px' } }>
                        <Typography style={ { maxWidth: '200px' } }>
                          {t('reg_kit.questionnaire.bioage.decimals_with_period')}
                        </Typography>
                        <Typography style={ { marginLeft: '60px' } }>
                          {t('reg_kit.questionnaire.bioage.normal_range')}
                        </Typography>
                      </div>
                    </div>
                  )
                }
                <Grid
                  item
                  xs={ 12 }
                  sm={ isKitWellness ? 6 : 12 }
                  className={ isKitWellness ? classes.questionInWellness : classes.question }
                  style={ { display: shouldShowQuestion(question.referral) ? '' : 'none' } }
                  ref={ formRefs[question.id] }
                >
                  <Question
                    kitId={ kitId }
                    emptyAnswerId={ emptyAnswerId }
                    question={ question }
                    index={ index }
                    visibleIndex={ -1 !== indexSubtracter && indexSubtracter <= index ? index - 1 : index }
                    formData={ formData }
                    itemChange={ itemChange }
                    correctValues={ correctValues }
                    setCorrectValues={ setCorrectValues }
                    multipleChange={ multipleChange }
                    multipleChangeOther={ multipleChangeOther }
                    itemChangeThree={ itemChangeThree }
                    itemChangeTwo={ itemChangeTwo }
                    dateIntegerChange={ dateIntegerChange }
                    setFormData={ setFormData }
                    customerCountry={ customerCountry }
                    checkMultiDisabled={ checkMultiDisabled }
                  />
                </Grid>
              </div>
            );
          })
      }
      {
        1 > 2 && (
          <div
            aria-hidden
            onClick={ () => {
              console.log('formData', formData,
                'correctValues', correctValues, 'emptyAnswerId', emptyAnswerId);
            } }
          >
            Click me
          </div>
        )
      }
    </Grid>
  );
};

export default Questionnaire;
