import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import EmailIcon from '@material-ui/icons/Email';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { IconButton } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import TextField from '@material-ui/core/TextField';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useFormRules, useInputValidation } from '../../hooks/form-rules';
import { useBlockingLoader } from '../../hooks/loader-hooks';
import { useAlerts } from '../../hooks/alerts-hooks';
import SignInVerificationForm from './SignInVerificationForm';
import { useAuth } from '../../AuthCtx';
import { useFormStylesIn } from './form-styles-signin';

export default function SignInForm({ color, selectedUserType }) {
  const { t } = useTranslation();
  const formClasses = useFormStylesIn();

  const { signIn, userIsUnconfirmed, userIsSemiAuthenticated } = useAuth();

  const history = useHistory();
  useEffect(() => {
    if (userIsUnconfirmed) {
      history.push('/account-verification');
    }
  }, [ userIsUnconfirmed ]);

  const { showBlockingLoader, hideBlockingLoader } = useBlockingLoader('sign-in-form');
  const { alertError } = useAlerts();

  const { emailRule, passwordRules } = useFormRules();

  const [ emailError, emailHelperText, validateEmail ] = useInputValidation(
    [ emailRule ],
  );

  const [ passwordError, passwordHelperText, validatePassword ] = useInputValidation(
    passwordRules,
  );

  const handleSignIn = (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      email,
      password,
    } = Object.fromEntries(formData);

    const formIsValid = ![
      validateEmail(email),
      validatePassword(password),
    ].some((item) => {
      return !item;
    });

    if (!formIsValid) {
      return;
    }

    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return signIn({ username: email, password });
      })
      .catch((err) => {
        alertError(err);
      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  const [ passwordVisibility, setPasswordVisibility ] = useState(false);
  const togglePasswordVisibility = () => {
    return setPasswordVisibility((prev) => {
      return !prev;
    });
  };

  if (userIsSemiAuthenticated) {
    return <SignInVerificationForm />;
  }

  return (
    <Container className={ formClasses.container } style={ { paddingBottom: '50px' } }>
      <div className={ formClasses.paper }>
        <div style={ { display: 'flex', justifyContent: 'center', marginTop: '20px' } }>
          <Typography>
            {t('Σύνδεση στο λογαριασμό σου.')}
          </Typography>
        </div>
        <form
          noValidate
          className={ formClasses.form }
          onSubmit={ (e) => {
            return handleSignIn(e);
          } }
        >
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' } }>
                <div style={ { background: '#F8F8F8',
                  padding: '10px',
                  borderRadius: '10px 0px 0px 10px',
                  width: 'fit-content',
                  height: 'fit-content' } }
                >
                  <EmailIcon style={ { color: 'action.active' } } />
                </div>
                <Tooltip
                  title={ selectedUserType === undefined
                    ? <Typography>{t('Επέλεξε αν είσαι Ιδιώτης ή Ιατρός/Επαγγελματίας υγείας')}</Typography> : '' }
                >
                  <TextField
                    size='small'
                    variant='standard'
                    required
                    InputProps={ { disableUnderline: true } }
                    fullWidth
                    disabled={ selectedUserType === undefined }
                    label='Email'
                    InputLabelProps={ { style: { color: emailError || selectedUserType === undefined ? '' : color } } }
                    name='email'
                    // color='secondary'
                    type='email'
                    autoComplete='email'
                    error={ emailError }
                    helperText={ t(emailHelperText) }
                    onChange={ (e) => {
                      return validateEmail(e.target.value);
                    } }
                    onBlur={ (e) => {
                      return validateEmail(e.target.value);
                    } }
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid item xs={ 12 }>
              <div style={ { display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: '16px' } }>
                <div style={ { background: '#F8F8F8',
                  padding: '10px',
                  borderRadius: '10px 0px 0px 10px',
                  width: 'fit-content',
                  height: 'fit-content' } }
                >
                  <VpnKeyIcon style={ { color: 'action.active' } } />
                </div>
                <Tooltip
                  title={ selectedUserType === undefined
                    ? <Typography>{t('Επέλεξε αν είσαι Ιδιώτης ή Ιατρός/Επαγγελματίας υγείας')}</Typography> : '' }
                >
                  <TextField
                    size='small'
                    error={ passwordError }
                    helperText={ t(passwordHelperText) }
                    disabled={ selectedUserType === undefined }
                    onChange={ (e) => {
                      return validatePassword(e.target.value);
                    } }
                    onBlur={ (e) => {
                      return validatePassword(e.target.value);
                    } }
                    variant='standard'
                    required
                    fullWidth
                    InputLabelProps={ { style: { color: passwordError || selectedUserType === undefined ? '' : color } } }
                    name='password'
                    label='Password'
                    type={ passwordVisibility ? 'text' : 'password' }
                    autoComplete='current-password'
                    InputProps={ {
                      disableUnderline: true,
                      endAdornment: (
                        <InputAdornment position='start'>
                          <IconButton
                            onClick={ togglePasswordVisibility }
                            disabled={ selectedUserType === undefined }
                          >
                            {!passwordVisibility ? <Visibility /> : <VisibilityOff />}
                          </IconButton>
                        </InputAdornment>
                      ),
                    } }
                  />
                </Tooltip>
              </div>
            </Grid>
            <Grid container justifyContent='space-between'>
              <Grid item xs={ 12 }>
                <Tooltip
                  title={ selectedUserType === undefined
                    ? <Typography>{t('Επέλεξε αν είσαι Ιδιώτης ή Ιατρός/Επαγγελματίας υγείας')}</Typography> : '' }
                >
                  <div>
                    <Button
                      fullWidth
                      type='submit'
                      variant='contained'
                      color='secondary'
                      className={ formClasses.submit }
                      disabled={ selectedUserType === undefined }
                      style={ { background: selectedUserType === undefined ? '' : color, color: selectedUserType === undefined ? '' : 'white' } }
                    >
                      {t('signIn')}
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
              <Grid item xs={ 12 }>
                <Grid item xs={ 12 }>
                  <div style={ { width: '100%', height: '13px', borderBottom: '1px solid #9C9C9C', textAlign: 'center' } }>
                    <span style={ { padding: '0 10px', marginTop: '0px', background: 'white', color: '#9C9C9C' } }>
                      {t('ή')}
                    </span>
                  </div>
                </Grid>

                <Tooltip
                  title={ selectedUserType === undefined
                    ? <Typography>{t('Επέλεξε αν είσαι Ιδιώτης ή Ιατρός/Επαγγελματίας υγείας')}</Typography> : '' }
                >
                  <div>
                    <Button
                      fullWidth
                      variant='outlined'
                      disabled={ selectedUserType === undefined }
                      style={ { background: 'white' } }
                      onClick={ (e) => {
                        e.preventDefault();
                        history.push('/register');
                      } }
                      className={ formClasses.submit }
                    >
                      {t('signUp')}
                    </Button>
                  </div>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
        </form>
      </div>
      <Grid
        className={ formClasses.links }
        container
        direction='column'
        item
        xs={ 12 }
        alignItems='center'
        justifyContent='center'
      >
        <Grid item style={ { display: 'flex', justifyContent: 'center', marginTop: '10px' } }>
          <Typography component='div' variant='body2'>
            <Tooltip
              title={ selectedUserType === undefined
                ? <Typography>{t('Επέλεξε αν είσαι Ιδιώτης ή Ιατρός/Επαγγελματίας υγείας')}</Typography> : '' }
            >
              <div>
                <a
                  style={ { fontSize: '15px',
                    color: selectedUserType === undefined ? '#BDBDBD' : color,
                    textDecoration: 'none',
                    fontWeight: 600,
                    pointerEvents: selectedUserType === undefined ? 'none' : 'auto' } }
                  href='/password-recovery'
                  onClick={ (ev) => {
                    ev.preventDefault();
                    history.push('/password-recovery');
                  } }
                >
                  {t('forgotPassword')}
                </a>
              </div>
            </Tooltip>
          </Typography>
        </Grid>
      </Grid>
    </Container>
  );
}
