import React, { useState } from 'react';

export const AppCtx = React.createContext({});

export function AppProvider(props) {
  const [ themeMode, setThemeMode ] = useState('light');
  const [ showNewRegiKitModal, setShowNewRegiKitModal ] = useState(false);
  const [ showedUseAppNotification, setShowedUseAppNotification ] = useState(false);
  const [ justBoughtAKit, setJustBoughtAKit ] = useState(false);
  const [ showPGxPopup, setShowPGxPopup ] = useState(false);
  const [ showUpdateAvailable, setShowUpdateAvailable ] = useState(false);

  const ctx = {
    themeMode,
    setThemeMode,
    showNewRegiKitModal,
    setShowNewRegiKitModal,
    showedUseAppNotification,
    setShowedUseAppNotification,
    justBoughtAKit,
    setJustBoughtAKit,
    showPGxPopup,
    setShowPGxPopup,
    showUpdateAvailable,
    setShowUpdateAvailable,
  };

  return (
    <AppCtx.Provider value={ ctx }>
      {props.children}
    </AppCtx.Provider>
  );
}
