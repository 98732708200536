import { useContext, useEffect, useRef } from 'react';
import { LoaderCtx } from '../LoaderCtx';
import { usePrevious } from './util-hooks';

export function useBlockingLoader(k, follow) {
  const key = useRef(k);
  const { showBlockingLoader, hideBlockingLoader } = useContext(LoaderCtx);

  const prevFollow = usePrevious(follow);

  useEffect(() => {
    if (prevFollow === follow) {
      return;
    }
    if (follow) {
      showBlockingLoader(key.current);
    } else {
      hideBlockingLoader(key.current);
    }
  }, [ follow ]);

  const showBlockingLoaderAux = () => {
    showBlockingLoader(key.current);
  };

  const hideBlockingLoaderAux = () => {
    hideBlockingLoader(key.current);
  };

  useEffect(() => {
    // if the component using this hook unmounts
    // destroy the loader
    return () => {
      return hideBlockingLoader(key.current);
    };
  }, []);

  return {
    showBlockingLoader: showBlockingLoaderAux,
    hideBlockingLoader: hideBlockingLoaderAux,
  };
}

