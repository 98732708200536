import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useClasses = makeStyles((theme) => {
  return {
    iconContainer: {
      borderRadius: 8,
      padding: theme.spacing(1),
      '&:hover $icon': {
        color: theme.palette.error.main,
      },
    },
    icon: {
    },
  };
});

const AppIconDelete = (props) => {
  const classes = useClasses();
  if (props.disabled) {
    return (
      <IconButton className={ classes.iconContainer } { ...props } aria-label='delete'>
        <DeleteIcon className={ classes.icon } />
      </IconButton>
    );
  }

  return (
    <Tooltip title='Delete' aria-label='delete'>
      <IconButton className={ classes.iconContainer } { ...props } aria-label='delete'>
        <DeleteIcon className={ classes.icon } />
      </IconButton>
    </Tooltip>
  );
};

export default AppIconDelete;
