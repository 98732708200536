import { useOptionSerial } from 'NavigationProvider';
import React from 'react';
import QuestionnaireAnswers from 'components/QuestionnaireAnswers';
import { blueColor } from './NutriGenetix';

const NutriGenetixQuestionnaireAnswers = () => {
  const { serial } = useOptionSerial('nutriGenetix');

  return (
    <QuestionnaireAnswers serial={ serial } color={ blueColor } />
  );
};

export default NutriGenetixQuestionnaireAnswers;
