/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/media-has-caption */
import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { makeStyles } from '@material-ui/core/styles';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import PersonIcon from '@material-ui/icons/Person';
import useMediaQueries from 'hooks/media-query-hooks';
import { Typography, Grid, Button, Icon, Card, CardHeader, CardContent, IconButton, Avatar, useMediaQuery } from '@material-ui/core';
import { useLocation } from 'react-router';
import { useAuth, UserGroups } from '../AuthCtx';
import SignInForm from '../components/forms/SignInForm';

const useStyles = makeStyles((theme) => {
  return {
    container: {
      display: 'flex',
      justifyContent: 'space-between',
      height: 'calc(100vh - 80px)',

      background: 'radial-gradient(35.06% 50.29% at 3.84% 46.19%, rgba(241, 243, 243, 0) 0%, rgba(255, 255, 255, 0) 100%), url("landing_page_background3.png")',
      // backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right top',
      backgroundSize: '50vw 100vh',

      [theme.breakpoints.down('lg')]: {
        backgroundSize: '60vw 100vh',
      },

      [theme.breakpoints.down('sm')]: {
        backgroundSize: '100vw 100vh',
      },

      [theme.breakpoints.down('sm')]: {
        backgroundSize: 'cover',

        backgroundPosition: 'right bottom',

        alignItems: 'center',
        flexDirection: 'column',
      },
    },
    gradientOverImage: {
      background: 'linear-gradient(90deg, rgba(241, 243, 243, 1) 4.82%, rgba(255, 255, 255, 0.1) 24.7%)',
      position: 'absolute',
      overflow: 'hidden',
      // background: 'radial-gradient(35.06% 50.29% at 3.84% 46.19%, rgba(241, 243, 243, 0) 0%, rgba(255, 255, 255, 0) 100%)',
      top: 0,
      left: '43vw',
      height: '100%',
      width: '100%',
      maxWidth: 'calc(100% - 43vw)',

      '@media (max-width:1917px)': {
        left: '37vw',
        maxWidth: 'calc(100% - 37vw)',
      },

      '@media (max-width:1471px)': {
        left: '37vw',
        maxWidth: 'calc(100% - 37vw)',
      },

      '@media (max-width:1280px)': {
        left: '33vw',
        maxWidth: 'calc(100% - 33vw)',
      },

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    image: {
      height: '91.2vh',
      marginLeft: 'auto',
    },
    video: {
      objectFit: 'cover',
      width: '100vw',
      height: 'auto',
      maxHeight: '1860px',
      position: 'absolute',
      maxWidth: '100%',
      top: '-250px',
      zIndex: 2,
      left: '0',
    },
    bigCircle: {
      height: '195px',
      width: '195px',
      borderRadius: '50%',
      position: 'absolute',
      display: 'inline-block',
      '&:hover div': {
        opacity: 1,
      },
    },
    serviceBox: {
      width: 'min(341px, 90vw)',
      height: '180px',
      background: 'rgba(255, 255, 255, 0.51)',
      backdropFilter: 'blur(20px)',
      borderRadius: '15px',
      border: '1px solid #39A6D3',
      padding: '30px',
    },
    serviceBoxInner: {
      fontWeight: 400,
      fontSize: '20px',
      lineHeight: '142.19%',
      background: 'linear-gradient(180deg, #39A6D3 0%, #53B7A7 100%)',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
      // -webkit-background-clip: text;
      // -webkit-text-fill-color: transparent;
      backgroundClip: 'text',
      textFillColor: 'transparent',
    },
    serviceBoxInnerBottom: {
      marginTop: '26px',
      fontWeight: '350',
      fontSize: '16px',
      lineHeight: '136%',
    },
    topServiceBox: {
      top: '0px',
      transform: 'translateY(-20%)',
      position: 'absolute',
      left: 0,
      right: '0',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    bottomServiceBox: {
      bottom: '0px',
      position: 'absolute',
      left: 0,
      right: '0px',
      transform: 'translateY(20%)',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    leftServiceBox: {
      bottom: 0,
      top: '0px',
      position: 'absolute',
      transform: 'translateX(-50%)',
      left: '0px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    rightServiceBox: {
      bottom: 0,
      top: '0px',
      position: 'absolute',
      transform: 'translateX(50%)',
      right: '0px',
      marginTop: 'auto',
      marginBottom: 'auto',
    },
    changingColorBox: {
      width: '100%',
      height: '100%',
      border: '1px solid #3DA8CD',
      borderLeft: '1px solid transparent',
      borderTop: '1px solid transparent',
      backdropFilter: 'blur(20px)',
      color: '#39A6D3',

      [theme.breakpoints.down('xs')]: {
        backdropFilter: 'none',
      },

      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',

      transition: 'color .3s',
      transitionTimingFunction: 'linear',

      background: 'transparent',

      '&:hover': {
        background: 'linear-gradient(180deg, #39A6D3 0%, #53B7A7 100%)',
        color: 'white',
      },
    },
    boxWithImage: {
      background: 'white',
      borderRadius: '20px',
      padding: '18px 22px 3px 3px',
      minHeight: '472px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',

      transition: '0.5s',

      '&:hover': {
        background: '#F1F3F3',
      },

      '&:hover img': {
        transform: 'scale(1.1) !important',
      },

      '@media (max-width: 1355px)': {
        minHeight: '442px',
      },

      '@media (max-width: 875px)': {
        minHeight: '472px',
      },

      '@media (max-width: 400px)': {
        minHeight: '250px',
      },
    },
    boxWithImageImage: {
      marginBottom: '20px',
      marginLeft: '20px',

      // position: 'absolute',
      // position: 'relative',
      marginTop: 'auto',
      marginRight: 'auto',
      // width: 'auto',
      width: '70%',
      height: 'auto',

      transition: 'all .5s ease-in-out',
      transformOrigin: 'bottom left',
      transform: 'scale(1)',

      '@media (max-width:1250px)': {
        width: '80%',
      },

      '@media (max-width:875px)': {
        width: '70%',
      },
    },
    changingColorBoxContainer: {
      width: 'min(795px, 90vw)',
      minHeight: '570px',
      borderTop: '1px solid #3DA8CD',
      borderLeft: '1px solid #3DA8CD',
    },
    textInsideImage: {
      position: 'absolute',
      width: '300px',
      height: '50px',
      top: 0,
      bottom: 0,
      right: 0,
      left: 0,
      marginTop: 'auto',
      marginBottom: 'auto',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '300',
      fontSize: '20px',
      textAlign: 'center',
      lineHeight: '142.19%',

      opacity: 0,
      transition: 'opacity 0.5s',
    },
    learnMoreButton: {
      position: 'absolute',
      bottom: '15px',
      borderRadius: '43px',
      left: 0,
      width: '90vw',
      background: 'rgba(255, 255,255, 0.71)',
      marginLeft: 'auto',
      marginRight: 'auto',
      right: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      fontSize: '15px',
      lineHeight: '18px',
      color: '#484848',
      backdropFilter: 'blur(20px)',
      zIndex: 30,

      '@media (max-height:700px)': {
        display: 'none',
      },

      '&:hover': {
        background: 'rgba(198, 199, 202, 0.22)',
      },
    },
    fullOpacity: {
      opacity: 1,
    },
    emrText: {
      position: 'absolute',
      top: '33vh',
      marginLeft: '150px',

      '@media (max-width:1355px)': {
        marginLeft: '50px',
      },

      '@media (max-width:1240px)': {
        marginLeft: '15px',
      },
    },
    personalFileBox: {
      boxShadow: '0px 7px 18px -7px rgba(84, 84, 84, 0.52)',
      borderRadius: '60px',
      background: 'white',
      padding: '10px',
      display: 'flex',
      alignItems: 'center',
      gap: '10px',
      marginLeft: '80px',
      width: '477px',

      '@media (max-width:1355px)': {
        marginLeft: '0',
      },
    },
    personalFile: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 300,
      fontSize: '20px',
      lineHeight: '113.19%',
      color: '#484848',
      textShadow: '0px 7px 18px rgba(84, 84, 84, 0.52)',
      width: '650px',
    },
    myEMR: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '75.3833px',
      lineHeight: '88px',

      width: '260px',

      background: 'linear-gradient(90.7deg, #526A9D 0%, #31B5E1 23.93%, #8AB845 48.9%, #F5C91E 77%, #ED1F24 99.89%)',
      backgroundClip: 'text',
      textFillColor: 'transparent',
      WebkitBackgroundClip: 'text',
      WebkitTextFillColor: 'transparent',
    },
    innerForm: {
      zIndex: 12,
      background: 'white',
      position: 'relative',
      borderRadius: '16px',
      margin: '0px',
      display: 'flex',
      justifyContent: 'center',
      alignContent: 'space-around',
      padding: '0px',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
    },
    signIn: {
      width: '480px',
      height: '550px',
    },
    signInUp: {
      marginTop: '15vh',
      marginLeft: '11vw',
      '@media (max-width:600px) or (max-height:800px)': {
        marginTop: '3vh',
      },
      '@media (max-width:960px)': {
        marginLeft: '0vw',
      },
      maxWidth: 'min(480px, 100vw)',
    },
    userTypes: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      color: '#666666',
      position: 'relative',
      bottom: '-13px',
      zIndex: '0',
      width: 'min(480px, 100vw)',
      justifyContent: 'space-between',
      height: '80px',
    },
    userType: {
      alignItems: 'center',
      width: '100%',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      paddingTop: '5px',
      paddingBottom: '10px',
      gap: '5px',
      borderRadius: '16px 16px 0px 0px',
      height: '100%',
      minHeight: '67px',
      fontSize: '12px',
      lineHeight: '113%',
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '400',
      backgroundColor: 'white',
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        minHeight: '80px',
      },

      '&:hover': {
        cursor: 'pointer',
      },
    },

    nonSelectedDoctor: {
      color: '#818791',
    },

    nonSelectedCivilian: {
      color: '#818791',
    },

    userTypeSingle: {
      backgroundColor: '#93C221',
      color: 'white',
    },
    userTypeDoctor: {
      backgroundColor: '#536B9D',
      color: 'white',
    },
    userTypeBussiness: {
      backgroundColor: '#484848',
      color: 'white',
    },
    benefitsContainer: {
      maxWidth: '30vw',

      [theme.breakpoints.down('md')]: {
        maxWidth: '60vw',
      },

      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    benefitsText: {
      fontWeight: '300',
      fontFamily: 'Roboto',
      marginTop: '9px',
      textAlign: 'center',
    },
    wrapWhenSmall: {
      flexWrap: 'nowrap',
      [theme.breakpoints.down('sm')]: {
        flexWrap: 'wrap',
        marginLeft: '20px',
        gap: '8px !important',
      },
    },
    bottomPartContainer: {
      // marginLeft: '150px',
      // [theme.breakpoints.down('sm')]: {
      //   marginLeft: '0',
      // },

      display: 'flex',
      justifyContent: 'space-around',
      gap: '50px',
      flexWrap: 'wrap',

      '@media (max-width:615px)': {
        justifyContent: 'flex-start',
      },
    },
    similarProductsContainer: {
      width: '90vw',
      background: '#F1F3F3',
      position: 'relative',
      zIndex: '300',
      padding: '52px 0',
      borderRadius: '15px',
      margin: 'auto',

      '@media (max-width:615px)': {
        paddingLeft: '',
        paddingRight: '',
      },
    },
    availableProductsContainer: {
      display: 'grid',
      gridTemplateColumns: `repeat(auto-fit, minmax(${ 270 }px, 1fr))`,
      gridAutoRows: 'min-content',
      gap: '24px',

      width: '90%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    serviceBoxContainerMobile: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    straightLine: {
      display: 'block',
      height: '1px',
      width: '1px',
      border: 0,
      borderTop: '25px solid #39A6D3',
      margin: 0,
      padding: 0,
    },
    profileCard: {
      background: '#FFFFFF',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '16px',
      maxWidth: '550px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '31px',
      height: '550px',

      [theme.breakpoints.down('sm')]: {
        height: '450px',
      },

      '&:hover': {
        border: '1px solid',
      },

      cursor: 'pointer',

    },
    profileCardPatient: {
      '&:hover button': {
        background: '#93C221',
      },
    },
    profileCardDoctor: {
      '&:hover button': {
        background: '#526A9D',
      },
    },

    profileButtonPatient: {
      background: 'linear-gradient(0deg, rgba(147, 194, 33, 0.7), rgba(147, 194, 33, 0.7)), #FFFFFF',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '31px',
      color: 'white',
      marginTop: 'auto',
      width: '157px',
      height: '49px',
      border: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      cursor: 'pointer',
    },
    profileButtonDoctor: {
      background: 'linear-gradient(0deg, rgba(82, 106, 157, 0.7), rgba(82, 106, 157, 0.7)), #FFFFFF',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '31px',
      color: 'white',
      marginTop: 'auto',
      width: '157px',
      height: '49px',
      border: 0,
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 500,
      fontSize: '16px',
      lineHeight: '19px',
      cursor: 'pointer',
    },
    selectProfileText: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: 600,
      fontSize: '31px',
      lineHeight: '131%',
      color: '#484848',
      marginTop: '50px',
      marginBottom: '60px',

      '@media (max-height:800px)': {
        marginBottom: '10px',
        marginTop: '10px',
      },

      [theme.breakpoints.down('sm')]: {
        marginBottom: '00px',
        marginTop: '20px',
      },

    },
    selectProfileContainer: { display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      background: 'white',
      height: `calc(100vh - ${ theme.layout.topBarHeight }px)`,
      width: '100%',

      [theme.breakpoints.up('md')]: {
        // overflow: 'hidden',
      } },
  };
});

const SelectProfileType = ({ setSelectedUserType }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const { isMdDown } = useMediaQueries();

  return (
    <div className={ classes.selectProfileContainer }>
      <Typography
        className={ classes.selectProfileText }
      >
        {t('Επίλεξε προφίλ')}
      </Typography>

      <Grid container spacing={ 1 } style={ { position: 'relative', zIndex: 1, bottom: '0px' } }>
        <Grid item xs={ 12 } md={ 6 }>
          <div
            className={ clsx(classes.profileCard, classes.profileCardPatient) }
            style={ { marginRight: isMdDown ? 'auto' : '0',
              marginLeft: 'auto',
              borderColor: '#93C221' } }
            tabIndex={ 0 }
            role='button'
            onClick={ () => {
              setSelectedUserType('civilian');
              window.scrollTo({ top: 0 });
            } }
          >
            <img
              src={ isMdDown ? '/myemr-individual-white-background-m.png' : '/myemr-individual-white-background-l.png' }
              alt='myemr-individual-white-background-l.png'
              style={ { maxWidth: 'calc(100vw - 8px)' } }
            />

            <Typography
              style={ { fontWeight: 600,
                fontSize: '25px',
                marginTop: '20px',
                color: '#93C221' } }
              variant='h6'
            >
              {t('Είσαι Ιδιώτης;')}
            </Typography>

            <button
              className={ classes.profileButtonPatient }
              type='button'
            >
              {t('Επόμενο Βήμα')}
            </button>
          </div>
        </Grid>
        <Grid item xs={ 12 } md={ 6 }>
          <div
            className={ clsx(classes.profileCard, classes.profileCardDoctor) }
            tabIndex={ -1 }
            role='button'
            onClick={ () => {
              setSelectedUserType('doctor');
              window.scrollTo({ top: 0 });
            } }
            style={ { marginLeft: isMdDown ? 'auto' : '0',
              borderColor: '#526A9D',
              marginRight: 'auto' } }
          >
            <img
              src={ isMdDown ? '/myemr-doctors-white-background-m.png' : '/myemr-doctors-white-background-l.png' }
              alt='myemr-doctors-white-background-l.png'
              style={ { maxWidth: 'calc(100vw - 8px)' } }
            />

            <Typography
              style={ { marginTop: '20px',
                color: '#526A9D',
                fontWeight: 600,
                fontSize: '25px',
                textAlign: 'center' } }
              variant='h6'
            >
              {t('Είσαι Ιατρός')}
              {' '}
              {t('ή')}
            </Typography>
            <Typography
              style={ { fontSize: '25px',
                color: '#526A9D',
                textAlign: 'center',
                fontWeight: 600 } }
              variant='h6'
            >
              {t('Επαγγελματίας υγείας;')}
            </Typography>

            <button
              type='button'
              className={ classes.profileButtonDoctor }
            >
              {t('Επόμενο Βήμα')}
            </button>
          </div>
        </Grid>
      </Grid>

      <img
        src='/myemr-logo-Intersect-vector-l.svg'
        alt='myemr-logo-Intersect-vector-l.svg'
        style={ {
          marginTop: 'auto',
          position: 'fixed',
          bottom: '0',
          width: '100%',
          maxWidth: '1467px',
          height: 'auto',
        } }
      />
    </div>
  );
};

const ChangingColorBox = ({ topText, bottomText }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={ {
      height: '100%',
      width: '100%',
      display: 'flex',
    } }
    >
      <div className={ classes.changingColorBox }>
        <Typography
          style={ {
            fontWeight: '600',
            fontSize: '20px',
            lineHeight: '136.19%',
            textShadow: '0px 13.1831px 30.2113px rgba(0, 0, 0, 0.05), 0px 2.94462px 9.81539px rgba(0, 0, 0, 0.0298054), 0px 0.87669px 2.9223px rgba(0, 0, 0, 0.0201946)',
            textAlign: 'center',
            // marginTop: '30%',
          } }
        >
          {t(topText)}
        </Typography>

        <Typography
          style={ {
            fontWeight: 400,
            marginTop: '19px',
            fontSize: '16px',
            lineHeight: '136%',
            textAlign: 'center',
          } }
        >
          {t(bottomText)}
        </Typography>
      </div>
    </div>
  );
};

const BoxWithImage = ({ image, text, imgStyle = {} }) => {
  const classes = useStyles();

  return (
    <div className={ classes.boxWithImage }>
      <Typography style={ {
        width: '100%',
        textAlign: 'right',
        fontWeight: 600,
        font: 'Roboto',
        color: '#545454',
        fontSize: '20px',
        lineHeight: '142.19%',
        maxWidth: '50%',
        alignSelf: 'flex-end',
      } }
      >
        {text}
      </Typography>

      <img
        className={ classes.boxWithImageImage }
        src={ image }
        alt={ image }
        style={ {
          ...imgStyle,
        } }
      />
    </div>
  );
};

const CustomerCard = ({ image, name, type, text }) => {
  const { t } = useTranslation();

  return (
    <Card
      style={ { maxWidth: 345,
        height: '400px',
        borderRadius: '15px',
        margin: '10px 0' } }
      elevation={ 8 }
    >
      <CardHeader
        avatar={ (
          <img
            src={ image }
            alt={ image }
            style={ {
              height: '55px',
              width: '55px',
            } }
          />
        ) }
        title={ t(name) }
        subheader={ t(type) }
        titleTypographyProps={ {
          style: {
            fontWeight: '600',
            fontSize: '16px',
            lineHeight: '142.19%',
            color: '#484848',
            textShadow: '0px 13.1831px 30.2113px rgba(0, 0, 0, 0.05), 0px 2.94462px 9.81539px rgba(0, 0, 0, 0.0298054), 0px 0.87669px 2.9223px rgba(0, 0, 0, 0.0201946)',
          },
        } }
        subheaderTypographyProps={ {
          style: {
            color: '#9C9C9C',
            fontWeight: '400',
            fontSize: '13px',
            fontFamily: 'Inter',
            lineHeight: '16px',
          },
        } }
      />
      <CardContent style={ {
        padding: '30px',
      } }
      >
        <Typography variant='body2'>
          {t(text)}
        </Typography>
      </CardContent>
    </Card>
  );
};

function NextArrow(props) {
  const { onClick, className, style } = props;
  return (
    <div
      className={ className }
      style={ { ...style } }
    >
      <IconButton onClick={ onClick } style={ { padding: 0 } }>
        <NavigateNextIcon fontSize='large' />
      </IconButton>
    </div>
  );
}

function PrevArrow(props) {
  const { onClick, className, style } = props;
  return (
    <div
      className={ className }
      style={ { ...style } }
    >
      <IconButton onClick={ onClick } style={ { padding: 0 } }>
        <NavigateBeforeIcon fontSize='large' />
      </IconButton>
    </div>
  );
}

const FifthPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div
      style={ {
        minHeight: '100vh',
        backgroundColor: 'white',
      } }
    >
      <div style={ { display: 'flex', justifyContent: 'center', marginTop: '140px', marginBottom: '100px' } }>
        <Typography variant='h2' style={ { color: '#384252', maxWidth: 'min(657px, 90vw)' } }>
          <span style={ { fontWeight: 'bold' } }>
            {t('With myEMR')}
          </span>
          {t(', you can keep track of your progress every step of the way.')}
        </Typography>
      </div>

      <div style={ { width: '100%',
        background: 'url("/Footer-background-Medium.jpg")',
        height: '100%',
        backgroundPosition: 'bottom',
        minHeight: '100vh',
        backgroundRepeat: 'no-repeat',
        display: 'flex',
        justifyContent: 'flex-end',
        flexDirection: 'column',

        paddingBottom: '100px' } }
      >
        <div style={ { marginLeft: '10vw' } }>
          <Typography variant='h2' style={ { marginBottom: '43px' } }>
            {t('Επικοινωνήστε μαζί μας')}
          </Typography>

          <div style={ { display: 'flex',
            flexWrap: 'wrap',
            justifyContent: 'space-between',
            paddingRight: '10px',
            maxWidth: '1000px',
            gap: '20px' } }
          >
            <div style={ { display: 'flex',
              alignItems: 'center',
              border: '1px solid #39A6D3',
              borderRadius: '15px',
              width: 'min(407px, 80vw)',
              background: 'white',
              padding: '11px 0 11px 20px',
              gap: '11px' } }
            >
              <Avatar
                src='/live-chat.png'
                alt='live-chat'
                style={ { height: '30px', width: '36px', background: '#F3F8E7' } }
              />

              <Typography style={ {
                fontWeight: '400',
                fontSize: '16px',
                lineHeight: '19px',
              } }
              >
                {t('Χρειάζεσαι βοήθεια;')}
              </Typography>

            </div>

            <div className={ classes.bottomPartContainer }>
              <div>
                <Typography style={ {
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '147.19%',
                  color: '#545454',
                } }
                >
                  {t('Για άμεση εξυπηρέτηση καλέστε μας στο')}
                </Typography>

                <Typography style={ {
                  fontWeight: 400,
                  fontSize: '14px',
                  lineHeight: '147.19%',
                  color: '#828282',
                } }
                >
                  {t('(Δευτέρα – Παρασκευή 9πμ.- 5μμ.)')}
                </Typography>

                <Typography style={ {
                  fontWeight: 400,
                  fontSize: '16px',
                  lineHeight: '147.19%',
                  textDecorationLine: 'underline',
                  color: '#545454',
                } }
                >
                  +30 2111021821
                </Typography>
              </div>

              <div style={ { fontWeight: 400,
              // marginLeft: '72px',
                fontSize: '16px',
                lineHeight: '147.2%',
                color: '#545454' } }
              >
                <Typography>
                  {t('Αποστολή email')}
                </Typography>
                <Typography>
                  info@idnagenomics.com
                </Typography>
              </div>
            </div>

          </div>

        </div>
      </div>

    </div>
  );
};

const _FourthPage = () => {
  const { t } = useTranslation();

  const items = [
    {
      name: 'Μήνα Ρ.',
      type: 'Εταιρική Πελάτισσα',
      text: 'Οι εκθέσεις είναι καλά οργανωμένες και παρουσιάζουν τις πληροφορίες με σαφή και συνοπτικό τρόπο. Η ικανότητα της εφαρμογής να συνδέει γενετικά δεδομένα με συνθήκες υγείας και παράγοντες τρόπου ζωής είναι εντυπωσιακή και θα μπορούσε να είναι μια ανεκτίμητη πηγή για άτομα που θέλουν να λάβουν ενημερωμένες αποφάσεις για την υγεία τους.',
      image: '/Avatar_Business-client-woman-medium.png',
    },

    {
      name: 'Κάτια Π.',
      type: 'Ειδική Παθολόγος',
      text: 'Οι εκθέσεις είναι καλά οργανωμένες και παρουσιάζουν τις πληροφορίες με σαφή και συνοπτικό τρόπο. Η ικανότητα της εφαρμογής να συνδέει γενετικά δεδομένα με συνθήκες υγείας και παράγοντες τρόπου ζωής είναι εντυπωσιακή και θα μπορούσε να είναι μια ανεκτίμητη πηγή για άτομα που θέλουν να λάβουν ενημερωμένες αποφάσεις για την υγεία τους.',
      image: '/Avatar_Doctor-woman-medium.png',
    },

    {
      name: 'Παναγιώτης Λ.',
      type: 'Ιδιώτης',
      text: 'Οι εκθέσεις είναι καλά οργανωμένες και παρουσιάζουν τις πληροφορίες με σαφή και συνοπτικό τρόπο. Η ικανότητα της εφαρμογής να συνδέει γενετικά δεδομένα με συνθήκες υγείας και παράγοντες τρόπου ζωής είναι εντυπωσιακή και θα μπορούσε να είναι μια ανεκτίμητη πηγή για άτομα που θέλουν να λάβουν ενημερωμένες αποφάσεις για την υγεία τους.',
      image: '/Avatar_Individual-man-medium.png',
    },

    {
      name: 'Παναγιώτης AAA.',
      type: 'Ιδιώτης',
      text: 'Οι εκθέσεις είναι καλά οργανωμένες και παρουσιάζουν τις πληροφορίες με σαφή και συνοπτικό τρόπο. Η ικανότητα της εφαρμογής να συνδέει γενετικά δεδομένα με συνθήκες υγείας και παράγοντες τρόπου ζωής είναι εντυπωσιακή και θα μπορούσε να είναι μια ανεκτίμητη πηγή για άτομα που θέλουν να λάβουν ενημερωμένες αποφάσεις για την υγεία τους.',
      image: '/Avatar_Individual-man-medium.png',
    },

  ];

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    dots: false,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 1351,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 1350,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div
      style={ {
        width: '100vw',
        height: '600px',
        background: 'white',
      } }
    >
      <Typography
        variant='h2'
        style={ { textAlign: 'center', marginTop: '126px', marginBottom: '72px' } }
      >
        {t('Testimonials')}
      </Typography>

      <div style={ {
        background: 'radial-gradient(17.06% 28.41% at 77.75% 58.22%, #E8F9FF 2.08%, rgba(232, 249, 255, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */, radial-gradient(22.67% 37.74% at 22.67% 38.79%, #E8F9FF 2.08%, rgba(232, 249, 255, 0) 100%)',
        display: 'flex',
        justifyContent: 'center',
      } }
      >
        <div style={ {
          width: 'min(1280px, 100vw)',
        } }
        >
          <Slider { ...settings }>
            {
              items.map((item) => {
                return (
                  <CustomerCard
                    key={ item.name }
                    name={ item.name }
                    type={ item.type }
                    text={ item.text }
                    image={ item.image }
                  />
                );
              })
            }
          </Slider>
        </div>

      </div>
    </div>
  );
};

const ThirdPage = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const isSmallScreen = useMediaQuery('(max-width:1275px)');

  return (
    <div style={ { background: 'white' } }>
      <div className={ classes.similarProductsContainer }>
        <Typography variant='h2' style={ { textAlign: 'center', marginBottom: '45px' } }>
          {t('Σχετικά Προϊόντα')}
        </Typography>

        <Typography style={ { fontSize: '21px',
          color: '#484848',
          marginLeft: '5%',
          marginBottom: '15px' } }
        >
          {t('Medical')}
        </Typography>

        <div className={ classes.availableProductsContainer }>
          <BoxWithImage
            image='/kit_images/iDNA_Cardio Health.png'
            text={ t('iDNA Cardio Health') }
          />

          <BoxWithImage
            image='/kit_images/iDNA_NutriGenetix.png'
            text={ t('iDNA  NutriGenetix') }
          />

          <BoxWithImage
            image='/kit_images/iDNA_PGx_CNS.png'
            text={ t('iDNA PGx CNS') }
          />
        </div>

        <Typography style={ { fontSize: '21px',
          color: '#484848',
          margin: '27px 0 15px 5%' } }
        >
          {t('Wellness')}
        </Typography>

        <div className={ classes.availableProductsContainer }>
          <BoxWithImage
            image='/kit_images/iDNA_Test_3to1.png'
            text={ t('iDNATest3to1') }
          />

          <BoxWithImage
            image='/kit_images/iDNA_Test_Nutrition.png'
            text={ t('iDNA Test Διατροφή') }
          />

          <BoxWithImage
            image='/kit_images/iDNA_Bio_Age.png'
            text={ t('iDNA Βιολογική Ηλικία') }
          />
        </div>

      </div>
    </div>
  );
};

const CircleWithBoxesMobile = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
      <img
        src='services-background-dynamic-element.png'
        alt='services-background'
        style={ {
          height: 'auto',
          maxWidth: 'max(50vw, 250px)',
          marginLeft: 'auto',
          marginRight: 'auto',
        } }
      />

      <hr className={ classes.straightLine } />

      <div className={ classes.serviceBoxContainerMobile }>
        <div className={ clsx(classes.serviceBox) }>
          <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
            <img src='icon_loading-small.png' alt='icon_loading-small' />

            <Typography className={ classes.serviceBoxInner }>
              {t('Παρακολούθηση')}
            </Typography>
          </div>

          <Typography className={ classes.serviceBoxInnerBottom }>
            {t('Παρακολούθηση πορείας (status) των γενετικών αναλύσεων')}
          </Typography>

        </div>

        <hr className={ classes.straightLine } />

        <div className={ clsx(classes.serviceBox) }>
          <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
            <img src='icon_doctor-small.png' alt='icon_doctor-small' />

            <Typography className={ classes.serviceBoxInner }>
              {t('Λογαριασμός')}
            </Typography>
          </div>

          <Typography className={ classes.serviceBoxInnerBottom }>
            {t('Online εγγραφή test')}
          </Typography>
        </div>

        <hr className={ classes.straightLine } />

        <div className={ clsx(classes.serviceBox) }>
          <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
            <img src='icon_management-small.png' alt='icon_management-small' />

            <Typography className={ classes.serviceBoxInner }>
              {t('Διαμοιρασμός')}
            </Typography>
          </div>

          <Typography className={ classes.serviceBoxInnerBottom }>
            {t('Διαχείριση γενετικών αποτελεσμάτων')}
          </Typography>
        </div>

        <hr className={ classes.straightLine } />

        <div className={ clsx(classes.serviceBox) }>
          <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
            <img src='icon_share-network-small.png' alt='icon_share-network-small' />

            <Typography className={ classes.serviceBoxInner }>
              {t('Διαχείριση')}
            </Typography>
          </div>

          <Typography className={ classes.serviceBoxInnerBottom }>
            {t('Δυνατότητα πρόσβασης από επαγγελματίες υγείας')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

const CircleWithBoxesDesktop = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (

    <div style={ { marginTop: '50px', position: 'relative', height: 'fit-content' } }>
      <img
        src='services-background-dynamic-element.png'
        alt='services-background'
        style={ {
          height: 'auto',
          maxWidth: '591px',
        } }
      />

      <div className={ clsx(classes.topServiceBox, classes.serviceBox) }>
        <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
          <img src='icon_loading-small.png' alt='icon_loading-small' />

          <Typography className={ classes.serviceBoxInner }>
            {t('Παρακολούθηση')}
          </Typography>
        </div>

        <Typography className={ classes.serviceBoxInnerBottom }>
          {t('Παρακολούθηση πορείας (status) των γενετικών αναλύσεων')}
        </Typography>

      </div>

      <div className={ clsx(classes.bottomServiceBox, classes.serviceBox) }>
        <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
          <img src='icon_share-network-small.png' alt='icon_share-network-small' />

          <Typography className={ classes.serviceBoxInner }>
            {t('Διαχείριση')}
          </Typography>
        </div>

        <Typography className={ classes.serviceBoxInnerBottom }>
          {t('Δυνατότητα πρόσβασης από επαγγελματίες υγείας')}
        </Typography>
      </div>

      <div className={ clsx(classes.leftServiceBox, classes.serviceBox) }>
        <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
          <img src='icon_doctor-small.png' alt='icon_doctor-small' />

          <Typography className={ classes.serviceBoxInner }>
            {t('Λογαριασμός')}
          </Typography>
        </div>

        <Typography className={ classes.serviceBoxInnerBottom }>
          {t('Online εγγραφή test')}
        </Typography>
      </div>

      <div className={ clsx(classes.rightServiceBox, classes.serviceBox) }>
        <div style={ { display: 'flex', gap: '17px', alignItems: 'center' } }>
          <img src='icon_management-small.png' alt='icon_management-small' />

          <Typography className={ classes.serviceBoxInner }>
            {t('Διαμοιρασμός')}
          </Typography>
        </div>

        <Typography className={ classes.serviceBoxInnerBottom }>
          {t('Διαχείριση γενετικών αποτελεσμάτων')}
        </Typography>
      </div>
    </div>
  );
};

const SecondPage = ({ secondPageRef }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isSmallScreen = useMediaQuery('(max-width:1500px)');
  const isMobileScreen = useMediaQuery('(max-width:970px)');

  return (
    <div
      style={ { position: 'relative',
        // minHeight: isSmallScreen ? 'calc(130vh - 80px)' : 'calc(130vh - 80px)',
        background: 'white' } }
      id='secondPageId'
      ref={ secondPageRef }
    >
      <div
        style={ {
          backgroundBlendMode: 'color, normal, normal, normal',
          height: '1860px',
          maxWidth: '100%',
          width: '100vw',
          position: 'absolute',
          top: '30vh',
        } }
      >
        <video className={ classes.video } autoPlay muted loop>
          <source src='idna-landing-page-video-HD.webm' type='video/webm' />
        </video>
      </div>

      <div style={ { display: 'flex',
        // height: '1860px',
        justifyContent: 'space-around',
        alignItems: isSmallScreen ? 'center' : 'flex-start',
        margin: `0 ${ isSmallScreen ? 'auto' : '100px' } 0 ${ isSmallScreen ? 'auto' : '50px' }`,
        flexDirection: isSmallScreen ? 'column' : 'row',
        marginTop: '150px',
        gap: isSmallScreen ? '30px' : '0',
        position: 'relative',
        zIndex: 2 } }
      >
        <div style={ { marginTop: '100px' } }>
          <Typography variant='h2' style={ { textAlign: 'center' } }>
            {t('Υπηρεσίες')}
          </Typography>

          <div
            style={ { display: 'flex',
              gap: '40px',
              marginTop: '20px' } }
            className={ classes.wrapWhenSmall }
          >
            <div style={ { display: 'flex', gap: '20px', alignItems: 'center' } }>
              <img
                src='/icon_arrow-circle-right-fill-medium.svg'
                alt='icon_arrow-circle-right-fill-medium.svg'
                style={ { width: '20px', height: '20px' } }
              />
              <div>
                <Typography style={ {
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '20px',
                } }
                >
                  {t('Για Ιδιώτες')}
                </Typography>

              </div>
            </div>

            <div style={ { display: 'flex', gap: '20px', alignItems: 'center' } }>
              <img
                src='/icon_arrow-circle-right-fill-medium.svg'
                alt='icon_arrow-circle-right-fill-medium.svg'
                style={ { width: '20px', height: '20px' } }
              />
              <Typography style={ {
                fontFamily: 'Roboto',
                fontStyle: 'normal',
                fontWeight: 300,
                fontSize: '20px',
              } }
              >
                {t('Για Επαγγελματίες')}
              </Typography>

            </div>
          </div>

          <div style={ { display: 'flex',
            gap: '20px',
            justifyContent: 'center',
            alignItems: isMobileScreen ? 'center' : 'flex-start',
            marginTop: '29px',
            flexDirection: 'column' } }
          >
            <Typography style={ {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '350',
              fontSize: '16px',
              marginTop: '17px',
              maxWidth: 'min(400px, 90vw)',
            } }
            >
              {t('Αποτελεί ένα ασφαλές και εύχρηστο σύστημα έτσι ώστε ιδιώτες και επαγγελματίες υγείας να εγγράφουν, αποθηκεύουν, διαχειρίζονται και διαμοιράζονται πληροφορίες.')}
            </Typography>

            <Typography style={ {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '350',
              fontSize: '16px',
              marginTop: '17px',
              maxWidth: 'min(400px, 90vw)',
            } }
            >
              {t('Η πλατφόρμα αποτελεί το προσωπικό αρχείο εξατομικευμένων αναλύσεων υγείας και ευζωίας σας.')}
            </Typography>
          </div>

        </div>

        {
          isMobileScreen ? (
            <CircleWithBoxesMobile />
          ) : (
            <CircleWithBoxesDesktop />
          )
        }

      </div>

      <div
        style={ {
          background: 'linear-gradient(180deg, rgba(255, 255, 255, 0.0) 0%, #E8F9FF 32.79%, #F4FFFD 52.58%, rgba(255, 255, 255, 0) 108.55%)',
          zIndex: 2,
          paddingTop: '100px',
          paddingBottom: '100px',
          position: 'relative',
          marginTop: isSmallScreen ? '0' : '300px',
        } }
      >
        <div style={ { display: 'flex',
          justifyContent: 'space-around',
          flexWrap: 'wrap',
          gap: '50px',
          marginLeft: isSmallScreen ? 'auto' : '10vw',
          marginRight: isSmallScreen ? 'auto' : '5vw' } }

        >
          <div className={ classes.benefitsContainer }>
            <Typography
              variant='h2'
              className={ classes.benefitsText }
            >
              {t('Πλεονεκτήματα')}
            </Typography>

            <div
              style={ { display: 'flex', gap: '20px', marginTop: '25px', justifyContent: 'flex-start' } }
              className={ classes.wrapWhenSmall }
            >
              <div style={ { display: 'flex', gap: '20px', alignItems: 'center' } }>
                <img
                  src='/icon_arrow-circle-right-fill-medium.svg'
                  alt='icon_arrow-circle-right-fill-medium.svg'
                  style={ { width: '20px', height: '20px' } }
                />
                <Typography style={ {
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '20px',
                } }
                >
                  {t('Για Ιδιώτες')}
                </Typography>
              </div>

              <div style={ { display: 'flex', gap: '20px', alignItems: 'center' } }>
                <img
                  src='/icon_arrow-circle-right-fill-medium.svg'
                  alt='icon_arrow-circle-right-fill-medium.svg'
                  style={ { width: '20px', height: '20px' } }
                />
                <Typography style={ {
                  fontFamily: 'Roboto',
                  fontStyle: 'normal',
                  fontWeight: 300,
                  fontSize: '20px',
                } }
                >
                  {t('Για Επαγγελματίες')}
                </Typography>
              </div>
            </div>

            <Typography style={ {
              fontFamily: 'Roboto',
              fontStyle: 'normal',
              fontWeight: '350',
              fontSize: '16px',
              marginTop: '17px',
              marginLeft: !isMobileScreen ? '' : 'auto',
              marginRight: !isMobileScreen ? '' : 'auto',
              maxWidth: 'min(400px, 90vw)',
            } }
            >
              {t('Αποτελεί ένα ασφαλές και εύχρηστο σύστημα έτσι ώστε ιδιώτες και επαγγελματίες υγείας να εγγράφουν, αποθηκεύουν, διαχειρίζονται και διαμοιράζονται πληροφορίες.')}
            </Typography>
          </div>

          <div
            style={ {
              background: 'linear-gradient(180deg, #FFFFFF 0%, #E8F9FF 32.79%, #F4FFFD 52.58%, rgba(255, 255, 255, 0) 108.55%)',
              zIndex: 3,
            } }
          >
            <Grid
              container
              className={ classes.changingColorBoxContainer }
            >
              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox topText='Ευκολία' bottomText='Εύκολη πρόσβαση στα αρχεία' />
              </Grid>

              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox
                  topText='Αρχειοθέτηση'
                  bottomText='Ψηφιακό αρχείο γενετικών αποτελεσμάτων'
                />
              </Grid>

              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox
                  topText='Έλεγχος'
                  bottomText='Έλεγχος των προσωπικών σας δεδομένων'
                />
              </Grid>

              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox
                  topText='Προσβασιμότητα'
                  bottomText='Προσβασιμότητα και δυνατότητα αναζήτησης δεδομένων'
                />
              </Grid>

              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox
                  topText='Ασφάλεια'
                  bottomText='Ασφάλεια βάση αυστηρών πρωτόκόλλων'
                />
              </Grid>

              <Grid item xs={ 12 } sm={ 6 } md={ 4 }>
                <ChangingColorBox
                  topText='Κοινοποίηση'
                  bottomText='Διαμοιρασμός αρχείων με επαγγελματίες υγείας'
                />
              </Grid>
            </Grid>
          </div>
        </div>

      </div>

    </div>
  );
};

const SignInUpPage = ({ selectedUserType, setSelectedUserType }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { setUserGroup } = useAuth();

  const params = useLocation().search;
  const paramUserType = new URLSearchParams(params).get('userType');

  const [ formColor, setFormColor ] = useState('#94C321');

  useEffect(() => {
    if ('doc' === paramUserType) {
      setUserGroup(UserGroups.Doctor);
      setSelectedUserType('doctor');
      setFormColor('#526D9C');
    } else {
      setUserGroup(UserGroups.Patient);
    }
  }, []);

  useEffect(() => {
    if ('doctor' === selectedUserType) {
      setFormColor('#526D9C');
      setUserGroup(UserGroups.Doctor);
    } else if ('civilian' === selectedUserType) {
      setFormColor('#94C321');
      setUserGroup(UserGroups.Patient);
    }
  }, [ selectedUserType ]);

  const secondPageRef = useRef(null);

  const isSmallScreen = useMediaQuery('(max-width:1110px)');

  const UserTypes = () => {
    return (
      <div className={ classes.userTypes }>
        <Grid container style={ { height: '100%' } }>
          <Grid item xs={ 6 } sm={ 4 } style={ { height: '100%' } }>
            <button
              type='button'
              onClick={ () => { setSelectedUserType('civilian'); } }
              className={ `${ classes.userType } ${ 'civilian' === selectedUserType ? classes.userTypeSingle : classes.nonSelectedCivilian }` }
            >
              <PersonIcon
                style={ {
                  fill: 'civilian' === selectedUserType ? '' : '#818791',
                } }
              />
              {t('Είσαι ιδιώτης?')}
            </button>
          </Grid>
          <Grid item xs={ 6 } sm={ 4 } style={ { height: '100%' } }>
            <button
              type='button'
              onClick={ () => { setSelectedUserType('doctor'); } }
              className={ `${ classes.userType } ${ 'doctor' === selectedUserType ? classes.userTypeDoctor : classes.nonSelectedDoctor }` }
            >
              <Icon>
                <img
                  src={ 'doctor' === selectedUserType ? 'maki_doctor_white.svg' : 'maki_doctor.svg' }
                  alt='doctor'
                />
              </Icon>
              {t('Είσαι γιατρός ή επαγγελματίας υγείας?')}
            </button>
          </Grid>
          <Grid item xs={ 0 } sm={ 4 } style={ { height: '100%' } }>
            <button
              type='button'
              onClick={ () => { setSelectedUserType('bussiness'); } }
              className={ `${ classes.userType } ${ 'bussiness' === selectedUserType ? classes.userTypeBussiness : classes.nonSelectedCivilian }` }
            >
              <BusinessCenterIcon style={ { fill: 'bussiness' === selectedUserType ? 'white' : '#818791' } } />
              {t('Είσαι εταιρικός πελάτης?')}
            </button>
          </Grid>
        </Grid>
      </div>
    );
  };

  const SignInUpMenu = () => {
    return (
      <div className={ classes.container } style={ { position: 'relative' } }>
        <div className={ classes.gradientOverImage } />

        <div className={ classes.signInUp }>
          <UserTypes />
          <div className={ classes.innerForm }>
            <SignInForm color={ formColor } selectedUserType={ selectedUserType } />
          </div>
        </div>
        <div style={ {
          // background: 'radial-gradient(35.06% 50.29% at 3.84% 46.19%, #F1F3F3 0%, rgba(255, 255, 255, 0) 100%)',
          width: '100%',
          height: '100%',
        } }
        >
          <div style={ { display: isSmallScreen ? 'none' : '',
            position: 'relative',
            height: '90vh' } }
          >
            <div className={ classes.emrText }>
              <img src='/myEMR.svg' alt='myEMR' style={ { height: '105px', width: 'auto' } } />
              <div
                className={ classes.personalFileBox }
              >
                <img
                  src='icon-circle-information-fill-medium.png'
                  alt='info-icon'
                  style={ {
                    width: '40px',
                    height: '40px',
                  } }
                />
                <Typography className={ classes.personalFile }>
                  {t('Το προσωπικό σου αρχείο εξατομικευμένων αναλύσεων υγείας και ευζωίας')}
                </Typography>
              </div>
            </div>
          </div>

        </div>

        <Button
          className={ classes.learnMoreButton }
          endIcon={
            <ExpandMoreIcon style={ { fontSize: '30px' } } />
          }
          onClick={
            () => {
              const scrollDiv = secondPageRef.current?.offsetTop;
              if (scrollDiv) {
                window.scrollTo({ top: scrollDiv, behavior: 'smooth' });
              }
            }
          }
        >
          {t('learnMore')}
        </Button>
      </div>
    );
  };

  return (
    <>
      <SignInUpMenu />
      <SecondPage secondPageRef={ secondPageRef } />
      <ThirdPage />
      <FifthPage />
    </>
  );
};

const LandingPage = () => {
  const [ selectedUserType, setSelectedUserType ] = useState(undefined);

  const params = useLocation().search;
  const paramUserType = new URLSearchParams(params).get('userType');

  useEffect(() => {
    if ('doc' === paramUserType) {
      setSelectedUserType('doctor');
    } else if ('personal' === paramUserType) {
      setSelectedUserType('civilian');
    }
  }, []);

  if (selectedUserType === undefined) {
    return (
      <SelectProfileType setSelectedUserType={ setSelectedUserType } />
    );
  }

  return (
    <SignInUpPage
      selectedUserType={ selectedUserType }
      setSelectedUserType={ setSelectedUserType }
    />
  );
};

export default LandingPage;
