
export const generalConsents = {
  GDPR_CONSENT: 'GDPR',
  LEGAL_GUARDIAN_CONSENT: 'GUARDIAN',
  TRUE_AND_ACCURATE_FACTS_CONSENT: 'TRUE_AND_ACCURATE_FACTS_CONSENT',
  ANONYMOUS_DNA_RESEARCH_PURPOSE_CONSENT: 'RESEARCH',
  ANONYMOUS_REPORT_RESEARCH_PURPOSE_CONSENT: 'RESEARCH',
  PARENTAL_CONSENT: 'PARENTAL_CONSENT',
};

export const pgxConsents = {
  PGX_GENERAL_CONSENT: 'PGX_GENERAL_CONSENT',
  PGX_DRUGS_CONSENT: 'PGX_DRUGS_CONSENT',
  PGX_DRUGS_24_CNS: 'PGX_DRUGS_24_CNS',
  PGX_DRUGS_31_CNS: 'PGX_DRUGS_31_CNS',
  PGX_DOCTOR_CONSENT: 'PGX_DOCTOR_CONSENT',
  PHARMACOGENETIC_ANALYSIS_CONSENT: 'PHARMACOGENETIC_ANALYSIS_CONSENT',
};

export const doctorConsents = {
  DOCTOR_GENERAL_CONSENT: 'DOCTOR_GENERAL_CONSENT',
};

export const wellnessConsents = {
  NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT: 'NUTRIGENETIX_CARDIO_WELLNESS_GENERAL_CONSENT',
  GENETIC_ANALYSIS_CONSENT: 'GENETIC_ANALYSIS_CONSENT',
};

export const marketingConsents = {
  EMAIL_INFORMATIVE_MATERIAL_CONSENT: 'PROMOTION',
};

export const bioAgeConsents = {
  BIOLOGICAL_AGE_GENERAL_CONSENT: 'BIOLOGICAL_AGE_GENERAL_CONSENT',
  BIO_DATA_CONSENT: 'BIO_DATA_CONSENT',
};
