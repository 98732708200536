import React, { useMemo } from 'react';
import { useOptionSerial } from 'NavigationProvider';
import { useNutritionDataReport } from 'models/kits';
import { I18nextProvider } from 'react-i18next';
import PDFViewer from '../../components/PDFViewer';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const NutritionPdfVersion = () => {
  const { serial: newSerial } = useOptionSerial('nutrition');
  const { locale } = useNutritionDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <PDFViewer serial={ newSerial } />
    </I18nextProvider>
  );
};

export default NutritionPdfVersion;
