import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl, adviceImages } from '../CardiometabolicHealth';

const SaturatedFat = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT15', 'NCAT5', 'NCAT10', 'NCAT4' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τα';
    const description = 'NCAT5_description';

    if ('NCAT15' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToSalt;
      url = profileToUrl.sensitivityToSalt;
    } else if ('NCAT5' === filtered.ID) {
      iconPath = profileToIcon.saturatedFat;
      url = profileToUrl.saturatedFat;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('NCAT10' === filtered.ID) {
      iconPath = profileToIcon.fattyAcids;
      url = profileToUrl.fattyAcids;
    } else if ('NCAT4' === filtered.ID) {
      iconPath = profileToIcon.transFats;
      url = profileToUrl.transFats;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ dietCard } activePage='cardiometabolicHealth' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Κορεσμένα λιπαρά'
        activeColor={ dietCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.saturatedFat }
      />
    </div>
  );
};

export default SaturatedFat;
