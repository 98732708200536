import React from 'react';
import { useSportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NAthleticProfile';
import { blueColor } from '../Sports';

const NAerobics = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useSportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Performance' === currData.type && 'Αθλητικό προφίλ' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';

    if ('SCAT1' === filtered.ID) {
      iconPath = profileToIcon.stamina;
      url = profileToUrl.stamina;
    } else if ('SCAT2' === filtered.ID) {
      iconPath = profileToIcon.strength;
      url = profileToUrl.strength;
    } else if ('SCAT3' === filtered.ID) {
      iconPath = profileToIcon.power;
      url = profileToUrl.power;
    } else if ('SCAT4' === filtered.ID) {
      iconPath = profileToIcon.aerobics;
      url = profileToUrl.aerobics;
      value = filtered.result;
      resultText = filtered.genetic_profile_text;
      advice = filtered.advice;
    } else if ('SCAT5' === filtered.ID) {
      iconPath = profileToIcon.increaseMuscle;
      url = profileToUrl.increaseMuscle;
    } else if ('SCAT6' === filtered.ID) {
      iconPath = profileToIcon.wantToExercise;
      url = profileToUrl.wantToExercise;
    } else if ('SCAT7' === filtered.ID) {
      url = profileToUrl.painTolerance;
      iconPath = '';
    }

    return { ...filtered, icon: iconPath, url, prefix };
  });

  const tab = {
    color: blueColor,
    id: 'sportsPage',
    title: 'Άθληση',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ sportsCard } activePage='sportsAthleticProfile' isNutrition />
      <Result
        sensitivityProfiles={ sensProfiles }
        activePage='athleticProfile'
        activeProfile='Αεροβική ικανότητα και καρδιoαναπνευστικά οφέλη'
        activeColor={ sportsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
      />
    </div>
  );
};

export default NAerobics;
