import Grid from '@material-ui/core/Grid';
import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import noData from '../../assets/no_data.svg';

const NoData = (props) => {
  const { t } = useTranslation();
  const {
    className,
    style,
    text,
  } = props;

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={ className }
      style={ {
        ...style,
      } }
    >
      <Grid item style={ { padding: '20px' } }>
        <img alt='no-data' src={ noData } width='100px' height='auto' />
        <Typography variant='subtitle2' color='textSecondary' align='center'>
          {text || t('no-data')}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default NoData;
