import React, { useMemo } from 'react';
import { useOptionSerial } from 'NavigationProvider';
import { usePgxDataReport } from 'models/kits';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { I18nextProvider } from 'react-i18next';
import PDFViewer from '../../components/PDFViewer';

const PGxPdfVersion = () => {
  const { serial: newSerial } = useOptionSerial('pgx');
  const { locale } = usePgxDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  return (
    <I18nextProvider i18n={ i18nProv }>
      <PDFViewer serial={ newSerial } />
    </I18nextProvider>
  );
};

export default PGxPdfVersion;
