import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import useAPI from 'hooks/api-hooks';
import {
  Grid,
  Dialog, DialogTitle, DialogActions, Button,
  Typography, CircularProgress, DialogContent,
  Divider,
} from '@material-ui/core';
import useMediaQueries from '../hooks/media-query-hooks';
import DoctorDetailsForm from '../components/forms/doctor-forms/DoctorDetailsForm';

const useStyles = makeStyles((_theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    paper: { minWidth: '770px' },
  };
});

const DoctorProfilePage = (props) => {
  const { title } = props;
  const classes = useStyles();

  const { isMd } = useMediaQueries();
  const { t } = useTranslation();
  const { client } = useAPI();

  const [ deleteAccountConfirmOpen, setDeleteAccountConfirmOpen ] = useState(false);

  const history = useHistory();
  const [ loadingDelete, setLoadingDelete ] = useState(false);

  const deleteAccount = () => {
    setLoadingDelete(true);
    client.delete('doctors').then(() => {
      history.push('/logout');
    });
  };

  return (
    <>
      <Dialog
        classes={ { paper: classes.paper } }
        onClose={ () => { setDeleteAccountConfirmOpen(false); } }
        open={ deleteAccountConfirmOpen }
      >
        <DialogTitle>{t('areYouSureDeleteAccount')}</DialogTitle>
        <DialogContent>
          <Typography>
            {t('deleteAccountText1')}
          </Typography>
          <Typography>
            {t('deleteAccountText11')}
          </Typography>

          <Typography>
            {t('deleteAccountText12')}
          </Typography>

          <Typography>
            {t('deleteAccountText2')}

            {' '}
            <span style={ { fontWeight: 'bold' } }>
              {'"'}
              {t('confirmDeletion')}
              {'"'}
            </span>
            {'. '}

            {t('deleteAccountButtonText')}
          </Typography>

          <br />

          <Typography>
            {t('deleteAccountText3')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            autoFocus
            onClick={ () => { setDeleteAccountConfirmOpen(false); } }
            disabled={ loadingDelete }
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={ deleteAccount }
            style={ { color: loadingDelete ? '#CCCCCC' : 'red' } }
            disabled={ loadingDelete }
          >
            { loadingDelete
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }
            {t('confirmDeletion')}
          </Button>
        </DialogActions>
      </Dialog>
      <Grid container spacing={ isMd ? 4 : 2 }>
        <Grid item xs={ 12 }>
          <div
            className={ classes.titleCta }
            style={ { display: 'flex', justifyContent: 'space-between' } }
          >

            <Typography variant='h6' color='textPrimary'>
              {title}
            </Typography>

            <Button
              variant='contained'
              style={ { color: 'white', background: 'red' } }
              onClick={ () => { setDeleteAccountConfirmOpen(true); } }
            >
              {t('deleteAccount')}
            </Button>
          </div>
        </Grid>
        <Grid item xs={ 12 }>
          <Divider />
        </Grid>
        <Grid container item xs={ 12 } md={ 10 }>
          <Grid item xs={ 12 }>
            <DoctorDetailsForm />
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default DoctorProfilePage;
