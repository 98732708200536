import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import AlertsArea from './components/AlertsArea';

export const AlertsCtx = React.createContext({});

const Timer = function (callback, delay) {
  let timerId = delay;
  let start = delay;
  let remaining = delay;

  this.pause = () => {
    clearTimeout(timerId);
    remaining -= Date.now() - start;
  };

  this.resume = () => {
    start = Date.now();
    clearTimeout(timerId);
    timerId = setTimeout(callback, remaining);
  };

  this.resume();
};

export function AlertsProvider(props) {
  const [ alerts, setAlerts ] = useState([]);
  const { t } = useTranslation();
  const deleteAlert = (alertId) => {
    setAlerts((prevAlerts) => {
      return prevAlerts.filter((alert) => {
        return alert.id !== alertId;
      });
    });
  };

  const hideAlert = (alertId) => {
    setAlerts((prevAlerts) => {
      const index = prevAlerts.findIndex((alert) => { return alert.id === alertId; });
      if (-1 === index) { return prevAlerts; }

      const newAlerts = [ ...prevAlerts ];
      newAlerts[index].show = false;
      return newAlerts;
    });

    // clear the memory once the alert gets hidden
    setTimeout(() => { return deleteAlert(alertId); }, 1000);
  };

  const createAlert = ({ type = 'info', message: msg }) => {
    let message;
    switch (typeof msg) {
      case 'string':
        message = msg;
        break;
      case 'object':
        if (!msg.code || !('string' === typeof msg.code)) {
          message = t('somethingWentWrong');
        } else {
          message = t(msg.code);
        }
        break;
      default:
        message = JSON.stringify(msg);
    }

    // Don't show the same alert multiple times.
    const sameAlert = alerts.find((alert) => {
      return alert.message === message;
    });
    if (null != sameAlert) {
      return;
    }

    const alertId = uuid();

    const timer = new Timer((() => {
      hideAlert(alertId);
    }), 16000);

    const alert = {
      id: alertId,
      message,
      type, // 'error' | 'info' | 'success' | 'warning'
      show: true,
      timer,
      onClose: () => { return hideAlert(alertId); },
    };

    setAlerts((prevAlerts) => {
      const newAlerts = [ alert, ...prevAlerts ];

      return newAlerts.filter((value, index, self) => {
        return index === self.findIndex((tt) => {
          return (
            tt.message === value.message
          );
        });
      });
    });
  };

  const autoHidePause = () => {
    setAlerts((prevAlerts) => {
      prevAlerts.forEach((alert) => {
        alert.timer.pause();
      });
      return prevAlerts;
    });
  };

  const autoHideResume = () => {
    setAlerts((prevAlerts) => {
      prevAlerts.forEach((alert) => {
        alert.timer.resume();
      });
      return prevAlerts;
    });
  };

  const alertSuccess = (message) => { return createAlert({ type: 'success', message }); };
  const alertError = (message) => { return createAlert({ type: 'error', message }); };
  const alertInfo = (message) => { return createAlert({ type: 'info', message }); };
  const alertWarning = (message) => { return createAlert({ type: 'warning', message }); };

  const ctx = {
    alertSuccess,
    alertError,
    alertInfo,
    alertWarning,
  };

  return (
    <AlertsCtx.Provider value={ ctx }>
      {props.children}
      <AlertsArea
        alerts={
          alerts
        }
        alertsAutoHidePause={ autoHidePause }
        alertsAutoHideResume={ autoHideResume }
      />
    </AlertsCtx.Provider>
  );
}
