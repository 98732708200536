import { Grid, makeStyles, useTheme } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import CollectionKeys from 'models/atoms/collection-keys';
import { validate as uuidValidate } from 'uuid';
import VisibilityIcon from '@material-ui/icons/Visibility';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import clsx from 'clsx';
import ListItemText from '@material-ui/core/ListItemText';
import IconButton from '@material-ui/core/IconButton';
import FilterIcon from '@material-ui/icons/FilterList';
import Button from '@material-ui/core/Button';
import { useTranslation } from 'react-i18next';
import { MdDelete } from 'react-icons/md';
import CircularProgress from '@material-ui/core/CircularProgress';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';
import { parseDate, useDatetimeFormat } from '../../utils';
import AppLoading from '../../components/app/AppLoading';
import { useNotification, useNotifications } from '../../models/notifications';
import AppPagination from '../../components/app/AppPagination';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      width: '100%',
    },
    listItem: {
      marginTop: '1px',
    },
    listItemConsent: {
      marginTop: '0px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
  };
});

const NotificationItem = ({ notification, i }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();

  const dateFormat = useDatetimeFormat();
  const {
    readNotification,
    notificationIsUpdating,
    deleteNotification,
    notificationIsDeleting,
  } = useNotification(notification.id, { enabled: false });

  let primaryText;
  let secondaryText;

  const { client } = useAPI();

  const {
    data: serialNumberDetails,
    isLoading: serialNumberDetailsLoading,
  } = useQuery(
    [ CollectionKeys.SerialNumbers, 'serial_eq', notification.metadata.serial_number ], () => {
      return client.get(`${ CollectionKeys.SerialNumbers }?serial_eq=${ notification.metadata.serial_number }`);
    },
  );

  const serialNumber = useMemo(() => {
    if (null == notification.metadata.serial_number) {
      return notification.metadata.serial_number;
    }

    const isSerialRepeat = uuidValidate(notification.metadata.serial_number?.split('_').at(-1));
    if (isSerialRepeat) {
      if (serialNumberDetailsLoading || serialNumberDetails === undefined) {
        return -1;
      }

      return `${ notification.metadata.serial_number?.split('_')[0] } (${ (t('DynamicMonitoring')) }: ${ parseDate(serialNumberDetails[0].created_at) })`;
    }

    return notification.metadata.serial_number;
  }, [ notification, serialNumberDetailsLoading, serialNumberDetails, t ]);

  if ('pgx-doctor-consent' === notification.type) {
    primaryText = `${ t('notificationsPage.pgx.doctorTitle') } ${ serialNumber } - ${ dateFormat(notification.created_at) }`;
    secondaryText = `${ t('Ο χρήστης') } ${ notification.metadata.from.name } ${ notification.metadata.from.family_name } ${ t('notificationsPage.pgx.doctorText') }`;
  } else if ('healthcare-professional-share-results' === notification.type) {
    primaryText = `${ t('notificationsPage.healthcare.doctorTitle') } ${ notification.metadata.from.name } ${ notification.metadata.from.family_name } - ${ dateFormat(notification.created_at) }`;
    secondaryText = `${ t('Ο χρήστης') } ${ notification.metadata.from.name } ${ notification.metadata.from.family_name } ${ t('notificationsPage.healthcare.doctorText') } ${ serialNumber }`;
  } else if ('done' === notification.type) {
    primaryText = `${ t('Report available') } - ${ dateFormat(notification.created_at) }`;
    secondaryText = `${ t('We inform you that the results report for the serial number') } ${ serialNumber } ${ t('είναι διαθέσιμο στην επιλογή Λίστα ασθενών / πελατών.') }`;
  } else if ('sample-received' === notification.type) {
    primaryText = `${ t('Sample received') } - ${ dateFormat(notification.created_at) }`;
    secondaryText = `${ t('We inform you that we have received the buccal swab sample with name') } ${ notification.metadata.from.family_name } ${ notification.metadata.from.name } ${ t('and serial number') } ${ serialNumber }.`;
  } else if ('done_doctor' === notification.type) {
    primaryText = `${ t('Report available') } - ${ dateFormat(notification.created_at) }`;
    secondaryText = `${ t('We inform you that the results report for the serial number') } ${ serialNumber } ${ t('είναι διαθέσιμο στην επιλογή Αποτελέσματα.') }`;
  }

  if (-1 === serialNumber) {
    return null;
  }

  return (
    <ListItem
      className={
        clsx(
          classes.listItem,
          i % 2 ? (
            notification.read ? classes.listItemEven : classes.listItemEvenActive
          ) : (notification.read ? classes.listItemOdd : classes.listItemOddActive),
        )
      }
    >
      <ListItemText
        primary={ primaryText }
        secondary={ secondaryText }
      />
      { !notification.read && (
        <IconButton
          style={ { color: theme.palette.success.main } }
          component='span'
          disabled={ notificationIsUpdating }
          onClick={ () => {
            return readNotification();
          } }
        >
          { notificationIsUpdating ? (
            <CircularProgress
              color='primary'
              variant='indeterminate'
              size={ 24 }
            />
          ) : <VisibilityIcon color='action' /> }
        </IconButton>
      ) }
      <IconButton
        style={ { color: theme.palette.error.main } }
        component='span'
        disabled={ notificationIsDeleting }
        onClick={ () => {
          return deleteNotification();
        } }
      >
        { notificationIsDeleting ? (
          <CircularProgress
            color='primary'
            variant='indeterminate'
            size={ 24 }
          />
        ) : <MdDelete /> }
      </IconButton>
    </ListItem>
  );
};

const NotificationsList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [ onlyUnread, setOnlyUnread ] = useState(false);

  const {
    notifications,
    notificationsLoading,
    notificationsError,
    pagination,
    notificationsCount,
  } = useNotifications(onlyUnread);

  const filterLabel = useMemo(() => {
    let value = onlyUnread ? `${ t('unread') }` : `${ t('all') }`;
    if (notificationsCount) {
      value = `${ value } (${ notificationsCount })`;
    }
    return value;
  }, [ t, onlyUnread, notificationsCount ]);

  if (notificationsLoading) {
    return <AppLoading />;
  }

  if (notificationsError) {
    return <p>Failed to load notifications</p>;
  }

  return (
    <Grid container>
      <Grid container item xs={ 12 } justifyContent='space-between' alignItems='center'>
        <Grid item>
          <Button
            onClick={ () => { return setOnlyUnread((p) => { return !p; }); } }
            variant='outlined'
            startIcon={ <FilterIcon /> }
          >
            {filterLabel}
          </Button>
        </Grid>
        <Grid item>
          <AppPagination
            page={ pagination.page }
            setPage={ pagination.setPage }
            count={ pagination.count }
            limit={ pagination.limit }
          />
        </Grid>
      </Grid>
      <Grid container item xs={ 12 }>
        <List className={ classes.list }>
          {
            notifications.map((notification, i) => {
              return (
                <NotificationItem
                  key={ notification.id }
                  notification={ notification }
                  i={ i }
                />
              );
            })
          }
        </List>
      </Grid>
    </Grid>
  );
};

export default NotificationsList;
