import {
  GiStethoscope,
  BsFillStarFill,
} from 'react-icons/all';
import MyDoctors from './pages/MyDoctors';
import Invitations from './pages/Invitations';
import { DoctorInvitations } from './pages/DoctorInvitations';
import DoctorConsents from './pages/DoctorConsents';

const pages = [
  {
    id: 'my-doctors',
    title: 'my-doctors',
    Component: MyDoctors,
    Icon: GiStethoscope,
    type: 'item',
  },
  {
    id: 'invitations',
    title: 'invitations',
    Component: Invitations,
    Icon: BsFillStarFill,
    type: 'item',
  },
];

export default pages;

export const MyDocPages = [
  {
    id: 'my-doctors',
    title: 'my-doctors',
    Component: DoctorInvitations,
    Icon: GiStethoscope,
    type: 'item',
  },
  {
    id: 'invitations',
    title: 'invitations',
    Component: DoctorConsents,
    Icon: BsFillStarFill,
    type: 'item',
  },
];
