import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { iDNA3ToOneCards } from './iDNA3ToOne';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';

export const profileToIcon = {
  detoxificationCapacity: { big: '/IKANOTHTA APOTOXINWSHS.jpg', small: '/IKANOTHTA APOTOXINWSHS_SMALL.jpg' },
  antioxidantNeeds: { big: '/ANTIOXEIDOTIKES ANAGES.jpg', small: '/ANTIOXEIDOTIKES ANAGES_SMALL.jpg' },
};

export const profileToUrl = {
  detoxificationCapacity: 'detoxificationCapacity',
  antioxidantNeeds: 'antioxidantNeeds',
};

export const adviceImages = {
  detoxificationCapacity: { left: null, right: '/ikanotita apotoxinosis.jpg' },
  antioxidantNeeds: { left: null, right: '/antioxeidotikes anagkes.jpg' },
};

const DetoxificationCapacityAntioxidantNeeds = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT1', 'VCAT1' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT1' === filtered.ID) {
      iconPath = profileToIcon.detoxificationCapacity;
      url = profileToUrl.detoxificationCapacity;
    } else if ('VCAT1' === filtered.ID) {
      iconPath = profileToIcon.antioxidantNeeds;
      url = profileToUrl.antioxidantNeeds;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ vitaminsCard } activePage='detoxificationCapacityAntioxidantNeeds' />
        <PhotoWithText
          options={ vitaminsCard }
          activePage='detoxificationCapacityAntioxidantNeeds'
          text={ data[0].value.detox_antioxidant_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default DetoxificationCapacityAntioxidantNeeds;
