import { useMutation } from 'react-query';
import useAPI from 'hooks/api-hooks';

export const useReferAFriend = () => {
  const { client } = useAPI({
    codeAlertSuccess: 'You successfully invite your friends',
  });

  const m = useMutation(
    'referal',
    ({ emails }) => {
      return client.post('coupons',
        { email_restrictions: emails });
    },
  );

  return m;
};
