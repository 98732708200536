import React, { useMemo, useState } from 'react';
import { useAuth } from 'AuthCtx';
import { TableContainer, IconButton, Table, TableHead, TableCell, TableRow, Typography, Paper, TableBody, Avatar } from '@material-ui/core';
import { useOptionSerial } from 'NavigationProvider';
import clsx from 'clsx';
import { Tab } from 'pages/iDNA3ToOne/Common';
import { PrevNextCardio } from 'pages/iDNACardioHealth/Common';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import DoneIcon from '@material-ui/icons/Done';
import { useBioAgeData, useBioAgeDataReport } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import InfoIcon from '@material-ui/icons/Info';
import { I18nextProvider, useTranslation } from 'react-i18next';
import useMediaQueries from 'hooks/media-query-hooks';
import i18nEng from '../../i18n_eng';
import i18nEl from '../../i18n_el';
import { Card, bioAgeTab, BioAgeCards, useStyles } from './BioAgeResults';
import { orangeColor } from './BiologicalAge';
import BioAgeAnalysisMobile, { BioAgeExplanationMobile } from './BioAgeAnalysisMobile';

export const explanationTypes = [
  {
    description: 'Βέλτιστο εύρος',
    iconType: 'green_tick',
    recomendation: 'Συνέχισε έτσι',
    color: '#00B050',
  },

  {
    description: 'Κανονικό εύρος αλλά πάνω από το βέλτιστο',
    iconType: 'orange_up_arrow',
    recomendation: 'Περιθώριο βελτίωσης',
    color: '#FFC721',
  },

  {
    description: 'Κανονικό εύρος αλλά κάτω από το βέλτιστο',
    iconType: 'orange_down_arrow',
    recomendation: 'Περιθώριο βελτίωσης',
    color: '#FFC721',
  },

  {
    description: 'Πάνω από το φυσιολογικό εύρος',
    iconType: 'red_up_arrow',
    recomendation: 'Συμβουλευτείτε το γιατρό σας',
    color: 'red',
  },

  {
    description: 'Kάτω από το φυσιολογικό εύρος',
    iconType: 'red_down_arrow',
    recomendation: 'Συμβουλευτείτε το γιατρό σας',
    color: 'red',
  },
];

const BioTable = ({ testTypes }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  if (null == testTypes) {
    return (
      <LoaderSkeleton />
    );
  }

  return (
    <TableContainer component={ Paper } style={ { background: 'unset', borderRadius: '15px' } }>
      <Table
        style={ { minWidth: 650 } }
        aria-label='simple table'
        className={ classes.table }
      >
        <TableHead style={ { background: orangeColor } }>
          <TableRow>
            <TableCell
              align='left'
              className={ classes.tableHeaderCell }
              style={ {
                borderTopLeftRadius: '15px',
              } }
            >
              <Typography variant='h6' style={ { fontWeight: 700, color: 'white' } }>
                {t('Βιοδείκτες')}
              </Typography>
            </TableCell>

            <TableCell align='left' className={ classes.tableHeaderCell }>
              <Typography variant='h6' style={ { fontWeight: 700, color: 'white' } }>
                {t('Λειτουργία')}
              </Typography>
            </TableCell>

            <TableCell align='left' className={ classes.tableHeaderCell }>
              <Typography variant='h6' style={ { fontWeight: 700, color: 'white' } }>
                {t('Φυσιολογικές τιμές')}
              </Typography>
            </TableCell>

            <TableCell
              align='center'
              className={ classes.tableHeaderCell }
              style={ {
                borderTopRightRadius: '15px',
              } }
            >
              <Typography
                variant='h6'
                style={ { fontWeight: 700, color: 'white' } }
              >
                {t('Αποτέλεσμα')}
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {testTypes.map((row, idx) => {
            const bgColor = 1 === idx % 2 ? '#FFF4EA' : '#FFF9F2';

            return (
              <TableRow
                key={ row.name }
                className={ clsx(classes.tableRow, 1 === idx % 2 ? classes.tableRowOdd : classes.tableRowEven) }
              >
                <TableCell
                  className={ classes.tableCell }
                  component='th'
                  scope='row'
                  align='left'
                >
                  <Typography>
                    {t(row.description)}
                  </Typography>
                </TableCell>

                <TableCell className={ classes.tableCell } align='left'>
                  <Typography>
                    {t(row.operation)}
                  </Typography>
                </TableCell>

                <TableCell className={ classes.tableCell } align='left'>
                  <Typography>
                    {
                      row.normalValues
                    }
                  </Typography>
                </TableCell>

                <TableCell
                  className={ classes.tableCell }
                  align='center'
                  style={ { padding: 0,
                    background: 'white',
                    position: 'relative' } }
                >
                  <div style={ { width: '50%',
                    background: bgColor,
                    position: 'absolute',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 0 } }
                  >
                    <Typography>
                      {
                        row.value
                      }
                    </Typography>
                  </div>

                  <div style={ { width: '50%',
                    background: bgColor,
                    position: 'absolute',
                    height: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    top: 0,
                    left: 'calc(49% + 2px)' } }
                  >
                    {
                      'green_tick' === row.icon && (
                        <DoneIcon style={ { color: '#00B050' } } />
                      )
                    }
                    {
                      'orange_up_arrow' === row.icon && (
                        <ArrowUpwardIcon style={ { color: '#FFC721', transform: 'rotate(45deg)' } } />
                      )
                    }

                    {
                      'orange_down_arrow' === row.icon && (
                        <ArrowUpwardIcon style={ { color: '#FFC721', transform: 'rotate(145deg)' } } />
                      )
                    }

                    {
                      'red_down_arrow' === row.icon && (
                        <ArrowUpwardIcon style={ { color: 'red', transform: 'rotate(180deg)' } } />
                      )
                    }

                    {
                      'red_up_arrow' === row.icon && (
                        <ArrowUpwardIcon style={ { color: 'red' } } />
                      )
                    }

                  </div>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

const BioExplanation = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const bioAgeData = useBioAgeData()?.[0]?.value?.demoContent?.__values;

  const { userAttributes } = useAuth();

  if (!userAttributes) {
    return null;
  }

  let date = bioAgeData._DATE_OF_BLOOD_EXAMINATION;
  if ('US' === userAttributes.country) {
    date = date.split('/');
    date = `${ date[1] }/${ date[0] }${ date[2] }`;
  }

  return (
    <div style={ { marginTop: '20px' } }>
      <Card>

        <div style={ { display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center' } }>
          <Typography variant='h5' className={ classes.bigText } style={ { marginBottom: '45px' } }>
            {t('Επεξήγηση συμβόλων')}
          </Typography>

          <div style={ { display: 'flex', gap: '16px', flexDirection: 'column' } }>
            {explanationTypes.map((row, id) => {
              return (
                <div
                  // eslint-disable-next-line react/no-array-index-key
                  key={ id }
                  style={ { background: '#F1F3F3',
                    padding: '10px 28px',
                    display: 'flex',
                    borderRadius: '15px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    gap: '50px' } }

                >
                  <Typography>
                    {t(row.description)}
                  </Typography>

                  <div
                    className={ classes.explanationIconRow }
                    style={ { background: row.color } }
                  >
                    <Avatar style={ { background: 'white', marginRight: '8.5px' } }>
                      {
                        'green_tick' === row.iconType && (
                          <DoneIcon style={ { color: row.color } } />
                        )
                      }
                      {
                        'orange_up_arrow' === row.iconType && (
                          <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(45deg)' } } />
                        )
                      }

                      {
                        'orange_down_arrow' === row.iconType && (
                          <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(145deg)' } } />
                        )
                      }

                      {
                        'red_down_arrow' === row.iconType && (
                          <ArrowUpwardIcon style={ { color: row.color, transform: 'rotate(180deg)' } } />
                        )
                      }

                      {
                        'red_up_arrow' === row.iconType && (
                          <ArrowUpwardIcon style={ { color: row.color } } />
                        )
                      }

                    </Avatar>

                    <Typography style={ { fontWeight: '500', color: 'white' } }>
                      {t(row.recomendation)}
                    </Typography>
                  </div>

                </div>
              );
            })}
          </div>
        </div>

      </Card>

      <Card style={ { marginTop: '20px' } }>
        <Typography
          style={ { marginTop: '5px', fontStyle: 'italic', padding: '10px' } }
          variant='body1'
        >
          {t('Η βιολογική σου ηλικία υπολογίστηκε βάσει των αιματολογικών σου εξετάσεων που πραγματοποιήθηκαν στις x/x/xx και τα αποτελέσματα ελέγχθηκαν από την εξειδικευμένη επιστημονική ομάδα μας.', { date })}
        </Typography>
      </Card>

    </div>
  );
};

const BioAgeAnalysis = () => {
  const bioAgeData = useBioAgeData()?.[0]?.value?.demoContent?.__values;
  const { serial } = useOptionSerial('bioAge');
  const { locale } = useBioAgeDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const { isMdDown } = useMediaQueries();

  const [ isMobileExplanationOpen, setIsMobileExplanationOpen ] = useState(false);

  if (null == bioAgeData || null == serial || '' === serial) {
    return <LoaderSkeleton />;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      {
        isMobileExplanationOpen && (
          <BioAgeExplanationMobile
            open={ isMobileExplanationOpen }
            handleClose={ () => { setIsMobileExplanationOpen(false); } }
          />
        )
      }
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        <Tab tab={ bioAgeTab }>

          <IconButton
            className='tw-m-0 tw-mr-2 tw-ml-auto tw-p-0 lg:tw-hidden'
            onClick={ (e) => {
              e.stopPropagation();
              setIsMobileExplanationOpen(true);
            } }
          >
            <InfoIcon className='tw-text-white' />
          </IconButton>
        </Tab>

        <PrevNextCardio
          options={ BioAgeCards }
          activePage='Ανάλυση'
          hasIcons
        />

        {
          isMdDown ? (
            <BioAgeAnalysisMobile testTypes={ bioAgeData._BIO_AGE_ARROW_TABLE } />
          ) : (
            <>

              <BioTable testTypes={ bioAgeData._BIO_AGE_ARROW_TABLE } />

              <BioExplanation />
            </>
          )
        }

      </div>
    </I18nextProvider>
  );
};

export default BioAgeAnalysis;
