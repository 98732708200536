import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Container } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import SimpleInputField from '../atoms/SimpleInputField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormSection from '../atoms/FormSection';
import FormNames from '../atoms/form-names';
import { useKit } from '../../../models/kits';
import { InvitationReason, useKitInvitation } from '../../../models/invitations';

const HealthcareProfessionalDetailsForm = (
  {
    kitId,
    onSubmit,
    onError,
  },
) => {
  const { t } = useTranslation();

  const { isLoading, kitHealthcareProfessionalInvitations } = useKit(kitId);
  const { createInvitation } = useKitInvitation({ kitId, reasonOfInvitation: InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS });

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { userAttributes } = useAuth();
  const { country } = userAttributes;

  const {
    requiredFieldRule,
    phoneNumberRule,
    mobilePhoneNumberRule,
    emailRule,
  } = useFormRules();

  const [
    errorFirstNameHP,
    helperTextFirstNameHP,
    validateFirstNameHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastNameHP,
    helperTextLastNameHP,
    validateLastNameHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorProfessionHP,
    helperTextProfessionHP,
    validateProfessionHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorEmailHP,
    helperTextEmailHP,
    validateEmailHP,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorMobileNumberHP,
    helperTextMobileNumberHP,
    validateMobileNumberHP,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      mobilePhoneNumberRule,
    ],
  );

  const [
    errorBusinessNumberHP,
    helperTextBusinessNumberHP,
    validateBusinessNumberHP,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    const {
      [`${ FormNames.firstName }HP`]: firstNameHP,
      [`${ FormNames.lastName }HP`]: lastNameHP,
      [`${ FormNames.profession }HP`]: professionHP,
      [`${ FormNames.email }HP`]: emailHP,
      [`${ FormNames.mobileNumber }HP`]: mobileNumberHP,
      [`${ FormNames.countryMobileNumber }HP`]: countryMobileNumberHP,
      [`${ FormNames.businessNumber }HP`]: businessNumberHP,
      [`${ FormNames.countryBusinessNumber }HP`]: countryBusinessNumberHP,
    } = Object.fromEntries(formData);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstNameHP(firstNameHP); },
      () => { return validateLastNameHP(lastNameHP); },
      () => { return validateProfessionHP(professionHP); },
      () => { return validateEmailHP(emailHP); },
      () => {
        return validateMobileNumberHP(
          {
            phoneNumber: mobileNumberHP,
            country: countryMobileNumberHP,
          },
        );
      },
      () => {
        return validateBusinessNumberHP(
          {
            phoneNumber: businessNumberHP,
            country: countryBusinessNumberHP,
          },
        );
      },
    ].every((f) => { return true === f(); })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return createInvitation({
          thirdPartyEmail: emailHP,
          metadata: {
            firstName: firstNameHP,
            lastName: lastNameHP,
            specialty: professionHP,
            email: emailHP,
            mobileNumber: mobileNumberHP,
            businessNumber: businessNumberHP,
          },
        });
      })
      .then((d) => {
        return onSubmit(d);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  if (kitHealthcareProfessionalInvitations && kitHealthcareProfessionalInvitations.length) {
    const {
      [FormNames.firstName]: firstNameHP,
      [FormNames.lastName]: lastNameHP,
      [FormNames.profession]: professionHP,
      [FormNames.brandName]: brandNameHP,
      [FormNames.email]: emailHP,
      [FormNames.mobileNumber]: mobileNumberHP,
      [FormNames.businessNumber]: businessNumberHP,
    } = kitHealthcareProfessionalInvitations[0].metadata;

    return (
      <Grid>
        <Container>
          <Alert severity='success'>
            {t('alreadyInvitedHealthcareProfessional')}
          </Alert>
        </Container>
        <form
          noValidate
          onSubmit={ (e) => {
            e.preventDefault();
            return onSubmit();
          } }
          style={ {
            padding: '24px',
          } }
        >
          <FormSection label={ t('healthcareProfessionalDetails') }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Grid container spacing={ 2 }>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('firstName') }
                      defaultValue={ firstNameHP }
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('lastName') }
                      defaultValue={ lastNameHP }
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 6 }>
                <SimpleInputField
                  label={ t('brandName') }
                  defaultValue={ brandNameHP }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 6 }>
                <SimpleInputField
                  label={ t('profession') }
                  defaultValue={ professionHP }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 }>
                <SimpleInputField
                  label={ t('email') }
                  defaultValue={ emailHP }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  label={ t('mobileNumber') }
                  defaultValue={ mobileNumberHP }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  label={ t('businessNumber') }
                  defaultValue={ businessNumberHP }
                  readOnly
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSectionSubmit
            label={ t('next') }
          />
        </form>
      </Grid>
    );
  }

  return (
    <div>
      <form
        noValidate
        onSubmit={ handleSubmit }
        style={ {
          padding: '24px',
        } }
      >
        <FormSection label={ t('healthcareProfessionalDetails') }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('firstName') }
                    lettersOnly
                    autoComplete='healthcare-professional-name'
                    name={ `${ FormNames.firstName }HP` }
                    required
                    error={ errorFirstNameHP }
                    helperText={ helperTextFirstNameHP }
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('lastName') }
                    lettersOnly
                    autoComplete='healthcare-professional-family-name'
                    name={ `${ FormNames.lastName }HP` }
                    required
                    error={ errorLastNameHP }
                    helperText={ helperTextLastNameHP }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('profession') }
                name={ `${ FormNames.profession }HP` }
                required
                autoComplete='healthcare-professional-profession'
                type='text'
                error={ errorProfessionHP }
                helperText={ helperTextProfessionHP }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('email') }
                name={ `${ FormNames.email }HP` }
                required
                autoComplete='healthcare-professional-email'
                type='email'
                error={ errorEmailHP }
                helperText={ helperTextEmailHP }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ `${ FormNames.mobileNumber }HP` }
                label={ t('mobileNumber') }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ `${ FormNames.countryMobileNumber }HP` }
                required
                error={ errorMobileNumberHP }
                helperText={ helperTextMobileNumberHP }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ `${ FormNames.businessNumber }HP` }
                label={ t('businessNumber') }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ `${ FormNames.countryBusinessNumber }HP` }
                error={ errorBusinessNumberHP }
                helperText={ helperTextBusinessNumberHP }
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSectionSubmit
          label={ t('save') }
          loading={ isSubmitting }
        />
      </form>
      <form
        noValidate
        onSubmit={ (e) => {
          e.preventDefault();
          return onSubmit(null, 'skip-step');
        } }
      >
        <FormSectionSubmit
          label={ t('skipStep') }
          disabled={ false }
        />
      </form>
    </div>
  );
};

HealthcareProfessionalDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

HealthcareProfessionalDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default HealthcareProfessionalDetailsForm;
