import React, { forwardRef, useEffect } from 'react';
import Slide from '@material-ui/core/Slide';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useMFA } from '../../../AuthCtx';
import ConfirmationCodeVerification from '../../forms/ConfirmationCodeVerification';
import useAction from '../../../hooks/action-hooks';

const EnableMFA = () => {
  const { setupMFA, enableMFA, totpUri: uri, qrCode: image } = useMFA();

  const { executeAsync, loading, error } = useAction(setupMFA);

  useEffect(() => {
    executeAsync();
  }, []);

  const sm = useMediaQuery((theme) => {
    return theme.breakpoints.down('sm');
  });

  const verify = (code) => {
    return enableMFA({ code });
  };

  return (
    <Container style={ { marginTop: '24px' } }>
      <Typography component='div' variant='h5' color='secondary'>
        Enable MFA
      </Typography>
      <Typography component='div' variant='body1' color='textPrimary'>
        {'Scan the QR Code with your mobile\'s camera and then enter the 6-digit-code that is displayed'}
      </Typography>

      {error ? <p>Something went wrong generating the QR Code. Please try again.</p>
        : (
          <Grid container direction='column' item alignItems='center' justifyContent='center'>
            <Grid
              container
              item
              alignItems='center'
              justifyContent='center'
              style={ { height: '180px' } }
            >
              <>
                {loading ? <CircularProgress /> : (
                  <>
                    {image && <img height='100%' width='auto' alt='qr-code' src={ image } />}
                    {sm && (
                      <div>
                        or click
                        {' '}
                        <a href={ uri }>here</a>
                      </div>
                    )}
                  </>
                )}
              </>
            </Grid>

          </Grid>
        )}

      {!error && !loading && <ConfirmationCodeVerification onVerify={ verify } />}
    </Container>
  );
};

// eslint-disable-next-line react/display-name
export const Transition = forwardRef((props, ref) => {
  return <Slide direction='up' ref={ ref } { ...props } />;
});

const DialogEnableMFA = (
  {
    open,
    onClose,
  },
) => {
  return (
    <Dialog
      open={ open }
      onClose={ onClose }
      TransitionComponent={ Transition }
      disableEnforceFocus
      keepMounted={ false }
      maxWidth='xs'
    >
      <EnableMFA />
    </Dialog>
  );
};

export default DialogEnableMFA;
