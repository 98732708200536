import React, { useMemo } from 'react';
import { Grid, Typography } from '@material-ui/core';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useCardioDataReport } from 'models/kits';
import { useOptionSerial } from 'NavigationProvider';
import { Title, PrevNextCardio } from './Common';
import { CardioHealthCards } from './iDNACardioHealth';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const ImportantInformationCardioContent = () => {
  const results = CardioHealthCards.filter((card) => {
    return 'cardioHealth' === card.id;
  })[0];

  const { t } = useTranslation();

  return (
    <div>
      <Title />
      <PrevNextCardio options={ results } activePage='Σημαντικές Πληροφορίες' />
      <Grid container style={ { background: 'white', borderRadius: '15px', padding: '10px' } }>
        <div>
          <ul>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important1')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important2')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important3')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important4')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important5')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important6')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important7')}
              </Typography>
            </li>
            <li style={ { paddingLeft: '10px' } }>
              <Typography>
                {t('important8')}
              </Typography>
            </li>
          </ul>
        </div>
      </Grid>
      <Grid
        container
        style={ { background: 'white', borderRadius: '15px', marginTop: '20px', padding: '30px' } }
      >
        <Typography variant='h6' style={ { fontWeight: 'bold', marginBottom: '10px' } }>
          {t('Σημείωση Νομικού Περιεχομένου')}
        </Typography>
        <Typography>
          {t('importantLong')}
        </Typography>
      </Grid>
    </div>
  );
};

const ImportantInformationCardio = () => {
  const { serial: newSerial } = useOptionSerial('IDNA Cardio Health');
  const { locale } = useCardioDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null === locale) {
    return null;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <ImportantInformationCardioContent />
    </I18nextProvider>
  );
};

export default ImportantInformationCardio;
