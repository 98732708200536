import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import React from 'react';
import ListItemText from '@material-ui/core/ListItemText';
import { makeStyles, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import clsx from 'clsx';
import IconButton from '@material-ui/core/IconButton';
import CheckIcon from '@material-ui/icons/Check';
import ClearIcon from '@material-ui/icons/Clear';
import { useQueryClient } from 'react-query';
import Grid from '@material-ui/core/Grid';
import { useDatetimeFormat } from '../../utils';
import { useItems } from '../../models/atoms/items-hook';
import CollectionKeys from '../../models/atoms/collection-keys';
import { useDialog } from '../../hooks/confirmation-dialog-hooks';
import useAPI from '../../hooks/api-hooks';
import { InvitationReason } from '../../models/invitations';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
  };
});

export default function DoctorPendingInvitations() {
  const classes = useStyles();
  const theme = useTheme();

  const { t } = useTranslation();

  const dateFormat = useDatetimeFormat();

  const { openDialog } = useDialog();

  const queryClient = useQueryClient();
  const { client: api } = useAPI();

  const openInvitationDialog = (invitation, accept) => {
    openDialog({
      title: accept ? t('accept') : t('reject'),
      body() {
        if (accept) {
          return <p>{t('acceptInvitationPrompt')}</p>;
        }
        return <p>{t('rejectInvitationPrompt')}</p>;
      },
      onSubmit() {
        return api.put(`invitations/${ invitation.id }`, {
          status: accept ? 'accept' : 'reject',
        }).then(() => {
          return Promise.all([
            [ CollectionKeys.Invitations ],
            [ CollectionKeys.CustomerDoctor ],
            [ CollectionKeys.CustomerDoctorKits ],
          ].map((collectionKey) => {
            return queryClient.invalidateQueries(collectionKey);
          }));
        });
      },
    });
  };

  const {
    items: pendingInvitations,
    itemsLoading: invitationsLoading,
    itemsError: invitationsError,
  } = useItems(CollectionKeys.Invitations, { defaultPagination: { limit: -1 },
    filterQuery: { status_in: [ 'pending' ], reason_in: [ InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS ] } });

  if (invitationsLoading) {
    return null;
  }

  if (invitationsError) {
    return <p>Failed to load consents</p>;
  }

  if (!pendingInvitations || !pendingInvitations.length) {
    return null;
  }

  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <Alert severity='info'>
          <AlertTitle>
            {t('pending-invitations-title')}
          </AlertTitle>
          {t('pending-invitations-description')}
        </Alert>
      </Grid>
      <Grid item xs={ 12 }>
        <List className={ classes.list }>
          {
            pendingInvitations.map((invitation, i) => {
              return (
                <ListItem
                  key={ invitation.id }
                  className={
                    clsx(classes.listItem, i % 2 ? classes.listItemEven : classes.listItemOdd)
                  }
                >
                  <ListItemText
                    primary={ `${ t('Ο χρήστης με serial') } ${ invitation?.registration_kit?.serial_number_id?.serial || '-' } ${ t('και όνομα') } ${ invitation.source.family_name } ${ invitation.source.name } ${ t('σας προσκαλεί για να τον παρακολουθήσετε') }` }
                    secondary={ `${ t('createdAt') } ${ dateFormat(invitation.created_at) }` }
                  />
                  <IconButton
                    style={ { color: theme.palette.success.main } }
                    component='span'
                    onClick={ () => { return openInvitationDialog(invitation, true); } }
                  >
                    <CheckIcon />
                  </IconButton>
                  <IconButton
                    style={ { color: theme.palette.error.main } }
                    component='span'
                    onClick={ () => { return openInvitationDialog(invitation, false); } }
                  >
                    <ClearIcon />
                  </IconButton>
                </ListItem>
              );
            })
          }
        </List>
      </Grid>
    </Grid>
  );
}
