import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import Badge from '@material-ui/core/Badge';
import * as _ from 'lodash';
import useMediaQueries from '../../../hooks/media-query-hooks';
import { useNavigation } from '../../../NavigationProvider';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      display: 'inline-flex',
      overflow: 'auto',
      flexWrap: 'nowrap',
      width: '100%',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
        overflow: 'hidden',
        flexDirection: 'column',
        marginRight: theme.spacing(-3),
        marginLeft: theme.spacing(-3),
      },
    },
    listItem: {
      marginRight: theme.spacing(4),
      flex: 0,
      [theme.breakpoints.up('md')]: {
        marginRight: theme.spacing(2),
        paddingRight: theme.spacing(3),
        paddingLeft: theme.spacing(3),
        // borderLeft: '3px solid transparent',
      },
    },
    listItemActive: {
      [theme.breakpoints.up('md')]: {
        // borderLeft: `3px solid ${ theme.palette.secondary.dark }`,
      },
      [theme.breakpoints.down('sm')]: {
        borderTop: `3px solid ${ theme.palette.secondary.dark }`,
      },
      '& .menu__item': {
        color: theme.palette.secondary.dark,
      },
    },
  };
});

const Menu = (
  {
    pages,
  },
) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMd } = useMediaQueries();
  const { activePageId: pageId, navigateToPage } = useNavigation();
  return (
    <List disablePadding className={ classes.list }>
      {pages.filter((item) => {
        const {
          isMenuHidden,
        } = { ...{ isMenuHidden: false }, ...item };
        return !isMenuHidden;
      }).map((item) => {
        const href = `/?pid=${ item.id }`;
        const {
          Icon,
          badgeProps: { count: badgeCount },
        } = { ...{ isMenuHidden: false, badgeProps: { count: undefined } }, ...item };

        return (
          <div key={ item.id }>
            <ListItem
              key={ item.id }
              component='a'
              href={ href }
              style={ { width: '100%' } }
              onClick={ (e) => {
                e.preventDefault();
                navigateToPage({ pageId: item.id });
              } }
              className={ clsx(
                classes.listItem,
                pageId === item.id || item.alternativeIds?.includes(pageId) ? classes.listItemActive : {},
              ) }
              disableGutters
            >
              <Badge badgeContent={ badgeCount } color='error'>
                <Icon
                  color={ pageId === item.id || item.alternativeIds?.includes(pageId) ? '#526D9C' : theme.palette.text.primary }
                  size='18px'
                  style={ { minWidth: '18px' } }
                />
              </Badge>

              <Typography
                variant='subtitle1'
                color='textSecondary'
                className='menu__item'
                noWrap={ !isMd }
                style={ { marginLeft: isMd ? theme.spacing(3) : theme.spacing(1) } }
              >
                {t(item.title)}
              </Typography>
            </ListItem>

            {
              'folder' === item.type && null != item.items && (null == item.isMenuHidden || false === item.isMenuHidden) && (
                <div style={ { marginLeft: '50px' } }>
                  <Menu pages={ item.items } />
                </div>
              )
            }
          </div>
        );
      })}
    </List>
  );
};

export default Menu;
