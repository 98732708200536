import React from 'react';
import { useNutritionData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { yellowColor } from './Nutrition';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';

export const profileToIcon = {
  sensitivityToCoffee: { big: '/KAFEINI.jpg', small: '/KAFEINI_SMALL.jpg' },
  sensitivityToAlcohol: { big: '/ALKOOL.jpg', small: '/ALKOOL_SMALL.jpg' },
  sensitivityToLactose: { big: '/LACTOZI.jpg', small: '/LACTOZI_SMALL.jpg' },
  sensitivityToGluten: { big: '/GLOUTENI.jpg', small: '/GLOUTENI_SMALL.jpg' },
};

export const profileToUrl = {
  sensitivityToCoffee: 'nutritionSensitivityToCoffee',
  sensitivityToAlcohol: 'nutritionSensitivityToAlcohol',
  sensitivityToLactose: 'nutritionSensitivityToLactose',
  sensitivityToGluten: 'nutritionSensitivityToGluten',
};

const NFoodSensitivities = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useNutritionData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Τροφικές Ευαισθησίες' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT14' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToCoffee;
      url = profileToUrl.sensitivityToCoffee;
    } else if ('NCAT16' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToAlcohol;
      url = profileToUrl.sensitivityToAlcohol;
    } else if ('NCAT17' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToLactose;
      url = profileToUrl.sensitivityToLactose;
    } else if ('NCAT18' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToGluten;
      url = profileToUrl.sensitivityToGluten;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: yellowColor,
    id: 'nutritionalGuide',
    title: 'Διατροφή',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext options={ dietCard } activePage='nutritionFoodSensitivities' isNutrition />
      <PhotoWithText
        options={ dietCard }
        activePage='foodSensitivities'
        text={ data[0].value.sensitivities_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
    </div>
  );
};

export default NFoodSensitivities;

