/* eslint-disable prefer-const */
import React, { useState } from 'react';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import Alert from '@material-ui/lab/Alert';
import { Container } from '@material-ui/core';
import { useAuth } from 'AuthCtx';
import SimpleInputField from '../atoms/SimpleInputField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormSection from '../atoms/FormSection';
import FormNames from '../atoms/form-names';
import { useKit } from '../../../models/kits';
import { InvitationReason, useKitInvitation } from '../../../models/invitations';
import DoctorSearchForm from '../common-forms/DoctorSearchForm';
import SpecialtySelectField from '../atoms/SpecialtySelectField';

const DoctorDetailsFormReferral = (
  {
    kitId,
    onSubmit,
    onError,
    goToPrevPage,
  },
) => {
  const { t } = useTranslation();

  const { isLoading, kitDoctorInvitations, isKitPGX, kitMetadata } = useKit(kitId);
  const { createInvitation } = useKitInvitation({ kitId, reasonOfInvitation: InvitationReason.HEALTHCARE_PROFESSIONAL_SHARE_RESULTS });
  const { createInvitation: createConsentInvitation } = useKitInvitation({ kitId, reasonOfInvitation: InvitationReason.PGX_DOCTOR_CONSENT });

  const [ isSubmitting, setSubmitting ] = useState(false);
  const [ doctorData, setDoctorData ] = useState(null);

  const { userAttributes } = useAuth();
  const { country } = userAttributes;

  const [ hasSelectedDoctor, setHasSelectedDoctor ] = useState(null);

  const handleDoctorData = (data) => {
    setHasSelectedDoctor(null !== data);
    setDoctorData(data);
  };

  const {
    requiredFieldRule,
    phoneNumberRule,
    mobilePhoneNumberRule,
    emailRule,
  } = useFormRules();

  const [
    // errorFirstName,
    // helperTextFirstName,
    _1,
    _2,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorSpecialty,
    helperTextSpecialty,
    validateSpecialty,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      mobilePhoneNumberRule,
    ],
  );

  const [
    errorBusinessNumber,
    helperTextBusinessNumber,
    validateBusinessNumber,
  ] = useInputValidation(
    [
      (v) => { return requiredFieldRule(v?.phoneNumber); },
      (v) => { return requiredFieldRule(v?.country); },
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();

    if (false === hasSelectedDoctor || null === hasSelectedDoctor) {
      setSubmitting(false);
      setHasSelectedDoctor(false);
      return onError();
    }

    const { name: firstName, family_name: lastName, email, specialty,
      phone_number: mobileNumber, business_number: businessNumber, brand_name: brandName } = doctorData;

    return Promise.resolve()
      .then(() => {
        if (null != kitMetadata?.referenceNumber && 0 < kitMetadata?.referenceNumber.length) {
          return createInvitation({
            thirdPartyEmail: email,
            metadata: {
              firstName,
              lastName,
              email,
              specialty,
              mobileNumber,
              businessNumber,
              brand_name: brandName,
            },
          });
        }

        return createConsentInvitation({
          thirdPartyEmail: email,
          metadata: {
            firstName,
            lastName,
            email,
            specialty,
            mobileNumber,
            businessNumber,
            brand_name: brandName,
          },
        });
      })
      .then((d) => {
        return onSubmit(d);
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  if (isLoading) {
    return <p>Loading</p>;
  }

  if (kitDoctorInvitations && kitDoctorInvitations.length) {
    const {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.email]: email,
      [FormNames.specialty]: specialty,
      brand_name: brandName,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.businessNumber]: businessNumber,
    } = kitDoctorInvitations[0].metadata;

    return (
      <Grid>
        <Container>
          <Alert
            severity='success'
            style={ { textAlign: 'initial' } }
          >
            {t('reg_kit.doc_personal_data.common.already_invited')}
          </Alert>
        </Container>
        <form
          noValidate
          onSubmit={ (e) => {
            e.preventDefault();
            return onSubmit();
          } }
          style={ {
            padding: '24px',
          } }
        >

          <FormSection label={ isKitPGX ? t('common.therapist_info') : t('common.doctor_info') }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 }>
                <Grid container spacing={ 2 }>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('common.first_name') }
                      defaultValue={ firstName }
                      readOnly
                    />
                  </Grid>
                  <Grid item xs={ 12 } md={ 6 }>
                    <SimpleInputField
                      label={ t('common.last_name') }
                      defaultValue={ lastName }
                      readOnly
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={ 12 }>
                <SimpleInputField
                  label={ t('common.email') }
                  defaultValue={ email }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } sm={ 6 }>
                <SimpleInputField
                  label={ t('common.company_name') }
                  defaultValue={ brandName }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } sm={ 6 }>
                <SimpleInputField
                  label={ t('common.specialty') }
                  defaultValue={ specialty }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  label={ t('common.mobile_number') }
                  defaultValue={ mobileNumber }
                  readOnly
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimplePhoneField
                  label={ t('common.business_number') }
                  defaultValue={ businessNumber }
                  readOnly
                />
              </Grid>
            </Grid>
          </FormSection>
          <FormSectionSubmit
            goToPrevPage={ goToPrevPage }
            label={ t('common.next') }
          />
        </form>
      </Grid>
    );
  }

  return (
    <>
      <DoctorSearchForm
        title={ t('common.doctor_search') }
        func={ handleDoctorData }
        kitId={ kitId }
        country={ country }
        pgxError={ false === hasSelectedDoctor }
      />
      <form
        noValidate
        onSubmit={ handleSubmit }
        style={ {
          padding: '24px',
        } }
      >
        <FormSection
          label={ isKitPGX ? t('common.therapist_info') : t('common.doctor_info') }
          style={ { display: true !== hasSelectedDoctor ? 'none' : '', marginBottom: '0px' } }
        >
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 }>
              <Grid container spacing={ 2 }>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('common.first_name') }
                    lettersOnly
                    autoComplete='doctor-name'
                    name={ FormNames.firstName }
                    disabled
                    validate={ validateFirstName }
                    defaultValue={ doctorData?.name }
                    required
                  />
                </Grid>
                <Grid item xs={ 12 } md={ 6 }>
                  <SimpleInputField
                    label={ t('common.last_name') }
                    autoComplete='doctor-family-name'
                    name={ FormNames.lastName }
                    validate={ validateLastName }
                    lettersOnly
                    defaultValue={ doctorData?.family_name }
                    disabled
                    required
                    error={ errorLastName }
                    helperText={ helperTextLastName }
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <SimpleInputField
                label={ t('common.company_name') }
                autoComplete='brand-name'
                disabled
                name={ FormNames.brandName }
                defaultValue={ doctorData?.brand_name }
              />
            </Grid>
            <Grid item xs={ 12 } sm={ 6 }>
              <SpecialtySelectField
                label={ t('common.specialty') }
                name={ FormNames.specialty }
                validate={ validateSpecialty }
                required
                defaultValue={ doctorData?.specialty }
                disabled
                isKitPGX={ isKitPGX }
                autoComplete='specialty'
                error={ errorSpecialty }
                helperText={ helperTextSpecialty }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <SimpleInputField
                label={ t('common.email') }
                name={ FormNames.email }
                required
                autoComplete='doctor-email'
                defaultValue={ doctorData?.email }
                validate={ validateEmail }
                disabled
                type='email'
                error={ errorEmail }
                helperText={ helperTextEmail }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ FormNames.businessNumber }
                label={ t('businessNumber') }
                required
                validate={ validateBusinessNumber }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ FormNames.countryBusinessNumber }
                disabled
                defaultValue={ doctorData?.business_number || '' }
                error={ errorBusinessNumber }
                helperText={ helperTextBusinessNumber }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <SimplePhoneField
                name={ FormNames.mobileNumber }
                label={ t('common.mobile_number') }
                disabled
                validate={ validateMobileNumber }
                defaultCountryValue={ country ?? 'GR' }
                countrySelectName={ FormNames.countryMobileNumber }
                defaultValue={ doctorData?.phone_number || '' }
                error={ errorMobileNumber }
                helperText={ helperTextMobileNumber }
              />
            </Grid>
          </Grid>
        </FormSection>
        <FormSectionSubmit
          style={ { marginTop: true !== hasSelectedDoctor ? '0' : '32px' } }
          label={ t('common.save') }
          loading={ isSubmitting }
          goToPrevPage={ goToPrevPage }
        />
      </form>
    </>
  );
};

const DoctorDetailsForm = (props) => {
  return (
    <DoctorDetailsFormReferral { ...props } />
  );
};

DoctorDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

DoctorDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default DoctorDetailsForm;
