import React, { useState } from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { useTranslation } from 'react-i18next';
import { useItems } from '../../../models/atoms/items-hook';
import CollectionKeys from '../../../models/atoms/collection-keys';
import AppLoading from '../../../components/app/AppLoading';
import HealthcareProfessionalDetailsForm from './HealthcareProfessionalDetailsForm';

const Invitations = () => {
  const [ activeKitId, setActiveKitId ] = useState(null);
  const { t } = useTranslation();

  const { items: kits, itemsLoading } = useItems(CollectionKeys.Kits,
    { defaultPagination: { limit: -1 },
      filterQuery: {
        registrationStatus_nin: [ 'sample_pending' ],
        _where: {
          _or: [
            [{ 'thematic_package.thematic_category_id.name_ne': 'PGx' }],
            [{ 'thematic_package.thematic_category_id.name_eq': 'PGx' },
              { dynamic_report_available_eq: true,
                pdf_available: true },
            ],
          ],
        },
      } });

  if (itemsLoading) {
    return <AppLoading />;
  }

  return (
    <Grid container>
      <Grid item xs={ 12 }>
        <Typography variant='body2' color='textPrimary'>
          {t('Select a registered kit for which you would like to send an invitation to your doctor')}
        </Typography>
        <br />
        <Select
          style={ { minWidth: '200px' } }
          value={ activeKitId }
          onChange={ (e) => {
            return setActiveKitId(e.target.value);
          } }
          name='kit'
        >
          <MenuItem disabled value={ null }>
            {t('selectAKit')}
          </MenuItem>
          {
            kits.map((kit) => {
              return (
                <MenuItem key={ kit.id } value={ kit.id }>
                  {kit.serial_number_id.serial}
                </MenuItem>
              );
            })
          }
        </Select>
      </Grid>
      {
        !!activeKitId && (
          <HealthcareProfessionalDetailsForm kitId={ activeKitId } notInvitations={ false } />
        )
      }
    </Grid>
  );
};

export default Invitations;
