import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NavigationProvider } from 'NavigationProvider';
import { AppHeader } from '../../components/layout';

const useStyles = makeStyles((theme) => {
  return {
    flexWrapper: {
      display: 'flex',
      minHeight: `calc(100vh - ${ theme.layout.topBarHeight }px)`,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    aux: {
      display: 'flex',
      flexGrow: 1,
    },
  };
});

const BasicLayout = (props) => {
  const classes = useStyles();
  return (

    <div style={ { background: '#F1F3F3' } }>
      <NavigationProvider
        pageKey='pid'
        fallbackPageId='general'
      >
        <AppHeader />
      </NavigationProvider>
      <div className={ classes.flexWrapper }>
        {
          props.children
        }
        <div className={ classes.aux } />
      </div>
    </div>
  );
};

export default BasicLayout;
