import { useMediaQuery, useTheme } from '@material-ui/core';

const useMediaQueries = () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const isSm = useMediaQuery(theme.breakpoints.down('sm'), {
    defaultMatches: true,
  });

  const isXs = useMediaQuery(theme.breakpoints.only('xs'), {
    defaultMatches: true,
  });

  const isOnlySm = useMediaQuery(theme.breakpoints.only('sm'), {
    defaultMatches: true,
  });

  const isMdDown = useMediaQuery(theme.breakpoints.down('md'), {
    defaultMatches: true,
  });

  return {
    isMd,
    isSm,
    isMdDown,
    isOnlySm,
    isXs,
  };
};

export default useMediaQueries;
