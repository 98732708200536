import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { makeStyles } from '@material-ui/core/styles';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { Grid, Icon, Typography, Slider, withStyles, Avatar } from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useMediaQueries from 'hooks/media-query-hooks';
import { useAuth } from 'AuthCtx';
import { useNavigation } from '../../NavigationProvider';

const tabs = [
  {
    title: 'diet',
    id: 'diet',
    color: '#F5C91E',
  },
  {
    id: 'vitamins',
    title: 'vitaminsTraceElements',
    color: '#93C221',
  },
  {
    id: 'sports',
    title: 'sports',
    color: '#31B5E1',
  },
];

export const MorningBox = () => {
  const { t } = useTranslation();

  return (
    <div style={ {
      width: '100%',
      borderRadius: '29px',
      background: '#F39566',
      boxShadow: '0px 8px 20px 0px rgba(243, 149, 102, 0.19)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 4px 4px 24px',
    } }
    >
      <Typography style={ {
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '0.42px',
      } }
      >
        {t('Πρωινός τύπος')}
      </Typography>

      <Avatar
        style={ { background: 'white',
          width: '44px',
          height: '44px' } }
      >
        <img
          src='/icon_day.svg'
          alt='day icon'
        />
      </Avatar>
    </div>
  );
};

export const NightBox = () => {
  const { t } = useTranslation();

  return (
    <div style={ {
      width: '100%',
      borderRadius: '29px',
      background: '#225B83',
      boxShadow: '0px 8px 20px 0px rgba(34, 91, 131, 0.19)',
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: '4px 4px 4px 24px',
    } }
    >
      <Typography style={ {
        color: '#FFF',
        fontFamily: 'Roboto',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        letterSpacing: '0.42px',
      } }
      >
        {t('Νυχτερινός τύπος')}
      </Typography>

      <Avatar
        style={ { background: 'white',
          width: '44px',
          height: '44px' } }
      >
        <img
          src='/icon_night.svg'
          alt='day icon'
        />
      </Avatar>
    </div>
  );
};

const PrettoSlider = withStyles({
  root: {
    height: 17,
    cursor: 'initial',
  },
  thumb: {
    height: 35,
    width: 35,
    backgroundColor: '#fff',
    marginTop: -10,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 12,
    borderRadius: 2,
    opacity: 0,
  },
  rail: {
    height: 12,
    borderRadius: '26px',
    backgroundImage: 'linear-gradient(80deg, rgba(255,18,0,1) 2%, rgba(255,209,0,1) 39%, rgba(152,206,93,1) 74%, rgba(106,58,142,1) 100%)',
    opacity: 1,
  },
  markLabel: {
    fontSize: '14px',
    marginTop: '10px',
    fontWeight: 600,
    paddingLeft: '10px',
  },
})(Slider);

const PrettoSliderSports = withStyles({
  root: {
    height: 17,
    cursor: 'initial',
  },
  thumb: {
    height: 35,
    width: 35,
    backgroundColor: '#fff',
    marginTop: -10,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 12,
    borderRadius: 2,
    opacity: 0,
  },
  rail: {
    height: 12,
    borderRadius: '26px',
    backgroundImage: 'linear-gradient(90deg, rgba(252,192,10,1) 5%, rgba(253,253,2,1) 11%, rgba(116,221,0,1) 28%, rgba(18,101,158,1) 48%, rgba(120,0,190,1) 69%, rgba(95,95,95,1) 85%)',
    opacity: 1,
  },
  markLabel: {
    fontSize: '14px',
    marginTop: '10px',
    fontWeight: 600,
    paddingLeft: '10px',
  },
})(Slider);

const useStyles = makeStyles((theme) => {
  return {
    paper: { minWidth: '800px' },
    sliderThumbHigh: {
      '& .MuiSlider-thumb': {
        border: '7px solid #FF1200',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#FF1200',

        paddingLeft: '17px',
      },
    },
    sliderThumbIncreased: {
      '& .MuiSlider-thumb': {
        border: '7px solid #FF9700',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#FF9700',
      },
    },
    sliderThumbTypical: {
      '& .MuiSlider-thumb': {
        border: '7px solid #98CE5D',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#98CE5D',
      },
    },
    sliderThumbReduced: {
      '& .MuiSlider-thumb': {
        border: '7px solid #783FA1',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#783FA1',
      },
    },

    sliderThumbHighSports: {
      '& .MuiSlider-thumb': {
        border: '7px solid #7800be',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#7800be',

        paddingLeft: '17px',
      },
    },
    sliderThumbIncreasedSports: {
      '& .MuiSlider-thumb': {
        border: '7px solid #12659e',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#12659e',
      },
    },
    sliderThumbTypicalSports: {
      '& .MuiSlider-thumb': {
        border: '7px solid #74dd00',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#74dd00',
      },
    },
    sliderThumbReducedSports: {
      '& .MuiSlider-thumb': {
        border: '7px solid #fcc00a',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#fcc00a',
      },
    },
    sliderThumbEliteSports: {
      '& .MuiSlider-thumb': {
        border: '7px solid #5f5f5f',
      },

      '& .MuiSlider-markLabelActive': {
        color: '#5f5f5f',
      },
    },
    profileValue: {
      width: '190px',
      height: '37px',
      color: 'white',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '14.45px',
      lineHeight: '17px',
      marginTop: '15.79px',
    },
    learnMoreArrow: {
      // marginTop: '3px',
    },
    learnMore: {
      background: '#FFFFFF',
      boxShadow: '0px 4px 20px 0px rgba(0, 0, 0, 0.08)',
      borderRadius: '15px',
      height: '3rem',
      width: '85%',
      textAlign: 'end',
      position: 'absolute',
      bottom: '-1.5rem',
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      padding: '14px',

      left: 0,
      right: 0,
      marginLeft: 'auto',
      marginRight: 'auto',

      '&:hover': {
        background: ({ color }) => { return color; },
        color: 'white',
        cursor: 'pointer',
      },
    },
    bar: {
      width: '208px',
      height: '100%',
      // height: '66px',
      marginTop: '32px',
    },
    alcoholBar: {
      width: '208px',
      height: '96px',
      // marginTop: '18px',
    },
    profileCards: {
      marginTop: '20px',
      paddingBottom: '24px',
    },
    profileCardTitleContainer: {
      height: '72px',
      marginLeft: 'auto',
    },
    profileCardTitle: {
      minHeight: '45px',
      padding: '5px',
      // width: '150px',
      // border: '1px solid #F5C91E',
      borderRadius: '8px',
      fontWeight: '500',
      fontSize: '16px',
      lineHeight: '125.19%',
      letterSpacing: '0.03em',
      // color: '#E6BC19',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
    },
    profileCard: {
      background: 'white',
      height: '330px',
      boxShadow: '0px 24px 80px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '15px',
      padding: '30px 30px 25px',
      position: 'relative',
    },
    photoWithText: {
      marginTop: '20px',
    },
    options: {
      width: '100%',
      marginTop: '20px',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '8px',
      height: '82.88px',
      alignItems: 'center',
      paddingLeft: '30px',
      paddingRight: '30px',
      '@media (max-width:600px)': {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
    tabs: {
      display: 'flex',
      gap: '3%',
      width: '100%',
      flexWrap: 'wrap',
    },
    tab: {
      height: '36px',
      color: 'white',
      fontWeight: '700',
      fontFamily: 'Roboto',
      fontSize: '20px',
      lineHeight: '125.19%',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '16px',
      paddingLeft: '15px',
      display: 'flex',
      alignItems: 'center',
    },
    dearContainer: {
      padding: '3%',
      marginTop: '20px',
      height: '171px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      color: 'white',
      display: 'flex',
    },
    dear: {
      fontWeight: '700',
      fontFamily: 'Roboto',
      fontSize: '43.4717px',
      lineHeight: '60px',
    },

    hideWhenSmall: {
      display: '',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    container: {
      padding: '50px',

      [theme.breakpoints.down('sm')]: {
        padding: '5px',
      },
    },
    smallFontWhenSmall: {
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '17px',
      },
    },
    blurredBox: {
      background: 'linear-gradient(106.1deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%)',
      backdropFilter: 'blur(40px)',
      // marginTop: '30px',
      // marginLeft: '30px',
      width: '62%',
      minWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      color: '#484848',
      padding: '40px 40px 40px 40px',
      borderRadius: '15px',
    },
    outsideBlurredBox: {
      margin: '30px 0',
      padding: '30px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    profilesContainer: {
      background: 'white',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      // padding: '3% 4% 5% 7%',
      // display: 'flex',
      width: '100%',
    },
    profiles: {
      display: 'flex',
      // justifyContent: 'center',
      // justifyContent: 'space-around',
      // width: '100%',
      // maxWidth: '97vw',
      gap: '10px',
      // width: '68vw',
      overflowX: 'auto',
    },
    profile: {
      // width: '180px',
      // maxWidth: '250px',
      width: '100%',
      minWidth: '210px',
      minHeight: '65px',
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
      letterSpacing: '0.03em',
      color: '#545454',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px',
      padding: '5px 15px',
      borderRadius: '8px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    prevNextIconText: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    prevNextIcon: {
      height: '61px',
      width: '61px',
      display: 'flex',
      padding: '8px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',

      [theme.breakpoints.down('sm')]: {
        height: '45px',
        width: '45px',
      },
    },
    hideLongText: {
      // maxWidth: '420px',
      fontFamily: 'Roboto',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
    prevNextAtTheSides: {
      width: '25%',
    },
    prevNextCenter: {
      maxWidth: '50%',

      [theme.breakpoints.down('md')]: {
        maxWidth: 'calc(100% - 150px)',
      },

      [theme.breakpoints.down('sm')]: {
        maxWidth: 'calc(100% - 130px)',
      },
    },
    prevNextCenterText: {
      fontSize: '23px',

      [theme.breakpoints.down('sm')]: {
        fontSize: '19px',
      },
    },
    photoWithTextImage: {
      // maxWidth: 'calc(100vw - 25px)',
      width: 'auto',
      borderRadius: '8px',
      height: '328px',

      [theme.breakpoints.down('md')]: {
        height: 'auto',
        width: '100%',
      },
    },
  };
});

export const Tab = ({ tab, children = null }) => {
  const { t } = useTranslation();
  const classes = useStyles();
  const { navigateToPage } = useNavigation();

  return (
    <div
      style={ { background: tab.color } }
      className={ clsx(classes.tab, classes.hoverPointer) }
      aria-hidden='true'
      onClick={ () => { navigateToPage({ pageId: tab.id }); } }
    >
      {t(tab.title)}

      {children}
    </div>
  );
};

export const PrevNext = ({ options, activePage, isNutrition = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  let fieldToCheck = 'text';
  if (isNutrition) {
    fieldToCheck = 'nurl';
  }

  const activePageId = options.fields.findIndex((field) => { return field[fieldToCheck] === activePage; });

  const orderedOptions = [];

  if (0 === activePageId) {
    orderedOptions.push(options.fields[1]);
    orderedOptions.push(options.fields[0]);
    if (2 === options.fields.length) {
      orderedOptions.push(options.fields[1]);
    } else {
      orderedOptions.push(options.fields[2]);
    }
  } else if (1 === activePageId) {
    orderedOptions.push(options.fields[0]);
    orderedOptions.push(options.fields[1]);
    if (2 === options.fields.length) {
      orderedOptions.push(options.fields[0]);
    } else {
      orderedOptions.push(options.fields[2]);
    }
  } else if (2 === activePageId) {
    orderedOptions.push(options.fields[0]);
    orderedOptions.push(options.fields[2]);
    orderedOptions.push(options.fields[1]);
  }

  const { navigateToPage } = useNavigation();

  const setActive = (id) => {
    if (0 === id) {
      navigateToPage({ pageId: orderedOptions[0][fieldToCheck] });
    } else if (1 === id) {
      navigateToPage({ pageId: orderedOptions[1][fieldToCheck] });
    } else if (2 === id) {
      navigateToPage({ pageId: orderedOptions[2][fieldToCheck] });
    }
  };

  return (
    <div className={ classes.options } style={ { backgroundColor: options.color } }>
      <div
        onClick={ () => { setActive(0); } }
        aria-hidden='true'
        className={ clsx(classes.prevNextIconText, classes.prevNextAtTheSides) }
      >
        <Icon
          className={ classes.prevNextIcon }
          style={ {
            background: 'rgba(255, 255, 255, 0.29)',
            marginRight: '17px',
          } }
        >
          <img src='/arrow-left.svg' alt='' />
        </Icon>
        <div
          style={ { fontSize: '14px' } }
          className={ clsx(classes.hideLongText, classes.hideWhenSmall) }
        >
          {t(orderedOptions[0].text)}
        </div>
      </div>
      <div
        className={ clsx(classes.prevNextIconText, classes.prevNextCenter) }
        style={ {
          display: 'flex',
          justifyContent: 'center',
        } }
        onClick={ () => { setActive(1); } }
        aria-hidden
      >
        <Icon
          className={ classes.prevNextIcon }
          style={ {
            marginRight: '17px',
          } }
        >
          <img src={ orderedOptions[1].icon } alt='icon' />
        </Icon>

        <div
          className={ clsx(classes.hideLongText, classes.smallFontWhenSmall, classes.prevNextCenterText) }
        >
          {t(orderedOptions[1].text)}
        </div>
      </div>
      <div
        onClick={ () => { setActive(2); } }
        aria-hidden='true'
        className={ clsx(classes.prevNextIconText, classes.prevNextAtTheSides) }
        style={ {
          display: 'flex',
          justifyContent: 'flex-end',
        } }
      >
        <div className={ classes.prevNextIconText }>
          <div
            style={ { fontSize: '14px' } }
            className={ clsx(classes.hideLongText, classes.hideWhenSmall) }
          >
            {t(orderedOptions[2].text)}
          </div>

          <Icon
            className={ classes.prevNextIcon }
            style={ {
              background: 'rgba(255, 255, 255, 0.29)',
              marginLeft: '17px',
            } }
          >
            <img src='/arrow-right.svg' alt='' />
          </Icon>
        </div>
      </div>
    </div>
  );
};

export const PhotoWithText = ({ options, activePage, text }) => {
  const classes = useStyles();

  const { image } = options.fields.find((field) => { return field.text === activePage; });
  const { isMdDown } = useMediaQueries();

  return (
    <Grid
      container
      className={ classes.photoWithText }
      spacing={ 1 }
      alignItems='stretch'
      style={ { gap: '10px' } }
    >
      <Grid style={ { height: isMdDown ? 'auto' : '328px',
        width: isMdDown ? '100%' : 'auto',
        display: 'block' } }
      >
        <img
          src={ image }
          alt=''
          className={ classes.photoWithTextImage }
        />

      </Grid>
      <Grid item lg style={ { height: '100%' } }>
        <div style={ { padding: '30px',
          width: '100%',
          minHeight: '328px',
          border: `1px solid ${ options.color }`,
          boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
          borderRadius: '8px' } }
        >
          <Typography>
            {text}
          </Typography>
        </div>
      </Grid>
    </Grid>
  );
};

export const valueToBarImageSports = {
  Reduced: '/ΠΡΟΦΙΛ ΑΠΟΔΟΣΗΣ_ΜΕΙΩΜΕΝΟ.png',
  Typical: '/ΠΡΟΦΙΛ ΑΠΟΔΟΣΗΣ_ΤΥΠΙΚΟ.png',
  Increased: '/ΠΡΟΦΙΛ ΑΠΟΔΟΣΗΣ_ΑΥΞΗΜΕΝΟ.png',
  High: '/ΠΡΟΦΙΛ ΑΠΟΔΟΣΗΣ_ΥΨΗΛΟ.png',
};

export const valueToBarImage = {
  Reduced: '/sensitivityBarLow.png',
  Typical: '/sensitivityBarNormal.png',
  Increased: '/sensitivityBarHigh.png',
  High: '/sensitivityBarVeryHigh.png',
};

export const alchoholWantValueToBarImage = {
  Reduced: '/ΕΠΙΘΥΜΙΑ ΑΛΚΟΟΛ_ΜΕΙΩΜΕΝΟ.png',
  Typical: '/ΕΠΙΘΥΜΙΑ ΑΛΚΟΟΛ_ΤΥΠΙΚΟpng.png',
  Increased: '/ΕΠΙΘΥΜΙΑ ΑΛΚΟΟΛ_ΑΥΞΗΜΕΝΟ.png',
  High: '/ΕΠΙΘΥΜΙΑ ΑΛΚΟΟΛ_ΥΨΗΛΟ.png',
};

export const alchoholMetabolismValueToBarImage = {
  Reduced: '/ΜΕΤΑΒΟΛΙΣΜΟΣ ΑΛΚΟΟΛ_ΜΕΙΩΜΕΝΟ.png',
  Typical: '/ΜΕΤΑΒΟΛΙΣΜΟΣ ΑΛΚΟΟΛ_ΤΥΠΙΚΟ.png',
  Increased: '/ΜΕΤΑΒΟΛΙΣΜΟΣ ΑΛΚΟΟΛ_ΥΨΗΛΟ.png',
  High: '/ΜΕΤΑΒΟΛΙΣΜΟΣ ΑΛΚΟΟΛ_ΥΨΗΛΟ.png',
};

export const valueToColor = {
  Reduced: '#673A92',
  Typical: '#93C221',
  Increased: '#F5C91E',
  High: '#FF0801',
};

export const CustomSlider = ({ profileResult, label = undefined, noMargin = false, sports = false }) => {
  const [ sliderValue, setSliderValue ] = useState(0);
  const [ sliderIntervalId, setSliderIntervalId ] = useState();
  const { t } = useTranslation();

  const classes = useStyles();

  const marks = [];

  let finalSliderValue = 0;
  let sliderThumbClass;

  if (sports) {
    switch (profileResult) {
      case 'High':
        finalSliderValue = 3;
        sliderThumbClass = classes.sliderThumbHighSports;
        marks.push({ value: 3, label: t('High') });
        break;

      case 'Increased':
        finalSliderValue = 2.2;
        sliderThumbClass = classes.sliderThumbIncreasedSports;
        marks.push({ value: 2.2, label: t('Increased') });
        break;

      case 'Typical':
        finalSliderValue = 1.2;
        sliderThumbClass = classes.sliderThumbTypicalSports;
        marks.push({ value: 1.2, label: t('Typical') });
        break;

      case 'Reduced':
        finalSliderValue = 0.35;
        sliderThumbClass = classes.sliderThumbReducedSports;
        marks.push({ value: 0.35, label: t('Reduced1') });
        break;

      case 'Elite':
        finalSliderValue = 3.8;
        sliderThumbClass = classes.sliderThumbEliteSports;
        marks.push({ value: 3.8, label: 'Elite' });
        break;

      default:
        break;
    }
  } else {
    switch (profileResult) {
      case 'High':
        finalSliderValue = 0.45;
        sliderThumbClass = classes.sliderThumbHigh;
        marks.push({ value: 0.45, label: t('High') });
        break;

      case 'Increased':
        finalSliderValue = 1.2;
        sliderThumbClass = classes.sliderThumbIncreased;
        marks.push({ value: 1.2, label: t('Increased') });
        break;

      case 'Typical':
        finalSliderValue = 2.2;
        sliderThumbClass = classes.sliderThumbTypical;
        marks.push({ value: 2.2, label: t('Typical') });
        break;

      case 'Reduced':
        finalSliderValue = 3;
        sliderThumbClass = classes.sliderThumbReduced;
        marks.push({ value: 3, label: t('Reduced') });
        break;

      default:
        break;
    }
  }

  const increment = () => {
    setSliderValue((oldValue) => {
      if (oldValue <= finalSliderValue) {
        return oldValue + 0.05;
      }
      return oldValue;
    });
  };

  useEffect(() => {
    if (sliderValue >= finalSliderValue) {
      clearInterval(sliderIntervalId);
    }
  }, [ sliderValue, finalSliderValue ]);

  useEffect(() => {
    const id = setInterval(increment, 40);
    setSliderIntervalId(id);
    return () => { return clearInterval(id); };
  }, []);

  return (
    <div style={ { marginTop: label && !noMargin ? '-27px' : 0, width: '100%', maxWidth: '360px' } }>
      {
        label && (
          <Typography>
            {t(label)}
          </Typography>
        )
      }

      {
        sports ? (
          <PrettoSliderSports
            value={ sliderValue }
            min={ 0 }
            className={ sliderThumbClass }
            max={ 3.7 }
            step={ 0.05 }
            marks={ marks }
            style={ {
              width: '100%',
            } }
          />
        )
          : (
            <PrettoSlider
              value={ sliderValue }
              min={ 0 }
              className={ sliderThumbClass }
              max={ 3.7 }
              step={ 0.05 }
              marks={ marks }
              style={ {
                width: '100%',
              } }
            />
          )
      }

    </div>
  );
};

const ProfileCard = ({ profile, color }) => {
  const classes = useStyles({ color });
  const { t } = useTranslation();

  const { navigateToPage } = useNavigation();
  const { isDoctor } = useAuth();

  return (
    <div
      className={ classes.profileCard }
      style={ { height: isDoctor ? '300px' : 'NCAT16' === profile.ID ? '330p' : '260px' } }
    >

      <div style={ { display: 'flex',
        marginBottom: '25px',
        width: '100%',
        borderRadius: '48px',
        background: '#F7F7FA',
        padding: '6px' } }
      >
        <Icon style={ { height: '49px', width: '50px', borderRadius: '50px', marginRight: '5px' } }>
          <img src={ profile.icon.small } alt='' style={ { height: '50px', width: '50px' } } />
        </Icon>
        <div
          className={ classes.profileCardTitle }
        >
          {t(profile.title)}
        </div>
      </div>

      {
        'NCAT16' !== profile.ID && 'NCAT19' !== profile.ID && (
          <Typography style={ {
            fontFamily: 'Roboto',
            color: 'black',
            fontSize: '15px',
            fontStyle: 'normal',
            fontWeight: 400,
            marginBottom: '20px',
            lineHeight: 'normal',

          } }
          >
            {t('Προφίλ ευαισθησίας')}
          </Typography>
        )
      }

      <div style={ { display: 'flex' } }>
        {
          'NCAT16' !== profile.ID && 'NCAT19' !== profile.ID && (
            <CustomSlider
              profileResult={ profile.result }
              sports={ [ 'SCAT1', 'SCAT2', 'SCAT3', 'SCAT4', 'SCAT5', 'SCAT6' ].includes(profile.ID) }
            />
          )
        }

        {
          'NCAT19' === profile.ID && (
            <div style={ { marginTop: '10px', width: '100%' } }>
              <NightBox />
            </div>
          )
        }
      </div>

      {
        'NCAT16' === profile.ID && 'NCAT19' !== profile.ID && (
          <div style={ { marginTop: '40px' } }>
            <CustomSlider
              label='Επιθυμία για αλκοόλ'
              profileResult={ profile.result_alcohol_desire }
            />
            <CustomSlider
              label='Μεταβολισμός αλκοόλ'
              profileResult={ profile.result_alcohol_metabolism }
              noMargin
            />
          </div>
        )
      }

      {
        !isDoctor && (
          <div
            className={ classes.learnMore }
            onClick={ () => { navigateToPage({ pageId: profile.url }); } }
            aria-hidden
            style={ {
              fontSize: '14px',
              fontStyle: 'normal',
              fontWeight: '400',
              lineHeight: '20px',
            } }
          >
            {t('Δες το αποτέλεσμά σου')}
            <Avatar
              style={ { background: color,
                marginLeft: '12px',
                width: '32px',
                height: '32px' } }
            >
              <ArrowForwardIcon className={ classes.learnMoreArrow } />
            </Avatar>

          </div>
        )
      }
    </div>
  );
};

export const ProfileCards = ({ sensitivityProfiles, color }) => {
  const classes = useStyles();

  return (
    <Grid container spacing={ 4 } className={ classes.profileCards }>
      {
        sensitivityProfiles.map((profile) => {
          return (
            <Grid
              item
              xs={ 12 }
              md={ 6 }
              lg={ 4 }
              key={ profile.title }
            >
              <ProfileCard
                profile={ profile }
                color={ color }
              />
            </Grid>
          );
        })
      }
    </Grid>
  );
};

export const Dear = ({ name, id, text }) => {
  const classes = useStyles();

  const { color } = tabs.find((tab) => { return tab.id === id; });

  return (
    <div className={ classes.dearContainer } style={ { background: color } }>
      <AccountCircleIcon style={ { fontSize: '65px' } } />
      <div style={ { display: 'flex', flexDirection: 'column', marginLeft: '21px' } }>
        <div className={ classes.dear }>
          Dear
          {' '}
          {name}
          ,
        </div>
        <div>
          {text}
        </div>
      </div>
    </div>
  );
};

export const Tabs = () => {
  return (
    <Grid container spacing={ 1 }>
      {
        tabs.map((tab) => {
          return (
            <Grid
              key={ tab.id }
              item
              xs={ 12 }
              lg={ 4 }

            >
              <Tab
                tab={ tab }
              />
            </Grid>
          );
        })
      }
    </Grid>
  );
};

