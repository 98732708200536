import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useKit } from 'models/kits';
import { useTranslation } from 'react-i18next';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Typography from '@material-ui/core/Typography';
import { AccordionDetails, Button, CircularProgress, makeStyles, TextField } from '@material-ui/core/';
import Alert from '@material-ui/lab/Alert';
import clsx from 'clsx';
import FormNames from '../atoms/form-names';

export const LoadingButton = (
  {
    loading,
    label,
    onClick,
    disabled = false,
  },
) => {
  return (
    <Grid container justifyContent='flex-end' style={ { marginTop: '32px' } }>
      <Grid item xs={ 12 } sm={ 4 }>
        <Button
          variant='contained'
          color='secondary'
          onClick={ onClick }
          fullWidth
          style={ {
            minHeight: '40px',
          } }
          disabled={ disabled || loading }
        >
          { loading
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }
          <div>
            { label }
          </div>
        </Button>
      </Grid>
    </Grid>
  );
};

export const LoadingButtonNoGrid = (
  {
    loading,
    label,
    variant = undefined,
    color = undefined,
    onClick = undefined,
    disabled = false,
    style = {},
    type = undefined,
    fullWidth = true,
  },
) => {
  return (
    <Button
      variant={ variant }
      color={ color }
      type={ type }
      onClick={ onClick }
      fullWidth={ fullWidth }
      style={ {
        minHeight: '40px',
        ...style,
      } }
      disabled={ disabled || loading }
    >
      { loading
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }
      <div>
        { label }
      </div>
    </Button>
  );
};

const PhysicalAddressForm = ({
  onSubmit: _1,
  onError,
  kitID,
}) => {
  const { t } = useTranslation();

  const [ streetAddress, setStreetAddress ] = useState('');
  const [ streetAddressError, setStreetAddressError ] = useState(false);
  const [ postalCode, setPostalCode ] = useState('');
  const [ postalCodeError, setPostalCodeError ] = useState(false);
  const [ city, setCity ] = useState('');
  const [ cityError, setCityError ] = useState(false);

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { updateKitMetadata, kitMetadata } = useKit(kitID);

  useEffect(() => {
    if ('' === streetAddress && '' === postalCode && '' === city && null != kitMetadata) {
      setStreetAddress(kitMetadata.address ?? '');
      setPostalCode(kitMetadata.postalCode ?? '');
      setCity(kitMetadata.city ?? '');
    }
  }, [ kitMetadata ]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSubmitting(true);
    // greedy validation
    // don't validate all of them immediately

    if (![
      () => {
        setStreetAddressError('' === streetAddress);
        return '' !== streetAddress;
      },
      () => {
        setPostalCodeError('' === postalCode);
        return '' !== postalCode;
      },
      () => {
        setCityError('' === city);
        return '' !== city;
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return updateKitMetadata({
          metadata: {
            address: streetAddress,
            postalCode,
            city,
            name: '',
            family_name: '',
            father_name: '',
          },
        });
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <form
      noValidate
      onSubmit={
        (e) => {
          e.preventDefault();
        }
      }
      style={ {
        padding: '24px',
      } }
    >
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 1 }>
            <Grid item xs={ 12 }>
              <TextField
                label={ t('streetAddress') }
                name={ FormNames.streetAddress }
                required
                error={ streetAddressError }
                variant='outlined'
                value={ streetAddress }
                onChange={ (e) => { setStreetAddress(e.target.value); } }
                helperText={ streetAddressError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
              />
            </Grid>
            <Grid item container spacing={ 2 }>
              <Grid item xs={ 4 }>
                <TextField
                  label={ t('postalCode') }
                  name={ FormNames.postalCode }
                  variant='outlined'
                  value={ postalCode }
                  onChange={ (e) => { setPostalCode(e.target.value); } }
                  required
                  error={ postalCodeError }
                  helperText={ postalCodeError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
                />
              </Grid>
              <Grid item xs={ 8 }>
                <TextField
                  label={ t('cityOrTown') }
                  name={ FormNames.city }
                  variant='outlined'
                  required
                  value={ city }
                  onChange={ (e) => { setCity(e.target.value); } }
                  error={ cityError }
                  helperText={ cityError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <LoadingButton
        label={ t('save') }
        loading={ isSubmitting }
        onClick={ handleSubmit }
        disabled={ '' === streetAddress || '' === postalCode || '' === city }
      />
    </form>
  );
};

const OtherPersonForm = (
  {
    onSubmit: _1,
    onError,
    kitID,
  },
) => {
  const { t } = useTranslation();

  const [ isSubmitting, setSubmitting ] = useState(false);

  const { updateKitMetadata, kitMetadata } = useKit(kitID);

  const [ firstName, setFirstName ] = useState('');
  const [ firstNameError, setFirstNameError ] = useState(false);
  const [ lastName, setLastName ] = useState('');
  const [ lastNameError, setLastNameError ] = useState(false);
  const [ parentFirstName, setParentFirstName ] = useState('');
  const [ parentFirstNameError, setParentFirstNameError ] = useState(false);

  useEffect(() => {
    if ('' === firstName && '' === lastName && '' === parentFirstName && null != kitMetadata) {
      setFirstName(kitMetadata.name ?? '');
      setLastName(kitMetadata.family_name ?? '');
      setParentFirstName(kitMetadata.father_name ?? '');
    }
  }, [ kitMetadata ]);

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    // greedy validation
    // don't validate all of them immediately

    if (![
      () => {
        setFirstNameError('' === firstName);
        return '' !== firstName;
      },
      () => {
        setLastNameError('' === lastName);
        return '' !== lastName;
      },
      () => {
        setParentFirstNameError('' === parentFirstName);
        return '' !== parentFirstName;
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    return Promise.resolve()
      .then(() => {
        return updateKitMetadata({
          metadata: {
            name: firstName,
            family_name: lastName,
            father_name: parentFirstName,
            address: '',
            postalCode: '',
            city: '',
          },
        });
      })
      .catch(onError)
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                label={ t('firstName') }
                lettersOnly
                name={ FormNames.firstName }
                required
                value={ firstName }
                onChange={ (e) => { setFirstName(e.target.value); } }
                error={ firstNameError }
                helperText={ firstNameError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                label={ t('lastName') }
                name={ FormNames.lastName }
                required
                lettersOnly
                error={ lastNameError }
                value={ lastName }
                onChange={ (e) => { setLastName(e.target.value); } }
                helperText={ lastNameError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
              />
            </Grid>
            <Grid item xs={ 12 } md={ 6 }>
              <TextField
                label={ t('parentFirstName') }
                name='parentFirstName'
                required
                value={ parentFirstName }
                onChange={ (e) => { setParentFirstName(e.target.value); } }
                error={ parentFirstNameError }
                helperText={ parentFirstNameError ? 'Αυτό το πεδίο είναι υποχρωτικό' : '' }
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <LoadingButton
        label={ t('save') }
        loading={ isSubmitting }
        onClick={ handleSubmit }
        disabled={ '' === firstName || '' === lastName || '' === parentFirstName }
      />

    </form>
  );
};

PhysicalAddressForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

PhysicalAddressForm.defaultProps = {
  onSubmit: () => {
    return null;
  },
  onError: () => {
    return null;
  },
};

OtherPersonForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

OtherPersonForm.defaultProps = {
  onSubmit: () => {
    return null;
  },
  onError: () => {
    return null;
  },
};

const useStyles = makeStyles((theme) => {
  return {
    accordion: {
      marginBottom: '24px',
      boxShadow: theme.palette.formShadow,
      border: `1px solid ${ theme.palette.alternate.light }`,
    },
    accordionExpanded: {
      border: `1px solid ${ theme.palette.secondary.light }`,
    },
    accordionHeader: {
      backgroundColor: 'unset',
    },
    accordionHeaderExpanded: {
      backgroundColor: theme.palette.secondary.light,
    },
  };
});

const EntitlePersonalResultsForm = (
  {
    onSubmit,
    onError,
    kitID,
  },
) => {
  const { t } = useTranslation();

  const classes = useStyles();

  const [ expanded, setExpanded ] = useState(false);

  const handleChange = (panel) => {
    return (event, isExpanded) => {
      setExpanded(isExpanded ? panel : false);
    };
  };

  const formOptions = [
    {
      title: t('shippingToPhysicalAddress'),
      info: t('shippingToPhysicalAddressPrompt'),
      component: PhysicalAddressForm,
      option: 'ship-to-physical-address',
    },
    {
      title: t('thirdPersonEntitlement'),
      info: t('thirdPersonEntitlementPrompt'),
      component: OtherPersonForm,
      option: 'entitle-third-person',
      explanation: t('thirdPersonEntitlementExplanation'),
    },
  ];
  return (
    <div>
      {
        formOptions.map(
          ({
            title,
            info,
            component: Page,
            option,
            explanation,
          }) => {
            return (
              <Accordion
                key={ option }
                expanded={ expanded === option }
                onChange={ handleChange(option) }
                className={ clsx(classes.accordion, (expanded === option) && classes.accordionExpanded) }
              >
                <AccordionSummary
                  className={ clsx(classes.accordionHeader, (expanded === option) && classes.accordionHeaderExpanded) }
                  expandIcon={ <ExpandMoreIcon /> }
                >
                  <Typography>
                    { title }
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid>

                    <Alert
                      severity='info'
                      style={ { textAlign: 'initial' } }
                    >
                      { info }
                      {' '}
                      { explanation }
                    </Alert>
                    <Page
                      kitID={ kitID }
                      onSubmit={
                        (data) => {
                          return onSubmit(data, option);
                        }
                      }
                      onError={ (err) => {
                        return onError(err, option);
                      } }
                    />
                  </Grid>
                </AccordionDetails>
              </Accordion>
            );
          },
        )
      }
    </div>
  );
};

EntitlePersonalResultsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

EntitlePersonalResultsForm.defaultProps = {
  onSubmit: () => {
    return null;
  },
  onError: () => {
    return null;
  },
};

export default EntitlePersonalResultsForm;
/*
<form
        noValidate
        onSubmit={ (e) => {
          e.preventDefault();
          return onSubmit(null, 'skip-step');
        } }
      >
        <FormSectionSubmit
          label={ t('skipStep') }
          disabled={ expanded }
        />
      </form>
*/

