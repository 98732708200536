import { makeStyles } from '@material-ui/core/styles';

export const useFormStylesIn = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(2),
      width: '333px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        marginTop: theme.spacing(4),
      },
      marginLeft: '0px',
      marginRight: '0px',
      padding: '0px',
    },
    description: {
      margin: theme.spacing(3, 0),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      marginLeft: '0px',
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'unset',
      },
      backgroundColor: theme.palette.alternate.light,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      minHeight: '40px',
      margin: theme.spacing(3, 0, 2),
      padding: '0px',
    },
    links: {
    },
  };
});

