import React, { useMemo } from 'react';
import { useCardioDataReport } from 'models/kits';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useOptionSerial } from 'NavigationProvider';
import i18nEng from '../../../i18n_eng';
import i18nEl from '../../../i18n_el';
import CardioResults, { ImageText } from '../Common';

const StefaniaNosos = () => {
  const { i18n } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { data, locale } = useCardioDataReport(serial, i18n.language);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null == data) {
    return <LoaderSkeleton />;
  }

  if (0 === data.length) {
    return (
      <h1>
        No data available
      </h1>
    );
  }

  const nosos = data[0].value['coronary artery disease'];

  const prsImage = [ JSON.parse(nosos._IMG_SCORE).base64, JSON.parse(nosos._IMG_SCORE_MOBILE).base64 ];
  const adjPrsImage = [ JSON.parse(nosos._IMG_ADJ_PRS_SCORE).base64, JSON.parse(nosos._IMG_ADJ_PRS_SCORE_MOBILE).base64 ];
  const prsScore = nosos.PRS_SCORE;
  const adjPrsScore = nosos.ADJPRS_SCORE;
  const prsCharacterisation = nosos.PRS_CHARACTERISATION;
  const traitProfile = nosos.TRAIT_PROFILE;
  const adjPrsCharacterisationLevels = nosos.ADJPRS_CHARACTERISATION_LEVELS;
  const adjPrsCharacterisationFem = nosos.ADJPRS_CHARACTERISATION_FEMALE;

  const imageContent = 'Η στεφανιαία νόσος αναπτύσσεται όταν οι στεφανιαίες αρτηρίες, δηλαδή τα κύρια αιμοφόρα αγγεία που τροφοδοτούν την καρδιά με αίμα πλούσιο σε οξυγόνο, δυσλειτουργούν. Η αθηροσκλήρωση που προκαλείται από  τις εναποθέσεις (αθηρωματικές πλάκες) που περιέχουν χοληστερόλη και η φλεγμονή που προκαλούν στις στεφανιαίες αρτηρίες, ευθύνεται συνήθως για τη στεφανιαία νόσο. Στην ηλικία των 50 ετών, περίπου το 5% των ατόμων αναπτύσσει στεφανιαία νόσο, ενώ στην ηλικία των 80 ετών το ποσοστό εμφάνισης της νόσου αγγίζει το 25%.';

  let characterisation = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === characterisation) {
    characterisation = 'Τυπική';
  } else if ('HIGH' === characterisation) {
    characterisation = 'Βέλτιστη';
  } else if ('LOW' === characterisation) {
    characterisation = 'Mειωμένη';
  }

  return (
    <div>
      <I18nextProvider i18n={ i18nProv }>
        <CardioResults />
        <ImageText
          traitProfile={ traitProfile }
          adjPrsCharacterisationLevels={ adjPrsCharacterisationLevels }
          characterisation={ characterisation }
          prsImage={ prsImage }
          adjPrsCharacterisationFem={ adjPrsCharacterisationFem }
          adjPrsImage={ adjPrsImage }
          prsScore={ prsScore }
          adjPrsScore={ adjPrsScore }
          prsCharacterisation={ prsCharacterisation }
          activeProfile='Στεφανιαία νόσος'
          imageTitle='Για τη Στεφανιαία Νόσο'
          source='https://www.mayoclinic.org/diseases-conditions/coronary-artery-disease/symptoms-causes/syc-20350613'
          imageContent={ imageContent }
        />
      </I18nextProvider>
    </div>
  );
};

export default StefaniaNosos;
