// Hook
import { useEffect, useRef, useState } from 'react';
import { supportedTelephoneCountries } from 'components/app/CountrySelect';

export function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

export const useGetCountryFromIP = () => {
  const [ country, setCountry ] = useState(null);
  const [ loaded, setLoaded ] = useState(false);

  useEffect(() => {
    (async () => {
      try {
        const response = await fetch('https://api.country.is').then((resp) => {
          return resp.json();
        }).catch(() => {
          return 'GR';
        });

        let parsedCountry;

        const supportedCountry = supportedTelephoneCountries.find((s) => {
          return s === response?.country;
        });

        if (null == response || 'GR' === response) {
          parsedCountry = 'GR';
        } else if (supportedCountry === undefined) {
          parsedCountry = 'US';
        } else {
          parsedCountry = response.country;
        }

        setCountry(parsedCountry);
      } catch {
        setCountry('GR');
      } finally {
        setLoaded(true);
      }
    })();
  }, []);

  return { country, loaded };
};
