import { useEffect, useState } from 'react';

export function useQueryParams({ page: initialPage, limit: initialLimit, sort: initialSort } = {}) {
  const [ page, setPage ] = useState(initialPage || 0);
  const [ limit, setLimit ] = useState(initialLimit || 10);
  const [ sort, setSortAux ] = useState(initialSort || { order: 'desc', orderBy: 'updated_at' });

  const setSort = (property, ascDesc) => {
    setSortAux({ order: ascDesc, orderBy: property });
  };

  useEffect(() => {
    setPage(0);
  }, [ limit ]);

  return {
    page,
    setPage,
    limit,
    setLimit,
    sort,
    setSort,
  };
}
