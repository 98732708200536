import { useTranslation } from 'react-i18next';
import React, { useMemo, useState } from 'react';
import { Button, useTheme } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useItems } from '../../models/atoms/items-hook';
import CollectionKeys from '../../models/atoms/collection-keys';
import AppTable from '../../components/app/AppTable';
import { InvitationReason } from '../../models/invitations';
import { useDialog } from '../../hooks/confirmation-dialog-hooks';
import useAPI from '../../hooks/api-hooks';

const ConsentsTable = () => {
  const { t } = useTranslation();

  const tableColumns = [
    { id: 1, label: t('serialNumber'), field: 'registration_kit.serial_number_id.serial' },
    { id: 2, label: t('header-name'), field: 'source.name' },
    { id: 3, label: t('header-surname'), field: 'source.family_name' },
    { id: 4,
      label: t('approvalStatus'),
      field: 'status',
      customRenderer({ row }) {
        // eslint-disable-next-line no-unused-vars
        const theme = useTheme();
        if ('accept' === row.status) {
          return (
            <>
              {
                (t('accepted'))
              }
            </>
          );
        }
        if ('reject' === row.status) {
          return (
            <>
              {
                (t('rejected'))
              }
            </>
          );
        }

        return (
          <>
            {
              (t('pending'))
            }
          </>
        );
      } },
    { id: 5,
      label: t('Action'),
      sortable: false,
      field: 'created_at',
      customRenderer({ row }) {
        const { openDialog } = useDialog();
        const queryClient = useQueryClient();
        const { client: api } = useAPI();
        const openInvitationDialog = (invitation, accept) => {
          openDialog({
            title: accept ? t('iConsent') : t('iDontConsent'),
            body() {
              if (accept) {
                return <p>{t('Είστε σίγουρος ότι θέλετε να δώσετε τη συγκατάθεσή σας;')}</p>;
              }
              return <p>{t('Είστε σίγουρος ότι ΔΕΝ θέλετε να δώσετε τη συγκατάθεσή σας;')}</p>;
            },
            onSubmit() {
              return api.put(`invitations/${ invitation.id }`, {
                status: accept ? 'accept' : 'reject',
              }).then(() => {
                return Promise.all([
                  [ CollectionKeys.Invitations ],
                  [ CollectionKeys.CustomerDoctor ],
                  [ CollectionKeys.CustomerDoctorKits ],
                ].map((collectionKey) => {
                  return queryClient.invalidateQueries(collectionKey);
                }));
              });
            },
          });
        };

        const theme = useTheme();

        if ('reject' === row.status || 'accept' === row.status) {
          return (
            null
          );
        }

        if ('accept' === row.status) {
          return (
            <Button
              style={ { color: theme.palette.error.main, fontSize: '14px' } }
              component='span'
              size='small'
              onClick={ () => { return openInvitationDialog(row, false); } }
              variant='outlined'
            >
              {t('iDontConsent')}
            </Button>
          );
        }

        return (
          <div>
            <Button
              style={ { color: theme.palette.success.main, fontSize: '14px', marginRight: '10px' } }
              component='span'
              size='small'
              onClick={ () => { return openInvitationDialog(row, true); } }
              variant='outlined'
            >
              {t('iConsent')}
            </Button>

            <Button
              style={ { color: theme.palette.error.main, fontSize: '14px' } }
              component='span'
              size='small'
              onClick={ () => { return openInvitationDialog(row, false); } }
              variant='outlined'
            >
              {t('iDontConsent')}
            </Button>
          </div>
        );
      } },
  ];

  const [ search, setSearch ] = useState('');

  const filterQuery = useMemo(() => {
    const trimmedSearch = search.trim();

    if ('' === trimmedSearch) {
      return {};
    }

    const searchTokens = trimmedSearch
      .split(' ')
      .map((tt) => { return tt.trim(); })
      .filter((s) => { return '' !== s; });

    if (0 === searchTokens.length) {
      return {};
    }

    if (1 === searchTokens.length) {
      const [ f ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'registration_kit.serial_number_id.serial_contains': f }],
            [{ 'source.name_contains': f }],
            [{ 'source.family_name_contains': f }],
            [{ 'source.email_contains': f }],
          ],
        },
      };
    }

    if (2 === searchTokens.length) {
      // assume the user has written firstName and lastName
      const [ f, l ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'source.name_contains': f, 'source.family_name_contains': l }],
            [{ 'source.name_contains': l, 'source.family_name_contains': f }],
          ],
        },
      };
    }

    return {
    };
  }, [ search ]);

  const {
    items: something,
    itemsLoading: somethingLoading,
    itemsError: somethingError,
    pagination,
  } = useItems(CollectionKeys.Invitations,
    {
      defaultPagination: { limit: 5, sort: { order: 'desc', orderBy: 'created_at' } },
      filterQuery: { ...filterQuery, reason: InvitationReason.PGX_DOCTOR_CONSENT },
    });

  if (somethingError) {
    return <p>Failed to load clients</p>;
  }

  return (
    <AppTable
      columns={ tableColumns }
      paginationProps={ pagination }
      loading={ somethingLoading }
      data={ something }
      searchProps={ {
        search,
        setSearch,
      } }
    />
  );
};

export default ConsentsTable;
