import React from 'react';
import { useVitaminsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { greenColor } from './VitaminsPage';

export const profileToIcon = {
  vitaminA: { big: '/VITAMIN Α.jpg', small: '/VITAMIN Α_SMALL.jpg' },
  vitaminB6: { big: '/VITAMIN Β6.jpg', small: '/VITAMIN Β6_SMALL.jpg' },
  vitaminB9Acid: { big: '/VITAMIN Β9.jpg', small: '/VITAMIN Β9_SMALL.jpg' },
  vitaminB12: { big: '/VITAMIN Β12.jpg', small: '/VITAMIN Β12_SMALL.jpg' },
  vitaminC: { big: '/VITAMIN C.jpg', small: '/VITAMIN C_SMALL.jpg' },
  vitaminD: { big: '/VITAMIN D.jpg', small: '/VITAMIN D_SMALL.jpg' },
  vitaminE: { big: '/VITAMIN Ε.jpg', small: '/VITAMIN Ε_SMALL.jpg' },
};

export const profileToUrl = {
  vitaminA: 'vitaminsVitaminA',
  vitaminB6: 'vitaminsVitaminB6',
  vitaminB9Acid: 'vitaminsVitaminB9Acid',
  vitaminB12: 'vitaminsVitaminB12',
  vitaminC: 'vitaminsVitaminC',
  vitaminD: 'vitaminsVitaminD',
  vitaminE: 'vitaminsVitaminE',
};

const NVitamins = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useVitaminsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Βιταμίνες' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('VCAT2' === filtered.ID) {
      iconPath = profileToIcon.vitaminA;
      url = profileToUrl.vitaminA;
    } else if ('VCAT3' === filtered.ID) {
      iconPath = profileToIcon.vitaminB6;
      url = profileToUrl.vitaminB6;
    } else if ('VCAT4' === filtered.ID) {
      iconPath = profileToIcon.vitaminB9Acid;
      url = profileToUrl.vitaminB9Acid;
    } else if ('VCAT5' === filtered.ID) {
      iconPath = profileToIcon.vitaminB12;
      url = profileToUrl.vitaminB12;
    } else if ('VCAT6' === filtered.ID) {
      iconPath = profileToIcon.vitaminC;
      url = profileToUrl.vitaminC;
    } else if ('VCAT7' === filtered.ID) {
      iconPath = profileToIcon.vitaminD;
      url = profileToUrl.vitaminD;
    } else if ('VCAT8' === filtered.ID) {
      iconPath = profileToIcon.vitaminE;
      url = profileToUrl.vitaminE;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: greenColor,
    id: 'vitaminsPage',
    title: 'Βιταμίνες',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext
        options={ vitaminsCard }
        activePage='vitaminsVitamins'
        isNutrition
      />
      <PhotoWithText
        options={ vitaminsCard }
        activePage='vitamins'
        text={ data[0].value.vitamins_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
    </div>
  );
};

export default NVitamins;
