import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';

const useClasses = makeStyles((theme) => {
  return {
    iconContainer: {
      borderRadius: 8,
      padding: theme.spacing(1),
      '&:hover $icon': {
        color: theme.palette.warning.main,
      },
    },
    icon: {
    },
  };
});

const AppIconEdit = (props) => {
  const classes = useClasses();

  if (props.disabled) {
    return (
      <IconButton className={ classes.iconContainer } { ...props } aria-label='edit'>
        <EditIcon className={ classes.icon } />
      </IconButton>
    );
  }

  return (
    <Tooltip title='Edit' aria-label='edit'>
      <IconButton className={ classes.iconContainer } { ...props } aria-label='edit'>
        <EditIcon className={ classes.icon } />
      </IconButton>
    </Tooltip>
  );
};

export default AppIconEdit;
