import React from 'react';
import { useTranslation } from 'react-i18next';
import { NavigationProvider, useNavigation } from 'NavigationProvider';
import NavigationMenu from 'components/app/NavigationMenu';
import { Divider, Grid, makeStyles, Typography } from '@material-ui/core';
import useMediaQueries from 'hooks/media-query-hooks';
import PillButton from 'components/app/PillButton';
import { MyDocPages } from '../pages';

const useStyles = makeStyles((theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
  };
});

const Pages = () => {
  const { t } = useTranslation();
  const { activePageId: pageId } = useNavigation();
  const {
    title: activeTitle,
    Component: ActivePage,
  } = MyDocPages.find((item) => {
    return item.id === pageId;
  });
  return <ActivePage title={ t(activeTitle) } />;
};

const MyDoctors = (props) => {
  const { className, title, ...rest } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { isMd } = useMediaQueries();
  const { navigateToPage } = useNavigation();

  const handleInviteDoctor = () => {
    navigateToPage({ pageId: 'invite-a-doctor' });
  };

  return (
    <>
      <div className={ className } { ...rest }>
        <Grid container spacing={ isMd ? 4 : 2 }>
          <Grid item xs={ 12 }>
            <div className={ classes.titleCta }>
              <Typography variant='h6' color='textPrimary'>
                {title}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={ 12 }>
            <Divider />
          </Grid>
          <NavigationProvider pageKey='sid' fallbackPageId='my-doctors'>
            <NavigationMenu pages={ MyDocPages } />
            <Grid container style={ { padding: '48px 24px' } } spacing={ 2 }>
              <Pages />
            </Grid>
          </NavigationProvider>
        </Grid>
        <PillButton onClick={ handleInviteDoctor }>{t('inviteADoctor')}</PillButton>
      </div>
    </>
  );
};

export default MyDoctors;

