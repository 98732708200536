import { Grid, List, ListItem, ListItemText, makeStyles } from '@material-ui/core';
import React from 'react';
import { useDatetimeFormat } from 'utils';
import clsx from 'clsx';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import AppPagination from 'components/app/AppPagination';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    list: {
      width: '100%',
    },
    listItem: {
      marginBottom: '1px',
    },
    listItemOdd: {
      backgroundColor: theme.palette.alternate.light,
    },
    listItemEven: {
      backgroundColor: 'white',
    },
    listItemOddActive: {
      backgroundColor: '#ffeee0',
    },
    listItemEvenActive: {
      backgroundColor: '#ffe5ce',
    },
  };
});

const InvitationItem = ({ invitation, i }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const dateFormat = useDatetimeFormat();

  let reason = '';
  if ('healthcare-professional-share-results' === invitation.reason) {
    reason = t('notificationsPage.healthcare.request');
  } else if ('pgx-doctor-consent' === invitation.reason) {
    reason = t('notificationsPage.pgx.request');
  }

  return (
    <ListItem
      className={
        clsx(
          classes.listItem,
          i % 2 ? (
            invitation.read ? classes.listItemEven : classes.listItemEvenActive
          ) : (invitation.read ? classes.listItemOdd : classes.listItemOddActive),
        )
      }
    >
      <ListItemText
        primary={ `${ t('doctorHealthProfessional') }: ${ invitation.metadata.firstName } ${ invitation.metadata.lastName } ${ invitation.metadata.profession ? `(${ t(invitation.metadata.profession) })` : '' }` }
        secondary={ `${ dateFormat(invitation.created_at) } - ${ reason }` }
      />
      {'pending' === invitation.status && t('On hold')}
      {'reject' === invitation.status && t('rejected')}
      {'accept' === invitation.status && t('accepted')}
    </ListItem>
  );
};

const DoctorConsents = () => {
  const {
    items: invitations,
    isLoading: invitationsLoading,
    pagination,
  } = useItems(CollectionKeys.Invitations, {
    filterQuery: {
      status_nin: [ 'accept' ],
    },

  });

  if (invitationsLoading) {
    return <p>Loading</p>;
  }

  return (
    <>
      <Grid container>
        <Grid item>
          <AppPagination
            page={ pagination.page }
            setPage={ pagination.setPage }
            count={ pagination.count }
            limit={ pagination.limit }
          />
        </Grid>
      </Grid>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <List>
            {
              invitations?.map((invitation, i) => {
                return (
                  <InvitationItem
                    key={ invitation.id }
                    invitation={ invitation }
                    i={ i }
                  />
                );
              })
            }
          </List>
        </Grid>
      </Grid>
    </>
  );
};
export default DoctorConsents;
