import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      background: theme.palette.alternate.light,
      marginTop: '100px',
    },
    inner: {
      margin: '0 auto',
      padding: theme.spacing(6, 2),
      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(8, 8),
      },
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(12, 8),
      },
    },
    innerNarrowed: {
      maxWidth: 800,
    },
  };
});

const SectionAlternate = (props) => {
  const { children, innerNarrowed, className, ...rest } = props;

  const classes = useStyles();

  return (
    <section
      className={ clsx('section-alternate', classes.root, className) }
      { ...rest }
    >
      <div
        className={ clsx(
          'section-alternate__content',
          classes.inner,
          innerNarrowed ? classes.innerNarrowed : {},
        ) }
      >
        {children}
      </div>
    </section>
  );
};

export default SectionAlternate;
