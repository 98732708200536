import { useItems } from './atoms/items-hook';
import CollectionKeys from './atoms/collection-keys';
import { useItem } from './atoms/item-hook';

export const useNotifications = (onlyUnread = false) => {
  const {
    items: notifications,
    itemsLoading: notificationsLoading,
    countFiltered: notificationsCount,
    itemsError: notificationsError,
    pagination,
  } = useItems(
    CollectionKeys.Notifications,
    {
      filterQuery: {
        ...onlyUnread && { read: false },

        _sort: 'read:asc,created_at:desc',
      },
      defaultPagination: {
        limit: 5,
        // sort: 'read:desc,created_at:desc',
        // sort: [
        //   { order: 'desc', orderBy: 'read' },
        //   { order: 'desc', orderBy: 'created_at' }],
      },
    },
  );

  return {
    notifications,
    notificationsLoading,
    notificationsError,
    notificationsCount,
    pagination,
  };
};

export const useNotification = (notificationId, queryOptions) => {
  const {
    item: notification,
    itemIsLoading: notificationIsLoading,
    itemIsUpdating: notificationIsUpdating,
    updateItem,
    deleteItem,
    itemIsDeleting: notificationIsDeleting,
  } = useItem(CollectionKeys.Notifications, notificationId, { queryOptions });

  const readNotification = () => {
    return updateItem({ read: true });
  };

  const deleteNotification = () => {
    return deleteItem({ id: notificationId });
  };

  return {
    notification,
    notificationIsLoading,
    notificationIsUpdating,
    readNotification,
    deleteNotification,
    notificationIsDeleting,
  };
};
