import { useTranslation } from 'react-i18next';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useNavigation } from '../../../NavigationProvider';

const usePageStyles = makeStyles(() => {
  return {
    root: {
      // width: '100%', // parent component has flex-direction: column
      alignSelf: 'stretch',
      height: 'auto', // must learn why this works
    },
  };
});

const findPage = (items, thePageId) => {
  for (let i = 0; i < items.length; i++) {
    if (items[i].id === thePageId) { return items[i]; }

    if ((Object.prototype.hasOwnProperty.call(items[i], 'items'))) {
      const possiblePage = findPage(items[i].items, thePageId);
      if (possiblePage) { return possiblePage; }
    }
  }

  return false;
};

const Pages = (
  {
    pages,
  },
) => {
  const { t } = useTranslation();
  const pageClasses = usePageStyles();
  const { activePageId: pageId } = useNavigation();

  const {
    title: activeTitle,
    Component: ActivePage,
    wrapperComponent: WrapperComponent,
  } = findPage(pages, pageId);

  if (!WrapperComponent) {
    return <ActivePage className={ pageClasses.root } title={ t(activeTitle) } />;
  }

  return (
    <WrapperComponent>
      <ActivePage className={ pageClasses.root } title={ t(activeTitle) } />
    </WrapperComponent>
  );
};

export default Pages;
