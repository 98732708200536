import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { getCountryCallingCode } from 'libphonenumber-js';
// import MenuItem from '@material-ui/core/MenuItem';
// import Select from '@material-ui/core/Select';
// import { Typography } from '@material-ui/core';
import FormControl from '@material-ui/core/FormControl';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

export const supportedTelephoneCountries = [
  'AD',
  'AL',
  'AT',
  'AX',
  'BA',
  'BE',
  'BG',
  'BY',
  'CH',
  'CY',
  'CZ',
  'DE',
  'DK',
  'EE',
  'ES',
  'FI',
  'FO',
  'FR',
  'GB',
  'GG',
  'GR',
  'HR',
  'HU',
  'IS',
  'IE',
  'IM',
  'IT',
  'JE',
  'LI',
  'LT',
  'LU',
  'LV',
  'MC',
  'MD',
  'MK',
  'MT',
  'NL',
  'NO',
  'PL',
  'PT',
  'RO',
  'RU',
  'SE',
  'SI',
  'SJ',
  'SK',
  'SM',
  'UA',
  'US',
  'VA',
  'IDNA',
];

const CountrySelect = ({ name, value, onChange, readOnly, disabled }) => {
  const supportedTelephoneCountriesWithNum = useMemo(() => {
    return supportedTelephoneCountries.map((tc) => {
      if ('IDNA' === tc) {
        return tc;
      }

      return `${ tc } +${ getCountryCallingCode(tc) }`;
    });
  }, [ supportedTelephoneCountries ]);

  const fullValue = useMemo(() => {
    if ('IDNA' === value) {
      return value;
    }
    return `${ value } +${ getCountryCallingCode(value) }`;
  }, [ value ]);

  return (
    <FormControl
      style={ { minWidth: '75px', paddingLeft: '0' } }
      size='small'
      disabled={ disabled }
    >
      {/* <Select
        { ...rest }
        variant='standard'
        color='secondary'
        autoWidth
        style={ {
          padding: 0,
        } }
        disabled={ !!readOnly || disabled }
        name={ name }
        value={ value }
        onChange={ (event) => {
          return onChange(event.target.value || 'international');
        } }
      >
        <MenuItem value='international'>
          <Typography component='div' variant='subtitle2'>
            {t('International')}
          </Typography>
        </MenuItem>
        {
          getCountries()
            .filter((country) => {
              return supportedTelephoneCountries.includes(country);
            })
            .map((country) => {
              return (
                <MenuItem key={ country } value={ country }>
                  <Typography component='div' variant='subtitle2'>
                    {`${ country } +${ getCountryCallingCode(country) }`}
                  </Typography>
                </MenuItem>
              );
            })
        }
      </Select> */}

      <Autocomplete
        id='country-select-demo'
        options={ supportedTelephoneCountriesWithNum }
        disableClearable
        fullWidth
        autoHighlight
        filterOptions={ (opt) => {
          return opt.filter((s) => {
            return 'IDNA' !== s;
          });
        } }
        ListboxProps={ { style: { minWidth: '200px' } } }
        value={ fullValue }
        size='small'
        onChange={ (event, newValue) => {
          if (null != onChange) {
            const parsedValue = newValue.split('+')?.[0].trimEnd();
            onChange(parsedValue);
          }
        } }
        renderOption={ (option) => {
          return (
            <Typography variant='subtitle2'>{option}</Typography>
          );
        } }
        disabled={ !!readOnly }
        color='secondary'
        renderInput={ (params) => {
          return (
            <TextField
              { ...params }
              // variant='outlined'
              margin='dense'
              color='secondary'
              size='small'
              name={ name }
              fullWidth
              style={ {
                paddingRight: '0',
              } }
              inputProps={ {
                ...params.inputProps,
                autoComplete: 'new-password', // disable autocomplete and autofill
                style: {
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  fontWeight: 500,
                  fontSize: '0.805rem',
                  lineHeight: '1.57',
                  letterSpacing: '0.00714em',
                },
              } }
            />
          );
        } }
      />

    </FormControl>
  );
};

CountrySelect.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.oneOf([ ...supportedTelephoneCountries ]).isRequired,
  onChange: PropTypes.func.isRequired,
  readOnly: PropTypes.bool,
};

CountrySelect.defaultProps = {
  readOnly: false,
};

export default CountrySelect;
