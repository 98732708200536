import { Grid, Typography } from '@material-ui/core';
import useDoctorNavigationPages from 'components/layout/doctor-dashboard/navigationPages';
import { useNavigation } from 'NavigationProvider';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { Page, TopPart, useStyles } from './WelcomePage';

const DoctorWelcomePage = () => {
  const navigationPages = useDoctorNavigationPages();
  const classes = useStyles();
  const { navigateToPage } = useNavigation();
  const { t } = useTranslation();

  const getPages = useCallback((pages, folderName = null) => {
    const isHiddenFolder = 'Εγγραφή & αποτελέσματα ασθενών/πελατών' === folderName
    || 'Εγγραφή & αποτελέσματα ασθενών/πελατών' === folderName
    || null === folderName;

    return (
      <div
        style={ {
          // border: isHiddenFolder ? 'none' : '1px solid black',
          padding: isHiddenFolder ? '0' : '20px',
          borderRadius: '15px',
          width: null !== folderName ? '100%' : '',
          margin: '15px 0',
          boxShadow: isHiddenFolder ? 'none' : 'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',
        } }
      >
        <Typography
          style={ { display: isHiddenFolder ? 'none' : '' } }
          variant='h5'
        >
          {t(folderName)}
        </Typography>
        <Grid
          item
          container
          xs={ 12 }
          spacing={ 2 }
        >
          {
            pages.filter((page) => {
              if ('general' === page.id) {
                return false;
              }

              const {
                isMenuHidden,
              } = { ...{ isMenuHidden: false }, ...page };
              return !isMenuHidden;
            }).map((page) => {
              const href = `/?pid=${ page.id }`;

              if ('folder' === page.type) {
                return (getPages(page.items, page.title));
              }

              return (
                <Grid
                  className={ classes.pageGridItem }
                  key={ href }
                  item
                  xs={ 12 }
                  md={ 6 }
                  lg={ 4 }
                  style={ {
                    // boxShadow: 'rgba(0, 0, 0, 0.1) 0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px',
                    // maxWidth: '30%',
                    width: 'auto',
                  } }
                  onClick={
                    (e) => {
                      e.preventDefault();
                      if (true !== page.isDisabled) {
                        navigateToPage({ pageId: page.id });
                      }
                    }
                  }
                >
                  <Page pageInfo={ page } styling={ { width: 'auto' } } />
                </Grid>
              );
            })
          }
        </Grid>
      </div>
    );
  }, [ navigationPages ]);

  return (
    <div>
      <TopPart isDoctor />
      <div
        style={ { background: '#f7f7f7', margin: '15px 0px 0px 0px', padding: '10px', marginTop: '-10px' } }
      >
        {getPages(navigationPages)}
      </div>
    </div>
  );
};

export default DoctorWelcomePage;
