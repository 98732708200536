import axios from 'axios';

import enUS from './assets/locales/en-US';
import elGR from './assets/locales/el-GR';

const _resources = {
  'en-US': enUS,
  'el-GR': elGR,
};

export const loadResources = async (locale) => {
  if ('el-GR' !== locale && 'en-US' !== locale) {
    return null;
  }

  const language = 'el-GR' === locale ? 'el-GR' : 'en-US';

  // eslint-disable-next-line no-unreachable
  return axios.get(`${ process.env.REACT_APP_API_URL }content-translations?language_eq=${ language }`,
    {
      headers: {
        'x-api-key': 'mobile_customer_secret_key_8bjg2a91-4519-6924-a4hi-5cc732edg79k',
      },
    })
    .then((response) => {
      if ('el-GR' === locale) {
        return {
          ...response.data[0].value,
          ...elGR.translation,
        };
      }

      if ('en-US' === locale) {
        return {
          ...response.data[0].value,
          ...enUS.translation,
        };
      }

      return response.data[0].value;
    })
    .catch((error) => { console.error(error); });
};

export const backendOptions = {
  loadPath: '{{lng}}|{{ns}}',
  request: (options, url, payload, callback) => {
    try {
      const [ lng ] = url.split('|');

      loadResources(lng).then((response) => {
        callback(null, {
          data: response,
          status: 200,
        });
      });
    } catch (e) {
      console.error(e);
      callback(null, {
        status: 500,
      });
    }
  },
};

export const getLanguageFromIP = async () => {
  const response = await fetch('https://api.country.is').then((resp) => {
    return resp.json();
  }).catch(() => {
    return 'GR';
  });

  if (null == response) {
    return 'el-GR';
  }

  const { country } = response;

  return ('GR' === country || 'GR' === response) ? 'el-GR' : 'en-US';
};

export const LanguageDetector = {
  type: 'languageDetector',
  async: true,
  detect: (callback) => {
    return getLanguageFromIP().then((res) => {
      callback(res);
    });
  },
  init: () => {},
  cacheUserLanguage: () => {},
};
