import React from 'react';
import { useVitaminsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { greenColor } from '../VitaminsPage';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NTraceElements';
import { adviceImages } from '../../iDNA3ToOne/TraceElements';

const NHighIron = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useVitaminsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Ιχνοστοιχεία' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';

    if ('VCAT9' === filtered.ID) {
      iconPath = profileToIcon.lowCalcium;
      url = profileToUrl.lowCalcium;
    } else if ('VCAT10' === filtered.ID) {
      iconPath = profileToIcon.highCalcium;
      url = profileToUrl.highCalcium;
    } else if ('VCAT11' === filtered.ID) {
      iconPath = profileToIcon.lowIron;
      url = profileToUrl.lowIron;
    } else if ('VCAT12' === filtered.ID) {
      iconPath = profileToIcon.highIron;
      url = profileToUrl.highIron;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('VCAT13' === filtered.ID) {
      iconPath = profileToIcon.magnesium;
      url = profileToUrl.magnesium;
    }

    return { ...filtered, icon: iconPath, url, prefix };
  });

  const tab = {
    color: greenColor,
    id: 'vitaminsPage',
    title: 'Βιταμίνες',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ vitaminsCard } activePage='vitaminsTraceElements' isNutrition />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Υπερφόρτωση σιδήρου'
        activeColor={ vitaminsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.highIron }
      />
    </div>
  );
};

export default NHighIron;
