import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { useOptionSerial } from 'NavigationProvider';
import { iDNA3ToOneCards } from './iDNA3ToOne';
import { Tabs, PhotoWithText, ProfileCards, PrevNext } from './Common';

export const profileToIcon = {
  kneeElbowInjury: { big: '/TRAYMATISMOS GONATO.jpg', small: '/TRAYMATISMOS GONATO_SMALL.jpg' },
  achillesTendonInjury: { big: '/AXILLEIOS TENONTAS.jpg', small: '/AXILLEIOS TENONTAS_SMALL.jpg' },
  musculoskeletalHealth: { big: '/MYOSKELETIKI YGEIA.jpg', small: '/MYOSKELETIKI YGEIA_SMALL.jpg' },
  recoveryAfterExercise: { big: '/APOTHERAPEIA.jpg', small: '/APOTHERAPEIA_SMALL.jpg' },
  painTolerance: { big: '/ANTOXI STON PONO.jpg', small: '/ANTOXI STON PONO_SMALL.jpg' },
};

export const profileToUrl = {
  kneeElbowInjury: 'kneeElbowInjury',
  achillesTendonInjury: 'achillesTendonInjury',
  musculoskeletalHealth: 'musculoskeletalHealth',
  recoveryAfterExercise: 'recoveryAfterExercise',
  painTolerance: 'painTolerance',
};

export const adviceImages = {
  kneeElbowInjury: { left: null, right: null },
  achillesTendonInjury: { left: null, right: null },
  musculoskeletalHealth: { left: null, right: null },
  recoveryAfterExercise: { left: null, right: null },
  painTolerance: { left: null, right: null },
};

const InjuryPredisposition = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'SCAT8', 'SCAT9', 'SCAT10', 'SCAT11', 'SCAT7' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('SCAT8' === filtered.ID) {
      iconPath = profileToIcon.kneeElbowInjury;
      url = profileToUrl.kneeElbowInjury;
    } else if ('SCAT9' === filtered.ID) {
      iconPath = profileToIcon.achillesTendonInjury;
      url = profileToUrl.achillesTendonInjury;
    } else if ('SCAT10' === filtered.ID) {
      iconPath = profileToIcon.musculoskeletalHealth;
      url = profileToUrl.musculoskeletalHealth;
    } else if ('SCAT11' === filtered.ID) {
      iconPath = profileToIcon.recoveryAfterExercise;
      url = profileToUrl.recoveryAfterExercise;
    } else if ('SCAT7' === filtered.ID) {
      url = profileToUrl.painTolerance;
      iconPath = profileToIcon.painTolerance;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ sportsCard } activePage='injuryPredisposition' />
        <PhotoWithText
          options={ sportsCard }
          activePage='injuryPredisposition'
          text={ data[0].value.injury_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ sportsCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default InjuryPredisposition;
