import React from 'react';
import { useVitaminsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { greenColor } from './VitaminsPage';

export const profileToIcon = {
  lowCalcium: { big: '/ASVESTIO - XAMHLA EPIPEDA ASVESTIOU.jpg', small: '/ASVESTIO - XAMHLA EPIPEDA ASVESTIOU_SMALL.jpg' },
  highCalcium: { big: '/ASVESTIO - AYXHMENH SYGENTRWSH ASVESTIOU.jpg', small: '/ASVESTIO - AYXHMENH SYGENTRWSH ASVESTIOU_SMALL.jpg' },
  lowIron: { big: '/SIDIROS-HAMILA EPIPEDA SIDIROU.jpg', small: '/SIDIROS-HAMILA EPIPEDA SIDIROU_SMALL.jpg' },
  highIron: { big: '/SIDIROS-YPERFORTOSI SIDIROU.jpg', small: '/SIDIROS-YPERFORTOSI SIDIROU_SMALL.jpg' },
  magnesium: { big: '/MAGNHSIO.jpg', small: '/MAGNHSIO_SMALL.jpg' },
};

export const profileToUrl = {
  lowCalcium: 'vitaminsLowCalcium',
  highCalcium: 'vitaminsHighCalcium',
  lowIron: 'vitaminsLowIron',
  highIron: 'vitaminsHighIron',
  magnesium: 'vitaminsMagnesium',
};

const TraceElements = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useVitaminsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Ιχνοστοιχεία' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('VCAT9' === filtered.ID) {
      iconPath = profileToIcon.lowCalcium;
      url = profileToUrl.lowCalcium;
    } else if ('VCAT10' === filtered.ID) {
      iconPath = profileToIcon.highCalcium;
      url = profileToUrl.highCalcium;
    } else if ('VCAT11' === filtered.ID) {
      iconPath = profileToIcon.lowIron;
      url = profileToUrl.lowIron;
    } else if ('VCAT12' === filtered.ID) {
      iconPath = profileToIcon.highIron;
      url = profileToUrl.highIron;
    } else if ('VCAT13' === filtered.ID) {
      iconPath = profileToIcon.magnesium;
      url = profileToUrl.magnesium;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: greenColor,
    id: 'vitaminsPage',
    title: 'Βιταμίνες',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext
        options={ vitaminsCard }
        activePage='vitaminsTraceElements'
        isNutrition
      />
      <PhotoWithText
        options={ vitaminsCard }
        activePage='traceElements'
        text={ data[0].value.elements_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
    </div>
  );
};

export default TraceElements;
