import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { AppCtx } from 'AppCtx';
import { usePerformUpdate } from 'components/UpdateAvailable';

const NavigationCtx = React.createContext({});

export const NavigationProvider = ({ pageKey, fallbackPageId, cardioSerial, _threeToOneSerial, children,
  _nutritionSerial, _vitaminsSerial, _sportsSerial, _bioAgeSerial, _pgxSerial, _nutrigenetixSerial }) => {
  const [ fallBackPage, setFallBackPage ] = useState(fallbackPageId);
  const [ activePageId, setActivePageId ] = useState(fallbackPageId);
  const [ fetchKits, setFetchKits ] = useState(0);
  // add state to take serial for results & pdf Viewer Component
  const [ serial, setSerial ] = useState(cardioSerial);
  const [ threeToOneSerial, setThreeToOneSerial ] = useState(_threeToOneSerial);
  const [ nutritionalSerial, setNutritionalSerial ] = useState(_nutritionSerial);
  const [ vitaminsSerial, setVitaminsSerial ] = useState(_vitaminsSerial);
  const [ sportsSerial, setSportsSerial ] = useState(_sportsSerial);
  const [ bioAgeSerial, setBioAgeSerial ] = useState(_bioAgeSerial);
  const [ pgxSerial, setPgxSerial ] = useState(_pgxSerial);
  const [ nutriGenetixSerial, setNutriGenetixSerial ] = useState(_nutrigenetixSerial);
  const location = useLocation();
  const history = useHistory();

  const performUpdate = usePerformUpdate();

  const { showUpdateAvailable } = React.useContext(AppCtx);

  const navigateToPage = ({ pageId, topLevel = false }) => {
    let { search } = location;
    search = search.replace('msg=success', '').replace('msg=error', '').replace('msg=cancel', '');

    const params = new URLSearchParams(search);
    params.delete(pageKey);
    if (pageId) {
      params.append(pageKey, pageId);
    }

    window.scrollTo(
      { top: 0 },
    );

    history.push({
      pathname: location.pathname,
      search: topLevel ? '' : params.toString(),
    });

    if (showUpdateAvailable) {
      performUpdate();
    }
  };

  useEffect(() => {
    const { search } = location;
    const pageId = new URLSearchParams(search).get(pageKey) || fallBackPage;
    setActivePageId(pageId);
  }, [ location, fallBackPage ]);

  useEffect(() => {
    return () => {
      // It's important to use history.location instead of just location
      // history is a mutable object so this is considered a hack
      // in order to remove the pageId param once the component is unmounted
      const { search } = history.location;
      const params = new URLSearchParams(search);
      params.delete(pageKey);
      history.replace({
        search: params.toString(),
      });
    };
  }, []);

  const ctx = {
    activePageId,
    navigateToPage,
    fetchKits,
    setFetchKits,
    serial,
    setSerial,
    threeToOneSerial,
    setThreeToOneSerial,
    nutritionalSerial,
    setNutritionalSerial,
    vitaminsSerial,
    setVitaminsSerial,
    sportsSerial,
    setSportsSerial,
    bioAgeSerial,
    setBioAgeSerial,
    pgxSerial,
    setPgxSerial,
    nutriGenetixSerial,
    setNutriGenetixSerial,
    fallBackPage,
    setFallBackPage,
  };

  return (
    <NavigationCtx.Provider value={ ctx }>
      {children}
    </NavigationCtx.Provider>
  );
};

export const useNavigation = () => {
  const {
    activePageId,
    navigateToPage,
  } = React.useContext(NavigationCtx);

  return {
    activePageId,
    navigateToPage,
  };
};

export const useFetching = () => {
  const {
    fetchKits,
    setFetchKits,
  } = React.useContext(NavigationCtx);

  return {
    fetchKits,
    setFetchKits,
  };
};

export const useFallBackPage = () => {
  const {
    fallBackPage,
    setFallBackPage,
  } = React.useContext(NavigationCtx);

  return {
    fallBackPage,
    setFallBackPage,
  };
};

// add hook to have Serial in context
export const useSerial = () => {
  const {
    serial,
    setSerial,
  } = React.useContext(NavigationCtx);

  return {
    serial,
    setSerial,
  };
};

export const useOptionSerial = (option) => {
  let theSerial;
  let setSerial;

  if ('IDNA Cardio Health' === option) {
    theSerial = React.useContext(NavigationCtx).serial;
    setSerial = React.useContext(NavigationCtx).setSerial;
  } else if ('3-in-1' === option) {
    theSerial = React.useContext(NavigationCtx).threeToOneSerial;
    setSerial = React.useContext(NavigationCtx).setThreeToOneSerial;
  } else if ('nutrition' === option) {
    theSerial = React.useContext(NavigationCtx).nutritionalSerial;
    setSerial = React.useContext(NavigationCtx).setNutritionalSerial;
  } else if ('vitamins' === option) {
    theSerial = React.useContext(NavigationCtx).vitaminsSerial;
    setSerial = React.useContext(NavigationCtx).setVitaminsSerial;
  } else if ('sports' === option) {
    theSerial = React.useContext(NavigationCtx).sportsSerial;
    setSerial = React.useContext(NavigationCtx).setSportsSerial;
  } else if ('bioAge' === option) {
    theSerial = React.useContext(NavigationCtx).bioAgeSerial;
    setSerial = React.useContext(NavigationCtx).setBioAgeSerial;
  } else if ('pgx' === option) {
    theSerial = React.useContext(NavigationCtx).pgxSerial;
    setSerial = React.useContext(NavigationCtx).setPgxSerial;
  } else if ('nutriGenetix' === option) {
    theSerial = React.useContext(NavigationCtx).nutriGenetixSerial;
    setSerial = React.useContext(NavigationCtx).setNutriGenetixSerial;
  }

  const serial = theSerial;

  return {
    serial,
    setSerial,
  };
};
