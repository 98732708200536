
const CollectionKeys = {
  Kits: 'registration-kits',
  CustomerDoctorKits: 'customer-doctor-kits',
  CustomerDoctor: 'customer-doctors',
  Invitations: 'invitations',
  Notifications: 'notifications',
  DoctorSortedKits: 'registration-kits/sort-doctor-kits',
  SerialNumbers: 'serial-numbers',
};

export default CollectionKeys;
