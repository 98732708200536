import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import React, { createContext, useContext, useEffect, useState } from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import AppIconRemove from './app-icons/AppIconRemove';
import AppIconAdd from './app-icons/AppIconAdd';

const AdvancedFilterContext = createContext({});

const AdvancedAndFilterProvider = (
  {
    initialAndFilterOptions,
    properties,
    onQueryChange,
    children,
  },
) => {
  const [ filterOptions, setFilterOptions ] = useState(initialAndFilterOptions);
  const [ query, setQuery ] = useState(null);

  useEffect(() => {
    onQueryChange(query);
  }, [ query ]);

  const makeQuery = () => {
    const q = {
      _where: {
        _or: [
          /*
          [{ stars: 2 }, { pricing_lt: 80 }], // implicit AND
          */

          filterOptions
            .filter((f) => {
              return !!f.operation && !!f.value?.length;
            })
            .map((f) => {
              const complexKey = `${ f.property }_${ f.operation }`;
              const numberRegex = new RegExp(/^[+-]?\d+(\.\d+)?$/);
              return {
                [complexKey]: numberRegex.test(f.value) ? parseFloat(f.value) : f.value,
              };
            }),
        ],
      },
    };
    setQuery(q);
    return q;
  };

  const getFilterByIdx = (idx) => {
    return filterOptions[idx];
  };

  const resetFilterAtIdx = (idx) => {
    setFilterOptions((prevFilterOptions) => {
      return [ ...prevFilterOptions.slice(0, idx), {}, ...prevFilterOptions.slice(idx + 1) ];
    });
  };

  const setFilterAtIdx = (idx, filterProperty, value) => {
    setFilterOptions((prevFilterOptions) => {
      const f = prevFilterOptions[idx];
      return [ ...prevFilterOptions.slice(0, idx), { ...f, [filterProperty]: value }, ...prevFilterOptions.slice(idx + 1) ];
    });
  };

  const setFilterPropertyAtIdx = (idx, value) => {
    resetFilterAtIdx(idx);
    setFilterAtIdx(idx, 'property', value);
  };

  const setFilterOperationAtIdx = (idx, value) => {
    setFilterAtIdx(idx, 'operation', value);
  };

  const setFilterValueAtIdx = (idx, value) => {
    setFilterAtIdx(idx, 'value', value);
  };

  const addFilter = () => {
    return setFilterOptions((prev) => {
      return [ ...prev, { }];
    });
  };

  const removeFilterAtIdx = (idx) => {
    setFilterOptions((prev) => {
      return [ ...prev.slice(0, idx), ...prev.slice(idx + 1) ];
    });
  };

  const ctx = {
    filterOptions,
    getFilterByIdx,
    setFilterPropertyAtIdx,
    setFilterOperationAtIdx,
    setFilterValueAtIdx,
    addFilter,
    removeFilterAtIdx,
    properties,
    makeQuery,
  };

  return (
    <AdvancedFilterContext.Provider value={ ctx }>
      {children}
    </AdvancedFilterContext.Provider>
  );
};

const useAdvancedAndFilter = () => {
  const {
    filterOptions,
    getFilterByIdx,
    setFilterPropertyAtIdx,
    setFilterOperationAtIdx,
    setFilterValueAtIdx,
    addFilter,
    removeFilterAtIdx,
    properties,
    makeQuery,
  } = useContext(AdvancedFilterContext);

  return {
    filterOptions,
    getFilterByIdx,
    setFilterPropertyAtIdx,
    setFilterOperationAtIdx,
    setFilterValueAtIdx,
    addFilter,
    removeFilterAtIdx,
    properties,
    makeQuery,
  };
};

export const filterTypes = {
  NUMERIC: 'numeric',
  FREE_TEXT: 'free_text',
  CATEGORY: 'category',
};

const filterOperations = {
  LESS_THAN: {
    op: 'lt',
    label: '<',
  },
  EQUAL_OR_LESS_THAN: {
    op: 'lte',
    label: '<=',
  },
  GREATER_THAN: {
    op: 'gt',
    label: '>',
  },
  EQUAL_OR_GREATER_THAN: {
    op: 'gte',
    label: '>=',
  },
  CONTAINS: {
    op: 'contains',
    label: 'Contains',
  },
  EQUALS: {
    op: 'eq',
    label: 'Exact',
  },
  IN: {
    op: 'in',
    label: 'One of',
  },
  NIN: {
    op: 'nin',
    label: 'Not one of',
  },
};

const filterTypeToOps = {
  [filterTypes.NUMERIC]: [
    filterOperations.LESS_THAN,
    filterOperations.EQUAL_OR_LESS_THAN,
    filterOperations.GREATER_THAN,
    filterOperations.EQUAL_OR_GREATER_THAN,
  ],
  [filterTypes.FREE_TEXT]: [
    filterOperations.EQUALS,
    filterOperations.CONTAINS,
  ],
  [filterTypes.CATEGORY]: [
    filterOperations.IN,
    filterOperations.NIN,
  ],
};

const SelectAux = (
  {
    value,
    onChange,
    options,
    disabledValue,
    ...rest
  },
) => {
  return (
    <FormControl fullWidth size='small' { ...rest }>
      <Select
        { ...rest }
        size='small'
        fullWidth
        variant='outlined'
        color='secondary'
        value={ value || disabledValue }
        onChange={ (event) => {
          return onChange(event.target.value);
        } }
      >
        <MenuItem disabled value={ disabledValue }>
          <Typography component='div' variant='subtitle2'>
            { disabledValue }
          </Typography>
        </MenuItem>
        {
          options.map((option) => {
            return (
              <MenuItem key={ option.value } value={ option.value }>
                <Typography component='div' variant='subtitle2'>
                  { option.label }
                </Typography>
              </MenuItem>
            );
          })
        }
      </Select>
    </FormControl>
  );
};

const StringValueField = ({ value, onChange }) => {
  return (
    <TextField
      variant='outlined'
      size='small'
      fullWidth
      value={ value }
      onChange={ (e) => { return onChange(e.target.value); } }
    />
  );
};

const MultipleValueField = ({ value, onChange, options }) => {
  return (
    <Autocomplete
      multiple
      size='small'
      options={ options }
      getOptionLabel={ (option) => { return option.label; } }
      value={ value }
      onChange={ (e, v) => {
        return onChange(v);
      } }
      filterSelectedOptions
      renderInput={ (params) => {
        return (
          <TextField
            { ...params }
            variant='outlined'
            size='small'
          />
        );
      } }
    />
  );
};

const FilterResolver = (
  {
    idx,
  },
) => {
  const {
    properties,
    getFilterByIdx,
    setFilterOperationAtIdx,
    setFilterValueAtIdx,
  } = useAdvancedAndFilter();

  const {
    value: filterValue,
    operation: filterOperation,
    property: filterProperty,
  } = getFilterByIdx(idx);

  const categoryOptions = properties.find((p) => { return p.value === filterProperty; }).options;
  const propertyType = properties.find((p) => { return p.value === filterProperty; }).type;

  const operationOptions = filterTypeToOps[propertyType];

  const selectedCategoryOptions = propertyType === filterTypes.CATEGORY ? filterValue?.map((v) => {
    return categoryOptions.find((o) => { return o.value === v; });
  }).filter((l) => { return !!l; }) : [];

  return (
    <Grid item container spacing={ 1 } style={ { flexWrap: 'nowrap' } }>
      <Grid item>
        <SelectAux
          value={ filterOperation || null }
          onChange={ (op) => { return setFilterOperationAtIdx(idx, op); } }
          options={ operationOptions.map((o) => { return { ...o, value: o.op }; }) }
          disabledValue={ null }
        />
      </Grid>
      <Grid item>
        {
          propertyType === filterTypes.CATEGORY
            ? (
              <MultipleValueField
                value={ selectedCategoryOptions || [] }
                onChange={ (v) => { return setFilterValueAtIdx(idx, v.map((o) => { return o.value; })); } }
                options={ categoryOptions }
              />
            )
            : (
              <StringValueField
                value={ filterValue || '' }
                onChange={ (v) => { return setFilterValueAtIdx(idx, v); } }
              />
            )
        }
      </Grid>
    </Grid>
  );
};

const FilterProperty = (
  {
    idx,
  },
) => {
  const { properties, getFilterByIdx, setFilterPropertyAtIdx } = useAdvancedAndFilter();

  const propertyValue = getFilterByIdx(idx).property || null;

  const selectedProperty = properties.find((p) => { return p.value === propertyValue; });

  return (
    <Grid item container spacing={ 1 } style={ { flexWrap: 'nowrap' } }>
      <Grid item style={ { minWidth: '150px' } }>
        <SelectAux
          value={ propertyValue }
          onChange={ (v) => { return setFilterPropertyAtIdx(idx, v); } }
          options={ properties }
          disabledValue={ null }
        />
      </Grid>
      <Grid item>
        {
          selectedProperty && (
            <FilterResolver idx={ idx } />
          )
        }
      </Grid>
    </Grid>
  );
};

const ExplicitAnd = () => {
  const { filterOptions, removeFilterAtIdx } = useAdvancedAndFilter();

  return (
    <Grid container spacing={ 1 } item>
      <Grid item>
        {
          filterOptions.map((f, index) => {
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Grid container spacing={ 1 } item key={ index } style={ { flexWrap: 'nowrap' } }>
                <Grid item>
                  <AppIconRemove onClick={ () => { return removeFilterAtIdx(index); } } />
                </Grid>
                <Grid item>
                  {/* eslint-disable-next-line react/no-array-index-key */}
                  <FilterProperty idx={ index } />
                </Grid>
              </Grid>
            );
          })
        }
      </Grid>
    </Grid>
  );
};

const AddFilterButton = () => {
  const { addFilter } = useAdvancedAndFilter();
  return (
    <Grid item container alignItems='center' spacing={ 1 }>
      <Grid item>
        <AppIconAdd onClick={ () => { return addFilter(); } } />
      </Grid>
      <Grid item>
        <Typography>
          Advanced filters
        </Typography>
      </Grid>
    </Grid>
  );
};

const ApplyFilters = () => {
  const { filterOptions, makeQuery } = useAdvancedAndFilter();

  if (!filterOptions.length) {
    return null;
  }

  return (
    <Grid item xs={ 12 } style={ { margin: '8px' } }>
      <Button onClick={ () => { return makeQuery(); } }>
        Apply filters
      </Button>
    </Grid>
  );
};
const AdvancedFilters = (
  {
    initialAndFilterOptions,
    properties,
    onQueryChange,
  },
) => {
  return (
    <AdvancedAndFilterProvider
      initialAndFilterOptions={ initialAndFilterOptions }
      properties={ properties }
      onQueryChange={ onQueryChange }
    >
      <Grid container style={ { margin: '16px 0' } }>
        <Grid item xs={ 12 }>
          <AddFilterButton />
        </Grid>
        <Grid item xs={ 12 }>
          <ExplicitAnd />
        </Grid>
        <ApplyFilters />
      </Grid>
    </AdvancedAndFilterProvider>
  );
};

AdvancedFilters.propTypes = {
  initialAndFilterOptions: PropTypes.arrayOf(PropTypes.shape({
    property: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.string),
      PropTypes.string,
    ]),
    operation: PropTypes.oneOf([ Object.values(filterOperations) ]),
  })).isRequired,
  properties: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf([ filterTypes.FREE_TEXT, filterTypes.NUMERIC ]).isRequired,
    }),
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      type: PropTypes.oneOf([ filterTypes.CATEGORY ]).isRequired,
      options: PropTypes.arrayOf(PropTypes.shape({
        value: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
      })).isRequired,
    }),
  ])).isRequired,
  onQueryChange: PropTypes.func,
};

AdvancedFilters.defaultProps = {
  onQueryChange: () => { return null; },
};

export default AdvancedFilters;

/**
 * <AdvancedFilters
 * properties={ [
 * { value: 'color', label: 'Color', type: filterTypes.CATEGORY, options: [{ value: 'red', label: 'Red' }, { value: 'blue', label: 'Blue' }] },
 * { value: 'size', label: 'Size', type: filterTypes.NUMERIC },
 * { value: 'paragraph', label: 'Paragraph', type: filterTypes.FREE_TEXT },
 * ] }
 * initialAndFilterOptions={ [] }
 * />
 */

