import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl, adviceImages } from '../Vitamins';

const VitaminB9Acid = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'VCAT2', 'VCAT3', 'VCAT4', 'VCAT5', 'VCAT6', 'VCAT7', 'VCAT8' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';
    const description = 'VCAT4_description';

    if ('VCAT2' === filtered.ID) {
      iconPath = profileToIcon.vitaminA;
      url = profileToUrl.vitaminA;
    } else if ('VCAT3' === filtered.ID) {
      iconPath = profileToIcon.vitaminB6;
      url = profileToUrl.vitaminB6;
    } else if ('VCAT4' === filtered.ID) {
      iconPath = profileToIcon.vitaminB9Acid;
      url = profileToUrl.vitaminB9Acid;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('VCAT5' === filtered.ID) {
      iconPath = profileToIcon.vitaminB12;
      url = profileToUrl.vitaminB12;
    } else if ('VCAT6' === filtered.ID) {
      iconPath = profileToIcon.vitaminC;
      url = profileToUrl.vitaminC;
    } else if ('VCAT7' === filtered.ID) {
      iconPath = profileToIcon.vitaminD;
      url = profileToUrl.vitaminD;
    } else if ('VCAT8' === filtered.ID) {
      iconPath = profileToIcon.vitaminE;
      url = profileToUrl.vitaminE;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ vitaminsCard } activePage='vitamins' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Βιταμίνη Β9 - Φολικό & Φυλλικό οξύ'
        activeColor={ vitaminsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.vitaminB9Acid }
      />
    </div>
  );
};

export default VitaminB9Acid;
