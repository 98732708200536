import React, { createRef, useEffect, useLayoutEffect, useState } from 'react';
import Alert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import Paper from '@material-ui/core/Paper';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      position: 'fixed',
      top: 80,
      right: theme.spacing(3),
      width: 350,
      maxHeight: '50vh',
      minHeight: 0,
      zIndex: 9998,
    },
    content: {
      maxHeight: '50vh',
      minHeight: 0,
      width: '100%',
      overflow: 'auto',
    },
    topGradient: {
      position: 'absolute',
      width: '100%',
      top: 0,
      left: 0,
      right: 0,
      height: 40,
      backgroundImage: 'linear-gradient(to top, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    },
    bottomGradient: {
      position: 'absolute',
      width: '100%',
      bottom: 0,
      left: 0,
      right: 0,
      height: 40,
      backgroundImage: 'linear-gradient(to bottom, rgba(255, 255, 255, 0), rgba(255, 255, 255, 1))',
    },
  };
});

const AlertsArea = (
  {
    alerts,
    alertsAutoHidePause,
    alertsAutoHideResume,
  },
) => {
  const classes = useStyles();
  const ref = createRef();
  const [ scrollable, setScrollable ] = useState(false);
  const [ showTopShadow, setShowTopShadow ] = useState(false);
  const [ showBottomShadow, setShowBottomShadow ] = useState(false);
  const theme = useTheme();
  const { t } = useTranslation();

  useLayoutEffect(() => {
    setScrollable(ref.current.clientHeight < ref.current.scrollHeight);
  }, [ ref ]);

  const handleOnScroll = () => {
    setShowTopShadow(5 < ref.current.scrollTop);
    setShowBottomShadow(5 < ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop);
  };

  useEffect(() => {
    const showTop = 5 < ref.current.scrollTop;
    const showBottom = 5 < ref.current.scrollHeight - ref.current.clientHeight - ref.current.scrollTop;
    if (showTop !== showTopShadow) { setShowTopShadow(showTop); }

    if (showBottom !== showBottomShadow) { setShowBottomShadow(showBottom); }
  }, [ ref, scrollable, showTopShadow, showBottomShadow ]);

  return (
    <div className={ classes.root }>
      {scrollable && showTopShadow && <div className={ classes.topGradient } />}
      <div
        ref={ ref }
        className={ classes.content }
        onMouseOver={ () => { return alertsAutoHidePause(); } }
        onFocus={ () => { return alertsAutoHidePause(); } }
        onMouseOut={ () => { return alertsAutoHideResume(); } }
        onBlur={ () => { return alertsAutoHideResume(); } }
        onScroll={ () => { return handleOnScroll(); } }
      >
        {
          alerts.map((alert) => {
            return (
              <Fade key={ alert.id } unmountOnExit in={ alert.show }>
                <Paper elevation={ 0 } style={ { marginBottom: theme.spacing(1) } }>
                  <Alert onClose={ alert.onClose } severity={ alert.type } closeText={ t('close') }>
                    {alert.message}
                  </Alert>
                </Paper>
              </Fade>
            );
          })
        }
      </div>
      {scrollable && showBottomShadow && <div className={ classes.bottomGradient } />}
    </div>
  );
};

export default AlertsArea;
