import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { iDNA3ToOneCards } from './iDNA3ToOne';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';

export const profileToIcon = {
  stamina: { big: '/ANTOXI.jpg', small: '/ANTOXI_SMALL.jpg' },
  strength: { big: '/MYIKI DYNAMH.jpg', small: '/MYIKI DYNAMH_SMALL.jpg' },
  power: { big: '/ISXYS.jpg', small: '/ISXYS_SMALL.jpg' },
  aerobics: { big: '/AEROVIKI IKANOTITA (Vo2max).jpg', small: '/AEROVIKI IKANOTITA (Vo2max)_SMALL.jpg' },
  increaseMuscle: { big: '/IKANOTHTA ANAPTYXHS MYIKHS MAZAS.jpg', small: '/IKANOTHTA ANAPTYXHS MYIKHS MAZAS_SMALL.jpg' },
  wantToExercise: { big: '/KINHTRO GIA ASKISI.jpg', small: '/KINHTRO GIA ASKISI_SMALL.jpg' },
};

export const profileToUrl = {
  stamina: 'stamina',
  strength: 'strength',
  power: 'power',
  aerobics: 'aerobics',
  increaseMuscle: 'increaseMuscle',
  wantToExercise: 'wantToExercise',
};

export const adviceImages = {
  stamina: { left: null, right: null },
  strength: { left: null, right: null },
  power: { left: null, right: null },
  aerobics: { left: null, right: null },
  increaseMuscle: { left: null, right: null },
  wantToExercise: { left: null, right: null },
};

const AthleticProfile = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'SCAT1', 'SCAT2', 'SCAT3', 'SCAT4', 'SCAT5', 'SCAT6' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('SCAT1' === filtered.ID) {
      iconPath = profileToIcon.stamina;
      url = profileToUrl.stamina;
    } else if ('SCAT2' === filtered.ID) {
      iconPath = profileToIcon.strength;
      url = profileToUrl.strength;
    } else if ('SCAT3' === filtered.ID) {
      iconPath = profileToIcon.power;
      url = profileToUrl.power;
    } else if ('SCAT4' === filtered.ID) {
      iconPath = profileToIcon.aerobics;
      url = profileToUrl.aerobics;
    } else if ('SCAT5' === filtered.ID) {
      iconPath = profileToIcon.increaseMuscle;
      url = profileToUrl.increaseMuscle;
    } else if ('SCAT6' === filtered.ID) {
      iconPath = profileToIcon.wantToExercise;
      url = profileToUrl.wantToExercise;
    }
    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ sportsCard } activePage='athleticProfile' />
        <PhotoWithText
          options={ sportsCard }
          activePage='athleticProfile'
          text={ data[0].value.sports_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ sportsCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default AthleticProfile;
