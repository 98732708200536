import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl, adviceImages } from '../WeightControl';

const Carbohydrates = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT3', 'NCAT6', 'NCAT12', 'NCAT9', 'NCAT19', 'NCAT8' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τους';
    let adviceImage;
    const description = 'NCAT3_description';

    if ('NCAT3' === filtered.ID) {
      adviceImage = adviceImages.carbohydrates;
      iconPath = profileToIcon.carbohydrates;
      url = profileToUrl.carbohydrates;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('NCAT6' === filtered.ID) {
      iconPath = profileToIcon.proteins;
      url = profileToUrl.proteins;
    } else if ('NCAT12' === filtered.ID) {
      iconPath = profileToIcon.fats;
      url = profileToUrl.fats;
    } else if ('NCAT9' === filtered.ID) {
      iconPath = profileToIcon.preferenceForSweetTaste;
      url = profileToUrl.preferenceForSweetTaste;
    } else if ('NCAT19' === filtered.ID) {
      iconPath = profileToIcon.biologicalClock;
      url = profileToUrl.biologicalClock;
    } else if ('NCAT8' === filtered.ID) {
      iconPath = profileToIcon.eatingSnacksBetweenMeals;
      url = profileToUrl.eatingSnacksBetweenMeals;
    }

    return { ...filtered, icon: iconPath, url, prefix, adviceImage, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ dietCard } activePage='weightManagement' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeColor={ dietCard.color }
        activeProfile='Υδατάνθρακες'
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.carbohydrates }
      />
    </div>
  );
};

export default Carbohydrates;
