import React, { useMemo } from 'react';
import { useCardioDataReport } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useOptionSerial } from 'NavigationProvider';
import { useTranslation, I18nextProvider } from 'react-i18next';
import i18nEng from '../../../i18n_eng';
import i18nEl from '../../../i18n_el';
import CardioResults, { ImageText } from '../Common';

const DiatatikiMyocardioPathia = () => {
  const { i18n } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { data, locale } = useCardioDataReport(serial, i18n.language);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null == data) {
    return <LoaderSkeleton />;
  }

  if (0 === data.length) {
    return (
      <h1>
        No data available
      </h1>
    );
  }

  const nosos = data[0].value['dilated cardiomyopathy'];

  const prsImage = [ JSON.parse(nosos._IMG_SCORE).base64, JSON.parse(nosos._IMG_SCORE_MOBILE).base64 ];
  const adjPrsImage = [ JSON.parse(nosos._IMG_ADJ_PRS_SCORE).base64, JSON.parse(nosos._IMG_ADJ_PRS_SCORE_MOBILE).base64 ];
  const prsScore = nosos.PRS_SCORE;
  const adjPrsScore = nosos.ADJPRS_SCORE;
  const prsCharacterisation = nosos.PRS_CHARACTERISATION;
  const traitProfile = nosos.TRAIT_PROFILE;
  const adjPrsCharacterisationLevels = nosos.ADJPRS_CHARACTERISATION_LEVELS;
  const adjPrsCharacterisationFem = nosos.ADJPRS_CHARACTERISATION_FEMALE;

  const imageContent = 'Η μυοκαρδιοπάθεια είναι μια ασθένεια του καρδιακού μυός που δυσκολεύει την καρδιά να μεταφέρει αίμα στο υπόλοιπο σώμα και μπορεί να οδηγήσει σε καρδιακή ανεπάρκεια. Η διατατική μυοκαρδιοπάθεια είναι ένας τύπος καρδιακής μυϊκής νόσου που προκαλεί τους θαλάμους της καρδιάς (κοιλίες) να λεπταίνουν και να τεντώνονται, και άρα να μεγαλώνουν. Ως αποτέλεσμα, δυσκολεύει την καρδιά να αντλεί αίμα στο υπόλοιπο σώμα. Ένα άτομο με διατατική μυοκαρδιοπάθεια μπορεί να μην παρατηρήσει κανένα σύμπτωμα στην αρχή, ενώ κοινά συμπτώματα, όπως η κόπωση και η δύσπνοια, μπορεί να μιμούνται άλλες νόσους. Η διατατική μυοκαρδιοπάθεια είναι μια κοινή αιτία καρδιακής ανεπάρκειας και μπορεί να γίνει απειλητική για τη ζωή.';

  let characterisation = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === characterisation) {
    characterisation = 'Τυπική';
  } else if ('HIGH' === characterisation) {
    characterisation = 'Βέλτιστη';
  } else if ('LOW' === characterisation) {
    characterisation = 'Mειωμένη';
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div>
        <CardioResults />
        <ImageText
          traitProfile={ traitProfile }
          adjPrsCharacterisationLevels={ adjPrsCharacterisationLevels }
          characterisation={ characterisation }
          adjPrsCharacterisationFem={ adjPrsCharacterisationFem }
          prsImage={ prsImage }
          adjPrsImage={ adjPrsImage }
          prsScore={ prsScore }
          adjPrsScore={ adjPrsScore }
          prsCharacterisation={ prsCharacterisation }
          activeProfile='Μυοκαρδιοπάθεια - Διατατική'
          imageTitle='Για τη Μυοκαρδιοπάθεια - Διατατική'
          imageContent={ imageContent }
          source='https://www.mayoclinic.org/diseases-conditions/dilated-cardiomyopathy/symptoms-causes/syc-20353149'
        />
      </div>
    </I18nextProvider>
  );
};

export default DiatatikiMyocardioPathia;
