import React, { useState } from 'react';
import InformativeDialog from './components/dialogs/InformativeDialog';

export const DialogsCtx = React.createContext({});

export function InformationDialogProvider(props) {
  const [ informativeDialog, setInformativeDialog ] = useState({ open: false, dialogProps: {} });

  const openInformativeDialog = (title, content) => {
    setInformativeDialog({
      open: true,
      dialogProps: {
        title,
        content,
      },
    });
  };

  const closeInformativeDialog = () => {
    setInformativeDialog({
      open: false,
      dialogProps: {},
    });
  };

  const isOpen = informativeDialog.open;

  const { dialogProps } = informativeDialog;

  const ctx = {
    openInformativeDialog,
    closeInformativeDialog,
  };

  return (
    <DialogsCtx.Provider value={ ctx }>
      {props.children}
      <InformativeDialog
        isOpen={ isOpen }
        dialogProps={ dialogProps }
        closeInformativeDialog={ closeInformativeDialog }
      />
    </DialogsCtx.Provider>
  );
}
