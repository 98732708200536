import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { Button } from '@material-ui/core';
import CircularProgress from '@material-ui/core/CircularProgress';
import React from 'react';
import { useTranslation } from 'react-i18next';

const FormSectionSubmit = (
  {
    loading,
    label,
    disabled,
    color = undefined,
    error = '',
    goToPrevPage = undefined,
    doSubmit = undefined,
    registerKitForChild = undefined,
    childLoading = false,
    style = undefined,
  },
) => {
  const { t } = useTranslation();

  return (
    <div style={ { width: '100%' } }>
      {
        null != error && '' !== error && (
          <span style={ { color: 'red', justifyContent: 'flex-end', display: 'flex' } }>
            *
            {
              ' '
            }
            {
              t(error)
            }
          </span>
        )
      }
      <Grid
        container
        justifyContent='flex-end'
        style={ { marginTop: '32px',
          alignItems: 'flex-end',
          justifyContent: 'space-between',
          width: '100%',
          gap: '20px',
          ...style } }
      >
        {
          goToPrevPage !== undefined && (
            <Grid item xs={ 12 } sm={ 4 }>
              <Button
                onClick={ goToPrevPage }
                variant='contained'
                color='secondary'
                fullWidth
                style={ {
                  minHeight: '40px',
                  color: '#526D9C' === color ? 'white' : '',
                } }
              >
                <div>
                  {t('common.back') }
                </div>
              </Button>

            </Grid>
          )
        }

        {
          '#526D9C' !== color && null != registerKitForChild && (
            <Grid item xs={ 12 } sm={ 4 }>
              <Button
                onClick={ registerKitForChild }
                variant='contained'
                disabled={ childLoading }
                color='secondary'
                fullWidth
                style={ {
                  minHeight: '40px',
                  color: '#526D9C' === color ? 'white' : '',
                } }
              >

                { childLoading
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }
                <div>
                  {t('Εγγραφή για ανήλικο') }
                </div>
              </Button>

            </Grid>
          )
        }

        <Grid
          item
          xs={ 12 }
          sm={ 4 }
          style={ { marginLeft: 'auto' } }
        >
          <Button
            type={ doSubmit === undefined ? 'submit' : 'button' }
            variant='contained'
            color='secondary'
            onClick={ () => {
              if (doSubmit !== undefined) {
                doSubmit();
              }
            } }
            fullWidth
            style={ {
              minHeight: '40px',
              color: '#526D9C' === color ? 'white' : '',
            } }
            disabled={ disabled || loading }
          >
            { loading
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }
            <div>
              { t(label) }
            </div>
          </Button>
        </Grid>
      </Grid>

    </div>
  );
};

FormSectionSubmit.propTypes = {
  label: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};

FormSectionSubmit.defaultProps = {
  loading: false,
  disabled: false,
};

export default FormSectionSubmit;
