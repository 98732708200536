import React, { useMemo, useState } from 'react';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import { WellnessPdf } from 'pages/iDNA3ToOne/iDNA3ToOne';
import { makeStyles, Icon } from '@material-ui/core';
import clsx from 'clsx';
import useAPI from 'hooks/api-hooks';
import { useQuery } from 'react-query';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useAuth } from 'AuthCtx';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import useMediaQueries from 'hooks/media-query-hooks';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useCardioDataReport } from 'models/kits';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

export const redColor = '#B72619';

export const CardioHealthCards = [
  {
    id: 'cardioHealth',
    title: 'cardioHealth',
    fields: [
      {
        text: 'Αποτελέσματα',
        url: 'stefaniaNososResults',
        icon: '',
        image: '',
      },
      {
        text: 'Σημαντικές Πληροφορίες',
        url: 'import-info-cardio',
        icon: '',
        image: '',
      },
      {
        text: 'Eρωτηματολογίο',
        url: 'questionnaire-cardio-health',
        icon: '',
        image: '',
      },
    ],
    url: '',
    color: redColor,
  },
];

export const useStylesCardio = makeStyles((theme) => {
  return {
    learnMoreArrow: {
      marginTop: '3px',
      transform: 'rotate(-45deg)',
    },
    page: {
      display: 'flex',
      padding: '30px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      minHeight: '250px',
      borderRadius: '16px',
      flexWrap: 'nowrap',
      gap: '0',
      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        gap: '8px',
      },
      [theme.breakpoints.down('sm')]: {
        // padding: theme.spacing(3, 8),
      },
    },
    topPart: {
      background: 'white',
      boxShadow: '-4px 8px 13px rgba(0, 0, 0, 0.12)',
      backdropFilter: 'blur(40px)',
      borderRadius: '8px',
      paddingBottom: '42px',
    },
    title: {
      marginTop: '44px',
      fontWeight: '600',
      fontFamily: 'Roboto',
      fontSize: 'min(43.4717px, 9vw)',
      lineHeight: '51px',
      paddingLeft: '47px',
    },
    subtitle: {
      marginTop: '10px',
      fontWeight: '400',
      fontFamily: 'Roboto',
      fontSize: '12px',
      lineHeight: '125.19%',
      paddingLeft: '47px',
    },
    hello: {
      fontWeight: '700',
      display: 'flex',
      alignItems: 'center',
      gap: '30px',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      fontSize: 'min(28px, 5.8vw)',
      fontFamily: 'Roboto',
      paddingLeft: '40px',
      lineHeight: '137.19%',
      color: 'white',
      height: '110px',
      paddingBottom: '10px',
      background: redColor,
    },
    whatYouWillFind: {
      color: '#484848',
      borderRadius: '16px',
      background: 'white',
      paddingBottom: '15px',
      paddingLeft: '30px',
      paddingTop: '30px',
      fontSize: '16px',
      fontFamily: 'Roboto',
      marginTop: '-20px',
      zIndex: '2000',
    },
    downloadText: {
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      paddingLeft: '30px',
      paddingRight: '30px',
      transition: 'all .4s',
      '&:hover': {
        paddingLeft: '40px',
        boxShadow: '0px 6.77675px 22.58917px 0px rgba(133, 133, 133, 0.40)',
      },

      '@media (max-width:1450px)': {
        // marginBottom: '5%',
      },
    },
    infoOuter: {
      background: redColor,
      width: '100%',
      height: '100%',
      padding: '25px',
      borderRadius: '15px',
      position: 'relative',
    },
    infoInner: {
      background: 'white',
      padding: '40px',
      borderRadius: '15px',
    },
    learnMore: {
      position: 'absolute',
      left: 0,
      right: 0,
      bottom: '5px',
      marginLeft: 'auto',
      marginRight: 'auto',
      textAlign: 'center',
      background: 'white',
      width: '250px',
      height: '50px',
      boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '27px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',

      border: '2px solid #B72619',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    pdf: {
      background: 'white',
      minHeight: '200px',
      display: 'flex',
      flexWrap: 'wrap',
      rowGap: '30px',
      alignItems: 'center',
      padding: '40px',
      borderRadius: '15px',
      justifyContent: 'space-between',
    },
    longTextOnDesktop: {
      maxWidth: '60%',
      [theme.breakpoints.down('sm')]: {
        maxWidth: '100%',
      },
    },
    downloadIcon: {
      width: '100px',
      height: '100px',
      left: '48px',
      top: '44px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px',
    },
    hoverPointer: {
      '&:hover': {
        cursor: 'pointer',
      },
    },
  };
});

export const Pdf = ({ navigateToPage, url = undefined }) => {
  const classes = useStylesCardio();
  const { t } = useTranslation();

  return (
    <div className={ classes.pdf }>
      <div style={ { display: 'flex',
        flexWrap: 'wrap',
        flexGrow: 2,
        rowGap: '10px',
        alignItems: 'center' } }
      >
        <Icon className={ clsx(classes.downloadIcon) }>
          <img
            src='/download.svg'
            alt=''
          />
        </Icon>
        <div style={ { marginLeft: '40px', width: 'max(300px, 100%)', maxWidth: 'fit-content' } }>
          <div
            style={ {
              fontWeight: '600',
              fontSize: 'min(30px, 6vw)',
              lineHeight: '125.19%',
              fontFamily: 'Roboto',
              marginBottom: '10px',
            } }
          >
            Pdf version
          </div>
          <div className={ classes.longTextOnDesktop }>
            {t('Here you will find your personal results report in a printable format.')}
          </div>
        </div>
      </div>
      <div
        style={ { height: '50px',
          width: '242px',
          display: 'flex',
          alignItems: 'center',
          boxShadow: '0px 30.3396px 101.132px rgba(0, 0, 0, 0.05), 0px 6.77675px 22.5892px rgba(0, 0, 0, 0.0298054), 0px 2.01762px 6.72539px rgba(0, 0, 0, 0.0201946)',
          borderRadius: '27px',
          justifyContent: 'center' } }
        className={ classes.hoverPointer }
        aria-hidden='true'
        onClick={ () => { navigateToPage({ pageId: url === undefined ? 'pdfVersionCardio' : url }); } }
      >
        {t('download')}
      </div>
    </div>
  );
};

const Info = ({ showArrow, setShowArrow, navigateToPage }) => {
  const classes = useStylesCardio();
  const { t } = useTranslation();

  return (
    <div className={ classes.infoOuter }>
      <div className={ classes.infoInner }>
        <div>
          {
            t('Η Βαθμολογία Πολυγονιδιακού Ρίσκου (PRS) και το Προσαρμοσμένο PRS (Adjusted PRS) λειτουργούν ως εργαλείο εκτίμησης – screening του γενετικού και φαινοτυπικού ρίσκου εμφάνισης καρδιαγγειακών παθήσεων και ο ρόλος τους είναι η πρόληψη.')
          }
        </div>
        <br />
        <div>
          {
            t('Η έγκαιρη ανίχνευση, η πρόληψη και η εξατομικευμένη παρέμβαση είναι θεμελιώδεις στόχοι για την προαγωγή της βέλτιστης καρδιαγγειακής υγείας. Μέχρι σήμερα, η πρόβλεψη κλινικού ρίσκου βασίζεται σε δημογραφικά χαρακτηριστικά (ηλικία, φύλο, εθνικότητα), στον τρόπο ζωής (κάπνισμα, κατανάλωση αλκοόλ, διατροφή, σωματική άσκηση), σε παραμέτρους υγείας (δείκτης μάζας σώματος, αρτηριακή πίεση, αιματολογικές εξετάσεις) και στο οικογενειακό ιστορικό. Παραδόξως, ο γενετικός έλεγχος τείνει να απουσιάζει από αυτή τη λίστα, τη στιγμή που η γενετική προδιάθεση μπορεί να εξεταστεί νωρίτερα στη ζωή από οποιοδήποτε άλλο παράγοντα ρίσκου.')
          }
        </div>
        <br />
        <div>
          {t('Πλέον, η Βαθμολογία Πολυγονιδιακού Ρίσκου (Polygenic Risk Score - PRS) είναι ένα σύγχρονο εργαλείο ανάλυσης του γενετικού προφίλ που εκτιμά την πιθανολογική ευαισθησία (προδιάθεση) σε πλήθος καρδιαγγειακών παθήσεων, από μία και μόνο απλή εξέταση παρειακού επιχρίσματος. Επιπροσθέτως, το Προσαρμοσμένο PRS (Adjusted PRS) αποτελεί μία μοναδική και καινοτόμο μέθοδο που συνδυάζει το PRS με τα τρέχοντα χαρακτηριστικά του τρόπου ζωής και του φαινοτύπου σου, παρέχοντας με αυτό τον τρόπο την ικανότητα δυναμικής παρακολούθησης από εσένα, του ρίσκου για ανάπτυξη καρδιαγγειακών παθήσεων.')}
        </div>
      </div>
      <div
        className={ classes.learnMore }
        onClick={ () => { navigateToPage({ pageId: 'stefaniaNososResults' }); } }
        aria-hidden='true'
        onMouseOver={ () => { setShowArrow(true); } }
        onFocus={ () => { setShowArrow(true); } }
        onMouseLeave={ () => { setShowArrow(false); } }
      >
        {t('Δες τα αποτελέσματά σου')}

        {
          showArrow && (
            <ArrowForwardIcon
              className={ classes.learnMoreArrow }
            />
          )
        }
      </div>
    </div>
  );
};

const Page = ({ title, text, icon }) => {
  const classes = useStylesCardio();
  const { t } = useTranslation();

  return (
    <div style={ { background: 'white', flex: '1' } }>
      <div style={ { color: redColor,
        fontWeight: '700',
        fontSize: 'min(30px, 6vw)',
        fontFamily: 'Roboto',
        paddingLeft: '30px',
        lineHeight: '125.19%' } }
      >
        {t(title)}
      </div>
      <div
        className={ classes.page }
      >
        <div style={ { marginRight: '30px' } }>
          {icon}
        </div>
        <div style={ { fontWeight: '400', fontSize: '16px', lineHeight: '125.19%', fontFamily: 'Roboto' } }>
          {t(text)}
        </div>
      </div>
    </div>
  );
};

const CardioHealthContent = () => {
  const classes = useStylesCardio();
  const { client } = useAPI();

  const { serial: newSerial } = useOptionSerial('IDNA Cardio Health');

  const { isSm } = useMediaQueries();

  const {
    data: kits,
  } = useQuery(
    [ 'registration-kits' ],
    () => {
      return client.get('registration-kits', {
        params: {
          _limit: -1,
        },
      });
    },
  );

  const [ showArrow, setShowArrow ] = useState(false);
  const { navigateToPage } = useNavigation();

  const { userAttributes } = useAuth();

  const { t } = useTranslation();

  if (!kits) {
    return <LoaderSkeleton />;
  }

  if (!userAttributes) {
    return <LoaderSkeleton />;
  }

  let {
    firstName,
  } = userAttributes;

  const iDNAkit = kits.find((kit) => {
    return null != kit.thematic_package.thematic_category_id.name && Object.prototype.hasOwnProperty.call(kit.thematic_package, 'id')
    && ('IDNA Cardio Health' === kit.thematic_package.thematic_category_id.name || 'Cardio Health' === kit.thematic_package.thematic_category_id.name)
    && kit.serial_number_id.serial === newSerial;
  });

  const { serial } = iDNAkit.serial_number_id;

  if (null != iDNAkit.child_id) {
    firstName = iDNAkit?.child_id?.name;
  }

  const FSerial = newSerial || serial;

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
      <div className={ classes.topPart }>
        <div style={ { display: 'flex', justifyContent: 'space-between' } }>
          <div style={ { marginTop: '1rem' } }>
            <div className={ classes.title }>
              Cardio Health
            </div>
            <div className={ classes.subtitle }>
              Serial Number:
              {' '}
              {FSerial}
            </div>
          </div>
          <img src='/cardioHealthHeart.png' alt='' style={ { display: isSm ? 'none' : '' } } />
        </div>
        <div className={ classes.hello }>
          <AccountCircleIcon style={ { transform: 'scale(2.5)' } } />
          <div>
            {
              `${ t('hello') } ${ firstName },`
            }
          </div>
        </div>
        <div
          className={ classes.whatYouWillFind }
          style={ {
            lineHeight: '161.19%',
          } }
        >
          <div style={ { width: '95%' } }>
            {t('Εδώ θα βρεις το εξατομικευμένο report που έχει προκύψει από όλες τις γενετικές και φαινοτυπικές πληροφορίες που συλλέξαμε από σένα. Περιλαμβάνει εκτίμηση του γενετικού σου ρίσκου εμφάνισης καρδιαγγειακών παθήσεων όπως η στεφανιαία νόσος, η μυοκαρδιοπάθεια (διατατική, υπερτροφική), η καρδιακή ανεπάρκεια, η αρρυθμία - κολπική μαρμαρυγή και το ισχαιμικό εγκεφαλικό επεισόδιο.')}
          </div>
        </div>
        <div style={ { display: 'flex', paddingLeft: '30px', paddingRight: '30px', gap: '25px', marginTop: '20px', flexWrap: 'wrap' } }>
          <Page
            title='Γενετική Ανάλυση'
            icon={ (
              <Icon
                style={ { fontSize: '65px',
                  background: redColor,
                  fontFamily: 'Roboto',
                  height: '71px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '71px',
                  boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
                  borderRadius: '10px',
                  padding: '10px' } }
              >
                <img
                  src='/whiteDNA.png'
                  alt=''
                  style={ {
                    height: '45px',
                    width: '45px',
                  } }
                />
              </Icon>
            ) }
            text={ t('Με το παρειακό επίχρισμα που λάβαμε από εσένα, πραγματοποιήθηκε γενετική ανάλυση για τον εντοπισμό των παραλλαγών ως προς τους πολυμορφισμούς που υπάρχουν στο DNA σου. Κάθε παραλλαγή αντιστοιχεί σε διαφοροποιημένη γονιδιακή λειτουργία και συσχετίζεται με διαφοροποιημένη προδιάθεση. Με βάση το γονότυπό σου υπολογίστηκε το PRS σου για κάθε καρδιαγγειακή πάθηση.') }
          />
          <Page
            title='Συμπλήρωση Eρωτηματολογίου'
            icon={ (
              <Icon
                style={ { fontSize: '65px',
                  background: redColor,
                  fontFamily: 'Roboto',
                  height: '71px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '71px',
                  boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
                  borderRadius: '10px',
                  padding: '10px' } }
              >
                <img
                  src='/whiteReport.png'
                  alt=''
                  style={ {
                    height: '45px',
                    width: '45px',
                  } }
                />
              </Icon>
            ) }
            text={ t('Με τη συμπλήρωση του ερωτηματολογίου* διερευνήθηκαν τα χαρακτηριστικά του τρόπου ζωής και του φαινοτύπου σου (διατροφικές συνήθειες, επίπεδο σωματικής άσκησης, κάπνισμα, δείκτης μάζας σώματος, αρτηριακή πίεση, ολική χοληστερόλη και σάκχαρο) και αξιολογήθηκε η τωρινή σου κατάσταση καρδιαγγειακής υγείας. Με βάση την κατηγοριοποίηση καρδιαγγειακής υγείας και την ηλικία σου, και σε συνδυασμό με το PRS σου, υπολογίστηκε το Προσαρμοσμένο PRS σου για κάθε πάθηση υπό εξέταση.') }
          />
        </div>
      </div>
      <Info
        showArrow={ showArrow }
        setShowArrow={ setShowArrow }
        navigateToPage={ navigateToPage }
      />
      <WellnessPdf
        navigateToPage={ navigateToPage }
        url='pdfVersionCardio'
        buttonColor={ redColor }
      />
    </div>
  );
};

const iDNACardioHealth = () => {
  const { serial: newSerial } = useOptionSerial('IDNA Cardio Health');
  const { locale } = useCardioDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null === locale) {
    return null;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <CardioHealthContent />
    </I18nextProvider>
  );
};

export default iDNACardioHealth;
