import Grid from '@material-ui/core/Grid';
import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';

const AppLoading = (props) => {
  const {
    className,
    style,
  } = props;

  return (
    <Grid
      container
      direction='row'
      justifyContent='center'
      alignItems='center'
      className={ className }
      style={ {
        ...style,
      } }
    >
      <Grid
        container
        item
        xs={ 12 }
        style={ { padding: '20px' } }
        alignItems='center'
        justifyContent='center'
      >
        <CircularProgress color='secondary' variant='indeterminate' />
      </Grid>
    </Grid>
  );
};

export default AppLoading;
