import Typography from '@material-ui/core/Typography';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import { useTheme } from '@material-ui/core';
import { useHistory } from 'react-router';
import emr from '../../../assets/emr.svg';
import useMediaQueries from '../../../hooks/media-query-hooks';

const Logo = () => {
  const history = useHistory();
  const { isMd } = useMediaQueries();
  const theme = useTheme();
  return (
    <Grid container wrap='nowrap'>
      <Grid
        item
        style={ { cursor: 'pointer', display: 'flex', gap: '30px' } }
        onClick={ () => {
          history.push('/');
        } }
      >
        <img
          alt='emr'
          src='/iDNA_logo_Vector.png'
          style={ { maxHeight: isMd ? '50px' : '45px' } }
          height='100%'
          width='auto'
        />
      </Grid>
      { 2 < 1 && (
        <>
          <Grid container item alignContent='center' xs={ 3 } sm={ 2 }>
            <img
              alt='emr'
              src={ emr }
              style={ { maxHeight: isMd ? '50px' : '30px' } }
              height='100%'
              width='auto'
            />
          </Grid>
          <Grid container item alignContent='center' xs={ 9 } sm={ 10 }>
            <Typography variant='h4' color='textSecondary'>
              <span style={ { color: theme.palette.secondary.main } }>my</span>
              EMR
            </Typography>
          </Grid>
        </>
      ) }
    </Grid>
  );
};

export default Logo;
