import React from 'react';
import { makeStyles } from '@material-ui/core';
import { NavigationProvider } from 'NavigationProvider';
import { DoctorAppHeader } from '../../components/layout';

const useStyles = makeStyles((theme) => {
  return {
    flexWrapper: {
      display: 'flex',
      minHeight: `calc(100vh - ${ theme.layout.topBarHeight }px)`,
      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    aux: {
      display: 'flex',
      flexGrow: 1,
    },
  };
});

const DoctorBasicLayout = (props) => {
  const classes = useStyles();
  return (

    <>
      <NavigationProvider
        pageKey='pid'
        fallbackPageId='profile'
      >
        <DoctorAppHeader />
      </NavigationProvider>
      <div className={ classes.flexWrapper }>
        {
          props.children
        }
        <div className={ classes.aux } />
      </div>
    </>
  );
};

export default DoctorBasicLayout;
