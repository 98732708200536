import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';
import { iDNA3ToOneCards } from './iDNA3ToOne';

export const profileToIcon = {
  carbohydrates: { big: '/YDATAN8RAKES.jpg', small: '/YDATAN8RAKES_SMALL.jpg' },
  proteins: { big: '/PROTEINI.jpg', small: '/PROTEINI_SMALL.jpg' },
  fats: { big: '/LIPARA.jpg', small: '/LIPARA_SMALL.jpg' },
  eatingSnacksBetweenMeals: { big: '/SNACK METAXY GEYMATON.jpg', small: '/SNACK METAXY GEYMATON_SMALL.jpg' },
  preferenceForSweetTaste: { big: '/PROTIMISI STI GLYKIA GEYSH.jpg', small: '/PROTIMISI STI GLYKIA GEYSH_SMALL.jpg' },
  biologicalClock: { big: '/BIOLOGIKO ROLOI.jpg', small: '/BIOLOGIKO ROLOI_SMALL.jpg' },
};

export const profileToUrl = {
  carbohydrates: 'carbohydrates',
  proteins: 'proteins',
  fats: 'fats',
  eatingSnacksBetweenMeals: 'snacksBetweenMeals',
  preferenceForSweetTaste: 'sweetTaste',
  biologicalClock: 'biologicalClock',
};

export const adviceImages = {
  carbohydrates: { left: '/ydatanthrakes-1.jpg', right: '/ydatanthrakes-2.jpg' },
  proteins: { left: '/prwteines-1.jpg', right: '/prwteines-2.jpg' },
  fats: { left: '/lipara-1.jpg', right: '/lipara-2.jpg' },
  eatingSnacksBetweenMeals: { left: null, right: '/katanalosi snack metaxi geymatwn.jpg' },
  preferenceForSweetTaste: { left: null, right: '/protimisi sti glykia geysi.jpg' },
  biologicalClock: { left: '/biologiko roloi.jpg', right: null },
};

const WeightControl = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT3', 'NCAT6', 'NCAT12', 'NCAT9', 'NCAT19', 'NCAT8' ].includes(currData.ID);
  })
    .map((filtered) => {
      let iconPath = '';
      let url = '';

      if ('NCAT3' === filtered.ID) {
        iconPath = profileToIcon.carbohydrates;
        url = profileToUrl.carbohydrates;
      } else if ('NCAT6' === filtered.ID) {
        iconPath = profileToIcon.proteins;
        url = profileToUrl.proteins;
      } else if ('NCAT12' === filtered.ID) {
        iconPath = profileToIcon.fats;
        url = profileToUrl.fats;
      } else if ('NCAT9' === filtered.ID) {
        iconPath = profileToIcon.preferenceForSweetTaste;
        url = profileToUrl.preferenceForSweetTaste;
      } else if ('NCAT19' === filtered.ID) {
        iconPath = profileToIcon.biologicalClock;
        url = profileToUrl.biologicalClock;
      } else if ('NCAT8' === filtered.ID) {
        iconPath = profileToIcon.eatingSnacksBetweenMeals;
        url = profileToUrl.eatingSnacksBetweenMeals;
      }

      return { ...filtered, icon: iconPath, url };
    });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ dietCard } activePage='weightManagement' />
        <PhotoWithText
          options={ dietCard }
          activePage='weightManagement'
          text={ data[0].value.weight_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default WeightControl;
