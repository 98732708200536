import React from 'react';
import { useVitaminsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { greenColor } from './VitaminsPage';

export const profileToIcon = {
  detoxificationCapacity: { big: '/IKANOTHTA APOTOXINWSHS.jpg', small: '/IKANOTHTA APOTOXINWSHS_SMALL.jpg' },
  antioxidantNeeds: { big: '/ANTIOXEIDOTIKES ANAGES.jpg', small: '/ANTIOXEIDOTIKES ANAGES_SMALL.jpg' },
};

export const profileToUrl = {
  detoxificationCapacity: 'vitaminsDetoxificationCapacity',
  antioxidantNeeds: 'vitaminsAntioxidantNeeds',
};

const DetoxificationCapacityAntioxidantNeeds = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useVitaminsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Ικανότητα αποτοξίνωσης & αντιοξειδωτικές ανάγκες' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT1' === filtered.ID) {
      iconPath = profileToIcon.detoxificationCapacity;
      url = profileToUrl.detoxificationCapacity;
    } else if ('VCAT1' === filtered.ID) {
      iconPath = profileToIcon.antioxidantNeeds;
      url = profileToUrl.antioxidantNeeds;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: greenColor,
    id: 'vitaminsPage',
    title: 'Βιταμίνες',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext
        options={ vitaminsCard }
        activePage='vitaminsDetoxificationCapacityAntioxidantNeeds'
        isNutrition
      />
      <PhotoWithText
        options={ vitaminsCard }
        activePage='detoxificationCapacityAntioxidantNeeds'
        text={ data[0].value.detox_antioxidant_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
    </div>
  );
};

export default DetoxificationCapacityAntioxidantNeeds;
