import React from 'react';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { useLocation } from 'react-router';

const NotFound = () => {
  const location = useLocation();

  return (
    <Grid container justifyContent='center' alignItems='center' style={ { minHeight: '100vh' } }>
      <Grid item>
        <Typography variant='h2'>
          404 | Not Found
        </Typography>
        <Typography variant='body2'>
          Unable to resolve:
          {' '}
          {`${ location.pathname }${ location.search }`}
        </Typography>
      </Grid>

    </Grid>
  );
};

export default NotFound;
