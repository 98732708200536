import { useTranslation } from 'react-i18next';
import CancelIcon from '@material-ui/icons/Cancel';
import React, { useMemo, useState } from 'react';
import { Button, Dialog, IconButton, useTheme } from '@material-ui/core';
import { AiOutlineFilePdf } from 'react-icons/all';
import PDFViewer from 'components/PDFViewer';
import { useItems } from '../../models/atoms/items-hook';
import CollectionKeys from '../../models/atoms/collection-keys';
import AppTable from '../../components/app/AppTable';

const PatientsTable = () => {
  const { t } = useTranslation();

  const [ patientReportOpen, setPatientReportOpen ] = useState(false);
  const [ doctorReportOpen, setDoctorReportOpen ] = useState(false);

  const [ serialNumber, setSerialNumber ] = useState(undefined);
  const [ nameSuffix, setNameSuffix ] = useState('');

  const tableColumns = [
    { id: 1, label: t('serialNumber'), field: 'registration_kit.serial_number_id.serial' },
    { id: 2, label: t('header-name'), field: 'customer.name' },
    { id: 3, label: t('header-surname'), field: 'customer.family_name' },
    { id: 4, label: t('email'), field: 'customer.email' },
    {
      id: 5,
      label: '',
      sortable: false,
      field: 'created_at',
      customRenderer({ row }) {
        let hasPdf = false;
        let serial;

        if (null != row.registration_kit) {
          hasPdf = row.registration_kit?.pdf_available && 'done' === row.registration_kit?.registrationStatus;
          serial = row.registration_kit?.serial_number_id?.serial;
        }

        const isKitPGX = 'iDNA PGx kit' === row.registration_kit?.thematic_package?.name;
        const categoryId = row.registration_kit?.thematic_package?.real_name;

        // eslint-disable-next-line no-unused-vars
        const theme = useTheme();

        const isKitOnlyNutri = 'iDNA NutriGenetix kit' === categoryId || 'NutriGenetix Laboratories kit' === categoryId;

        return (
          <>
            {
              isKitPGX ? (
                <>
                  <Button
                    startIcon={ <AiOutlineFilePdf color='red' /> }
                    variant='outlined'
                    style={ { marginRight: '10px' } }
                    disabled={ !hasPdf }
                    onClick={ () => {
                      setSerialNumber(serial);
                      setPatientReportOpen(false);
                      setDoctorReportOpen(true);
                      setNameSuffix('');
                    } }
                  >
                    {t('Doctors report')}
                  </Button>

                  <Button
                    startIcon={ <AiOutlineFilePdf color='red' /> }
                    variant='outlined'
                    disabled={ !hasPdf }
                    onClick={ () => {
                      setSerialNumber(serial);
                      setDoctorReportOpen(false);
                      setNameSuffix('');
                      setPatientReportOpen(true);
                    } }
                  >
                    {t('Patients report')}
                  </Button>
                </>
              ) : isKitOnlyNutri ? (
                <>
                  <Button
                    startIcon={ <AiOutlineFilePdf color='red' /> }
                    variant='outlined'
                    style={ { marginRight: '10px' } }
                    disabled={ !hasPdf }
                    onClick={ () => {
                      setSerialNumber(serial);
                      setNameSuffix('_diet');
                      setPatientReportOpen(true);
                      setDoctorReportOpen(false);
                    } }
                  >
                    {t('Πλάνο διατροφής')}
                  </Button>

                  <Button
                    startIcon={ <AiOutlineFilePdf color='red' /> }
                    variant='outlined'
                    disabled={ !hasPdf }
                    onClick={ () => {
                      setSerialNumber(serial);
                      setNameSuffix('_analysis');
                      setPatientReportOpen(true);
                      setDoctorReportOpen(false);
                    } }
                  >
                    {t('Διατροφογενετική Ανάλυση')}
                  </Button>
                </>
              ) : (
                <Button
                  startIcon={ <AiOutlineFilePdf color='red' /> }
                  variant='outlined'
                  disabled={ !hasPdf }
                  onClick={ () => {
                    setNameSuffix('');
                    setSerialNumber(serial);
                    setPatientReportOpen(true);
                  } }
                >
                  {t('report')}
                </Button>
              )
            }
          </>
        );
      },
    },
  ];

  const [ search, setSearch ] = useState('');

  const filterQuery = useMemo(() => {
    const trimmedSearch = search.trim();

    if ('' === trimmedSearch) {
      return {};
    }

    const searchTokens = trimmedSearch
      .split(' ')
      .map((tt) => { return tt.trim(); })
      .filter((s) => { return '' !== s; });

    if (0 === searchTokens.length) {
      return {};
    }

    if (1 === searchTokens.length) {
      const [ f ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'registration_kit.serial_number_id.serial_contains': f }],
            [{ 'customer.name_contains': f }],
            [{ 'customer.family_name_contains': f }],
            [{ 'customer.email_contains': f }],
          ],
        },
      };
    }

    if (2 === searchTokens.length) {
      // assume the user has written firstName and lastName
      const [ f, l ] = searchTokens;
      return {
        _where: {
          _or: [
            [{ 'customer.name_contains': f, 'customer.family_name_contains': l }],
            [{ 'customer.name_contains': l, 'customer.family_name_contains': f }],
          ],
        },
      };
    }

    return {
      _limit: 0,
    };
  }, [ search ]);

  const {
    items: something,
    itemsLoading: somethingLoading,
    itemsError: somethingError,
    pagination,
  } = useItems(CollectionKeys.CustomerDoctorKits,
    {
      defaultPagination: { limit: 5, sort: { order: 'desc', orderBy: 'created_at' } },
      filterQuery,
    });

  if (somethingError) {
    return <p>Failed to load clients</p>;
  }

  return (
    <>
      {
        patientReportOpen && (
          <Dialog
            open={ patientReportOpen }
            onClose={ () => { setPatientReportOpen(false); setNameSuffix(''); } }
            fullWidth
            maxWidth='lg'
          >
            <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
              <IconButton
                style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
                onClick={ () => { setPatientReportOpen(false); } }
              >
                <CancelIcon style={ { color: 'red' } } />
              </IconButton>
              <PDFViewer serial={ serialNumber } fromInvitation nameSuffix={ nameSuffix } />
            </div>
          </Dialog>
        )
      }

      {
        doctorReportOpen && (
          <Dialog
            open={ doctorReportOpen }
            onClose={ () => { setDoctorReportOpen(false); } }
            fullWidth
            maxWidth='lg'
          >
            <div style={ { padding: '30px', borderRadius: '15px', position: 'relative' } }>
              <IconButton
                style={ { position: 'absolute', top: '5px', right: '5px', padding: 0, margin: 0 } }
                onClick={ () => { setDoctorReportOpen(false); } }
              >
                <CancelIcon style={ { color: 'red' } } />
              </IconButton>
              <PDFViewer serial={ serialNumber } isDoctor fromInvitation />
            </div>
          </Dialog>
        )
      }

      <AppTable
        columns={ tableColumns }
        paginationProps={ pagination }
        loading={ somethingLoading }
        data={ something }
        searchProps={ {
          search,
          setSearch,
        } }
      />
    </>
  );
};

export default PatientsTable;
