import { Typography, Slide, Dialog, DialogContent, DialogContentText, IconButton } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { useTranslation } from 'react-i18next';

const Transition = React.forwardRef((props, ref) => {
  return <Slide direction='up' ref={ ref } { ...props } />;
});

Transition.displayName = 'Transition';

function HolidaysNotice({ open, handleClose }) {
  const { t } = useTranslation();

  return (
    <Dialog
      open={ open }
      TransitionComponent={ Transition }
      onClose={ handleClose }
      PaperProps={ {
        style: {
          borderRadius: '15px',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 'min(100vw, 600px)',
          height: '500px',
          position: 'relative',
        },
      } }
    >
      <IconButton
        style={ {
          position: 'absolute',
          top: '10px',
          right: '10px',
        } }
        onClick={ handleClose }
      >
        <CloseIcon
          fontSize='large'
          color='error'
        />
      </IconButton>

      <DialogContent style={ { borderRadius: '15px', display: 'flex', alignItems: 'center' } }>
        <DialogContentText>
          <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'center' } }>
            <img
              src='/my_emr_general_holiday_img-350px.png'
              alt='holiday'
              style={ { marginBottom: '40px', maxWidth: '330px', width: '100%' } }
            />

            <Typography variant='h6' style={ { color: '#1B1B1B' } }>
              {t('Η εταιρία θα παραμείνει κλειστή')}
            </Typography>

            <Typography
              variant='h6'
              style={ { color: '#93C221', fontWeight: 'bold' } }
            >
              {t('5/8 - 16/8')}
            </Typography>

            <Typography variant='h6' style={ { color: '#1B1B1B' } }>
              {t('λόγω καλοκαιρινών διακοπών')}
            </Typography>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

HolidaysNotice.displayName = 'HolidaysNotice';

export default HolidaysNotice;
