import { useMutation, useQueryClient } from 'react-query';
import { KITS_KEY } from './kits';
import useAPI from '../hooks/api-hooks';
import CollectionKeys from './atoms/collection-keys';

export const InvitationReason = {
  PGX_DOCTOR_CONSENT: 'pgx-doctor-consent',
  HEALTHCARE_PROFESSIONAL_SHARE_RESULTS: 'healthcare-professional-share-results',
};

export const useKitInvitation = ({ kitId, reasonOfInvitation }) => {
  const { client } = useAPI();
  const queryClient = useQueryClient();

  const { mutateAsync: createInvitation } = useMutation(
    [ 'invitation', {
      kitId,
      reasonOfInvitation,
    }],
    (
      {
        metadata,
        thirdPartyEmail,
      },
    ) => {
      return client.post('invitations', {
        registration_kit: kitId,
        metadata,
        reason: reasonOfInvitation,
        target_email: thirdPartyEmail,
      });
    },
    {
      onSettled() {
        return queryClient.invalidateQueries([ CollectionKeys.Invitations ])
          .then(() => {
            return queryClient.invalidateQueries([ KITS_KEY, { kitId: `${ kitId }` }]);
          });
      },
    },
  );

  return {
    createInvitation,
  };
};
