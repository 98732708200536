import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { List, ListItem, Typography } from '@material-ui/core';
import clsx from 'clsx';
import { useTheme } from '@material-ui/styles';
import { useTranslation } from 'react-i18next';
import useMediaQueries from '../../hooks/media-query-hooks';
import { useNavigation } from '../../NavigationProvider';

const useStyles = makeStyles((theme) => {
  return {
    list: {
      display: 'inline-flex',
      overflow: 'auto',
      flexWrap: 'nowrap',
      width: '100%',
    },
    listItem: {
      margin: theme.spacing(0, 2),
      justifyContent: 'center',
    },
    listItemActive: {
      borderBottom: `1px solid ${ theme.palette.secondary.dark }`,
      '& .menu__item': {
        color: theme.palette.secondary.dark,
      },
    },
  };
});

const NavigationMenu = ({ pages }) => {
  const classes = useStyles();
  const theme = useTheme();
  const { t } = useTranslation();
  const { isMd } = useMediaQueries();

  const { activePageId: pageId, navigateToPage } = useNavigation();

  return (
    <List disablePadding className={ classes.list }>
      {pages.map((item) => {
        const href = '';
        const { Icon } = item;
        return (
          <ListItem
            key={ item.id }
            component='a'
            href={ href }
            onClick={ (e) => {
              e.preventDefault();
              navigateToPage({ pageId: item.id });
            } }
            className={ clsx(
              classes.listItem,
              pageId === item.id ? classes.listItemActive : {},
            ) }
            disableGutters
          >
            <Icon
              color={ pageId === item.id ? theme.palette.secondary.dark : theme.palette.text.primary }
              size='24px'
              style={ { minWidth: '24px', marginRight: theme.spacing(1) } }
            />
            <Typography
              variant='subtitle1'
              color='textSecondary'
              className='menu__item'
              noWrap={ !isMd }
            >
              {t(item.title)}
            </Typography>
          </ListItem>
        );
      })}
    </List>
  );
};

export default NavigationMenu;
