import React from 'react';
import { useNutritionData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { adviceImages } from '../../iDNA3ToOne/WeightControl';
import { yellowColor } from '../Nutrition';
import { Tab, PrevNext } from '../../iDNA3ToOne/Common';
import { iDNA3ToOneCards } from '../../iDNA3ToOne/iDNA3ToOne';
import { Result } from '../../iDNA3ToOne/SensitivityProfiles/Common';
import { profileToIcon, profileToUrl } from '../NWeightManagement';

const NSweetTaste = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useNutritionData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT3', 'NCAT6', 'NCAT12', 'NCAT9', 'NCAT19', 'NCAT8' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'τη';
    let adviceImage;
    const description = 'NCAT9_description';

    if ('NCAT3' === filtered.ID) {
      iconPath = profileToIcon.carbohydrates;
      url = profileToUrl.carbohydrates;
    } else if ('NCAT6' === filtered.ID) {
      iconPath = profileToIcon.proteins;
      url = profileToUrl.proteins;
    } else if ('NCAT12' === filtered.ID) {
      iconPath = profileToIcon.fats;
      url = profileToUrl.fats;
    } else if ('NCAT9' === filtered.ID) {
      iconPath = profileToIcon.preferenceForSweetTaste;
      url = profileToUrl.preferenceForSweetTaste;
      resultText = filtered.genetic_profile_text;
      adviceImage = adviceImages.preferenceForSweetTaste;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('NCAT19' === filtered.ID) {
      iconPath = profileToIcon.biologicalClock;
      url = profileToUrl.biologicalClock;
    } else if ('NCAT8' === filtered.ID) {
      iconPath = profileToIcon.eatingSnacksBetweenMeals;
      url = profileToUrl.eatingSnacksBetweenMeals;
    }

    return { ...filtered, icon: iconPath, url, prefix, description, adviceImage };
  });

  const tab = {
    color: yellowColor,
    id: 'nutritionalGuide',
    title: 'Διατροφή',
  };

  return (
    <div>
      <Tab tab={ tab } />
      <PrevNext options={ dietCard } activePage='nutritionWeightManagement' isNutrition />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Προτίμηση στη γλυκιά γεύση'
        activeColor={ dietCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.preferenceForSweetTaste }
      />
    </div>
  );
};

export default NSweetTaste;
