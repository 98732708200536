import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { useNavigation, useOptionSerial } from 'NavigationProvider';
import { useDoctorIsAbleToGiveConsent } from 'components/layout/doctor-dashboard/navigationPages';
import useMediaQueries from 'hooks/media-query-hooks';
import CollectionKeys from 'models/atoms/collection-keys';
import { useItems } from 'models/atoms/items-hook';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { results } from 'pages/Results';
import { useStyles } from 'pages/DoctorResultsPage';
import AppTable from 'components/app/AppTable';
import { useAuth } from 'AuthCtx';
import { useQuery } from 'react-query';
import useAPI from 'hooks/api-hooks';

const ResultsTable = ({ kits, search, setSearch, isLoading, pagination, refetch }) => {
  const { t } = useTranslation();
  // eslint-disable-next-line no-unused-vars
  const { navigateToPage } = useNavigation();
  // eslint-disable-next-line no-unused-vars
  const { setSerial } = useOptionSerial('3-in-1');

  const isPgxDoctor = useDoctorIsAbleToGiveConsent();

  const { userIsAuthenticated } = useAuth();
  const { client } = useAPI();

  const { data: doctorData, isLoading: doctorDataLoading } = useQuery([ 'doctors', 'me', 'backend', `${ userIsAuthenticated }` ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('doctors/me');
  });

  const allKits = useMemo(() => {
    return kits?.filter((kit) => {
      return null != kit.thematic_package && Object.prototype.hasOwnProperty.call(kit.thematic_package, 'id');
    })
      .map((kit, id) => {
        const foundKit = results.find((result) => {
          return result.type === kit.thematic_package.thematic_category_id.name
      || result.alt_type === kit.thematic_package.thematic_category_id.name || result.other_alt_type === kit.thematic_package.thematic_category_id.name;
        });

        let type = kit.thematic_package.name;
        if ('Cardio Health' === type || 'iDNA Cardio Health' === type || 'iDNA Cardio Health kit' === type) {
          type = 'IDNA Cardio Health';
        }

        let { registrationStatus } = kit;

        if (true === kit.serial_number_id.sample_available && 'registration_done' === kit.registrationStatus) {
          registrationStatus = 'sample_received';
        }

        return {
          id,
          kitId: kit.id,
          product: type,
          serial: kit.serial_number_id.serial,
          sendAnalysisReportStatus: kit.sendAnalysisReportStatus,
          sendDietReportStatus: kit.sendDietReportStatus,
          navTitle: foundKit?.navTitle,
          icon: foundKit?.icon,
          title: foundKit?.title,
          style: foundKit?.style,
          fields: foundKit?.fields,
          status: 'active',
          pdfAvailable: kit.pdf_available,
          registrationStatus,
          sendReportStatus: kit.sendReportStatus,
          familyName: kit.customer_id?.family_name ?? '',
          firstName: kit.customer_id?.name ?? '',
          email: kit.customer_id?.email ?? '',
          customerId: kit.customer_id?.id,
        };
      });
  }, [ kits, isLoading ]);

  const tableColumns = [
    { id: 1,
      label: t('product'),
      sortable: false,
      field: 'product',
      customRenderer({ row }) {
        const foundResult = results.find((result) => {
          return result.type === row.product
      || result.alt_type === row.product || result.other_alt_type === row.product;
        });

        if (!foundResult) {
          return null;
        }

        return (
          <div style={ { display: 'flex', flexDirection: 'column', overflowX: 'visible', whiteSpace: 'nowrap' } }>
            {foundResult.title}
            <img
              src={ foundResult.icon }
              alt={ foundResult.title }
              style={ { width: '100px', height: '100px', ...foundResult.imageStyle } }
            />
          </div>
        );
      } },
    { id: 2, label: 'Serial Number', field: 'serial', sortable: false },
    { id: 3,
      label: isPgxDoctor ? t('patFirstLastName') : t('patCustFirstLastName'),
      field: 'firstName',
      sortable: false,
      customRenderer({ row }) {
        return (
          <div style={ { display: 'flex', flexDirection: 'column', alignItems: 'flex-start' } }>
            <span style={ { paddingLeft: '10px' } }>
              { row.firstName }
              {' '}
              { row.familyName }
            </span>
          </div>
        );
      } },
    { id: 4,
      label: t('Επανεκτίμηση Nutrigenetix'),
      field: '',
      sortable: false,
      customRenderer({ row }) {
        return (
          <Button
            variant='contained'
            disabled={ doctorDataLoading || 0 === doctorData?.available_repeat_reports }
            color='secondary'
            style={ { color: 'white' } }
            onClick={ () => {
              setSerial(row.kitId);
              navigateToPage({ pageId: 'new-repeat-questionnaire' });
            } }
          >
            {t('Fill out a new questionnaire')}
          </Button>
        );
      } },
  ];

  return (
    <AppTable
      refetch={ refetch }
      isDoctor
      columns={ tableColumns }
      data={ allKits }
      loading={ isLoading }
      searchProps={ {
        search,
        setSearch,
      } }
      paginationProps={ pagination }
    />
  );
};

const RepeatNutrigenetix = () => {
  const classes = useStyles();

  const { isMd } = useMediaQueries();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  const [ search, setSearch ] = useState('');

  const [ filterQuery, setFilterQuery ] = useState({ });

  useEffect(() => {
    let searchQueryKey = {
      registrationStatus_eq: 'done',
      'serial_number_id.ancestor_null': true,
      _limit: -1,
      _where: {
        _or: [
          { 'thematic_package.thematic_category_id.name_eq': 'Nutrigenetix' },
        ],
      },
    };

    if ('' !== search) {
      searchQueryKey = {
        _limit: -1,
        registrationStatus_eq: 'done',
        'serial_number_id.ancestor_null': true,
        _where: [
          { _or: [
            [{ 'thematic_package.thematic_category_id.name_eq': 'Nutrigenetix' }],
          ] },

          { _or: [
            [{ 'serial_number_id.serial_contains': search }],
            [{ customer_id_null: false }, { 'customer_id.family_name_contains': search }],
          ] },
        ],
      };
    }

    setFilterQuery({
      ...searchQueryKey,
    });
  }, [ search ]);

  const {
    items: kits,
    itemsLoading: isLoading,
    pagination,
    refetch,
  } = useItems(CollectionKeys.DoctorSortedKits, { defaultPagination: { limit: 5 }, filterQuery });

  const { userIsAuthenticated } = useAuth();

  const { client } = useAPI();

  const { data: doctorData, isLoading: doctorDataLoading } = useQuery([ 'doctors', 'me', 'backend', `${ userIsAuthenticated }` ], () => {
    if (!userIsAuthenticated) {
      return undefined;
    }
    return client.get('doctors/me');
  });

  if (doctorDataLoading) {
    return null;
  }

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 } md={ 6 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {t('NutriGenetix Δυναμική Παρακολούθηση')}
          </Typography>
        </div>
      </Grid>

      <Grid item xs={ 12 } md={ 6 }>
        <div style={ { display: 'flex',
          flexDirection: 'column',
          width: 'fit-content',
          marginLeft: 'auto',
          padding: '15px 25px 15px 25px',
          border: '1px solid #526D9C',
          borderRadius: '20px' } }
        >
          <div style={ { fontWeight: '600', fontSize: '18px', textAlign: 'right' } }>
            {doctorData?.available_repeat_reports ?? 0}
          </div>
          <div style={ { fontWeight: '500', textAlign: 'right', fontSize: '0.95rem' } }>
            {t('Διαθέσιμες')}
          </div>
          <div style={ { fontWeight: '500', textAlign: 'right', fontSize: '0.95rem' } }>
            {t('επανεκτιμήσεις')}
          </div>

          <Button
            variant='contained'
            color='secondary'
            style={ { color: 'white', marginTop: '5px' } }
            onClick={ () => {
              navigateToPage({ pageId: 'buy-repeat-nutrigenetix' });
            } }
          >
            {t('Αγορά πακέτου')}
          </Button>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 }>
        <Grid item xs={ 12 }>
          <ResultsTable
            pagination={ pagination }
            kits={ kits }
            search={ search }
            setSearch={ setSearch }
            refetch={ refetch }
            isLoading={ isLoading }
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default RepeatNutrigenetix;
