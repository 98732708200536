import React from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: theme.palette.secondary.dark,
      backgroundColor: 'rgba(0, 0, 0, 0.0)',
    },
  };
});

export default function BlockingLoader(
  {
    open,
  },
) {
  const classes = useStyles();

  return (
    <Backdrop className={ classes.backdrop } open={ !!open }>
      <CircularProgress style={ { color: '#93C221' } } />
    </Backdrop>
  );
}
