import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl, adviceImages } from '../TraceElements';

const Magnesium = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'VCAT9', 'VCAT10', 'VCAT11', 'VCAT12', 'VCAT13' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'το';
    const description = 'VCAT13_description';

    if ('VCAT9' === filtered.ID) {
      iconPath = profileToIcon.lowCalcium;
      url = profileToUrl.lowCalcium;
    } else if ('VCAT10' === filtered.ID) {
      iconPath = profileToIcon.highCalcium;
      url = profileToUrl.highCalcium;
    } else if ('VCAT11' === filtered.ID) {
      iconPath = profileToIcon.lowIron;
      url = profileToUrl.lowIron;
    } else if ('VCAT12' === filtered.ID) {
      iconPath = profileToIcon.highIron;
      url = profileToUrl.highIron;
    } else if ('VCAT13' === filtered.ID) {
      iconPath = profileToIcon.magnesium;
      url = profileToUrl.magnesium;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ vitaminsCard } activePage='traceElements' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activeProfile='Μαγνήσιο'
        activeColor={ vitaminsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
        adviceImage={ adviceImages.magnesium }
      />
    </div>
  );
};

export default Magnesium;
