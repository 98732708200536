import { AppHeader } from 'components/layout';
import React from 'react';
import { useTranslation } from 'react-i18next';

const UnderMaintenancePage = () => {
  const { t } = useTranslation();

  return (
    <div>
      <AppHeader hideUserFields />
      <div style={ { display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '20px',
        marginTop: '50px' } }
      >
        <div style={ { textAlign: 'center', maxWidth: '950px' } }>
          {t('Please be advised that we are performing scheduled maintenance to improve our website.')}
        </div>
        <div style={ { textAlign: 'center', maxWidth: '850px' } }>
          {t('During this period, site functionalities will be unavailable.')}
        </div>
        <div style={ { textAlign: 'center', maxWidth: '850px' } }>
          {t('Thank you for your understanding.')}
        </div>
      </div>
    </div>
  );
};

export default UnderMaintenancePage;
