import { makeStyles, IconButton, Icon, Typography, Grid, Button, Dialog, DialogContent } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import React, { useEffect, useState } from 'react';
import CardBase from 'components/app/CardBase';
import { useTranslation } from 'react-i18next';
import { useNavigation } from 'NavigationProvider';
import clsx from 'clsx';
import useMediaQueries from 'hooks/media-query-hooks';
import { CardioHealthCards } from './iDNACardioHealth';

const redColor = '#B72619';

const useStyles = makeStyles((theme) => {
  return {
    hideWhenSmall: {
      display: '',
      [theme.breakpoints.down('md')]: {
        display: 'none',
      },
    },
    container: {
      padding: '50px',

      [theme.breakpoints.down('sm')]: {
        padding: '5px',
      },
    },
    smallFontWhenSmall: {
      fontSize: '32px',
      [theme.breakpoints.down('md')]: {
        fontSize: '24px',
      },
      [theme.breakpoints.down('sm')]: {
        fontSize: '17px',
      },
    },
    blurredBox: {
      background: 'linear-gradient(106.1deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%)',
      backdropFilter: 'blur(40px)',
      // marginTop: '30px',
      // marginLeft: '30px',
      width: '62%',
      minWidth: '300px',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
      color: '#484848',
      padding: '40px 40px 40px 40px',
      borderRadius: '15px',
    },
    outsideBlurredBox: {
      margin: '30px 0',
      padding: '30px',
      [theme.breakpoints.down('sm')]: {
        padding: 0,
      },
      backgroundSize: 'cover',
      backgroundPosition: 'center',
    },
    profilesContainer: {
      background: 'white',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      // padding: '3% 4% 5% 7%',
      // display: 'flex',
      width: '100%',
    },
    title: {
      fontWeight: '700',
      fontSize: '20px',
      fontFamily: 'Roboto',
      lineheight: '125.19%',
      background: redColor,
      height: '36px',
      color: 'white',
      display: 'flex',
      alignItems: 'center',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '16px',
      paddingLeft: '15px',
    },
    profiles: {
      display: 'flex',
      // justifyContent: 'center',
      // justifyContent: 'space-around',
      // width: '100%',
      // maxWidth: '97vw',
      gap: '10px',
      // width: '68vw',
      overflowX: 'auto',
    },
    profile: {
      // width: '180px',
      // maxWidth: '250px',
      width: '100%',
      minWidth: '210px',
      minHeight: '65px',
      fontWeight: '400',
      fontSize: '14px',
      fontFamily: 'Roboto',
      lineHeight: '125.19%',
      letterSpacing: '0.03em',
      color: '#545454',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px',
      padding: '5px 15px',
      borderRadius: '8px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    options: {
      marginBottom: '26px',
      width: '100%',
      marginTop: '20px',
      color: 'white',
      display: 'flex',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '8px',
      height: '82.88px',
      alignItems: 'center',
      paddingLeft: '30px',
      paddingRight: '30px',
      '@media (max-width:600px)': {
        paddingLeft: '5px',
        paddingRight: '5px',
      },
    },
    prevNextIconText: {
      display: 'flex',
      alignItems: 'center',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    prevNextIcon: {
      height: '61px',
      width: '61px',
      display: 'flex',
      padding: '8px',
      boxShadow: '0px 24px 55px rgba(0, 0, 0, 0.05), 0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054), 0px 1.59602px 5.32008px rgba(0, 0, 0, 0.0201946)',
      borderRadius: '10px',
    },
    hideLongText: {
      // maxWidth: '420px',
      fontFamily: 'Roboto',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
    },
  };
});

export const sensProfiles = [
  {
    title: 'Στεφανιαία νόσος',
    url: 'stefaniaNososResults',
    icon: '/1 Στεφανιαία νόσος.png',
  },
  {
    title: 'Μυοκαρδιοπάθεια - Διατατική',
    url: 'diatatikiMyocardioPathiaResults',
    icon: '/2 Μυοκαρδιοπάθεια (Διατατική μυοκαρδιοπάθεια).png',
  },
  {
    title: 'Μυοκαρδιοπάθεια - Υπερτροφική',
    url: 'ypertrofikiMyocardioPathiaResults',
    icon: '/3 Μυοκαρδιοπάθεια (Υπερτροφική μυοκαρδιοπάθεια).png',
  },
  {
    title: 'Καρδιακή Ανεπάρκεια',
    url: 'kardiakhAneparkeiaResults',
    icon: '/4 Καρδιακή ανεπάρκεια 1.png',
  },
  {
    title: 'Αρρυθμία - Kολπική μαρμαρυγή',
    url: 'ArrythmiaResults',
    icon: '/5 Αρρυθμία  (Κολπική μαρμαρυγή).png',
  },
  {
    title: 'Ισχαιμικό εγκεφαλικό επεισόδιο',
    url: 'egkefalikoResults',
    icon: '/6 Ισχαιμικό εγκεφαλικό επεισόδιο 1.png',
  },
];

export const PrevNextCardio = ({ options, activePage, hasIcons = false }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const activePageId = options.fields.findIndex((field) => { return field.text === activePage; });

  const orderedOptions = [];

  if (0 === activePageId) {
    orderedOptions.push(options.fields[1]);
    orderedOptions.push(options.fields[0]);
    if (2 === options.fields.length) {
      orderedOptions.push(options.fields[1]);
    } else {
      orderedOptions.push(options.fields[2]);
    }
  } else if (1 === activePageId) {
    orderedOptions.push(options.fields[0]);
    orderedOptions.push(options.fields[1]);
    if (2 === options.fields.length) {
      orderedOptions.push(options.fields[0]);
    } else {
      orderedOptions.push(options.fields[2]);
    }
  } else if (2 === activePageId) {
    orderedOptions.push(options.fields[0]);
    orderedOptions.push(options.fields[2]);
    orderedOptions.push(options.fields[1]);
  }

  const { navigateToPage } = useNavigation();

  const setActive = (id) => {
    if (0 === id) {
      navigateToPage({ pageId: orderedOptions[0].url });
    } else if (1 === id) {
      navigateToPage({ pageId: orderedOptions[1].url });
    } else if (2 === id) {
      navigateToPage({ pageId: orderedOptions[2].url });
    }
  };

  return (
    <div className={ classes.options } style={ { backgroundColor: options.color } }>
      <div
        onClick={ () => { setActive(0); } }
        aria-hidden='true'
        className={ classes.prevNextIconText }
        style={ { width: '25%' } }
      >
        <Icon
          className={ classes.prevNextIcon }
          style={ {
            background: 'rgba(255, 255, 255, 0.29)',
            marginRight: '17px',
          } }
        >
          <img src='/arrow-left.svg' alt='' />
        </Icon>
        <div
          style={ { fontSize: '14px' } }
          className={ clsx(classes.hideLongText, classes.hideWhenSmall) }
        >
          {t(orderedOptions[0].text)}
        </div>
      </div>
      <div
        className={ classes.prevNextIconText }
        style={ {
          display: 'flex',
          justifyContent: 'center',
          width: '50%',
        } }
        onClick={ () => { setActive(1); } }
        aria-hidden
      >
        {
          hasIcons && (
            <Icon
              className={ classes.prevNextIcon }
              style={ {
                marginRight: '17px',
              } }
            >
              <img src={ orderedOptions[1].icon } alt='icon' />
            </Icon>
          )
        }

        <div
          className={ clsx(classes.hideLongText, classes.smallFontWhenSmall) }
        >
          {t(orderedOptions[1].text)}
        </div>
      </div>
      <div
        onClick={ () => { setActive(2); } }
        aria-hidden='true'
        className={ classes.prevNextIconText }
        style={ {
          display: 'flex',
          justifyContent: 'flex-end',
          width: '25%',
        } }

      >
        <div className={ classes.prevNextIconText }>
          <div
            style={ { fontSize: '14px' } }
            className={ clsx(classes.hideLongText, classes.hideWhenSmall) }
          >
            {t(orderedOptions[2].text)}
          </div>

          <Icon
            className={ classes.prevNextIcon }
            style={ {
              background: 'rgba(255, 255, 255, 0.29)',
              marginLeft: '17px',
            } }
          >
            <img src='/arrow-right.svg' alt='' />
          </Icon>
        </div>
      </div>
    </div>
  );
};

export const Profiles = ({ sensitivityProfiles, activeProfile }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  useEffect(() => {
    const profilesEl = document.getElementById('all-profiles');
    const activeProfileEl = document.getElementById(activeProfile);
    profilesEl.scrollLeft = activeProfileEl.offsetLeft - profilesEl.offsetLeft;
  }, []);

  return (
    <div className={ classes.profiles } id='all-profiles'>
      {
        sensitivityProfiles.map((profile) => {
          return (
            <div
              id={ profile.title }
              key={ profile.title }
              className={ classes.profile }
              style={ { border: activeProfile === profile.title ? `solid 1px ${ redColor }` : '',
                background: activeProfile === profile.title ? `${ redColor }1A` : '' } }
              onClick={ () => { navigateToPage({ pageId: profile.url }); } }
              aria-hidden
            >
              <Icon style={ {
                width: '49px',
                height: '49px',
                borderRadius: '50px',
              } }
              >
                <img src={ profile.icon } alt='' style={ { height: '50px', width: '50px' } } />
              </Icon>
              <span style={ { fontSize: '13px' } }>
                {t(profile.title)}
              </span>
            </div>
          );
        })
      }
    </div>
  );
};

const BigImageWithText = ({ icon, fullTitle: title, description, source }) => {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  let fullTitle = title;

  if ('en-US' === i18n.language) {
    const titleArr = fullTitle.split(' ');
    titleArr.shift();
    titleArr.shift();

    fullTitle = t(titleArr.join(' '));

    if ('Στεφανιαία Νόσο' === fullTitle) {
      fullTitle = t('Στεφανιαία νόσος');
    }

    fullTitle = `For the ${ fullTitle }`;
  }

  return (
    <div
      className={ classes.outsideBlurredBox }
      style={ {
        backgroundImage: `url("${ icon }")`,
        backgroundColor: 'white',
        backgroundRepeat: 'no-repeat',
      } }
    >
      <div
        className={ classes.blurredBox }
      >
        <div style={ {
          fontWeight: '700',
          fontSize: 'min(43.4717px, 7vw)',
          lineHeight: '125.19%',
          wordWrap: 'break-word',
        } }
        >
          {fullTitle}
        </div>
        <div style={ { marginTop: '30px',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '145.19%',
          letterSpacing: '0.03em' } }
        >
          {t(description)}
          <div style={ { marginTop: '3px', wordWrap: 'break-word' } }>
            (
            {t('source')}
            :
            {
              ' '
            }
            <a href={ source } target='_blank' rel='noopener noreferrer'>
              {source}
            </a>
            )
          </div>
        </div>
      </div>
    </div>
  );
};

export const ImageText = ({
  prsImage,
  characterisation,
  adjPrsImage,
  prsScore,
  adjPrsScore,
  // eslint-disable-next-line no-unused-vars
  adjPrsCharacterisationFem,
  prsCharacterisation,
  activeProfile,
  imageTitle,
  imageContent,
  traitProfile,
  adjPrsCharacterisationLevels,
  source,
}) => {
  const classes = useStyles();

  const [ isOpen, setIsOpen ] = useState(false);
  const [ isADJOpen, setADJIsOpen ] = useState(false);
  const { isSm } = useMediaQueries();
  const { t } = useTranslation();

  let color = redColor;
  const greenColor = '#68A042';
  const blueColor = '#005EA4';
  const otherRedColor = '#C80000';

  if ('Τυπική' === characterisation) {
    color = greenColor;
  } else if ('Βέλτιστη' === characterisation) {
    color = blueColor;
  } else if ('Mειωμένη' === characterisation) {
    color = otherRedColor;
  }

  let prsColor;
  if (20 >= prsScore) {
    prsColor = blueColor;
  } else if (20 < prsScore && 80 >= prsScore) {
    prsColor = greenColor;
  } else {
    prsColor = otherRedColor;
  }

  const handleDialogOpen = () => {
    setIsOpen(true);
  };

  const handleDialogClose = () => {
    setIsOpen(false);
  };

  const handleADJDialogOpen = () => {
    setADJIsOpen(true);
  };

  const handleADJDialogClose = () => {
    setADJIsOpen(false);
  };

  return (
    <div>
      <CardBase style={ { borderRadius: '15px' } }>
        <div className={ classes.profilesContainer }>
          <Profiles sensitivityProfiles={ sensProfiles } activeProfile={ activeProfile } />
        </div>

        <div className={ classes.container }>
          <div style={ { display: 'flex', justifyContent: 'space-between', flexWrap: isSm ? 'wrap' : 'nowrap', marginBottom: '10px' } }>
            <Typography style={ { width: '100%',
              fontWeight: 600,
              fontSize: 'min(37px, 7vw)',
              textAlign: 'left',
              marginBottom: '10px',
              color: '#C00000',
              fontFamily: 'Roboto' } }
            >
              {t('Βαθμολογία πολυγονιδιακού ρίσκου')}
              {' '}
              (PRS):
              {' '}
              {prsScore}
              %
            </Typography>

            <Button
              variant='outlined'
              style={ {
                borderRadius: '5px',
                marginTop: '13px',
                borderWidth: '1px',
                borderColor: 'black',
                color: '#545454',
                fontWeight: '300',
                fontSize: '15px',
                lineHeight: '18px',
                marginLeft: '10px',
                height: '45px',
              } }
              onClick={ handleDialogOpen }
            >
              {
                t('whatIsPRS')
              }
            </Button>
          </div>
          <Grid container spacing={ 3 } style={ { marginBottom: '20px' } }>
            <Grid item xs={ 12 }>
              <img
                src={ isSm ? prsImage[1] : prsImage[0] }
                style={ { maxWidth: '100%' } }
                alt=''
              />
            </Grid>
            <Grid item xs={ 12 }>
              <p>
                {t('Η βαθμολογία PRS είναι')}
                {' '}
                <strong>
                  { prsScore }
                  %
                </strong>
                {' '}
                {t('επί του πληθυσμού. Αυτό σημαίνει ότι σε κάθε 100 άτομα, το εκτιμώμενο δικό σου PRS είναι υψηλότερο από {{prsScore}} άτομα και ίδιο ή χαμηλότερο από τα υπόλοιπα {{prsScore100}} άτομα. Επομένως, το γενετικό σου υπόβαθρο σε θέτει σε', { prsScore, prsScore100: 100 - prsScore })}
                {' '}
                <strong>
                  { prsCharacterisation }
                </strong>
                {' '}
                {t('ρίσκο εμφάνισης της πάθησης')}
                .
              </p>
            </Grid>
          </Grid>
          <div style={ { display: 'flex', justifyContent: 'space-between', flexWrap: isSm ? 'wrap' : 'nowrap' } }>
            <Typography style={ { width: '100%',
              fontWeight: 600,
              fontSize: 'min(37px, 7vw)',
              textAlign: 'left',
              color: '#C00000',
              fontFamily: 'Roboto' } }
            >
              {t('Προσαρμοσμένο PRS (Adjusted PRS)')}
              :
              {
                ' '
              }
              {adjPrsScore}
              %

            </Typography>
            <Button
              variant='outlined'
              style={ {
                marginTop: '13px',
                borderRadius: '5px',
                borderWidth: '1px',
                borderColor: 'black',
                color: '#545454',
                fontWeight: '300',
                fontSize: '15px',
                lineHeight: '18px',
                height: '45px',
              } }
              onClick={ handleADJDialogOpen }
            >
              {t('whatIsADJPRS')}
            </Button>
          </div>
          <Grid container spacing={ 3 }>
            <Grid item xs={ 12 }>
              <div style={ {
                fontFamily: '\'Open Sans\', sans-serif',
                fontWeight: 400,
                fontSize: 16,
                marginTop: '15px',
              } }
              >
                {t('whatIsTakenIntoAccount')}
                <ol>
                  <li style={ { paddingLeft: '20px' } }>
                    {t('Βαθμολογία PRS')}
                    :
                    {' '}
                    <span style={ { color: prsColor, fontWeight: 'bold' } }>
                      {prsScore}
                      %
                    </span>
                  </li>
                  <li style={ { paddingLeft: '20px' } }>
                    {t('Βαθμολογία εκτίμησης τρέχουσας κατάστασης καρδιαγγειακής υγείας')}
                    :
                    {' '}
                    <span style={ { color, textTransform: 'capitalize', fontWeight: 'bold' } }>
                      {t(characterisation)}
                    </span>
                  </li>
                </ol>
              </div>
            </Grid>
            <Grid item xs={ 12 }>
              <img
                src={ isSm ? adjPrsImage[1] : adjPrsImage[0] }
                alt=''
                style={ { maxWidth: '100%' } }
              />
            </Grid>
            <Grid item xs={ 12 }>
              <p>
                {t('Το Προσαρμοσμένο PRS (Adjusted PRS) εκτιμάται ότι είναι')}
                {' '}
                <strong>
                  {adjPrsScore}
                  %
                </strong>
                {', '}
                {t('δηλαδή')}
                {' '}
                <strong>
                  {traitProfile}
                </strong>
                {' '}
                {t('σε σχέση με την βαθμολογία του PRS και βρίσκεται σε')}
                {' '}
                <strong>
                  {adjPrsCharacterisationLevels}
                  {
                    ' '
                  }
                  {t('επίπεδαEL')}
                </strong>
                {' '}
                {t('ρίσκου σε σχέση με το γενικό πληθυσμό. Η τήρηση υγιεινών συνηθειών και η συμμόρφωση με τις οδηγίες του γιατρού σου, μπορεί να συμβάλουν στη βέλτιστη καρδιαγγειακή υγεία και στην πρόληψη καρδιαγγειακών παθήσεων.')}
              </p>
            </Grid>
          </Grid>
        </div>
      </CardBase>

      <Dialog
        keepMounted={ false }
        open={ isADJOpen }
        onClose={ handleADJDialogClose }
        fullWidth
        maxWidth='md'
      >
        <DialogContent style={ { padding: '30px', position: 'relative' } }>
          <div style={ { position: 'absolute', right: '10px', top: '10px' } }>
            <IconButton onClick={ handleADJDialogClose }>
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            } }
          >
            <div
              style={ {
                padding: '5px',
                borderRadius: '22px',
              } }
            >
              <Typography
                style={ {
                  fontWeight: 600,
                  fontSize: '20px',
                  fontFamily: 'Roboto',
                } }
              >
                {t('Προσαρμοσμένη Βαθμολογία Πολυγονιδιακού Ρισκου (Adjusted PRS)')}
              </Typography>
              <p
                style={ {
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                } }
              >
                {
                  t('Το Προσαρμοσμένο PRS εκτιμά δυναμικά το συνδυαστικό ρίσκο εμφάνισης καρδιαγγειακών παθήσεων, συνεκτιμώντας χαρακτηριστικά του τρόπου ζωής και του φαινοτύπου, μέσω εγκεκριμένου ερωτηματολογίου καρδιαγγειακής υγείας*. Συγκεκριμένα, εξετάζει τις διατροφικές συνήθειες, το επίπεδο σωματικής άσκησης, το κάπνισμα, το δείκτη μάζας σώματος (Body Mass Index - BMI), την αρτηριακή πίεση, την ολική χοληστερόλη και το σάκχαρο, για να προσαρμόσει το PRS και να εκτιμήσει το ρίσκο με βάση την ηλικία και την τρέχουσα κατάσταση καρδιαγγειακής υγείας του ατόμου. Ενώ το PRS υπολογίζεται μία φορά και είναι απαράλλαχτο σε όλη τη διάρκεια της ζωής, το Προσαρμοσμένο PRS μπορεί να υπολογιστεί πολλές φορές στη διάρκεια της ζωής με βάση την εκάστοτε κατάσταση του τρόπου ζωής και του φαινοτύπου του ατόμου.')
                }
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        keepMounted={ false }
        open={ isOpen }
        onClose={ handleDialogClose }
        fullWidth
        maxWidth='md'
      >
        <DialogContent style={ { padding: '30px', position: 'relative' } }>
          <div style={ { position: 'absolute', right: '10px', top: '10px' } }>
            <IconButton onClick={ handleDialogClose }>
              <CloseIcon />
            </IconButton>
          </div>
          <div
            style={ {
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
            } }
          >
            <div
              style={ {
                padding: '5px',
                borderRadius: '22px',
              } }
            >
              <Typography
                style={ {
                  fontWeight: 600,
                  fontSize: '20px',
                  fontFamily: 'Roboto',
                } }
              >
                {t('Βαθμολογία Πολυγονιδιακού Ρισκου')}
                {' '}
                (PRS)
              </Typography>
              <p
                style={ {
                  fontSize: '16px',
                  fontFamily: 'Roboto',
                } }
              >
                {t('Το PRS λαμβάνει υπόψη πλήθος γενετικών πολυμορφισμών (SNPs) για την εκτίμηση του γενετικού ρίσκου για ανάπτυξη μιας συγκεκριμένης πάθησης. Κάθε γενετικός πολυμορφισμός μπορεί να είναι προστατευτικός ή προδιαθεσικός, ενώ από μόνος του τείνει να συμβάλλει σε μικρό βαθμό στο ρίσκο εμφάνισης μίας πάθησης. Αθροιστικά όμως, αυτές οι μικρές γενετικές διαφορές μπορεί να έχουν σημαντικά μεγαλύτερο αντίκτυπο. Συνεπώς, το PRS είναι ένα σταθμισμένο άθροισμα του αριθμού των αλληλομόρφων ρίσκου που φέρει ένα άτομο. Τόσο τα αλληλόμορφα ρίσκου όσο και η βαρύτητά τους καθορίζονται από τις επιδράσεις τους, όπως αυτές ανιχνεύονται σε δημοσιευμένες μελέτες γενετικής συσχέτισης (Genome Wide Association Studies - GWAS).')}
              </p>
            </div>
          </div>
        </DialogContent>
      </Dialog>

      <BigImageWithText
        source={ source }
        icon='/doctor_hearing.png'
        fullTitle={ imageTitle }
        description={ imageContent }
      />
    </div>

  );
};

export const Title = () => {
  const classes = useStyles();
  const { navigateToPage } = useNavigation();

  return (
    <div
      className={ classes.title }
      style={ { cursor: 'pointer' } }
      aria-hidden='true'
      onClick={ () => { navigateToPage({ pageId: 'iDNACardioHealth' }); } }
    >
      iDNA Cardio Health
    </div>
  );
};

const CardioResults = () => {
  const results = CardioHealthCards.filter((card) => {
    return 'cardioHealth' === card.id;
  })[0];

  return (
    <div>
      <Title />
      <PrevNextCardio options={ results } activePage='Αποτελέσματα' />
    </div>
  );
};

export default CardioResults;
