import React from 'react';
import { useSportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { iDNA3ToOneCards } from '../iDNA3ToOne/iDNA3ToOne';
import { Tab, PrevNext, PhotoWithText, ProfileCards } from '../iDNA3ToOne/Common';
import { blueColor } from './Sports';

export const profileToIcon = {
  kneeElbowInjury: { big: '/TRAYMATISMOS GONATO.jpg', small: '/TRAYMATISMOS GONATO_SMALL.jpg' },
  achillesTendonInjury: { big: '/AXILLEIOS TENONTAS.jpg', small: '/AXILLEIOS TENONTAS_SMALL.jpg' },
  musculoskeletalHealth: { big: '/MYOSKELETIKI YGEIA.jpg', small: '/MYOSKELETIKI YGEIA_SMALL.jpg' },
  recoveryAfterExercise: { big: '/APOTHERAPEIA.jpg', small: '/APOTHERAPEIA_SMALL.jpg' },
};

export const profileToUrl = {
  kneeElbowInjury: 'sportsKneeElbowInjury',
  achillesTendonInjury: 'sportsAchillesTendonInjury',
  musculoskeletalHealth: 'sportsMusculoskeletalHealth',
  recoveryAfterExercise: 'sportsRecoveryAfterExercise',
};

const NInjuryPredisposition = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const data = useSportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return ('Sensitivity' === currData.type && 'Προδιάθεση τραυματισμών' === currData.cluster);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('SCAT8' === filtered.ID) {
      iconPath = profileToIcon.kneeElbowInjury;
      url = profileToUrl.kneeElbowInjury;
    } else if ('SCAT9' === filtered.ID) {
      iconPath = profileToIcon.achillesTendonInjury;
      url = profileToUrl.achillesTendonInjury;
    } else if ('SCAT10' === filtered.ID) {
      iconPath = profileToIcon.musculoskeletalHealth;
      url = profileToUrl.musculoskeletalHealth;
    } else if ('SCAT11' === filtered.ID) {
      iconPath = profileToIcon.recoveryAfterExercise;
      url = profileToUrl.recoveryAfterExercise;
    }

    return { ...filtered, icon: iconPath, url };
  });

  const tab = {
    color: blueColor,
    id: 'sportsPage',
    title: 'Άθληση',
  };

  return (
    <div style={ { width: '100%' } }>
      <Tab tab={ tab } />
      <PrevNext options={ sportsCard } activePage='sportsInjuryPredisposition' isNutrition />
      <PhotoWithText
        options={ sportsCard }
        activePage='injuryPredisposition'
        text={ data[0].value.injury_summary_table_advices }
      />
      <ProfileCards sensitivityProfiles={ sensProfiles } color={ sportsCard.color } />
    </div>
  );
};

export default NInjuryPredisposition;
