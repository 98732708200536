import { FormControl, FormLabel, CircularProgress, RadioGroup,
  FormControlLabel, Radio, Typography, Button, Dialog, DialogActions,
  DialogContent, DialogContentText, Badge, withStyles } from '@material-ui/core';
import React, { useEffect, useMemo, useState } from 'react';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import 'date-fns';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import useAPI from 'hooks/api-hooks';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import * as _ from 'lodash';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';
import { useAuth } from 'AuthCtx';
import { useAlerts } from 'hooks/alerts-hooks';
import usLocale from 'date-fns/locale/en-US';
import grLocale from 'date-fns/locale/el';

const greenColor = '#93C221';

const localeOptions = { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'UTC' };
const utcLocaleOptions = { weekday: 'long', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: false, timeZone: 'Europe/Athens' };

function toISOString(date) {
  const tzo = -date.getTimezoneOffset();
  const dif = 0 <= tzo ? '+' : '-';
  const pad = function (num) {
    return (10 > num ? '0' : '') + num;
  };

  return `${ date.getFullYear()
  }-${ pad(date.getMonth() + 1)
  }-${ pad(date.getDate())
  }T${ pad(date.getHours())
  }:${ pad(date.getMinutes())
  }:${ pad(date.getSeconds())
  }${ dif }${ pad(Math.floor(Math.abs(tzo) / 60))
  }:${ pad(Math.abs(tzo) % 60) }`;
}

const StyledBadge = withStyles(() => {
  return {
    badge: {
      right: '6px',
      top: '6px',
    },
  };
})(Badge);

const useStyles = makeStyles(() => {
  return {
    slot: {
      padding: '15px',
      width: 'fit-content',
      borderRadius: '15px',
      border: '1px solid black',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    unselectedAvailableSlot: {
      background: 'white',
    },
    selectedAvailableSlot: {
      background: greenColor,
      border: `1px solid ${ greenColor }`,
      color: 'white',
    },
    nextButton: {
    },
  };
});

const LabelValue = ({ label, value, noTranslation = false, labelColor = '', boldLabel = false, valueColor = '', isUrl = false }) => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography style={ { textTransform: 'uppercase', color: labelColor, fontWeight: boldLabel ? 'bold' : 'normal' } }>
        {t(label) }
      </Typography>

      <Typography style={ { fontWeight: 'bold',
        color: valueColor,
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        whiteSpace: 'nowrap' } }
      >
        {
          !isUrl ? (
            noTranslation ? value : t(value)
          ) : (
            <a
              href={ value }
              target='_blank'
              rel='noreferrer'
            >
              {value}
            </a>
          )
        }
      </Typography>
    </div>
  );
};

const AvailableSlots = ({ slots, selectedSlotUUID, setSelectedSlotUUID, setSelectedSlotDate, isNutrigenetix, setBorrowedSlotSlotUUID }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const parsedSlots = useMemo(() => {
    if (true !== isNutrigenetix) {
      return slots;
    }

    const slotsCopy = _.cloneDeep(slots);
    const slotsToRemove = [];

    for (let i = 0; i < slotsCopy.length; i++) {
      const slot = slotsCopy[i];

      if (i < slotsCopy.length - 1) {
        const nextSlot = slotsCopy[i + 1];

        if (slot.date_to === nextSlot.date_from) {
          slot.date_to = nextSlot.date_to;
          slot.borrowed_slot = nextSlot.uuid;
          slotsToRemove.push(nextSlot.id);
          i += 1;
        } else {
          slotsToRemove.push(slot.id);
        }
      } else {
        slotsToRemove.push(slot.id);
      }
    }

    return slotsCopy.filter((s) => { return !slotsToRemove.includes(s.id); });
  }, [ slots, isNutrigenetix ]);

  return (
    <div>
      <Typography variant='h6' style={ { marginBottom: '5px' } }>
        {t('Διαθέσιμα slot')}
      </Typography>

      <div style={ { display: 'flex', flexWrap: 'wrap', gap: '10px' } }>

        {
          null == slots.length || 0 === slots.length || slots.every((slot) => { return 'book' === slot.status; }) ? (
            <div>{t('Δεν υπάρχουν διαθέσιμα slot αυτή τη μέρα.')}</div>
          )
            : (
              <>
                <Typography style={ { width: '100%', marginLeft: '3px' } } variant='subtitle1'>
                  {t('Επέλεξε ένα slot')}
                </Typography>
                {
                  parsedSlots?.filter((slot) => {
                    return 'open' === slot.status;
                  }).map((slot) => {
                    const dateFrom = new Date(slot.date_from);
                    const fromHours = dateFrom.getHours().toString().padStart(2, '0');
                    const fromMin = dateFrom.getMinutes().toString().padStart(2, '0');

                    const dateTo = new Date(slot.date_to);
                    const toHours = dateTo.getHours().toString().padStart(2, '0');
                    const toMin = dateTo.getMinutes().toString().padStart(2, '0');

                    // eslint-disable-next-line prefer-template
                    const fromTime = fromHours + ':' + fromMin;

                    // eslint-disable-next-line prefer-template
                    const toTime = toHours + ':' + toMin;

                    return (
                      <div
                        key={ slot.id }
                        className={ clsx(classes.slot,
                          selectedSlotUUID === slot.uuid ? classes.selectedAvailableSlot
                            : classes.unselectedAvailableSlot) }
                        aria-hidden
                        onClick={ () => {
                          setSelectedSlotUUID(slot.uuid);
                          setSelectedSlotDate(new Date(slot.date_from));
                          setBorrowedSlotSlotUUID(slot.borrowed_slot);
                        } }
                      >
                        <Typography>
                          {fromTime}
                          {' - '}
                          {toTime}
                        </Typography>
                      </div>
                    );
                  })
                }
              </>
            )
        }
      </div>
    </div>
  );
};

const AreYouSureDialog = ({ open, setOpen, handleSubmit }) => {
  const { t } = useTranslation();
  const [ isCanceling, setIsCanceling ] = useState(false);

  return (

    <Dialog
      open={ open }
      onClose={ () => { setOpen(false); } }
      fullWidth
      maxWidth='sm'
    >
      <DialogContent>
        <DialogContentText>
          {t('Είσαι σίγουρος ότι θέλεις να ακυρώσεις το ραντεβού σου;')}
        </DialogContentText>
        <DialogActions>
          <Button
            onClick={ () => { setOpen(false); } }
          >
            {t('no')}
          </Button>
          <Button onClick={ () => {
            setIsCanceling(true);
            handleSubmit().then(() => {
              setIsCanceling(false);
              setOpen(false);
            });
          } }
          >
            <div style={ { display: 'flex', justifyContent: 'center' } }>
              { isCanceling
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }

              {t('yes')}
            </div>
          </Button>
        </DialogActions>
      </DialogContent>
    </Dialog>
  );
};

const BookedAppointment = ({ appointment, refetchAll }) => {
  const { t, i18n } = useTranslation();
  const { client } = useAPI();
  const { alertSuccess } = useAlerts();

  const [ areYouSureDialog, setAreYouSureDialog ] = useState(false);

  const handleSubmit = () => {
    return client.put('/appointments/cancel', { uuid: appointment.uuid }).then(() => {
      return refetchAll();
    })
      .then(() => {
        alertSuccess(t('Το ραντεβού ακυρώθηκε επιτυχώς.'));
      });
  };

  let dateFrom = new Date(appointment?.date_from ?? undefined);
  const userTimezoneOffset = dateFrom.getTimezoneOffset() * 60000;
  dateFrom = new Date(dateFrom.getTime() - userTimezoneOffset);

  const canBeCanceled = useMemo(() => {
    return new Date() < new Date(appointment.date_from);
  }, [ appointment ]);

  return (
    <div style={ { display: 'flex', flexDirection: 'column', gap: '20px', padding: '10px 25px 25px 25px', color: '#818181', background: 'white', width: '100%', borderRadius: '15px' } }>
      <AreYouSureDialog
        open={ areYouSureDialog }
        setOpen={ setAreYouSureDialog }
        handleSubmit={ handleSubmit }
      />
      <div>
        <Typography style={ { color: greenColor } } variant='h6'>
          {t('Επιβεβαίωση ραντεβού')}
        </Typography>

        <Typography>
          {t('Το ραντεβού σας καταχωρήθηκε με επιτυχία')}
        </Typography>
      </div>

      <LabelValue
        label={ t('Επιβεβαίωση #') }
        value={ appointment?.uuid }
        noTranslation
        boldLabel
        valueColor='#b8b8b8'
      />

      <Typography style={ { color: greenColor } } variant='h6'>
        {t('Πληροφορίες Ραντεβού')}
      </Typography>

      <LabelValue
        label={ t('Ημερομηνία και ώρα') }
        value={ dateFrom?.toLocaleString(i18n.language, localeOptions) }
        noTranslation
      />

      <LabelValue
        label='Επιστημονικός σύμβουλος'
        value='Άντζυ Καπέλλου'
      />

      {
        'video_call' === appointment.type && (
          <LabelValue
            label='URL'
            value={ appointment.join_call_url }
            isUrl
          />
        )
      }

      <LabelValue
        label={ t('Yπηρεσία') }
        value={ t('Κλείσε ραντεβού') }
      />

      <LabelValue
        label='Serial number'
        value={ appointment?.serial_number.serial }
      />

      <Typography style={ { color: greenColor } } variant='h6'>
        {t('Πληροφορίες του πελάτη')}
      </Typography>

      <LabelValue
        label='header-name'
        value={ `${ appointment?.customer?.family_name } ${ appointment?.customer?.name }` }
      />

      <LabelValue
        label='mobileNumber'
        value={ appointment?.customer?.phone_number }
      />

      <LabelValue
        label='E-mail'
        value={ appointment?.customer?.email }
      />

      <LabelValue
        label={ t('Έχετε απορίες;') }
        value={ t('Καλέστε στο 2111021854-5 για βοήθεια') }
        labelColor={ greenColor }
      />

      {
        canBeCanceled && (
          <Button
            variant='contained'
            style={ { background: '#B72619', color: 'white', maxWidth: '230px' } }
            onClick={ () => {
              setAreYouSureDialog(true);
            } }
          >
            {t('cancel')}
          </Button>
        )
      }
    </div>
  );
};

const AppointmentVerificationDialog = ({ confirmAppointmentDialogOpen, setConfirmAppointmentDialogOpen, serial, selectedSlotDate, uuid,
  appointmentType, refetchAll, borrowedSlotSlotUUID }) => {
  const { t, i18n } = useTranslation();
  const { client } = useAPI();

  const { alertSuccess } = useAlerts();

  const [ isConfirming, setIsConfirming ] = useState(false);

  const handleSubmit = () => {
    setIsConfirming(true);
    client.post('/appointments/bookSlot', { uuid,
      serial_number: serial,
      ...borrowedSlotSlotUUID !== undefined ? { uuid_second: borrowedSlotSlotUUID } : [],
      type: appointmentType }).then(() => {
      return refetchAll();
    })
      .then(() => {
        setConfirmAppointmentDialogOpen(false);
      }).then(() => {
        alertSuccess(t('Το ραντεβού δημιουργήθηκε επιτυχώς.'));
      }).finally(() => {
        setIsConfirming(false);
      });
  };

  return (
    <Dialog
      open={ confirmAppointmentDialogOpen }
      onClose={ () => { setConfirmAppointmentDialogOpen(false); } }
      fullWidth
      maxWidth='sm'
    >
      <div style={ { padding: '25px', display: 'flex', flexDirection: 'column', gap: '20px', color: '#818181' } }>
        <Typography style={ { color: greenColor } } variant='h6'>
          {t('Επιβεβαιώστε τα στοιχεία του ραντεβού')}
        </Typography>

        <Typography>
          {t('Ελέγξτε τα στοιχεία της κράτησης σας και πατήστε το κουμπί Επιβεβαίωση αν όλα είναι όλα σωστά.')}
        </Typography>

        <Typography style={ { color: greenColor } } variant='h6'>
          {t('Πληροφορίες Ραντεβού')}
        </Typography>

        <LabelValue
          label='Ημερομηνία και ώρα'
          value={ selectedSlotDate?.toLocaleString(i18n.language, utcLocaleOptions) }
          noTranslation
        />

        <LabelValue
          label='Επιστημονικός σύμβουλος'
          value='Άντζυ Καπέλλου'
        />

        <LabelValue
          label='Yπηρεσία'
          value='Κλείσε ραντεβού'
        />

        <LabelValue
          label='Serial number'
          value={ serial }
        />
      </div>

      <DialogActions>
        <Button
          onClick={ () => { setConfirmAppointmentDialogOpen(false); } }
          disabled={ isConfirming }
        >
          {t('back')}
        </Button>
        <Button onClick={ handleSubmit } disabled={ isConfirming }>
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            { isConfirming
          && (
            <CircularProgress
              size={ 24 }
              color='secondary'
              style={ { margin: '0 10px' } }
            />
          ) }

            {t('confirm')}
          </div>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const ExplanationOfResults = ({ serial }) => {
  const { t, i18n } = useTranslation();

  const [ selectedDate, setSelectedDate ] = useState(new Date());

  const { client } = useAPI();

  const classes = useStyles();

  const [ appointmentType, setAppointmentType ] = useState('phone_call');

  const [ noTimeSlotError, setNoTimeSlotError ] = useState(false);

  const { userAttributes } = useAuth();
  const { country } = userAttributes;

  const locale = 'en-US' === i18n.language ? usLocale : grLocale;

  const { data: serialEntity, isLoading: serialEntityLoading } = useQuery([ 'serial', serial ], () => {
    return client.get(`serial-numbers/${ serial }`);
  });

  const isNutrigenetix = 'iDNA NutriGenetix kit' === serialEntity?.thematic_package?.real_name
  || 'NutriGenetix Laboratories kit' === serialEntity?.thematic_package?.real_name;

  const { data, isLoading, refetch } = useQuery(
    [ 'Appointment', selectedDate ], () => {
      const tempDayStart = _.cloneDeep(selectedDate);
      const tempDayEnd = _.cloneDeep(selectedDate);

      if (tempDayStart.toDateString() !== new Date().toDateString()) {
        tempDayStart.setHours(0, 0, 0, 0);
      } else {
        tempDayStart.setUTCHours(tempDayStart.getUTCHours() + 2);
      }

      tempDayEnd.setHours(23, 59, 59, 59);

      const tempDayStartStr = toISOString(tempDayStart);
      const tempDayEndStr = toISOString(tempDayEnd);

      return client.get('/appointments/getTimeSlots', { params: { date_from_gte: tempDayStartStr,
        date_to_lte: tempDayEndStr,
        _sort: 'date_from:asc',
        _limit: -1 } });
    },
  );

  const { data: allSlots, isLoading: allSlotsLoading, refetch: allSlotsRefetch } = useQuery(
    [ 'Appointment', 'all' ], () => {
      const tempDayStart = new Date();
      tempDayStart.setUTCHours(tempDayStart.getUTCHours() + 2);

      const tempDayEnd = new Date();
      tempDayEnd.setMonth(tempDayEnd.getMonth() + 3);

      if (tempDayStart.toDateString() !== new Date().toDateString()) {
        tempDayStart.setHours(0, 0, 0, 0);
      } else {
        tempDayStart.setUTCHours(tempDayStart.getUTCHours() + 2);
      }

      tempDayEnd.setHours(23, 59, 59, 59);

      const tempDayStartStr = toISOString(tempDayStart);
      const tempDayEndStr = toISOString(tempDayEnd);

      return client.get('/appointments/getTimeSlots', { params: { date_from_gte: tempDayStartStr,
        date_to_lte: tempDayEndStr,
        _sort: 'date_from:asc',
        _limit: -1 } });
    },
  );

  const { data: bookedAppointments, isLoading: isBookedAppointmentsLoading, refetch: bookedRefetch } = useQuery(
    [ 'Appointment', 'booked', serial ], () => {
      return client.get('/appointments/getTimeSlots', { params: { serial_number: serial, serial_number_eq: serial } });
    },
  );

  const [ selectedSlotUUID, setSelectedSlotUUID ] = useState();
  const [ borrowedSlotSlotUUID, setBorrowedSlotSlotUUID ] = useState();
  const [ selectedSlotDate, setSelectedSlotDate ] = useState();

  const [ confirmAppointmentDialogOpen, setConfirmAppointmentDialogOpen ] = useState(false);

  const refetchAll = () => {
    setSelectedSlotUUID(undefined);
    setSelectedSlotDate(undefined);

    return refetch().then(() => {
      allSlotsRefetch();
      return bookedRefetch();
    });
  };

  useEffect(() => {
    setSelectedSlotUUID(undefined);
    setNoTimeSlotError(false);
  }, [ selectedDate ]);

  useEffect(() => {
    if (selectedSlotUUID !== undefined) {
      setNoTimeSlotError(false);
    }
  }, [ selectedSlotUUID ]);

  const makeAnAppointment = () => {
    if (selectedSlotUUID === undefined) {
      setNoTimeSlotError(true);
      return;
    }

    setConfirmAppointmentDialogOpen(true);
  };

  if (isBookedAppointmentsLoading || serialEntityLoading) {
    return (<LoaderSkeleton />);
  }

  if (null != bookedAppointments && 0 !== bookedAppointments.length) {
    return (
      <BookedAppointment
        appointment={ bookedAppointments[bookedAppointments.length - 1] }
        refetchAll={ refetchAll }
      />
    );
  }

  return (
    <div style={ { color: '#818181', background: 'white', width: '100%', borderRadius: '15px', padding: '10px 25px 25px 25px' } }>
      <AppointmentVerificationDialog
        confirmAppointmentDialogOpen={ confirmAppointmentDialogOpen }
        borrowedSlotSlotUUID={ borrowedSlotSlotUUID }
        setConfirmAppointmentDialogOpen={ setConfirmAppointmentDialogOpen }
        serial={ serial }
        selectedSlotDate={ selectedSlotDate }
        uuid={ selectedSlotUUID }
        appointmentType={ appointmentType }
        refetchAll={ refetchAll }
      />

      <Typography variant='h4' style={ { marginBottom: '10px' } }>
        {t('explanationOfResults')}
      </Typography>

      <Typography variant='subtitle1' style={ { marginBottom: '20px' } }>
        {t('closeAppointmentExplanation')}
      </Typography>

      <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
        <FormControl>
          <FormLabel id='appointment-type-label' color='secondary'>
            <Typography variant='h5' style={ { color: '#818181' } }>
              {t('Τύπος Ραντεβού')}
            </Typography>
          </FormLabel>
          <RadioGroup
            aria-labelledby='appointment-type-label'
            row
            value={ appointmentType }
            onChange={ (e) => { setAppointmentType(e.target.value); } }
          >
            <FormControlLabel value='phone_call' control={ <Radio /> } label={ t('phoneCall') } />
            <FormControlLabel value='video_call' control={ <Radio /> } label={ t('videoCall') } />
          </RadioGroup>
        </FormControl>

        <div>
          <Typography variant='h6'>
            { t('Ημέρα') }
          </Typography>

          <MuiPickersUtilsProvider utils={ DateFnsUtils } locale={ locale }>
            <KeyboardDatePicker
              disableToolbar
              variant='inline'
              color='secondary'
              disablePast
              autoOk
              format={ 'US' === country ? 'MM/dd/yyyy' : 'dd/MM/yyyy' }
              value={ selectedDate }
              onChange={ (e) => { setSelectedDate(e); } }
              KeyboardButtonProps={ {
                'aria-label': 'change date',
              } }
              renderDay={ (date, _selectedDate, isInCurrentMonth, dayComponent) => {
                let hasAvailableSlot = false;

                if (isInCurrentMonth) {
                  hasAvailableSlot = allSlots.find((ss) => {
                    return new Date(ss.date_from).toDateString() === date.toDateString();
                  });
                }

                if (hasAvailableSlot) {
                  return (
                    <StyledBadge
                      variant='dot'
                      color='secondary'
                    >
                      {dayComponent}
                    </StyledBadge>
                  );
                }

                return (
                  <>
                    {
                      dayComponent
                    }
                  </>
                );
              } }
            />
          </MuiPickersUtilsProvider>
        </div>

        {
          (isLoading || allSlotsLoading)
            ? (<LoaderSkeleton />)
            : (
              <AvailableSlots
                slots={ data }
                selectedSlotUUID={ selectedSlotUUID }
                setSelectedSlotUUID={ setSelectedSlotUUID }
                setSelectedSlotDate={ setSelectedSlotDate }
                setBorrowedSlotSlotUUID={ setBorrowedSlotSlotUUID }
                isNutrigenetix={ isNutrigenetix }
              />
            )
        }
      </div>

      <div style={ { display: 'flex', flexDirection: 'column', marginTop: '30px' } }>
        <div style={ { marginBottom: '20px' } }>
          <Typography style={ { color: greenColor, fontWeight: 'bold' } }>
            {t('Έχετε απορίες;')}
          </Typography>
          <Typography>
            {t('Καλέστε στο 2111021854-5 για βοήθεια')}
          </Typography>
        </div>

        {noTimeSlotError && (
          <span style={ { color: 'red', marginBottom: '3px' } }>
            {'* '}
            {t('Δεν έχεις επιλέξει κάποιο slot.')}
          </span>
        )}

        <Button
          variant='contained'
          color='secondary'
          className={ classes.nextButton }
          onClick={ makeAnAppointment }
          style={ { maxWidth: '200px' } }
          disabled={ isLoading || null == data || 0 === data.length }
        >
          {t('next')}
        </Button>
      </div>

    </div>
  );
};

export default ExplanationOfResults;
