import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { InvitationReason } from 'models/invitations';
import { useItems } from 'models/atoms/items-hook';
import CollectionKeys from 'models/atoms/collection-keys';
import {
  Grid,
  Typography,
  Divider,
} from '@material-ui/core';
import { Redirect } from 'react-router';
import { useTranslation } from 'react-i18next';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import useMediaQueries from '../hooks/media-query-hooks';
import { useDoctorIsAbleToGiveConsent } from '../components/layout/doctor-dashboard/navigationPages';
import ConsentsTable from './doctor-consents-page/ConsentsTable';

const useStyles = makeStyles((_theme) => {
  return {
    titleCta: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
  };
});

const DoctorConsentsPage = (props) => {
  const { title } = props;
  const classes = useStyles();
  const { t } = useTranslation();

  const { isMd } = useMediaQueries();

  const doctorIsAbleToGiveConsent = useDoctorIsAbleToGiveConsent();

  const {
    items: pendingInvitations,
    itemsLoading: pendingInvitationsLoading,
  } = useItems(CollectionKeys.Invitations,
    {
      filterQuery: { _limit: -1, reason: InvitationReason.PGX_DOCTOR_CONSENT, status_eq: 'pending' },
    });

  if (!doctorIsAbleToGiveConsent) {
    return <Redirect to='/' />;
  }

  return (
    <Grid container spacing={ isMd ? 4 : 2 }>
      <Grid item xs={ 12 }>
        <div className={ classes.titleCta }>
          <Typography variant='h6' color='textPrimary'>
            {title}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={ 12 }>
        <Divider />
      </Grid>
      <Grid container item xs={ 12 }>
        {
          !pendingInvitationsLoading && 0 < pendingInvitations?.length && (
            <Grid item xs={ 12 }>
              <Alert severity='info'>
                <AlertTitle>
                  {t('Εκκρεμείς συγκαταθέσεις')}
                </AlertTitle>
                {t('Έχετε αιτήματα για να δώσετε συγκατάθεση. Μπορείτε να δώσετε ή να μη δώσετε την συγκατάθεσή σας πατώντας το αντίστοιχο κουμπί.')}
              </Alert>
            </Grid>
          )
        }
        <Grid item xs={ 12 }>
          <ConsentsTable />
        </Grid>
      </Grid>
    </Grid>
  );
};

export default DoctorConsentsPage;
