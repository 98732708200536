import React from 'react';
import { useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useTranslation } from 'react-i18next';
import { Tabs, PrevNext } from '../Common';
import { iDNA3ToOneCards } from '../iDNA3ToOne';
import { Result } from './Common';
import { profileToIcon, profileToUrl } from '../InjuryPredisposition';

const PainTolerance = () => {
  const sportsCard = iDNA3ToOneCards.filter((card) => {
    return 'sports' === card.id;
  })[0];

  let resultText = '';
  let value = '';
  let advice = '';

  const { i18n } = useTranslation();

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'SCAT8', 'SCAT9', 'SCAT10', 'SCAT11', 'SCAT7' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';
    const prefix = 'την';
    const description = 'SCAT11_description';

    if ('SCAT7' === filtered.ID) {
      url = profileToUrl.painTolerance;
      iconPath = profileToIcon.painTolerance;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    } else if ('SCAT8' === filtered.ID) {
      iconPath = profileToIcon.kneeElbowInjury;
      url = profileToUrl.kneeElbowInjury;
    } else if ('SCAT9' === filtered.ID) {
      iconPath = profileToIcon.achillesTendonInjury;
      url = profileToUrl.achillesTendonInjury;
    } else if ('SCAT10' === filtered.ID) {
      iconPath = profileToIcon.musculoskeletalHealth;
      url = profileToUrl.musculoskeletalHealth;
    } else if ('SCAT11' === filtered.ID) {
      iconPath = profileToIcon.recoveryAfterExercise;
      url = profileToUrl.recoveryAfterExercise;
      resultText = filtered.genetic_profile_text;
      value = filtered.result;
      advice = filtered.advice;
    }

    return { ...filtered, icon: iconPath, url, prefix, description };
  });

  return (
    <div>
      <Tabs />
      <PrevNext options={ sportsCard } activePage='athleticProfile' />
      <Result
        sensitivityProfiles={ sensProfiles }
        activePage='athleticProfile'
        activeProfile='Αντοχή στον πόνο'
        activeColor={ sportsCard.color }
        resultText={ resultText }
        value={ value }
        advice={ advice }
      />
    </div>
  );
};

export default PainTolerance;
