import { useContext } from 'react';
import { AlertsCtx } from '../AlertsCtx';

export function useAlerts() {
  const {
    alertSuccess,
    alertError,
    alertInfo,
    alertWarning,
  } = useContext(AlertsCtx);

  return {
    alertSuccess,
    alertError,
    alertInfo,
    alertWarning,
  };
}
