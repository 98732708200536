import CardBase from 'components/app/CardBase';
import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Chip, Divider, Grid, TextField, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { Autocomplete } from '@material-ui/lab';
import { v4 as uuid } from 'uuid';
import { useReferAFriend } from 'models/refer-a-friend';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      backgroundColor: '#ededed',
    },
    referFriendTitle: {
      color: '#94c320',
      fontWeight: 'bold',
      fontSize: '30px',
      fontFamily: 'Roboto',
    },
    referFriendDesc: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontSize: '20px',
      fontFamily: 'Roboto',
      textShadow: '2px 2px gray',
    },
    referFriendDesc1: {
      color: '#ffffff',
      fontSize: '20px',
      fontFamily: 'Roboto',
      textShadow: '2px 2px gray',
    },
    card1: {
      backgroundColor: '#f5ca1e',
    },
    howYouBenefitTitle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: '20px',
    },
    howYouBenefitDesc: {
      fontSize: '15px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    card2: {
      backgroundColor: '#f5ca1e',
    },
    friendBenefitTitle: {
      color: '#ffffff',
      fontWeight: 'bold',
      fontFamily: 'Roboto',
      fontSize: '20px',
    },
    friendBenefitDesc: {
      fontSize: '15px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    usefullInfoTitle: {
      color: '#94c320',
      fontFamily: 'Roboto',
      fontSize: '50px',
    },
    usefullInfoListItem: {
      fontSize: '15px',
      fontFamily: 'Roboto',
    },
    termsLink: {
      color: '#94c320',
      fontSize: '18px',
      fontFamily: 'Roboto',
      fontWeight: 'bold',
    },
    autoComplete: {
      width: '100%',
    },
    button: {
      fontWeight: 'bold',
      fontSize: '18px',
      fontFamily: 'Roboto',
      color: theme.palette.common.white,
      background: theme.palette.secondary.main,
      boxShadow: '0px 4px 4px rgb(0 0 0 / 16%)',
      borderRadius: '40px',
      marginTop: '25px',
      padding: '8px 8px',
      '&:hover': {
        background: theme.palette.secondary.dark,
      },
    },
  };
});

const ReferAFriendPage = () => {
  const classes = useStyles();
  const [ receivers, setReceivers ] = useState([]);

  const listItemId = uuid();

  const { mutateAsync: referFriends } = useReferAFriend();

  const handleSendEmails = (e) => {
    e.preventDefault();
    console.log('receivers: ', receivers);
    const emails = receivers;
    return referFriends({ emails })
      .then((data) => {
        console.log(data);
      }).catch()
      .finally(() => {

      });
  };

  return (
    <Grid container spacing={ 5 }>
      <Grid item xs={ 6 }>
        <CardBase withShadow align='left' className={ classes.root }>
          <Typography className={ classes.referFriendTitle }>
            Σύστησε το φίλο σου
          </Typography>
          <p className={ classes.referFriendDesc }>
            Προσκαλέστε τους φίλους και την οικογένεια σας στην iDNA Genomics και βγείτε κερδισμένοι!
          </p>
          <Divider
            variant='middle'
            style={ { width: '100%', marginTop: '3px', marginBottom: '3px', color: 'white' } }
          />
          <p className={ classes.referFriendDesc1 }>
            Όσους περισσότερους καλέσετε τόσες περισσότερες ευκαιρίες έχετε να κερδίσετε.
          </p>
        </CardBase>
      </Grid>
      <Grid item xs={ 6 }>
        <Grid item>
          <CardBase className={ classes.card1 }>
            <Typography className={ classes.howYouBenefitTitle }>
              Τι κερδίζετε;
            </Typography>
            <p className={ classes.howYouBenefitDesc }>
              Για κάθε ολοκληρωμένη αγορά iDNA Test που θα πραγματοποιούν
              τα άτομα που έχετε προσκαλέσει, κερδίζετε 10 πόντους που αντιστοιχούν
              σε δωροκάρτα αξίας 10 ευρώ από συνεργαζόμενο online φαμακείο.
            </p>
          </CardBase>
        </Grid>
        <Divider
          style={ { marginTop: '5px' } }
        />
        <Grid item>
          <CardBase className={ classes.card2 }>
            <Typography className={ classes.friendBenefitTitle }>
              Τι κερδίζουν oι φίλοι σας;
            </Typography>
            <p className={ classes.friendBenefitDesc }>
              Τα άτομα που θα προτείνετε, θα λάβουν e-mail με εκπτωτικό κουπόνι 20%
              σε όποιο iDNA Test επιλέξουν να αγοράσουν από το website μας.
            </p>
          </CardBase>
        </Grid>
      </Grid>
      <Grid item>
        <Typography className={ classes.usefullInfoTitle }>Χρήσιμες πληροφορίες</Typography>
        <Divider />
        <ul>
          <li className={ classes.usefullInfoListItem }>
            Οι φίλοι σας, θα πρέπει να πραγματοποιήσουν την αγορά τους μέσω του προγράμματος «Σύστησε το φίλο σου»
            και να χρησιμοποιήσουν τον εκπτωτικό κωδικό που θα έχουν λάβει μέσω email
            για να μπορέσετε να κερδίσετε τη δωροκάρτα αξίας 10ευρώ
          </li>
          <li className={ classes.usefullInfoListItem }>Οι προωθήσεις αφορούν αποκλειστικά μόνιμους κατοίκους Ελλάδας και Κύπρου.</li>
          <li className={ classes.usefullInfoListItem }>Η προσφορά δεν μπορεί να συνδυαστεί με καμία άλλη έκπτωση.</li>
          <li className={ classes.usefullInfoListItem }>Τους πόντους που μαζεύετε μπορείτε να τους εξαργυρώσετε μέσα στο προγραμματισμένο χρονικό πλαίσιο</li>
        </ul>
        <Link
          to={ { pathname: ' https://idnagenomics.com/en/terms-conditions/' } }
          target='_blank'
          className={ classes.termsLink }
        >
          Διαβάστε τους Όρους & Προϋποθέσεις
        </Link>
      </Grid>
      <Autocomplete
        multiple
        id='emails-list'
        options={ [] }
        defaultValue={ [] }
        freeSolo
        className={ classes.autoComplete }
        renderTags={ (value, getTagProps) => {
          return value.map((option, index) => {
            setReceivers(value);
            return (
              <Chip
                key={ listItemId }
                variant='outlined'
                label={ option }
                { ...getTagProps({ index }) }
              />
            );
          });
        } }
        renderInput={ (params) => {
          return (
            <TextField
              { ...params }
              label='Email List'
              placeholder='Add a friend by pressing enter after its dotName or address'
            />
          );
        } }
      />
      <Button
        onClick={ handleSendEmails }
        className={ classes.button }
      >
        Ξεκινήστε τώρα!
      </Button>
    </Grid>
  );
};

export default ReferAFriendPage;
