import React from 'react';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import { useTranslation } from 'react-i18next';
import { useAlerts } from '../../hooks/alerts-hooks';
import { useBlockingLoader } from '../../hooks/loader-hooks';
import { useAuth } from '../../AuthCtx';
import { useFormStylesUp } from './form-styles-signup';
import ConfirmationCodeVerification from './ConfirmationCodeVerification';

export default function SignInVerificationForm() {
  const formClasses = useFormStylesUp();
  const { t } = useTranslation();
  const { confirmSignIn } = useAuth();
  const { showBlockingLoader, hideBlockingLoader } = useBlockingLoader('sign-in-verification-form');
  const { alertError } = useAlerts();

  const handleVerify = (code) => {
    Promise.resolve()
      .then(() => {
        showBlockingLoader();
      })
      .then(() => {
        return confirmSignIn({ code });
      })
      .catch((err) => {
        alertError(err);
      })
      .finally(() => {
        hideBlockingLoader();
      });
  };

  return (
    <Container className={ formClasses.container }>
      <div className={ formClasses.paper }>
        <Typography component='div' variant='h5' color='secondary'>
          {t('step2verification')}
        </Typography>
        <Grid className={ formClasses.description }>
          <Typography align='left' component='div' variant='body1' color='textPrimary'>
            {t('step2verificationDesc')}
          </Typography>
        </Grid>
        <Grid container spacing={ 2 }>
          <ConfirmationCodeVerification onVerify={ handleVerify } />
        </Grid>
      </div>
    </Container>
  );
}
