import React, { useEffect } from 'react';
import SerialNumberRegistrationForm from 'components/forms/common-forms/SerialNumberRegistrationForm';
import { useNavigation, NavigationProvider, useOptionSerial } from 'NavigationProvider';
import { useKits } from 'models/kits';
import Alert from '@material-ui/lab/Alert';
import AlertTitle from '@material-ui/lab/AlertTitle';
import { Grid, Typography, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useStyles } from 'pages/DoctorResultsPage';
import KitRegistrationDashboard, { KitCompleteRegistrationPage, LoaderSkeleton } from './KitRegistrationDashboard';

const DoctorKitRegistrationResolver = ({ prevActivePageId, cardioSerial, threeToOneSerial, prevNavigateToPage }) => {
  const { navigateToPage, activePageId } = useNavigation();

  useEffect(() => {
    if ('general' === activePageId && 'registerNewUpgradableKit' === prevActivePageId && null == cardioSerial && null == threeToOneSerial) {
      prevNavigateToPage({ pageId: 'kitUpgrade' });
    }
  }, [ prevActivePageId, cardioSerial, threeToOneSerial, activePageId ]);

  console.log('cardioSerial, threeToOneSerial', cardioSerial, threeToOneSerial);

  const hasRegisteredKit = 'general' === activePageId || '' === activePageId;
  const classes = useStyles();
  const { t } = useTranslation();

  let pageName = '';
  let registrationType = null;

  if (cardioSerial !== undefined) {
    registrationType = 'iDNA Cardio Health';
  } else if (threeToOneSerial !== undefined) {
    registrationType = 'iDNA Test 3in1';
  }

  switch (prevActivePageId) {
    case 'registerNewKitDoctorCardio':
      pageName = 'iDNA Cardio Health';
      registrationType = 'iDNA Cardio Health';
      break;
    case 'registerNewKitDoctorBioAge':
      pageName = 'iDNA Βιολογική Ηλικία';
      registrationType = 'iDNA Biological Age';
      break;

    case 'registerNewKitDoctor3In1':
      pageName = 'iDNATest3to1';
      registrationType = 'iDNA Test 3in1';
      break;

    case 'registerNewKitDoctorDiet':
      pageName = 'iDNA Test Διατροφή';
      registrationType = 'iDNA Nutrition Test';
      break;

    case 'registerNewKitDoctorNutrigenetix':
      pageName = 'iDNA NutriGenetix';
      registrationType = 'iDNA NutriGenetix kit';
      break;

    case 'registerNewKitDoctorPGx':
      pageName = 'iDNA PGx CNS';
      registrationType = 'iDNA PGx CNS Kit';
      break;

    default:
      break;
  }

  return (
    <div style={ { width: '100%' } }>
      {
        hasRegisteredKit ? (
          <>
            <Grid item xs={ 12 }>
              <div className={ classes.titleCta }>
                <Typography variant='h6' color='textPrimary'>
                  {t(pageName)}
                </Typography>
              </div>
            </Grid>
            <Grid item xs={ 12 }>
              <Divider />
            </Grid>

            <div style={ { display: 'flex', justifyContent: 'center', minHeight: '453px' } }>

              <SerialNumberRegistrationForm
                prevSerial={ threeToOneSerial ?? cardioSerial }
                onSubmit={ (data) => {
                  navigateToPage({ pageId: data.id });
                } }
                registrationType={ registrationType }
              />
            </div>
          </>
        ) : (
          <KitCompleteRegistrationPage />
        )
      }
    </div>
  );
};

const DoctorKitRegistrationDashboard = () => {
  const { activePageId, navigateToPage } = useNavigation();

  const { serial: threeToOneSerial, setSerial: set3To1Serial } = useOptionSerial('3-in-1');
  const { serial: cardioSerial, setSerial: setCardioSerial } = useOptionSerial('IDNA Cardio Health');

  useEffect(() => {
    if ('registerNewUpgradableKit' !== activePageId) {
      set3To1Serial(undefined);
      setCardioSerial(undefined);
    }
  }, [ activePageId ]);

  return (
    <NavigationProvider pageKey='kitId' fallbackPageId='general'>
      <DoctorKitRegistrationResolver
        prevNavigateToPage={ navigateToPage }
        prevActivePageId={ activePageId }
        threeToOneSerial={ threeToOneSerial }
        cardioSerial={ cardioSerial }
      />
    </NavigationProvider>
  );
};

export const DoctorKitRegistrationDashboardResume = () => {
  const { existsCompleteKitRegistration, isLoading } = useKits();
  const { t } = useTranslation();

  if (isLoading) {
    return (
      <LoaderSkeleton />
    );
  }

  if (!existsCompleteKitRegistration) {
    return (
      <Alert severity='info'>
        <AlertTitle>
          {t('Δεν έχετε κιτ που εκκρεμούν εγγραφή')}
        </AlertTitle>
      </Alert>
    );
  }

  return (
    <KitRegistrationDashboard />
  );
};

export default DoctorKitRegistrationDashboard;
