import { useOptionSerial } from 'NavigationProvider';
import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import QuestionnaireAnswers from 'components/QuestionnaireAnswers';
import { useCardioDataReport } from 'models/kits';
import { redColor } from './iDNACardioHealth';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const CardioQuestionnaireAnswers = () => {
  const { serial } = useOptionSerial('IDNA Cardio Health');
  const { locale } = useCardioDataReport(serial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null === locale) {
    return null;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <QuestionnaireAnswers serial={ serial } color={ redColor } />
    </I18nextProvider>
  );
};

export default CardioQuestionnaireAnswers;
