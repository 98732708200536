import React, { useMemo } from 'react';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';
import { iDNA3ToOneCards } from './iDNA3ToOne';

export const profileToIcon = {
  vitaminA: { big: '/VITAMIN Α.jpg', small: '/VITAMIN Α_SMALL.jpg' },
  vitaminB6: { big: '/VITAMIN Β6.jpg', small: '/VITAMIN Β6_SMALL.jpg' },
  vitaminB9Acid: { big: '/VITAMIN Β9.jpg', small: '/VITAMIN Β9_SMALL.jpg' },
  vitaminB12: { big: '/VITAMIN Β12.jpg', small: '/VITAMIN Β12_SMALL.jpg' },
  vitaminC: { big: '/VITAMIN C.jpg', small: '/VITAMIN C_SMALL.jpg' },
  vitaminD: { big: '/VITAMIN D.jpg', small: '/VITAMIN D_SMALL.jpg' },
  vitaminE: { big: '/VITAMIN Ε.jpg', small: '/VITAMIN Ε_SMALL.jpg' },
};

export const profileToUrl = {
  vitaminA: 'vitaminA',
  vitaminB6: 'vitaminB6',
  vitaminB9Acid: 'vitaminB9Acid',
  vitaminB12: 'vitaminB12',
  vitaminC: 'vitaminC',
  vitaminD: 'vitaminD',
  vitaminE: 'vitaminE',
};

export const adviceImages = {
  vitaminA: { left: '/vitamini A-1.jpg', right: '/vitamini A-2.jpg' },
  vitaminB6: { left: '/vitamini B6-1.jpg', right: '/vitamini B6-2.jpg' },
  vitaminB9Acid: { left: '/vitamini B9-1.jpg', right: '/vitamini B9-2.jpg' },
  vitaminB12: { left: '/vitamini B12-1.jpg', right: '/vitamini B12-2.jpg' },
  vitaminC: { left: '/vitamini C-1.jpg', right: '/vitamini C-2.jpg' },
  vitaminD: { left: '/vitamini D-1.jpg', right: '/vitamini D-2.jpg' },
  vitaminE: { left: '/vitamini E-1.jpg', right: '/vitamini E-2.jpg' },
};

const Vitamins = () => {
  const vitaminsCard = iDNA3ToOneCards.filter((card) => {
    return 'vitamins' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'VCAT2', 'VCAT3', 'VCAT4', 'VCAT5', 'VCAT6', 'VCAT7', 'VCAT8' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('VCAT2' === filtered.ID) {
      iconPath = profileToIcon.vitaminA;
      url = profileToUrl.vitaminA;
    } else if ('VCAT3' === filtered.ID) {
      iconPath = profileToIcon.vitaminB6;
      url = profileToUrl.vitaminB6;
    } else if ('VCAT4' === filtered.ID) {
      iconPath = profileToIcon.vitaminB9Acid;
      url = profileToUrl.vitaminB9Acid;
    } else if ('VCAT5' === filtered.ID) {
      iconPath = profileToIcon.vitaminB12;
      url = profileToUrl.vitaminB12;
    } else if ('VCAT6' === filtered.ID) {
      iconPath = profileToIcon.vitaminC;
      url = profileToUrl.vitaminC;
    } else if ('VCAT7' === filtered.ID) {
      iconPath = profileToIcon.vitaminD;
      url = profileToUrl.vitaminD;
    } else if ('VCAT8' === filtered.ID) {
      iconPath = profileToIcon.vitaminE;
      url = profileToUrl.vitaminE;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ vitaminsCard } activePage='vitamins' />
        <PhotoWithText
          options={ vitaminsCard }
          activePage='vitamins'
          text={ data[0].value.vitamins_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ vitaminsCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default Vitamins;
