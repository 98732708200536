import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import ReactCodeInput from 'react-verification-code-input';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import Grid from '@material-ui/core/Grid';
import { useFormStylesUp } from './form-styles-signup';

const useStyles = makeStyles((theme) => {
  return {
    reactCodeInput: {
      width: '100%',
      '& div': {
        '& input': {
          // fontFamily: '"Nunito", "Manrope"',
          fontFamily: '"Roboto"',
          fontSize: '14px',
          [theme.breakpoints.down('xs')]: {
            fontSize: '12px',
          },
        },
      },
    },
  };
});

const ConfirmationCodeVerification = (
  {
    onVerify,
  },
) => {
  const { t } = useTranslation();
  const codeInputClasses = useStyles();
  const formClasses = useFormStylesUp();

  // TODO: Add this to useMediaQueries
  const sm = useMediaQuery((theme) => {
    return theme.breakpoints.down('xs');
  });

  const [ code, setCode ] = useState(null);

  const handleSubmit = () => {
    if (!code || !code.length || 6 !== code.length) {
      return;
    }
    if (!onVerify) {
      return;
    }
    onVerify(code);
  };

  return (
    <div className={ formClasses.form }>
      <Grid container alignItems='center'>
        <ReactCodeInput
          className={ codeInputClasses.reactCodeInput }
          fieldWidth={ `${ 100 / 6 }%` }
          fieldHeight={ sm ? 56 : 56 }
          fields={ 6 }
          onChange={ (v) => {
            setCode(v);
          } }
        />
      </Grid>
      <Button
        disabled={ !code || !code.length || 6 !== code.length }
        fullWidth
        type='submit'
        variant='outlined'
        color='secondary'
        className={ formClasses.submit }
        autoFocus
        onClick={ () => {
          handleSubmit();
        } }
      >
        {t('confirmCode')}
      </Button>
    </div>
  );
};

export default ConfirmationCodeVerification;
