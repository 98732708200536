import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Section from '../app/Section';
import SectionAlternate from '../app/SectionAlternate';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      zIndex: 2,
      backgroundColor: theme.palette.alternate.main,
    },
    section: {
      padding: theme.spacing(1, 1),
      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(2, 1),
      },
      backgroundColor: theme.palette.alternate.main,
    },
    sectionAlternate: {
      backgroundColor: theme.palette.secondary.main,
      '& .section-alternate__content': {
        padding: 0,
        height: '4px',
      },
    },
  };
});

const Footer = () => {
  const classes = useStyles();
  return (
    <div className={ classes.root }>
      <Divider />
      <Section className={ classes.section }>
        <Grid container justifyContent='space-between' alignItems='center'>
          <Grid item>
            iDNA Genomics - Copyright &copy; 2021
          </Grid>
        </Grid>
      </Section>
      <SectionAlternate className={ classes.sectionAlternate } />
    </div>
  );
};

export default Footer;
