import { Typography, Grid } from '@material-ui/core';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import React, { useMemo } from 'react';
import { useOptionSerial } from 'NavigationProvider';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { useCardioDataReport } from 'models/kits';
import useMediaQueries from 'hooks/media-query-hooks';
import { PrevNextCardio, Title } from './Common';
import { CardioHealthCards } from './iDNACardioHealth';
import CardioQuestionnaireAnswers from './CardioQuestionnaireAnswers';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const redColor = '#B72619';

const items = [
  {
    img: '/red_plate.png',
    lowerText: 'Υγιεινή Διατροφή',
  },
  {
    img: '/red_pressure.png',
    lowerText: 'Διατήρηση Φυσιολογικής Αρτηριακής Πίεσης',
  },
  {
    img: '/red_weight.png',
    lowerText: 'Διατήρηση φυσιολογικού βάρους',
  },
  {
    img: '/red_finger.png',
    lowerText: 'Μείωση επιπέδων σακχάρου',
  },
  {
    img: '/red_no_smoke.png',
    lowerText: 'Διακοπή Καπνίσματος',
  },
  {
    img: '/red_running.png',
    lowerText: 'Τακτική Άσκηση',
  },
  {
    img: '/red_cardio.png',
    lowerText: 'Έλεγχος επιπέδων χοληστερόλης',
  },
  {
    img: '/red_sleep.png',
    lowerText: 'Επαρκής ύπνος',
  },
];

const ImportantInformationContent = () => {
  const riskMitigation = CardioHealthCards.filter((card) => {
    return 'cardioHealth' === card.id;
  })[0];

  const { i18n, t } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { isSm } = useMediaQueries();

  const { data } = useCardioDataReport(serial, i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  let color = redColor;

  let char = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === char || 'Τυπική' === char) {
    char = 'Τυπική';
    color = '#68A042';
  } else if ('HIGH' === char || 'Βέλτιστη' === char) {
    char = 'Βέλτιστη';
    color = '#005EA4';
  } else if ('LOW' === char || 'Χαμηλή' === char) {
    char = 'Χαμηλή';
    color = '#C80000';
  }

  const img = [ JSON.parse(data[0].value.demoContent.__values._IMG_CHARACTERISATION).base64, JSON.parse(data[0].value.demoContent.__values._IMG_CHARACTERISATION_MOBILE).base64 ];

  return (
    <div>
      <Title />
      <PrevNextCardio options={ riskMitigation } activePage='Eρωτηματολογίο' />
      <div style={ { display: 'flex', flexDirection: 'column', gap: '20px' } }>
        <div
          style={ { background: 'white',
            borderRadius: '15px',
            padding: '20px' } }
        >
          <Typography variant='h6'>
            {t('accordingToQuestionnaire')}
            {
              ' '
            }
            <span style={ { color, fontWeight: 'bold' } }>{t(char)}</span>
          </Typography>
          <div style={ { display: 'flex', justifyContent: 'center' } }>
            <img
              src={ isSm ? img[1] : img[0] }
              alt=''
              style={ {
                height: 'auto',
                maxWidth: '100%',
                marginTop: '20px',
              } }
            />
          </div>
        </div>
        <div style={ { background: 'white', borderRadius: '15px', padding: '20px' } }>
          <CardioQuestionnaireAnswers />
        </div>
        <div
          style={ { background: 'white',
            borderRadius: '15px',
            padding: '20px' } }
        >
          <Typography variant='h6' style={ { fontWeight: 'bold', marginBottom: '20px' } }>
            {t('8basicFactors')}
          </Typography>
          <Grid container spacing={ 10 }>
            {
              items.map((item) => {
                return (
                  <Grid item key={ item.lowerText }>
                    <img
                      src={ `${ 'en-US' === i18n.language ? `/en_${ item.img.substring(1) }` : item.img }` }
                      alt=''
                      style={ {
                        width: 'auto',
                        height: '175px',
                      } }
                    />
                  </Grid>
                );
              })
            }
          </Grid>
        </div>
      </div>
    </div>
  );
};

const CardioResultsQuestionnaire = () => {
  const { serial: newSerial } = useOptionSerial('IDNA Cardio Health');
  const { locale } = useCardioDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null === locale) {
    return null;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <ImportantInformationContent />
    </I18nextProvider>
  );
};

export default CardioResultsQuestionnaire;
