import { makeStyles } from '@material-ui/core/styles';

export const useFormStylesUp = makeStyles((theme) => {
  return {
    container: {
      marginTop: theme.spacing(2),
      maxWidth: '600px',
      [theme.breakpoints.down('xs')]: {
        maxWidth: '100%',
        marginTop: theme.spacing(4),
      },
    },
    description: {
      margin: theme.spacing(3, 0),
    },
    paper: {
      display: 'flex',
      flexDirection: 'column',
      boxShadow: theme.palette.formShadow,
      padding: theme.spacing(4),
      margin: theme.spacing(2, 0),
      [theme.breakpoints.down('xs')]: {
        boxShadow: 'unset',
      },
      backgroundColor: theme.palette.alternate.light,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(3),
    },
    submit: {
      minHeight: '40px',
      margin: theme.spacing(3, 0, 2),
    },
    links: {
      marginTop: theme.spacing(3),
    },
  };
});
