import React from 'react';
import RefreshIcon from '@material-ui/icons/Refresh';
import Typography from '@material-ui/core/Typography';
import { AppCtx } from 'AppCtx';
import { useTranslation } from 'react-i18next';
import { Button, useTheme } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useAuth } from 'AuthCtx';

export const usePerformUpdate = () => {
  const queryClient = useQueryClient();
  const { setShowUpdateAvailable } = React.useContext(AppCtx);
  const { refreshUserAttributes } = useAuth();

  return () => {
    setShowUpdateAvailable(false);
    queryClient.removeQueries();
    refreshUserAttributes();
    localStorage.clear();
    sessionStorage.clear();
    window.location.reload();
  };
};

const UpdateAvailable = () => {
  const { t } = useTranslation();
  const { isDoctor } = useAuth();
  const theme = useTheme();
  const performUpdate = usePerformUpdate();

  return (
    <div style={ {
      position: 'fixed',
      bottom: '30px',
      right: '30px',
      background: 'white',
      zIndex: 300,
      padding: '20px',
      border: `1px solid ${ theme.palette.secondary.main }`,
      borderRadius: '15px',
      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'rgba(100, 100, 111, 0.2) 0px 7px 29px 0px',
    } }
    >
      <Typography
        variant='h6'
        color='secondary'
        style={ {
          fontWeight: 'bold',
          display: 'flex',
          alignItems: 'center',
          gap: '5px',
          // color: '#93C221',
        } }
      >
        <RefreshIcon />
        {t('A new version is available!')}
      </Typography>

      <Typography style={ { marginTop: '5px', marginLeft: '28px', wordWrap: 'break-word', maxWidth: '370px' } }>
        {t('Click on “Update” to get the latest version')}
      </Typography>

      <Button
        variant='contained'
        onClick={ performUpdate }
        color='secondary'
        style={ {
          marginLeft: 'auto',
          marginTop: '20px',
          color: isDoctor ? 'white' : undefined,
        } }
      >
        {t('Update')}
      </Button>
    </div>
  );
};

export default UpdateAvailable;
