import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import React from 'react';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const InformativeDialog = (
  {
    isOpen,
    closeInformativeDialog,
    dialogProps: {
      title,
      content: Content,
    },
  },
) => {
  return (
    <Dialog
      hideBackdrop
      disableEnforceFocus
      open={ isOpen }
      onClose={ () => {
        return null;
      } }
    >
      <DialogTitle>
        {title && title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {Content && <Content />}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={ closeInformativeDialog } color='primary'>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InformativeDialog;
