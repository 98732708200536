/* eslint-disable prefer-const */
import React, { useState } from 'react';
import LimitedQuestionnaire from 'components/layout/LimitedQuestionnaire';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import { useTranslation } from 'react-i18next';
import useAPI from 'hooks/api-hooks';
import { useGetCountryFromIP } from 'hooks/util-hooks';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import SimpleInputField from '../atoms/SimpleInputField';
import SimpleRadio from '../atoms/SimpleRadio';
import SimpleDateField from '../atoms/SimpleDateField';
import SimplePhoneField from '../atoms/SimplePhoneField';
import { useFormRules, useInputValidation } from '../../../hooks/form-rules';
import FormSection from '../atoms/FormSection';
import FormSectionSubmit from '../atoms/FormSectionSubmit';
import FormNames from '../atoms/form-names';
import CountrySelectField, { countryNameToCode } from '../atoms/CountrySelectField';
import { useKit } from '../../../models/kits';

const UserDetailsForm = (
  {
    onSubmit,
    onError,
    goToPrevPage,
    kitId,
  },
) => {
  const { t } = useTranslation();

  // eslint-disable-next-line camelcase
  const { data, isLoading, isKitWellness, isKitPGX } = useKit(kitId);

  const { client } = useAPI();

  const { country: ipCountry, loaded } = useGetCountryFromIP();

  const [ wellnessQuestionnaire, setWellnessQuestionnaire ] = useState({});

  const [ wellnessQuestionnaireErrorID, setWellnessQuestionnaireErrorID ] = useState(-1);

  const customerId = data?.customer_id;
  const initialFirstName = customerId?.name ?? '';
  const initialLastName = customerId?.family_name ?? '';
  const initialDateOfBirth = customerId?.birthdate ?? '';
  const initialGender = customerId?.gender ?? '';
  const initialMobileNumber = customerId?.phone_number ?? '';
  const initialHouseNumber = customerId?.house_number ?? '';
  const initialEmail = customerId?.email ?? '';

  const hasReferral = null != data.metadata?.referenceNumber && '' !== data.metadata?.referenceNumber;

  let initialStreetAddress = '';
  let initialPostalCode = '';
  let initialCity = '';

  const address = customerId?.address ?? '';

  if ('' !== address && null != address) {
    try {
      const jsonAddress = JSON.parse(address || JSON.stringify({}));

      initialStreetAddress = jsonAddress.streetAddress;
      initialPostalCode = jsonAddress.postalCode;
      initialCity = jsonAddress.city;
    } catch (e) {
      console.log('address error', address);
    }
  }

  let initialCountry;
  if (null == customerId || null == customerId.country || '' === customerId.country) {
    initialCountry = ipCountry;
  } else {
    initialCountry = customerId?.country ?? 'GR';
  }

  const [ isSubmitting, setSubmitting ] = useState(false);

  const {
    requiredFieldRule,
    phoneNumberRule,
    dateRule,
    genderRule,
    requiredPhoneRule,
    emailRule,
  } = useFormRules();

  const [
    errorEmail,
    helperTextEmail,
    validateEmail,
  ] = useInputValidation(
    [
      requiredFieldRule,
      emailRule,
    ],
  );

  const [
    errorFirstName,
    helperTextFirstName,
    validateFirstName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorLastName,
    helperTextLastName,
    validateLastName,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorGender,
    helperTextGender,
    validateGender,
  ] = useInputValidation(
    [
      requiredFieldRule,
      genderRule,
    ],
  );

  const [
    errorDateOfBirth,
    helperTextDateOfBirth,
    validateDateOfBirth,
  ] = useInputValidation(
    [
      dateRule,
    ],
  );

  const [
    errorCountry,
    helperTextCountry,
    validateCountry,
  ] = useInputValidation(
    [
      requiredFieldRule,
    ],
  );

  const [
    errorMobileNumber,
    helperTextMobileNumber,
    validateMobileNumber,
  ] = useInputValidation(
    [
      requiredPhoneRule,
      phoneNumberRule,
    ],
  );

  const [
    errorHouseNumber,
    helperTextHouseNumber,
    validateHouseNumber,
  ] = useInputValidation(
    [
      phoneNumberRule,
    ],
  );

  const handleSubmit = (e) => {
    setSubmitting(true);
    e.preventDefault();
    const formData = new FormData(e.target);
    let {
      [FormNames.firstName]: firstName,
      [FormNames.lastName]: lastName,
      [FormNames.gender]: gender,
      [FormNames.dateOfBirth]: dateOfBirth,
      [FormNames.streetAddress]: streetAddress,
      [FormNames.postalCode]: postalCode,
      [FormNames.city]: city,
      [FormNames.country]: country,
      [FormNames.mobileNumber]: mobileNumber,
      [FormNames.countryMobileNumber]: countryMobileNumber,
      [FormNames.houseNumber]: houseNumber,
      [FormNames.countryHouseNumber]: countryHouseNumber,
      [FormNames.email]: email,
    } = Object.fromEntries(formData);

    country = countryNameToCode(country);

    // greedy validation
    // don't validate all of them immediately

    if (![
      () => { return validateFirstName(firstName); },
      () => { return validateLastName(lastName); },
      () => { return validateDateOfBirth(dateOfBirth); },
      () => { return validateGender(gender); },
      () => { return validateCountry(country); },
      () => { return validateEmail(email); },
      () => {
        return validateMobileNumber(
          {
            phoneNumber: mobileNumber,
            country: countryMobileNumber,
          },
        );
      },
      () => {
        return validateHouseNumber(
          {
            phoneNumber: houseNumber,
            country: countryHouseNumber,
          },
        );
      },
    ].every((f) => {
      return true === f();
    })) {
      setSubmitting(false);
      return onError();
    }

    if (null === customerId) {
      return client.post('/customers/doctorcreate', {
        registration_kit_id: kitId,
        name: firstName?.toUpperCase(),
        family_name: lastName?.toUpperCase(),
        email,
        gender,
        birthdate: dateOfBirth,
        country,
        address: JSON.stringify({ streetAddress: streetAddress?.toUpperCase(),
          postalCode,
          city: city?.toUpperCase() }),
        phone_number: mobileNumber,
        house_number: houseNumber,
      })
        .then((res) => {
          if (isKitWellness) {
            return client.put('/customers/details/doctorupdate', { extra_info: wellnessQuestionnaire, customerId: res.customer_id.id });
          } if (isKitPGX && !hasReferral) {
            return client.put('/customers/details/doctorupdate', { extra_info_pgx: wellnessQuestionnaire, customerId: res.customer_id.id });
          }

          return null;
        })
        .then(() => {
          onSubmit({
            firstName,
            lastName,
            gender,
            streetAddress,
            postalCode,
            city,
            dateOfBirth,
            mobileNumber,
            houseNumber,
          });
        }).then(() => {
          setSubmitting(false);
        });
    }

    return client.put('/customers/details/doctorupdate', { registration_kit_id: kitId,
      customerId: customerId?.id,
      name: firstName?.toUpperCase(),
      family_name: lastName?.toUpperCase(),
      email,
      gender,
      birthdate: dateOfBirth,
      country,
      address: JSON.stringify({ streetAddress: streetAddress?.toUpperCase(),
        postalCode,
        city: city?.toUpperCase() }),
      phone_number: mobileNumber,
      house_number: houseNumber })
      .then(() => {
        if (isKitWellness) {
          return client.put('/customers/details/doctorupdate', { customerId: customerId?.id,
            extra_info: wellnessQuestionnaire });
        } if (isKitPGX && !hasReferral) {
          return client.put('/customers/details/doctorupdate', { customerId: customerId?.id,
            extra_info_pgx: wellnessQuestionnaire });
        }

        return null;
      })
      .then(() => {
        onSubmit({
          firstName,
          lastName,
          gender,
          streetAddress,
          postalCode,
          city,
          dateOfBirth,
          mobileNumber,
          houseNumber,
        });
      }).then(() => {
        setSubmitting(false);
      });
  };

  if (isLoading || !loaded) {
    return (
      <LoaderSkeleton />
    );
  }

  return (
    <form
      noValidate
      onSubmit={ handleSubmit }
      style={ {
        padding: '24px',
      } }
    >
      <FormSection label={ isKitPGX ? t('reg_kit.personal_data.doctor.pgx.personal_data_title') : t('reg_kit.personal_data.doctor.non_pgx.personal_data_title') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 2 }>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('common.first_name') }
                  lettersOnly
                  upperCase
                  validate={ validateFirstName }
                  autoComplete='given-name'
                  defaultValue={ initialFirstName }
                  name={ FormNames.firstName }
                  required
                  error={ errorFirstName }
                  helperText={ helperTextFirstName }
                />
              </Grid>
              <Grid item xs={ 12 } md={ 6 }>
                <SimpleInputField
                  label={ t('lastName') }
                  lettersOnly
                  upperCase
                  defaultValue={ initialLastName }
                  validate={ validateLastName }
                  autoComplete='common.last_name'
                  name={ FormNames.lastName }
                  required
                  error={ errorLastName }
                  helperText={ helperTextLastName }
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimpleDateField
              country={ initialCountry }
              label={ t('common.birthdate') }
              defaultValue={ initialDateOfBirth }
              validate={ validateDateOfBirth }
              name={ FormNames.dateOfBirth }
              required
              error={ errorDateOfBirth }
              helperText={ helperTextDateOfBirth }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimpleRadio
              label={ t('common.gender') }
              secondaryLabel={ t('common.gender_explanation') }
              defaultValue={ initialGender }
              name={ FormNames.gender }
              required
              options={ [
                {
                  value: 'male',
                  label: t('common.male'),
                },
                {
                  value: 'female',
                  label: t('common.female'),
                },
              ] }
              error={ errorGender }
              helperText={ helperTextGender }
            />
          </Grid>

          {
            isKitWellness && (
              <Grid item xs={ 12 }>
                <LimitedQuestionnaire
                  kitId={ kitId }
                  wellnessQuestionnaire={ wellnessQuestionnaire }
                  setWellnessQuestionnaire={ setWellnessQuestionnaire }
                  wellnessQuestionnaireErrorID={ wellnessQuestionnaireErrorID }
                  setWellnessQuestionnaireErrorID={ setWellnessQuestionnaireErrorID }
                />
              </Grid>
            )
          }

        </Grid>

      </FormSection>

      <FormSection label={ t('common.postal_address') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <Grid container spacing={ 1 }>
              <Grid item xs={ 8 }>
                <SimpleInputField
                  label={ t('common.street_and_number') }
                  autoComplete='street-address'
                  defaultValue={ initialStreetAddress }
                  upperCase
                  name={ FormNames.streetAddress }
                />
              </Grid>

              <Grid item xs={ 4 }>
                <SimpleInputField
                  label={ t('common.postal_code') }
                  defaultValue={ initialPostalCode }
                  autoComplete='postal-code'
                  name={ FormNames.postalCode }
                />
              </Grid>
              <Grid item container spacing={ 2 }>
                <Grid item xs={ 12 } sm={ 8 }>
                  <SimpleInputField
                    label={ t('common.city') }
                    defaultValue={ initialCity }
                    upperCase
                    name={ FormNames.city }
                  />

                </Grid>

                <Grid item xs={ 12 } sm={ 4 }>
                  <CountrySelectField
                    label={ t('common.country') }
                    defaultValue={ initialCountry }
                    name={ FormNames.country }
                    required
                    error={ errorCountry }
                    helperText={ helperTextCountry }
                    validate={ validateCountry }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>

        </Grid>
      </FormSection>
      <FormSection label={ t('common.communication_info') }>
        <Grid container spacing={ 2 }>
          <Grid item xs={ 12 }>
            <SimpleInputField
              label={ t('common.email') }
              defaultValue={ initialEmail }
              name={ FormNames.email }
              required
              error={ errorEmail }
              helperText={ helperTextEmail }
              validate={ validateEmail }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.mobileNumber }
              label={ t('common.mobile_number') }
              defaultValue={ initialMobileNumber }
              required
              autoComplete='tel-national'
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryMobileNumber }
              validate={ validateMobileNumber }
              error={ errorMobileNumber }
              helperText={ helperTextMobileNumber }
            />
          </Grid>
          <Grid item xs={ 12 } md={ 6 }>
            <SimplePhoneField
              name={ FormNames.houseNumber }
              defaultValue={ initialHouseNumber }
              autoComplete='tel-national'
              label={ t('common.home_number') }
              defaultCountryValue={ initialCountry ?? 'GR' }
              countrySelectName={ FormNames.countryHouseNumber }
              error={ errorHouseNumber }
              helperText={ helperTextHouseNumber }
            />
          </Grid>
        </Grid>
      </FormSection>

      <Grid
        container
        style={ {
          padding: '0px 24px',
        } }
      >
        <FormSectionSubmit
          color='#526D9C'
          label={ t('common.save') }
          loading={ isSubmitting }
          goToPrevPage={ goToPrevPage }
        />
      </Grid>
    </form>
  );
};

UserDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onError: PropTypes.func,
};

UserDetailsForm.defaultProps = {
  onSubmit: () => { return null; },
  onError: () => { return null; },
};

export default UserDetailsForm;
