import React, { useMemo } from 'react';
import { useCardioDataReport } from 'models/kits';
import { useTranslation, I18nextProvider } from 'react-i18next';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { useOptionSerial } from 'NavigationProvider';
import i18nEng from '../../../i18n_eng';
import i18nEl from '../../../i18n_el';
import CardioResults, { ImageText } from '../Common';

const KardiakhAneparkeia = () => {
  const { i18n } = useTranslation();
  const { serial } = useOptionSerial('IDNA Cardio Health');

  const { data, locale } = useCardioDataReport(serial, i18n.language);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null == data) {
    return <LoaderSkeleton />;
  }

  if (0 === data.length) {
    return (
      <h1>
        No data available
      </h1>
    );
  }

  const nosos = data[0].value['heart failure'];

  const prsImage = [ JSON.parse(nosos._IMG_SCORE).base64, JSON.parse(nosos._IMG_SCORE_MOBILE).base64 ];
  const adjPrsImage = [ JSON.parse(nosos._IMG_ADJ_PRS_SCORE).base64, JSON.parse(nosos._IMG_ADJ_PRS_SCORE_MOBILE).base64 ];
  const prsScore = nosos.PRS_SCORE;
  const adjPrsScore = nosos.ADJPRS_SCORE;
  const prsCharacterisation = nosos.PRS_CHARACTERISATION;
  const traitProfile = nosos.TRAIT_PROFILE;
  const adjPrsCharacterisationLevels = nosos.ADJPRS_CHARACTERISATION_LEVELS;
  const adjPrsCharacterisationFem = nosos.ADJPRS_CHARACTERISATION_FEMALE;

  const imageContent = 'Η καρδιακή ανεπάρκεια (δυσλειτουργία του μυοκαρδίου) εμφανίζεται όταν ο καρδιακός μυς αδυνατεί να κυκλοφορήσει την απαιτούμενη ποσότητα αίματος που χρειάζεται το σώμα. Όταν συμβαίνει αυτό, υγρό μπορεί να συσσωρευτεί στους πνεύμονες, προκαλώντας δύσπνοια. Ένας τρόπος πρόληψης της καρδιακής ανεπάρκειας είναι ο έλεγχος καταστάσεων που μπορεί να την προκαλέσουν, όπως η στεφανιαία νόσος, η υψηλή αρτηριακή πίεση, ο διαβήτης και η παχυσαρκία.';

  let characterisation = data[0].value.demoContent.__values.CHARACTERISATION;
  if ('MEDIUM' === characterisation) {
    characterisation = 'Τυπική';
  } else if ('HIGH' === characterisation) {
    characterisation = 'Βέλτιστη';
  } else if ('LOW' === characterisation) {
    characterisation = 'Mειωμένη';
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div>
        <CardioResults />
        <ImageText
          traitProfile={ traitProfile }
          adjPrsCharacterisationLevels={ adjPrsCharacterisationLevels }
          characterisation={ characterisation }
          prsImage={ prsImage }
          adjPrsCharacterisationFem={ adjPrsCharacterisationFem }
          adjPrsImage={ adjPrsImage }
          prsScore={ prsScore }
          adjPrsScore={ adjPrsScore }
          prsCharacterisation={ prsCharacterisation }
          activeProfile='Καρδιακή Ανεπάρκεια'
          imageTitle='Για την Καρδιακή Ανεπάρκεια'
          imageContent={ imageContent }
          source='https://www.mayoclinic.org/diseases-conditions/heart-failure/symptoms-causes/syc-20373142'
        />
      </div>
    </I18nextProvider>
  );
};

export default KardiakhAneparkeia;
