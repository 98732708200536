import FormControlLabel from '@material-ui/core/FormControlLabel';
import React, { useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import PropTypes from 'prop-types';
import { FormHelperText, makeStyles } from '@material-ui/core';
import Checkbox from '@material-ui/core/Checkbox';
import { useTranslation } from 'react-i18next';
import { Transition } from '../mfa-components/DialogEnableMFA';

const useStyles = makeStyles(() => {
  return {
    dialog: {
      '& .MuiDialog-paper': {
        width: '80%',
        minWidth: '350px',
        minHeight: '300px',
      },
    },
  };
});

const CheckboxDialog = (
  {
    name,
    label,
    value,
    onChange,
    error,
    helperText,
    maxWidth = undefined,
    fullWidth = undefined,
    dialogProps: {
      title: dialogTitle,
      content: dialogContent,
    },
    color = undefined,
  },
) => {
  const [ open, setOpen ] = useState(false);

  const classes = useStyles();
  const { t } = useTranslation();
  const handleSwitch = () => {
    if (!value) {
      setOpen(true);
      return;
    }
    onChange(false);
  };

  const onAgree = () => {
    setOpen(false);
    return onChange(true);
  };

  const onDecline = () => {
    setOpen(false);
    return onChange(false);
  };

  return (
    <>
      <FormControlLabel
        name={ name }
        control={ (
          <Checkbox
            style={ { paddingTop: 0, paddingBottom: 0, color } }
            checked={ value }
            onChange={ handleSwitch }
            value={ value }
          />
        ) }
        label={ (
          <FormHelperText color='primary'>
            <a href='terms-and-conditions' style={ { pointerEvents: 'none' } }>
              {label}
            </a>
          </FormHelperText>
        ) }
      />
      {error && (
        <FormHelperText margin='dense' variant='outlined' error>
          {helperText}
        </FormHelperText>
      )}

      <Dialog
        className={ classes.dialog }
        TransitionComponent={ Transition }
        open={ open }
        maxWidth={ maxWidth }
        fullWidth={ fullWidth }
      >
        <DialogTitle>
          {dialogTitle}
        </DialogTitle>
        <DialogContent dividers>
          {dialogContent}
        </DialogContent>
        <DialogActions style={ { justifyContent: 'space-between', padding: '24px' } }>
          <Button
            style={ { minWidth: '140px' } }
            variant='outlined'
            autoFocus
            color='error'
            onClick={ onDecline }
          >
            {t('decline')}
          </Button>
          <Button
            style={ { minWidth: '140px', background: color, color: '#526D9C' === color ? 'white' : '' } }
            onClick={ onAgree }
            color='secondary'
            variant='contained'
          >
            {t('accept')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

CheckboxDialog.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

CheckboxDialog.defaultProps = {
  error: false,
  helperText: '',
};

export default CheckboxDialog;
