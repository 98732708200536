import CodeError from './CodeError';

class InvalidCredentialsError extends CodeError {
  constructor() {
    const code = 'invalidCredentials';
    const message = 'Incorrect username or password';
    super(code, message);
    if ('function' === typeof Error.captureStackTrace) {
      Error.captureStackTrace(this, this.constructor);
    } else {
      this.stack = (new Error(message)).stack;
    }
  }
}

export default InvalidCredentialsError;
