import React from 'react';
import Grid from '@material-ui/core/Grid';
import { AppHeader } from 'components/layout';
import Footer from '../../components/layout/Footer';

const FormLayout = (props) => {
  return (
    <Grid
      container
      direction='column'
      style={ { minHeight: '100vh' } }
    >
      <Grid
        container
        item
        xs={ 12 }
        alignItems='flex-end'
        justifyContent='space-between'
        style={ { padding: '0 16px',
          maxWidth: '88vw',
          margin: '0 auto',
          position: 'relative',
          minHeight: '70px' } }
      >
        <AppHeader />
      </Grid>
      <Grid
        container
        item
        xs={ 12 }
        style={ { flexGrow: 1 } }
      >
        {
          props.children
        }
      </Grid>
      <Footer />
    </Grid>
  );
};

export default FormLayout;
