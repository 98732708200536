import React, { useMemo } from 'react';
import { use3ToOneDataReport, useDynamicReportsData } from 'models/kits';
import i18nEl from 'i18n_el';
import i18nEng from 'i18n_eng';
import { LoaderSkeleton } from 'components/layout/KitRegistrationDashboard';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { Tabs, PrevNext, PhotoWithText, ProfileCards } from './Common';
import { iDNA3ToOneCards } from './iDNA3ToOne';

export const profileToIcon = {
  sensitivityToSalt: { big: '/ALATI.jpg', small: '/ALATI_SMALL.jpg' },
  saturatedFat: { big: '/KORESMENA LIPARA.jpg', small: '/KORESMENA LIPARA_SMALL.jpg' },
  fattyAcids: { big: '/OMEGA6 OMEGA 3.jpg', small: '/OMEGA6 OMEGA 3_SMALL.jpg' },
  transFats: { big: '/TRANS LIPARA.jpg', small: '/TRANS LIPARA_SMALL.jpg' },
};

export const profileToUrl = {
  sensitivityToSalt: 'sensitivityToSalt',
  saturatedFat: 'saturatedFat',
  fattyAcids: 'fattyAcids',
  transFats: 'transFats',
};

export const adviceImages = {
  sensitivityToSalt: { left: '/alati-1.jpg', right: '/alati-2.jpg' },
  saturatedFat: { left: null, right: '/koresmena lipara.jpg' },
  fattyAcids: { left: '/lipara-1.jpg', right: '/lipara-2.jpg' },
  transFats: { left: null, right: '/trans lipara.jpg' },
};

const CardiometabolicHealth = () => {
  const dietCard = iDNA3ToOneCards.filter((card) => {
    return 'diet' === card.id;
  })[0];

  const { i18n } = useTranslation();

  const { serial: newSerial } = useOptionSerial('3-in-1');
  const { locale } = use3ToOneDataReport(newSerial);
  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  const data = useDynamicReportsData(i18n.language);
  if (null == data || 0 === data.length) {
    return <LoaderSkeleton />;
  }

  const sensProfiles = data[0].value.categories.filter((currData) => {
    return [ 'NCAT15', 'NCAT5', 'NCAT10', 'NCAT4' ].includes(currData.ID);
  }).map((filtered) => {
    let iconPath = '';
    let url = '';

    if ('NCAT15' === filtered.ID) {
      iconPath = profileToIcon.sensitivityToSalt;
      url = profileToUrl.sensitivityToSalt;
    } else if ('NCAT5' === filtered.ID) {
      iconPath = profileToIcon.saturatedFat;
      url = profileToUrl.saturatedFat;
    } else if ('NCAT10' === filtered.ID) {
      iconPath = profileToIcon.fattyAcids;
      url = profileToUrl.fattyAcids;
    } else if ('NCAT4' === filtered.ID) {
      iconPath = profileToIcon.transFats;
      url = profileToUrl.transFats;
    }

    return { ...filtered, icon: iconPath, url };
  });

  return (
    <I18nextProvider i18n={ i18nProv }>
      <div style={ { width: '100%' } }>
        <Tabs />
        <PrevNext options={ dietCard } activePage='cardiometabolicHealth' />
        <PhotoWithText
          options={ dietCard }
          activePage='cardiometabolicHealth'
          text={ data[0].value.cardio_summary_table_advices }
        />
        <ProfileCards sensitivityProfiles={ sensProfiles } color={ dietCard.color } />
      </div>
    </I18nextProvider>
  );
};

export default CardiometabolicHealth;
