import React, { useMemo } from 'react';
import { I18nextProvider } from 'react-i18next';
import { useOptionSerial } from 'NavigationProvider';
import { useCardioDataReport } from 'models/kits';
import PDFViewer from '../../components/PDFViewer';
import i18nEl from '../../i18n_el';
import i18nEng from '../../i18n_eng';

const CardioPdfViewer = () => {
  const { serial: newSerial } = useOptionSerial('IDNA Cardio Health');
  const { locale } = useCardioDataReport(newSerial);

  const i18nProv = useMemo(() => {
    if ('el-GR' === locale) {
      return i18nEl;
    }

    return i18nEng;
  }, [ locale ]);

  if (null === locale) {
    return null;
  }

  return (
    <I18nextProvider i18n={ i18nProv }>
      <PDFViewer serial={ newSerial } />
    </I18nextProvider>
  );
};

export default CardioPdfViewer;
