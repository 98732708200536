import React, { useState } from 'react';
import { SwipeableDrawer, IconButton, List, ListItem, Typography, Avatar } from '@material-ui/core';
import ReorderIcon from '@material-ui/icons/Reorder';
import useMediaQueries from 'hooks/media-query-hooks';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import LangSwitcher from 'components/app/LangSwitcher';
import { Help } from '../PatientDashboard';
import usePatientNavigationPages from '../dashboard/navigationPages';
import useDoctorNavigationPages from '../doctor-dashboard/navigationPages';
import { useNavigation } from '../../../NavigationProvider';
import { isActive } from './Menu';

const MobileMenu = ({ isDoctor = false }) => {
  const [ open, setOpen ] = useState(false);

  function toggleDrawer() {
    setOpen(!open);
  }

  const { navigateToPage, activePageId: pageId } = useNavigation();

  const { isSm } = useMediaQueries();

  const { t } = useTranslation();

  let pages;
  if (isDoctor) {
    pages = useDoctorNavigationPages();
  } else {
    pages = usePatientNavigationPages();
  }

  const history = useHistory();

  pages = pages.filter((page) => {
    return ((true !== page.isDisabled && true !== page.isMenuHidden) || page.isOnlyMobile);
  });

  pages = pages.map((page) => {
    if ('results' === page.id) {
      page.items = page.items.filter((item) => {
        return !Object.prototype.hasOwnProperty.call(item, 'enabled') || item.enabled;
      });
    }

    return page;
  });

  return (
    <div style={ { display: isSm ? '' : 'none' } }>
      <IconButton onClick={ () => { return (toggleDrawer()); } }>
        <ReorderIcon style={ { transform: 'scale(1.5)', marginTop: '10px', marginRight: '5px' } } />
      </IconButton>
      <SwipeableDrawer
        onOpen={ () => { return (setOpen(true)); } }
        open={ open }
        onClose={ () => { return (setOpen(false)); } }
      >
        <List style={ { marginBottom: 'auto' } }>
          {
            pages.map((page) => {
              return (
                <ListItem
                  key={ page.id }
                  onClick={ () => {
                    setOpen(false);
                    navigateToPage({ pageId: page.id });
                  } }
                  style={ {
                    background: isActive(page, pageId) ? null != page.color ? page.color
                      : isDoctor ? '#648BAD'
                        : '#93C221' : '',
                    borderRadius: '15px',
                    width: '97%',
                    margin: '0 auto',
                    cursor: 'pointer',
                  } }
                >
                  <Avatar style={ {
                    background: isActive(page, pageId)
                      ? isDoctor ? '#648BAD'
                        : '#93C221'
                      : '#F4F4F4',
                  } }
                  >
                    <page.Icon
                      isActive={ isActive(page, pageId) }
                      style={ {
                        color: isDoctor && !isActive(page, pageId) ? '#648BAD' : '',
                      } }
                    />
                  </Avatar>
                  <Typography
                    variant='h6'
                    style={ { marginLeft: '10px',
                      color: isActive(page, pageId) ? 'white' : '' } }
                  >
                    {t(page.title)}
                  </Typography>
                </ListItem>
              );
            })
          }
          <ListItem
            onClick={ () => {
              localStorage.setItem('showedUseApp', 'false');
              history.push('/logout');
            } }
            style={ {
              borderRadius: '15px',
              width: '97%',
              margin: '0 auto',
              cursor: 'pointer',
            } }
          >
            <Avatar style={ { background: '#F4F4F4' } }>
              <img
                src='/signout.svg'
                alt='logout'
                style={ {
                  filter: isDoctor
                    ? 'brightness(0) saturate(100%) invert(52%) sepia(12%) saturate(1193%) hue-rotate(166deg) brightness(99%) contrast(88%)'
                    : '',
                } }
              />
            </Avatar>
            <Typography variant='h6' style={ { marginLeft: '10px' } }>
              {t('logout')}
            </Typography>
          </ListItem>
        </List>

        <div style={ { marginLeft: '20px', marginBottom: '30px' } }>
          <LangSwitcher />
        </div>

        {
          !isDoctor && (
            <Help style={ { width: '90%', margin: '0 auto 15px auto' } } />
          )
        }
      </SwipeableDrawer>
    </div>
  );
};

export default MobileMenu;
