import Grid from '@material-ui/core/Grid';
import { AiOutlineShop, BiBell, BsPerson, FiCalendar } from 'react-icons/all';
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import Badge from '@material-ui/core/Badge';
import AppTooltip from '../../app/AppTooltip';
import useMediaQueries from '../../../hooks/media-query-hooks';

const useStyles = makeStyles((theme) => {
  return {
    icon: {
      color: theme.palette.text.primary,
      fontSize: '28px',
    },
    iconButton: {
      '&:hover': {
        background: 'unset',
      },
    },
  };
});

const ActionContainer = (props) => {
  return (
    <Grid
      container
      item
      xs={ 3 }
      justifyContent='flex-end'
      alignItems='center'
      { ...props }
    >
      <div style={ { height: 'fit-content' } }>
        {props.children}
      </div>
    </Grid>
  );
};

const ActionButtons = () => {
  const classes = useStyles();
  const { isMd } = useMediaQueries();

  const iconSize = isMd ? 28 : 22;

  return (
    <Grid container item>
      <ActionContainer>
        <AppTooltip title='E-shop'>
          <IconButton
            className={ classes.iconButton }
            aria-label='e-shop'
          >
            <AiOutlineShop size={ iconSize } className={ classes.icon } />
          </IconButton>
        </AppTooltip>
      </ActionContainer>
      <ActionContainer>
        <AppTooltip title='Calendar'>
          <IconButton className={ classes.iconButton } aria-label='calendar'>
            <FiCalendar size={ iconSize } className={ classes.icon } />
          </IconButton>
        </AppTooltip>
      </ActionContainer>
      <ActionContainer>
        <AppTooltip title='Profile'>
          <IconButton className={ classes.iconButton } aria-label='profile'>
            <BsPerson size={ iconSize } className={ classes.icon } />
          </IconButton>
        </AppTooltip>
      </ActionContainer>
      <ActionContainer>
        <AppTooltip title='Notifications'>
          <IconButton className={ classes.iconButton } aria-label='notifications'>
            <Badge badgeContent={ 3 } color='error'>
              <BiBell size={ iconSize } className={ classes.icon } />
            </Badge>
          </IconButton>
        </AppTooltip>
      </ActionContainer>
    </Grid>
  );
};

export default ActionButtons;
