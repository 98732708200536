import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import React, { useEffect, useState } from 'react';
import { FormHelperText } from '@material-ui/core';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const SimpleRadio = (
  {
    name,
    defaultValue,
    label,
    secondaryLabel = '',
    options,
    error,
    helperText,
    required,
    disabled = false,
  },
) => {
  const [ value, setValue ] = useState('');
  const { t } = useTranslation();

  useEffect(() => {
    setValue(defaultValue);
  }, [ defaultValue ]);

  return (
    <FormControl component='fieldset' disabled={ disabled }>
      <FormLabel
        color='secondary'
        disabled={ disabled }
        component='legend'
      >
        {`${ label }`}

        <span>
          {
            `${ required ? ' *' : '' }`
          }
        </span>

        <div style={ { fontStyle: 'italic' } }>
          {secondaryLabel}
        </div>
      </FormLabel>
      <RadioGroup
        row
        aria-label={ name }
        value={ value }
        onChange={ (e) => {
          setValue(e.target.value);
        } }
        name={ name }
      >
        {
          options.map((option) => {
            return (
              <FormControlLabel
                key={ option.value }
                value={ option.value }
                disabled={ disabled }
                control={ <Radio /> }
                label={ option.label }
              />
            );
          })
        }

      </RadioGroup>
      {error && (
        <FormHelperText margin='dense' error>
          { t(helperText) }
        </FormHelperText>
      ) }
    </FormControl>
  );
};

SimpleRadio.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  defaultValue: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
  ).isRequired,
};

SimpleRadio.defaultProps = {
  defaultValue: '',
  error: false,
  helperText: null,
  required: false,
};

export default SimpleRadio;
