/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import { Typography, Icon, Box, Grid } from '@material-ui/core';
import { AiOutlineInfoCircle } from 'react-icons/ai';
import { useNavigation } from 'NavigationProvider';
import useMediaQueries from 'hooks/media-query-hooks';
import { CustomSlider, NightBox } from '../Common';
import './Common.css';

const useStyles = makeStyles(() => {
  return {
    blurredBox: {
      background: 'linear-gradient(106.1deg, rgba(255, 255, 255, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%)',
      backdropFilter: 'blur(40px)',
      // marginTop: '30px',
      // marginLeft: '30px',
      minWidth: '300px',
      width: '62%',
      color: '#484848',
      padding: '40px 40px 40px 40px',
      borderRadius: '15px',
    },
    textAboveImages: {
      fontFamily: 'Roboto',
      fontStyle: 'normal',
      fontWeight: '700',
      fontSize: '16px',
      lineHeight: '125.19%',
      letterSpacing: '0.03em',
      marginBottom: '24px',

      '@media (max-width:1450px)': {
        marginBottom: '10px',
      },

    },
    adviceSubtitle: {
      minHeight: '43px',
      fontSize: '16px',
      width: 'auto',
      borderRadius: '15px',
      paddingLeft: '10px',
      display: 'flex',
      alignItems: 'center',
      color: 'white',
      margin: '40px 0',
      padding: '5px',
    },
    geneHoverText: {
      background: 'white',
      width: '191px',
      zIndex: '1000',
      position: 'absolute',
      color: 'black',
      right: '0',
      top: '0px',
      padding: '10px 20px',
      borderRadius: '15px',
      marginLeft: 0,
      marginRight: 0,

      '@media (max-width:600px)': {
        // right: '300px',
      },
    },
    gene: {
      width: '191px',
      height: '47px',
      borderRadius: '15px',
      display: 'flex',
      alignItems: 'center',
      padding: '0 20px',

      '@media (max-width:600px)': {
        width: '32%',
        height: '80px',
        padding: '1px',
      },
    },
    boldGeneText: {
      fontWeight: '700',
      fontSize: '14px',
      lineHeight: '137.19%',
    },
    profileValue: {
      width: '190px',
      height: '37px',
      color: 'white',
      borderRadius: '8px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: '600',
      fontSize: '14.45px',
      lineHeight: '17px',
      marginTop: '20.79px',
    },
    profiles: {
      display: 'flex',
      marginTop: '16px',
      // justifyContent: 'center',
      // justifyContent: 'space-around',
      // width: '100%',
      gap: '10px',
      overflowX: 'auto',
      marginLeft: '5px',
      marginRight: '5px',
    },
    valueBox: {
      width: '208px',
      height: '37px',
    },
    bar: {
      width: '208px',
      // height: '66px',
      height: '100%',
    },
    alcoholBar: {
      width: '208px',
      height: '96px',
      // marginTop: '18px',
    },
    resultTitle: {
      fontWeight: '700',
      fontSize: '43.4717px',
      lineHeight: '60px',
    },
    resultText: {
      maxWidth: '30vw',
      fontWeight: '400',
      fontSize: '16px',
      lineHeight: '145.19%',
      marginTop: '34px',
      color: 'black',

      '@media (max-width:600px)': {
        maxWidth: '100%',
      },
    },
    profile: {
      // width: '180px',
      // maxWidth: '230px',
      width: '100%',
      minWidth: '210px',
      minHeight: '65px',
      fontWeight: '400',
      fontSize: '14px',
      lineHeight: '125.19%',
      letterSpacing: '0.03em',
      color: '#545454',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '20px',
      padding: '5px 15px',
      borderRadius: '8px',

      '&:hover': {
        cursor: 'pointer',
      },
    },
    profilesContainer: {
      background: 'white',
      boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
      borderRadius: '15px',
      // padding: '3% 4% 5% 7%',
      marginLeft: '10px',
      marginRight: '10px',
      marginTop: '20px',
      // display: 'flex',
      // flexWrap: 'wrap',
      width: '100%',
    },
    imageContainer: {
      display: 'inline-block',
      maxWidth: 'calc(50% - 5px)',

      '@media (max-width:1450px)': {
        maxWidth: '100%',
        width: '100%',

        display: 'flex',
        justifyContent: 'center',
      },
    },

  };
});

export const Profiles = ({ sensitivityProfiles, activeProfile, activeColor }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { navigateToPage } = useNavigation();

  useEffect(() => {
    const profilesEl = document.getElementById('all-profiles-wellness');
    const activeProfileEl = document.getElementById(activeProfile);
    profilesEl.scrollLeft = activeProfileEl.offsetLeft - profilesEl.offsetLeft;
  }, []);

  return (
    <div className={ classes.profiles } id='all-profiles-wellness'>
      {
        sensitivityProfiles.map((profile) => {
          return (
            <div
              key={ profile.title }
              id={ profile.title }
              className={ classes.profile }
              style={ { border: activeProfile === profile.title ? `solid 1px ${ activeColor }` : '',
                background: activeProfile === profile.title ? `${ activeColor }1A` : '',
                color: activeProfile === profile.title ? activeColor : '',
                fontWeight: activeProfile === profile.title ? 500 : 400 } }
              onClick={ () => { navigateToPage({ pageId: profile.url }); } }
              aria-hidden
            >
              <Icon style={ {
                width: '49px',
                height: '49px',
                borderRadius: '50px',
              } }
              >
                <img
                  src={ profile.icon.small }
                  alt=''
                  style={ { height: '50px', width: '50px' } }
                />
              </Icon>
              {t(profile.title)}
            </div>
          );
        })
      }
    </div>
  );
};

export const Gene = ({ text, background, hasButton = false, hoverText = '', numOfItems = 0 }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const { isSm } = useMediaQueries();

  return (
    <Box
      sx={ { background,
        color: hasButton ? 'white' : '#545454',
        position: 'relative',
        border: hasButton ? '' : '1px solid #545454',
        marginTop: '4px',
        justifyContent: hasButton ? 'space-between' : 'center' } }
      className={ clsx(classes.gene, hasButton ? classes.boldGeneText : '') }
    >
      {t(text)}
      {
        hasButton && (
          <div
            style={ { fontSize: '25px' } }
            className='gene-container'
          >
            <Box
              className={ clsx('gene-hover-text', classes.geneHoverText) }
              style={ {
                height: `calc(51px * ${ numOfItems } + 2px)`,
                left: isSm ? 'Γονίδιο' === text ? '100px' : '-90px' : 0,
              } }
            >
              <div style={ {
                fontWeight: '700',
                fontSize: '16px',
                lineHeight: '125.19%',
                marginBottom: '15px',
              } }
              >
                {t(text)}
              </div>
              <div style={ {
                fontWeight: '400',
                fontSize: '14px',
                lineHeight: '125.19%',
              } }
              >
                {hoverText}
              </div>
            </Box>

            <AiOutlineInfoCircle />
          </div>
        )
      }
    </Box>
  );
};

export const Gene2 = ({ header, itemsText, background, hoverText = '' }) => {
  const [ hovered, setHovered ] = useState(false);
  const { t } = useTranslation();

  return (
    <div style={ { position: 'relative', height: '100%' } }>
      {
        hovered && (
          <div
            style={ { position: 'absolute',
              background: 'white',
              top: 0,
              left: 0,
              width: '100%',
              padding: '10px 20px',
              minHeight: '100%',
              borderRadius: '15px',
              zIndex: 1000 } }
            onMouseLeave={ () => { setHovered(false); } }
          >
            <div style={ {
              fontWeight: '700',
              fontSize: '16px',
              lineHeight: '125.19%',
              marginBottom: '15px',
              color: 'black',
            } }
            >
              {t(header)}
            </div>

            <div style={ {
              color: 'black',
              fontWeight: '400',
              fontSize: '14px',
              lineHeight: '125.19%',
            } }
            >
              {hoverText}
            </div>
          </div>
        )
      }

      <div
        style={ {
          background,
          padding: '10px 20px',
          color: 'white',
          minWidth: '191px',
          borderRadius: '15px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        } }
      >
        <Typography style={ {
          fontWeight: 700,
          fontSize: '14px',
          lineHeight: '137.19%',
        } }
        >
          {t(header)}
        </Typography>

        <div
          onMouseOver={ () => { setHovered(true); } }
          onFocus={ () => { setHovered(true); } }
        >
          <AiOutlineInfoCircle
            style={ { fontSize: '25px' } }
          />
        </div>
      </div>

      {
        itemsText.map((item) => {
          return (
            <div
              key={ item }
              style={ {
                background: 'white',
                border: '1px solid #545454',
                // height: '47px',
                padding: '10px',
                color: 'rgb(84, 84, 84)',
                display: 'flex',
                alignItems: 'center',
                minWidth: '191px',
                justifyContent: 'center',
                marginTop: '4px',
                borderRadius: '15px',
                fontSize: '0.875rem',
                fontFamily: 'Roboto',
                fontWeight: 400,
                lineHeight: 1.43,
              } }
            >
              <Typography>
                {t(item)}
              </Typography>
            </div>
          );
        })
      }
    </div>
  );
};

export const Genes2 = ({ sensitivityProfiles, activeProfile, activeColor }) => {
  const { genes } = sensitivityProfiles.find((category) => {
    return category.title === activeProfile;
  });

  return (
    <Grid container spacing={ 1 }>
      <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
        <Gene2
          header='Γονίδιο'
          itemsText={ genes.map((s) => { return s.gene; }) }
          background={ activeColor }
          hoverText='Στο πεδίο αυτό  αναφέρονται τα γονίδια που σχετίζονται με την  κατηγορία, για τα οποία μελετάται η λειτουργία τους.​'
        />
      </Grid>

      <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
        <Gene2
          hoverText='Σε αυτό το πεδίο  αναφέρεται το σημείο στην αλληλουχία του DNA πάνω στην οποία βρίσκονται οι γενετικές παραλλαγές που μελετώνται στην αντίστοιχη κατηγορία.​'
          itemsText={ genes.map((s) => { return s.SNP; }) }
          header='Γονιδιακός τόπος'
          background={ activeColor }
        />
      </Grid>

      <Grid item xs={ 12 } sm={ 6 } lg={ 4 }>
        <Gene2
          hoverText='Σε αυτό το πεδίο  αναγράφεται η δική σου παραλλαγή ως προς τις βάσεις του DNA στον γονιδιακό τόπο που μελετάται στην αντίστοιχη κατηγορία.​'
          itemsText={ genes.map((s) => { return s.allele; }) }
          header='Η παραλλαγή σου'
          background={ activeColor }
        />
      </Grid>
    </Grid>
  );
};

export const Genes = ({ sensitivityProfiles, activeProfile, activeColor }) => {
  const { genes } = sensitivityProfiles.find((category) => {
    return category.title === activeProfile;
  });

  return (
    <div style={ { minHeight: '150px' } }>
      <div style={ { display: 'flex', gap: '4px', flexWrap: 'wrap' } }>
        <Gene
          hoverText='Στο πεδίο αυτό  αναφέρονται τα γονίδια που σχετίζονται με την  κατηγορία, για τα οποία μελετάται η λειτουργία τους.​'
          text='Γονίδιο'
          background={ activeColor }
          hasButton
          numOfItems={ genes.length + 1 }
        />
        <Gene
          hoverText='Σε αυτό το πεδίο  αναφέρεται το σημείο στην αλληλουχία του DNA πάνω στην οποία βρίσκονται οι γενετικές παραλλαγές που μελετώνται στην αντίστοιχη κατηγορία.​'
          text='Γονιδιακός τόπος'
          background={ activeColor }
          hasButton
          numOfItems={ genes.length + 1 }
        />
        <Gene
          hoverText='Σε αυτό το πεδίο  αναγράφεται η δική σου παραλλαγή ως προς τις βάσεις του DNA στον γονιδιακό τόπο που μελετάται στην αντίστοιχη κατηγορία.​'
          text='Η παραλλαγή σου'
          background={ activeColor }
          hasButton
          numOfItems={ genes.length + 1 }
        />
      </div>

      {
        null != genes && genes.map((gene, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <div key={ i } style={ { display: 'flex', gap: '4px' } }>
              <Gene text={ gene.gene } background='white' />
              <Gene text={ gene.SNP } background='white' />
              <Gene text={ gene.allele } background='white' />
            </div>
          );
        })
      }
    </div>
  );
};

const findFirstSentence = (text, start) => {
  const value = text.indexOf('.');

  if (!(/^\d+$/.test(text[value - 1]))) {
    return value + start;
  }

  return findFirstSentence(text.substring(value + 1), value + 1 + start);
};

const Advice = ({ advice, activeColor, adviceImage }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const isAdviceString = 'string' === typeof advice || advice instanceof String;

  let adviceTitle;
  let actualAdvice;
  let value;

  if (isAdviceString) {
    value = findFirstSentence(advice, 0);
    adviceTitle = advice.substring(0, value + 1);
    actualAdvice = advice.substring(value + 1);
  }

  const { isSm } = useMediaQueries();

  return (
    <div>
      <div style={ { display: 'flex',
        boxShadow: '0px 5.36071px 17.869px rgba(0, 0, 0, 0.0298054)',
        borderRadius: '15px',
        width: '100%',
        background: 'white',
        marginTop: '20px',
        paddingLeft: isSm ? '20px' : '63px',
        paddingRight: '63px',
        color: '#545454' } }
      >
        <div style={ { marginTop: '56px', width: '100%' } }>
          <div style={ {
            fontWeight: '700',
            fontSize: '43.4717px',
            lineHeight: '60px',
            marginBottom: '24px',
          } }
          >
            {t('advice')}
          </div>

          {
            isAdviceString ? (
              <>
                <div className={ classes.adviceSubtitle } style={ { background: activeColor } }>
                  <Typography>
                    {adviceTitle}
                  </Typography>
                </div>

                {
                  '</p>' !== actualAdvice && '' !== actualAdvice && (
                    <div
                      style={ { padding: '10px',
                        background: `${ `${ activeColor }26` }`,
                        borderRadius: '15px',
                        alignItems: 'center',
                        display: 'flex' } }
                    >
                      <Typography>
                        {actualAdvice}
                      </Typography>
                    </div>
                  )
                }
              </>
            ) : (
              <>
                {
                  advice.advice_start && '' !== advice.advice_start && (
                    <div
                      className={ classes.adviceSubtitle }
                      style={ { background: activeColor, margin: 0, marginBottom: '10px' } }
                    >
                      {advice.advice_start}
                    </div>
                  )
                }

                {
                  advice.advice_points_pre && (
                    <div style={ { marginTop: '10px' } }>
                      {
                        advice.advice_points_pre.map((adv) => {
                          return (
                            <Typography key={ adv }>
                              {adv}
                            </Typography>
                          );
                        })
                      }
                    </div>
                  )
                }

                {
                  advice.advice_points && (
                    <ul>
                      {
                        advice.advice_points.map((adv) => {
                          return (
                            <li key={ adv }>
                              <Typography>
                                {adv}
                              </Typography>
                            </li>
                          );
                        })
                      }
                    </ul>
                  )
                }

                {
                  advice.advice_points_post && (
                    <>
                      {
                        advice.advice_points_post.map((adv) => {
                          return (
                            <Typography key={ adv }>
                              {adv}
                            </Typography>
                          );
                        })
                      }
                    </>
                  )
                }

                {
                  advice.advice_end && '' !== advice.advice_end && (
                    <Typography style={ { marginTop: '10px' } }>
                      {
                        advice.advice_end
                      }
                    </Typography>
                  )
                }
              </>
            )
          }

          <div className={ classes.adviceSubtitle } style={ { background: activeColor } }>
            Δες στους πίνακες πόσες φυτικές ίνες χρειάζεσαι, ανάλογα με την ηλικία και το φύλο σου και σε ποιες τροφές θα τις βρεις.
          </div>

          <div style={ { display: 'flex',
            justifyContent: (null != adviceImage && (null == adviceImage.left || null == adviceImage.right)) ? 'center' : 'space-between',
            marginBottom: '50px',
            gap: '5px',
            rowGap: '20px',
            flexWrap: 'wrap' } }
          >
            {
              null != adviceImage && null != adviceImage.left && (
                <div className={ classes.imageContainer }>
                  <div>
                    <Typography className={ classes.textAboveImages }>
                      Συνιστώμενη Ημερήσια Δοσολογία (ΣΗΔ)
                    </Typography>

                    <div style={ { border: '1px solid #545454', display: 'inline-block', borderRadius: '15px', padding: '5px', marginBottom: 'auto' } }>
                      <img
                        src={ adviceImage.left }
                        alt=''
                        style={ {
                        // maxHeight: '350px',
                          width: '100%',
                        } }
                      />
                    </div>
                  </div>
                </div>
              )
            }
            {null != adviceImage && null != adviceImage.right && (
              <div className={ classes.imageContainer }>
                <div>
                  <Typography className={ classes.textAboveImages }>
                    Περιεκτικότητα τροφών φυτικών ινών
                  </Typography>
                  <div style={ { border: '1px solid #545454', display: 'inline-block', borderRadius: '15px', padding: '5px' } }>
                    <img
                      src={ adviceImage.right }
                      alt=''
                      style={ {
                      // width: isSm ? '80vw ' : '23vw',
                        width: '100%',
                      } }
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const BigImageWithText = ({ sensitivityProfiles, activeProfile }) => {
  const classes = useStyles();

  const { description, icon, prefix, title: originalTitle } = sensitivityProfiles.find((category) => {
    return category.title === activeProfile;
  });

  const { t, i18n } = useTranslation();

  const title = t(originalTitle);

  let char = ' ';

  let splitted = title.split(' ');
  if (splitted[0].includes('-')) {
    splitted = title.split('-');
    char = '-';
  }

  if ('ς' === splitted[0][splitted[0].length - 1] && 'τον' === prefix) {
    splitted[0] = splitted[0].slice(0, -1);
  }

  const trimmedTitle = splitted.join(char);

  let fullTitle = `Για ${ prefix } ${ trimmedTitle }`;

  if ('en-US' === i18n.language) {
    fullTitle = `For the ${ trimmedTitle }`;
  }

  return (
    <div style={ { margin: '30px 0',
      background: `url("${ icon.big }")`,
      padding: '30px',
      backgroundSize: 'cover',
      backgroundPosition: 'center' } }
    >
      <div
        className={ classes.blurredBox }
      >
        <div style={ {
          fontWeight: '600',
          fontSize: '40.4717px',
          lineHeight: '125.19%',
          wordWrap: 'break-word',
        } }
        >
          {fullTitle}
        </div>
        <div style={ { marginTop: '30px',
          fontWeight: '400',
          fontSize: '16px',
          lineHeight: '145.19%',
          letterSpacing: '0.03em' } }
        >
          {t(description)}
        </div>
      </div>
    </div>
  );
};

export const Result = (props) => {
  return (
    <ResultInner { ...props } />
  );
};

const ResultInner = ({ resultText, value: _1, advice, sensitivityProfiles, activeColor, activeProfile, adviceImage = undefined }) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const profile = sensitivityProfiles.find((category) => {
    return category.title === activeProfile;
  });

  return (
    <div>
      <div style={ { display: 'flex', flexDirection: 'column' } }>
        <div className={ classes.profilesContainer }>
          <Profiles
            sensitivityProfiles={ sensitivityProfiles }
            activeProfile={ activeProfile }
            activeColor={ activeColor }
          />

          <div style={ {
            padding: '3% 4% 5% 7%',
            display: 'flex',
            flexWrap: 'wrap',
          } }
          >
            <div style={ { display: 'flex', flexDirection: 'column' } }>
              <div style={ { color: '#545454' } }>
                <div className={ classes.resultTitle }>
                  {t('yourResult')}
                </div>
                <div
                  className={ classes.resultText }
                >
                  {resultText}
                </div>
              </div>

              <div style={ { marginTop: '40px' } } />

              {
                'NCAT16' !== profile.ID && 'NCAT19' !== profile.ID && (
                  <Typography style={ {
                    fontFamily: 'Roboto',
                    color: 'black',
                    fontStyle: 'normal',
                    marginBottom: '12px',

                    fontSize: '15px',
                    fontWeight: 400,
                    lineHeight: '145.19%',

                  } }
                  >
                    {t('Προφίλ ευαισθησίας')}
                  </Typography>
                )
              }

              <div style={ { display: 'flex', alignItems: 'center', gap: '32px', maxWidth: '400px' } }>

                {
                  'NCAT16' !== profile.ID && 'NCAT19' !== profile.ID && (
                    <CustomSlider
                      profileResult={ profile.result }
                      sports={ [ 'SCAT1', 'SCAT2', 'SCAT3', 'SCAT4', 'SCAT5', 'SCAT6' ].includes(profile.ID) }
                    />
                  )
                }

                {
                  'NCAT19' === profile.ID && (
                    <div style={ { marginTop: '10px', width: '100%' } }>
                      <NightBox />
                    </div>
                  )
                }

                {
                  'NCAT16' === profile.ID && (
                    <div style={ { display: 'flex', flexDirection: 'column', marginTop: '18px', width: '100%' } }>
                      <CustomSlider
                        label='Επιθυμία για αλκοόλ'
                        profileResult={ profile.result_alcohol_desire }
                      />
                      <CustomSlider
                        label='Μεταβολισμός αλκοόλ'
                        profileResult={ profile.result_alcohol_metabolism }
                        noMargin
                      />
                    </div>
                  )
                }
              </div>
            </div>
            <div style={ { marginTop: 'auto', marginLeft: 'auto' } }>
              <Genes2
                sensitivityProfiles={ sensitivityProfiles }
                activeProfile={ activeProfile }
                activeColor={ activeColor }
              />
            </div>
          </div>
        </div>
        <Advice
          advice={ advice }
          activeColor={ activeColor }
          adviceImage={ adviceImage }
        />
      </div>
      <BigImageWithText
        sensitivityProfiles={ sensitivityProfiles }
        activeProfile={ activeProfile }
      />
    </div>
  );
};

