import { Button, makeStyles } from '@material-ui/core';
import React from 'react';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => {
  return {
    root: {
      fontWeight: 'bold',
      fontSize: '18px',
      fontFamily: 'Roboto',
      lineHeight: '21px',
      letterSpacing: '.04em',
      color: theme.palette.common.white,
      background: theme.palette.secondary.main,
      boxShadow: '0px 4px 4px rgb(0 0 0 / 16%)',
      borderRadius: '40px',
      padding: '20px 54px',
      '&:hover': {
        background: theme.palette.secondary.dark,
      },
    },
  };
});

const PillButton = ({ className, children, ...rest }) => {
  const classes = useStyles();

  return (
    <Button className={ clsx(classes.root, className) } { ...rest }>
      {children}
    </Button>
  );
};

export default PillButton;
